import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Checkbox from '@mui/joy/Checkbox';

import { CloseFullscreen, OpenInFull } from '@mui/icons-material';
import { Button } from '@mui/joy';
import BundangWindowSvg from '../etc/bundangwindowsvg';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fil } from 'date-fns/locale';
import { englishLocationToKorean } from '../../../data/locationFuntion';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { Backdrop, CircularProgress } from '@mui/material';
import { rest } from 'lodash';

const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .open': {
                    color: "blue"
                },
                '& .close': {
                    color: "red"
                },
                '& .error': {
                    color: "red"
                },
            },
        };
    },
    { defaultTheme },
);

const gangnam = {
    restRoomNumbers: 0,
    studyRoomNumbers: 6,
}

const bundang = {
    restRoomNumbers: 5,
    studyRoomNumbers: 7,
}

const songpa = {
    restRoomNumbers: 0,
    studyRoomNumbers: 2,
}

const daechi = {
    restRoomNumbers: 0,
    studyRoomNumbers: 2,
}

const daechi2 = {
    restRoomNumbers: 0,
    studyRoomNumbers: 2,
}

const daechi3 = {
    restRoomNumbers: 0,
    studyRoomNumbers: 2,
}

const locationWindowInfo: any = {
    gangnam,
    bundang,
    songpa,
    daechi,
    daechi2,
    daechi3
}

const IotWindow: React.FC<any> = (props) => {

    const classes = useStyles2();

    const [currentStatus, setCurrentStatus] = useState<any>([]);
    const [checkedArray, setCheckedArray] = useState<boolean[]>([]);
    const [targetLocationWindowInfo, setTargetLocationWindowInfo] = useState<any>({
        restRoomNumbers: 0,
        studyRoomNumbers: 0
    });
    const [totalNumbers, setTotalNumbers] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<any>([]);
    const [columns, setColumns] = useState<any>([
        { field: "location", headerName: "지점", width: 150 },
        { field: "windowNumber", headerName: "창문 번호", width: 600 },
        { field: "direction", headerName: "방향", width: 100 },
        { field: "teacherName", headerName: "작동자", width: 100 },
        { field: "createdAt", headerName: "작동 시간", width: 150 },
    ]);
    const [update, setUpdate] = useState(0);

    const notify1 = () => toast.success("전송 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify2 = () => toast.error("전송 실패!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    const getOpenLog = async () => {

        try {

            const location = props.location;

            const response = await fetch("https://peetsunbae.com/dashboard/iot/getwindowopenlog", {
                method: "POST",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    location
                })
            });

            const result = await response.json();

            if (result.message === "success") {
                

                const data = result.data;

                const filteredData: any = [];

                data.forEach((each: any) => {

                    var isThere = false;

                    filteredData.forEach((filtered: any) => {
                        if (filtered.clientTime === each.clientTime) {
                            isThere = true;

                            filtered.whichWindow.push({
                                windowNumber: each.whichWindow,
                                isSuccess: each.isSuccess
                            });
                        }
                    });

                    if (!isThere) {

                        const time = new Date(+each.clientTime);
                        const dateString = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`;

                        const oneRow = {
                            name: each.name,
                            location: englishLocationToKorean(each.location),
                            direction: each.direction,
                            whichWindow: [{
                                windowNumber: each.whichWindow,
                                isSuccess: each.isSuccess,
                            }],
                            createdAt: dateString,
                            clientTime: each.clientTime
                        }

                        filteredData.push(oneRow);

                    }

                })

                

                const newRows: any = [];

                filteredData.forEach((each: any, index: number) => {

                    const oneRow: any = {};
                    oneRow.id = index;
                    oneRow.location = each.location;

                    var message = "";

                    each.whichWindow.sort((a: any, b: any) => {
                        return a.windowNumber - b.windowNumber;
                    });

                    each.whichWindow.forEach((window: any, index: number) => {

                        message += `${window.windowNumber}번 - ${window.isSuccess ? "성공" : "실패"}`;

                        if (index !== each.whichWindow.length - 1) {
                            message += " / ";
                        }

                    });

                    oneRow.windowNumber = message;
                    oneRow.direction = each.direction === "open" ? "열기" : "닫기";
                    oneRow.teacherName = each.name;
                    oneRow.createdAt = each.createdAt;

                    newRows.push(oneRow);

                });

                setRows([...newRows]);
            }

        } catch (e) {
            
        }

    }

    useEffect(() => {

        getOpenLog();

    }, [update]);

    useEffect(() => {

        if (!props.location) return;

        const location = props.location;
        const locationInfo = locationWindowInfo[location];

        if (!locationInfo) return;

        const totalNumbers = locationInfo.restRoomNumbers + locationInfo.studyRoomNumbers;

        const newCheckedArray = new Array(totalNumbers).fill(false);

        setCheckedArray([...newCheckedArray]);
        setTargetLocationWindowInfo(locationInfo);

    }, [props.location]);

    useEffect(() => {

        var count = 0;

        checkedArray.forEach((value) => {
            if (value) {
                count++;
            }
        });

        setTotalNumbers(count);


    }, [checkedArray]);

    useEffect(() => {

        getCurrentStatus();

    }, [targetLocationWindowInfo]);

    useEffect(() => {

        

    }, [currentStatus]);

    const getCurrentStatus = async () => {

        try {

            var windowsNumber = targetLocationWindowInfo.restRoomNumbers + targetLocationWindowInfo.studyRoomNumbers;

            if (windowsNumber === 0) return;

            const location = props.location;

            const ports = [];

            for (var i = 0; i < windowsNumber; i++) {
                const port = 10001 + i;
                ports.push(port);
            }

            const fetchPromises = ports.map((port) => {
                return fetch("https://peetsunbae.com/dashboard/iot/currentwindowstatus", {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        location,
                        port
                    })
                }).then((response) => response.json())
                    .then((result) => {
                        if (result.message === "success") {

                            setCurrentStatus((prev: any) => {

                                const newStatus = [...prev];
                                newStatus[port - 10001] = true;
                                return newStatus;

                            });
                        }

                        return result;
                    })
                    .catch((error: any) => {
                        setCurrentStatus((prev: any) => {
                            const newStatus = [...prev];
                            newStatus[port - 10001] = "error";
                            return newStatus;
                        });
                        throw error;
                    });
            });

            const settledResults = await Promise.allSettled(fetchPromises);
            


        } catch (e) {
            
        }

    }

    const submit = async (type: string) => {

        const location = props.location;

        if (!location) return;
        if (!type) return;

        //checkedArray가 모두 false인 경우
        if (checkedArray.every((value) => !value)) return;

        setLoading(true);

        const ports: any = [];

        checkedArray.forEach((value, index) => {
            if (value) {
                ports.push(10001 + index);
            }
        });

        const date = new Date();
        const time = date.getTime();

        const fetchPromises = ports.map((port: number) => {

            const data = {
                location,
                type,
                port,
                time
            }

            return fetch("https://peetsunbae.com/dashboard/iot/controlwindow", {
                method: "POST",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }).then((response) => response.json())
                .then((result) => {
                    if (result.message === "success") {
                        return result;
                    } else {
                        throw result;
                    }
                })
                .catch((error) => {
                    throw error;
                });

        });

        try {

            const settledResults = await Promise.allSettled(fetchPromises);

            

            setLoading(false);

            notify1();

            setUpdate((prev) => prev + 1);

        } catch (e) {
            

            setLoading(false);

            notify2();
        }



    }

    return (
        <div>
            <div style={{
                display: "flex",
            }}>
                {
                    (targetLocationWindowInfo && targetLocationWindowInfo.restRoomNumbers > 0) &&
                    <div style={{
                        marginRight: "40px"
                    }}>
                        <div>
                            <Checkbox
                                label="휴게실 창문"
                                checked={checkedArray.slice(0, targetLocationWindowInfo.restRoomNumbers).every((value) => value)}
                                onChange={(event) => {
                                    const newCheckedArray = [...checkedArray];
                                    newCheckedArray.fill(event.target.checked, 0, targetLocationWindowInfo.restRoomNumbers);
                                    setCheckedArray([...newCheckedArray]);
                                }}
                                size='lg'
                                indeterminate={checkedArray.slice(0, targetLocationWindowInfo.restRoomNumbers).some((value) => value) && !checkedArray.slice(0, targetLocationWindowInfo.restRoomNumbers).every((value) => value)}
                            />
                        </div>
                        <div style={{
                            marginLeft: "20px",
                            marginTop: "12px",
                        }}>
                            {
                                targetLocationWindowInfo &&
                                new Array(targetLocationWindowInfo.restRoomNumbers).fill(0).map((value, index) => {
                                    return (
                                        <div style={{
                                            marginBottom: "4px",
                                            display: "flex",
                                            alignItems: "center",
                                            width: "105px",
                                            justifyContent: "space-between"
                                        }}>
                                            <Checkbox
                                                checked={checkedArray[index]}
                                                onChange={(event) => {
                                                    const newCheckedArray = [...checkedArray];
                                                    newCheckedArray[index] = event.target.checked;
                                                    setCheckedArray([...newCheckedArray]);
                                                }}
                                                label={`창문 ${index + 1}`}
                                                size='lg'
                                            />
                                            <div
                                                style={{
                                                    width: "6px",
                                                    height: "6px",
                                                    backgroundColor: currentStatus[index] ? "blue" : "red",
                                                    borderRadius: "50%",
                                                }}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                
                <div>
                    <div>
                        <Checkbox
                            label="자습실 창문"
                            checked={checkedArray.slice(targetLocationWindowInfo.restRoomNumbers, targetLocationWindowInfo.restRoomNumbers + targetLocationWindowInfo.studyRoomNumbers).every((value) => value)}
                            onChange={(event) => {
                                const newCheckedArray = [...checkedArray];
                                newCheckedArray.fill(event.target.checked, targetLocationWindowInfo.restRoomNumbers, targetLocationWindowInfo.restRoomNumbers + targetLocationWindowInfo.studyRoomNumbers);
                                setCheckedArray([...newCheckedArray]);
                            }}
                            size='lg'
                            indeterminate={checkedArray.slice(targetLocationWindowInfo.restRoomNumbers, targetLocationWindowInfo.restRoomNumbers + targetLocationWindowInfo.studyRoomNumbers).some((value) => value) && !checkedArray.slice(targetLocationWindowInfo.restRoomNumbers, targetLocationWindowInfo.restRoomNumbers + targetLocationWindowInfo.studyRoomNumbers).every((value) => value)}
                        />

                    </div>
                    <div style={{
                        marginLeft: "20px",
                        marginTop: "12px",
                    }}>
                        {
                            targetLocationWindowInfo &&
                            new Array(targetLocationWindowInfo.studyRoomNumbers).fill(0).map((value, index) => {

                                var indexNumber = index + targetLocationWindowInfo.restRoomNumbers;

                                return (
                                    <div style={{
                                        marginBottom: "4px",
                                        display: "flex",
                                        alignItems: "center",
                                        width: "105px",
                                        justifyContent: "space-between"
                                    }}>
                                        <Checkbox
                                            checked={checkedArray[indexNumber]}
                                            onChange={(event) => {
                                                const newCheckedArray = [...checkedArray];
                                                newCheckedArray[indexNumber] = event.target.checked;
                                                setCheckedArray([...newCheckedArray]);
                                            }}
                                            label={`창문 ${indexNumber + 1}`}
                                            size='lg'
                                        />
                                        <div
                                            style={{
                                                width: "6px",
                                                height: "6px",
                                                backgroundColor: currentStatus[index] ? "blue" : "red",
                                                borderRadius: "50%",
                                            }}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div>
                <div style={{
                    marginTop: "30px"
                }}>
                    <Button startDecorator={<OpenInFull />} variant="soft" color="primary" onClick={() => submit("open")}
                        disabled={checkedArray.every((value) => !value)}
                    >창문 {totalNumbers}개 열기</Button>
                    <Button
                        disabled={checkedArray.every((value) => !value)}
                        style={{ marginLeft: "28px" }} startDecorator={<CloseFullscreen />} variant="soft" color="danger" onClick={() => submit("close")}>창문 {totalNumbers}개 닫기</Button>
                </div>
            </div>

            <div style={{
                display: "flex",
                marginTop: "20px"
            }}>
                {
                    [{
                        text: "정상",
                        color: "blue"
                    },
                    {
                        text: "에러",
                        color: "red"
                    }
                    ].map((value) => {
                        return (
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "10px",
                                fontSize: "14px",
                                marginRight: "20px"
                            }}>
                                <div
                                    style={{
                                        width: "6px",
                                        height: "6px",
                                        backgroundColor: value.color,
                                        borderRadius: "50%",
                                        marginRight: "6px"
                                    }}
                                />
                                <div>
                                    {value.text}
                                </div>
                            </div>
                        )
                    }
                    )
                }
            </div>
            <div>
                <div className={classes.root} style={{ height: 300, width: '1200px', backgroundColor: "white", marginTop: "32px" }}>
                    <DataGridPremium
                        rows={rows}
                        columns={columns}
                        density='compact'
                        getCellClassName={(params) => {
                            return ""
                        }}
                        getRowClassName={(params) => {

                            if(params.row.windowNumber && params.row.windowNumber.includes("실패")) {
                                return "error";
                            }

                            return ""
                        }}
                    />
                </div>
            </div>

            {/* <div>
                <BundangWindowSvg />
            </div> */}

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


        </div>
    )

}

export default IotWindow;