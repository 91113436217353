import React, { useState, useEffect } from 'react';
import styles from "../../money.module.css";
import { Button, IconButton, Option, Radio, RadioGroup, Select, Modal, Sheet } from '@mui/joy';
import { DataGridPremium, GridRowModel, GridToolbar } from '@mui/x-data-grid-premium';
import useStyles2 from '../../etc/useStyles';
import { Backdrop, CircularProgress, FormControl, MenuItem, OutlinedInput, Switch, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import ModalHeaderForMoney from '../../etc/modalheader';
import { Category, ControlPoint, ControlPointSharp, DeleteOutline, Filter, PhotoOutlined } from '@mui/icons-material';
import ModalBottomForMoney from '../../etc/modalbottom';
import { getFetch, postFetch } from '../../../../../../fetch/fetch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { id } from 'date-fns/locale';
import { set } from 'lodash';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import koLocale from 'date-fns/locale/ko'
import { ECHILD } from 'constants';
import { dateToDateString } from '../../../etc/etcfunctions';
import ImageModal from '../../../../use/imagemodal';
import ImageIcon from '@mui/icons-material/Image';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
};

const MenuTable: React.FC<any> = (props) => {

    const classes = useStyles2();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState<any>([]);
    const [columns, setColumns] = useState([
        { field: 'category', headerName: '카테고리', width: 90 },
        { field : "paymentKind", headerName : "결제수단", width : 100},
        { field: 'menuName', headerName: '메뉴 이름', width: 130 },
        { field: 'price', headerName: '가격', width: 120 },
        { field: 'startDate', headerName: '메뉴결제시작', width: 180 },
        { field: 'endDate', headerName: '메뉴결제종료', width: 180 },
        { field: 'subDescription1', headerName: '메뉴설명1', width: 200, 
            editable: true,
         },
        { field: 'subDescription2', headerName: '메뉴설명2', width: 200, 
            editable: true,
         },
        { field: 'addedOption', headerName: '추가된 옵션', width: 200 },
        {
            field: "menuImg", headerName: "첨부사진", width: 150,
            renderCell: (params: any) => {

                if (params.value) {
                    return (
                        <div
                            onClick={(e: any) => {
                                setImageSrc("https://peetsunbae.com" + params.value);
                                setOpen3(true);
                            }}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                                cursor: "pointer"
                            }}>
                            <ImageIcon color="success" />
                        </div>
                    )

                } else {
                    return (
                        <div>
                        </div>
                    )
                }
            }
        },
        {
            field: "memo", headerName: "메모", width: 150,
            editable: true
        },
        { field: "name", headerName: "작성자", width: 150 },
        { field: "createdAt", headerName: "작성일", width: 150 }
    ]);
    const [modalType, setModalType] = useState("");
    const [menuKind, setMenuKind] = useState("contents");
    const [menuName, setMenuName] = useState("");
    const [optionItems, setOptionItems] = useState([""]);
    const [isMandantory, setIsMandantory] = useState(false);
    const [maxNumber, setMaxNumber] = useState(1);
    const [disabled, setDisabled] = useState(true);
    const [editDisabled, setEditDisabled] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [update, setUpdate] = useState(0);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [alignment, setAlignment] = useState('total');
    const [price, setPrice] = useState<any>();
    const [paymentKind, setPaymentKind] = useState<any>("sunbae");

    const [year, setYear] = useState<any>();
    const [month, setMonth] = useState<any>();
    const [subDescription1, setSubDescription1] = useState<any>("");
    const [subDescription2, setSubDescription2] = useState<any>("");

    const [uploadFile, setUploadFile] = useState<any>();
    const [uploadFileName, setUploadFileName] = useState<any>();

    const [optionList, setOptionList] = useState<any>([]);
    const [currentOptions, setCurrentOptions] = useState<any>([]);

    const [startDateTime, setStartDateTime] = useState<any>(new Date());
    const [endDateTime, setEndDateTime] = useState<any>();
    const [open3, setOpen3] = useState(false);
    const [imageSrc, setImageSrc] = useState("");

    const notify1 = () => toast.success("전송 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const notify2 = () => toast.error("실패했습니다.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    function convertCurrencyStringToNumber(currencyString: string) {
        // 1. 숫자, 소수점, 음수 기호(-)를 제외한 모든 문자를 제거합니다.
        // 2. parseFloat(또는 Number)로 실수 형태로 변환합니다.
        const numericString = currencyString.replace(/[^0-9.-]+/g, '');
        return parseFloat(numericString);
    }




    useEffect(() => {

        setPrice("");
        setSubDescription1("");
        setSubDescription2("");
        setUploadFile(null);
        setUploadFileName("");
        setOptionList([]);
        setStartDateTime(new Date());
        setEndDateTime(new Date());

        if (menuKind === "contents") {
            setMenuName("");
            setYear("");
            setMonth("");
            return;
        }

    }, [menuKind]);

    useEffect(() => {

        if (menuKind === "contents") {
            setMenuName("");
            return;
        }

        var menuName = `${year}년 ${month}월 등록비`;
        setMenuName(menuName);



    }, [year, month, menuKind]);

    useEffect(() => {

        var newDisabled = false;

        if (menuKind === "register") {

            if (!year || !month || !subDescription1 || !subDescription2 || !uploadFile || !startDateTime || !endDateTime) {
                newDisabled = true;
            }

        }

        if (menuKind === "contents") {

            if (!menuName || !price || !subDescription1 || !subDescription2 || !uploadFile || !startDateTime || !endDateTime) {
                newDisabled = true;
            }

        }

        if (startDateTime && endDateTime) {
            const startTime = startDateTime.getTime();
            const endTime = endDateTime.getTime();

            if (startTime >= endTime) {
                newDisabled = true;
            }
        }


        setDisabled(newDisabled);


    }, [menuKind, menuName, year, month, price, subDescription1, subDescription2, uploadFile, uploadFileName, optionList, startDateTime, endDateTime, open]);

    useEffect(() => {

        if (selectedRow) {
            setEditDisabled(false);
        } else {
            setEditDisabled(true);
        }

    }, [selectedRow]);

    useEffect(() => {

        getOptions();

    }, []);

    useEffect(() => {

        getMenus();

    }, [update]);

    const getMenus = async () => {

        try {

            setTableLoading(true);

            const result: any = await getFetch("/dashboard/payments/menu/get");

            if (result.message !== "success") {
                setTableLoading(false);
                return;
            }



            const data = result.data;

            const newRows: any = [];

            data.forEach((each: any) => {

                const oneRow: any = {};
                oneRow.id = each.id;
                oneRow.category = each.menuKind === "register" ? "등록비" : each.menuKind === "contents" ? "컨텐츠" : "";
                oneRow.menuName = each.menuName;
                oneRow.price = each.price ? each.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원" : 0 + "원";
                oneRow.paymentKind = each.paymentKind === "sunbae" ? "선배페이" : "카드결제";

                const startDate = new Date(each.startDateTime);
                const endDate = new Date(each.endDateTime);

                const startDateString = dateToDateString(startDate, true);
                const endDateString = dateToDateString(endDate, true);

                oneRow.startDate = startDateString;
                oneRow.endDate = endDateString;
                oneRow.subDescription1 = each.subDescription1;
                oneRow.subDescription2 = each.subDescription2;
                oneRow.addedOption = each.options.map((item: any) => item.optionName).join(" / ");
                oneRow.menuImg = each.src;
                oneRow.memo = each.memo;
                oneRow.name = each.name;

                const createdAt = new Date(each.createdAt);
                const createdAtString = dateToDateString(createdAt);
                oneRow.createdAt = createdAtString;

                newRows.push(oneRow);

            })

            setRows([...newRows]);

            setTableLoading(false);

        } catch (e) {

        }

    }


    const getOptions = async () => {

        try {

            const result: any = await getFetch("/dashboard/payments/option/get");



            const data = result.data;

            const newCurrentOptions: any = [];

            data.forEach((item: any) => {

                const oneRow: any = {
                    id: item.id,
                    name: item.optionName,
                }

                newCurrentOptions.push(oneRow);

            });

            setCurrentOptions([...newCurrentOptions]);

        } catch (e) {

        }

    }

    const handleClose = () => {
        setOpen(false);
        setModalType("");
        setMonth("");
        setYear("");
        setMenuName("");
        setEditDisabled(true);
        setMaxNumber(1);
        setMenuKind("");
        setPrice("");
        setSubDescription1("");
        setSubDescription2("");
        setUploadFile(null);
        setUploadFileName("");
        setOptionList([]);
        setStartDateTime(new Date());
        setEndDateTime(new Date());
        setPaymentKind("sunbae");
    }

    const handleOpen = (type: string) => {

        if (type === "edit") {

            if (!selectedRow) {
                return;
            }

            const row = selectedRow.row;

            console.log(row);

            setMenuName(row.menuName);

            const priceNumber = row.price ? convertCurrencyStringToNumber(row.price) : 0;
            setPrice(priceNumber);
            setSubDescription1(row.subDescription1);
            setSubDescription2(row.subDescription2);
            setUploadFileName(row.menuImg);


        }

        setMenuKind("contents");
        setModalType(type);
        setOpen(true);

    }

    const handleCreateOption = () => {
        const newOptionItems = optionItems;
        newOptionItems.push("");
        setOptionItems([...newOptionItems]);
    }

    const handleDeleteOption = (index: number) => {
        const newOptionItems = optionItems;

        if (newOptionItems.length === 1) {
            return;
        }

        newOptionItems.splice(index, 1);
        setOptionItems([...newOptionItems]);
    }



    const submitNewMenu = async (e: any) => {

        try {

            e.preventDefault();

            const formData = new FormData();


            const data = {
                menuKind,
                menuName: menuKind === "register" ? `${year}, ${month}` : menuName,
                price: price ? Number(price.replace(/,/g, '')) : 0,
                subDescription1,
                subDescription2,
                optionList,
                startDateTime: startDateTime.getTime(),
                endDateTime: endDateTime.getTime(),
                paymentKind,
            }



            handleClose();

            if (menuKind === "register") {
                if (!year || !month) {

                    return;
                }
            }

            if (menuKind === "contents") {
                if (!menuName || !price) {

                    return;
                }
            }

            if (menuKind !== "register" && menuKind !== "contents") {

                return;
            }

            if (!subDescription1 || !subDescription2 || !uploadFile || !startDateTime || !endDateTime) {

                return;
            }

            if (startDateTime.getTime() >= endDateTime.getTime()) {

                return;
            }

            setLoading(true);

            formData.append("data", JSON.stringify(data));

            formData.append("file", uploadFile);



            const response: any = await fetch("https://peetsunbae.com/dashboard/payments/menu/create", {
                method: "POST",
                body: formData,
                credentials: "include"
            });

            const result = await response.json();

            setLoading(false);

            setUpdate(Math.random());



            if (result.message === "success") {
                notify1();
            } else {
                notify2();
            }

        } catch (e) {

            notify2();
        }


    }

    const submitEditMenu = async () => {

        try {

            if (!selectedRow) {
                return;
            }

            const id = selectedRow.id;

            const data = {
                MenuName: menuName,
                optionItems: optionItems,
                isMandantory: isMandantory,
                maxNumber: maxNumber,
                id
            }

            handleClose();

            if (!menuName || optionItems.length === 0) {

                return;
            }



            setLoading(true);

            const result: any = await postFetch("/dashboard/payments/option/update", data);

            setLoading(false);

            setUpdate(Math.random());



            if (result.message === "success") {
                notify1();
            } else {
                notify2();
            }

        } catch (e) {

        }

    }

    const submitDeleteOption = async () => {

        try {

            if (!selectedRow) {
                return;
            }

            if (!window.confirm("정말 삭제하시겠습니까?")) {
                return;
            }

            const id = selectedRow.id;

            if (!id) {
                return;
            }

            setLoading(true);

            const result: any = await postFetch("/dashboard/payments/menu/delete", { id });

            setLoading(false);

            if (result.message === "success") {
                setUpdate(Math.random());
                notify1();
            } else {
                notify2();
            }

        } catch (e) {

        }

    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {


        setAlignment(newValue);
    };

    //답변에 사진 올리기 기능-----------------------------------------------------------
    const fileOnChange = (event: any) => {
        if (event.target.files.length > 0) {
            if (event.target) {
                setUploadFile(event.target.files[0]);
                setUploadFileName(event.target.files[0].name);
            }
        }
    }

    const handlePlusOption = () => {
    }

    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {
            var field = "";

            console.log("letsmemo");

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const id = newRow.id;
            const value = newRow[field] ? newRow[field] : "";

            if (!field) {
                return newRow;
            }

            const data = {
                id,
                value,
                field
            }

            fetch("https://peetsunbae.com/dashboard/payments/menu/memo", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify(data)
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        if (result.message === "success") {
                            setUpdate(Math.random());
                        }
                    })
            })

            return newRow;

        }, []
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        alert(error.message);
    }, []);



    return (
        <div>
            <div className={styles.main}
            style={{
                width : "95%"
            }}
            >

                {/* <div style={{
                    marginBottom: "1.2rem"
                }}>
                    <ToggleButtonGroup value={alignment} onChange={handleChange} exclusive
                        color='primary'
                    >
                        <ToggleButton value="total">전체</ToggleButton>
                        <ToggleButton value="register">등록비</ToggleButton>
                        <ToggleButton value="contents">컨텐츠</ToggleButton>
                    </ToggleButtonGroup>
                </div> */}

                <div className={styles.btnLine}>
                    <div>
                        <TextField id="standard-basic" variant="standard" placeholder='메뉴이름 검색' />
                    </div>
                    <div className={styles.btns}>
                        <Button variant='soft' color='neutral' size='sm' sx={{ marginRight: "1rem" }} onClick={() => { handleOpen("create") }}>메뉴 생성</Button>
                        {/* <Button variant='soft' color='neutral' size='sm' sx={{ marginRight: "1rem" }}
                            disabled={editDisabled} onClick={() => { handleOpen("edit") }}
                        >수정하기</Button> */}
                        <Button variant='soft' color='danger' size='sm'
                            disabled={editDisabled} onClick={submitDeleteOption}
                        >삭제하기</Button>
                    </div>
                </div>

                <div className={classes.root} style={{ height: 500, width: '100%', backgroundColor: "white", marginTop: "24px" }}>
                    <DataGridPremium loading={tableLoading} rows={rows} columns={columns}
                        components={{ Toolbar: GridToolbar }}
                        density='compact'
                        hideFooter
                        onRowClick={(row) => {
                            setSelectedRow(row);
                        }}
                        processRowUpdate={handleEditCommit}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                    />
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Sheet sx={style}>
                    <ModalHeaderForMoney title={modalType === "create" ? "신규 메뉴 생성" : modalType === "edit" ? "메뉴 수정" : ""} />
                    <div className={styles.modalBody}>
                        <div style={{ height: "1rem" }}></div>
                        <div>
                            <RadioGroup value={menuKind} onChange={(e) => { setMenuKind(e.target.value) }} orientation='horizontal'>
                                {/* <Radio value="register" label={<span className={styles.radioText}>등록비</span>} /> */}
                                <Radio value="contents" label={<span className={styles.radioText}>컨텐츠</span>} />
                            </RadioGroup>
                        </div>
                        <div>
                            <div className={styles.title}>
                                메뉴 이름
                            </div>
                            {
                                menuKind === "register" ?
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        <div>등록비</div>
                                        <div>
                                            <FormControl variant='outlined'>
                                                <OutlinedInput
                                                    value={year} onChange={(e) => {

                                                        var value = e.target.value;

                                                        if (value.length > 4) {
                                                            return;
                                                        }
                                                        //0-9만 입력 가능
                                                        //value에 0-9가 아닌 문자가 포함되어 있으면 제거
                                                        value = value.replace(/[^0-9]/g, '');
                                                        setYear(Number(value));
                                                    }}
                                                    size='small'
                                                    endAdornment={<div>년</div>}
                                                    sx={{ width: "100px", marginLeft: "1rem", marginRight: "1rem" }}
                                                    placeholder='2024'
                                                />
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormControl variant='outlined'>
                                                <OutlinedInput
                                                    value={month} onChange={(e) => {
                                                        var value = e.target.value;

                                                        if (value.length > 2) {
                                                            return;
                                                        }

                                                        if (+value > 12) {
                                                            return;
                                                        }
                                                        //0-9만 입력 가능
                                                        //value에 0-9가 아닌 문자가 포함되어 있으면 제거
                                                        value = value.replace(/[^0-9]/g, '');
                                                        setMonth(Number(value));
                                                    }}
                                                    size='small'
                                                    endAdornment={<div>월</div>}
                                                    sx={{ width: "100px" }}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    :
                                    <TextField variant="outlined" fullWidth
                                        value={menuName} onChange={(e) => { setMenuName(e.target.value) }}
                                        size='small'
                                    />
                            }
                        </div>
                        <div>
                            <div className={styles.title}>
                                가격
                            </div>
                            <div>
                                <FormControl variant='outlined'
                                    fullWidth
                                >
                                    <OutlinedInput
                                        value={price} onChange={(e) => {



                                            if (menuKind === "register") {

                                                e.target.value = "";
                                            }

                                            var value = e.target.value;
                                            value = value.replace(/[^0-9]/g, '');
                                            //value를 3자리마다 콤마를 찍어줌
                                            value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                            setPrice(value);
                                        }}
                                        size='small'
                                        endAdornment={<div>원</div>}
                                        fullWidth
                                        placeholder={
                                            menuKind === "register" ? "학생 개별 가격" : ""
                                        }
                                        disabled={menuKind === "register" ? true : false}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div>
                            <div className={styles.title}>
                                메뉴설명1
                            </div>
                            <div>
                                <TextField variant="outlined" fullWidth size="small" value={subDescription1} onChange={(e) => { setSubDescription1(e.target.value) }}
                                    placeholder='계좌이체, 현금결제 등은 데스크 문의'
                                />
                            </div>
                        </div>
                        <div>
                            <div className={styles.title}>
                                메뉴설명2
                            </div>
                            <div>
                                <TextField variant="outlined" fullWidth size="small" value={subDescription2} onChange={(e) => { setSubDescription2(e.target.value) }}
                                    placeholder='시행일 : 7월 24일 화요일'
                                />
                            </div>
                        </div>
                        {
                            menuKind === "contents" ?
                                <div>
                                    <div className={styles.title}>
                                        옵션 선택
                                    </div>
                                    <div style={{ height: "8px" }}></div>
                                    {
                                        optionList.map((item: any, index: any) => {
                                            return (
                                                <div key={index} className={styles.optionItem}>
                                                    <div>
                                                        옵션 {index + 1}
                                                    </div>
                                                    <div className={styles.optionSelect}>
                                                        <div>
                                                            <Select
                                                                sx={{ width: "120px" }}
                                                                value={item} onChange={(e, newValue: any) => {
                                                                    const newOptionList = optionList;
                                                                    newOptionList[index] = newValue;
                                                                    setOptionList([...newOptionList]);
                                                                }}>
                                                                <Option value={""}>-</Option>
                                                                {
                                                                    (currentOptions && currentOptions.length > 0) && currentOptions.map((option: any, index: any) => {
                                                                        return (
                                                                            <Option key={index} value={option.id}>{option.name}</Option>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </div>
                                                        <div className={styles.deleteDiv}>
                                                            <DeleteOutline onClick={() => {
                                                                const newOptionList = optionList;
                                                                newOptionList.splice(index, 1);
                                                                setOptionList([...newOptionList]);
                                                            }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className={styles.clickPlus} onClick={(e) => {
                                        const newOptionList = optionList;
                                        newOptionList.push("");
                                        setOptionList([...newOptionList]);
                                    }}>
                                        <div className={styles.plusIcon}>
                                            <ControlPointSharp className={styles.plusIcon} onClick={handlePlusOption} />
                                        </div>
                                        <div className={styles.plusIconText}>
                                            옵션 추가하기
                                        </div>
                                    </div>
                                </div>
                                :
                                ""

                        }

                        <div>
                            <div className={styles.title}>
                                사진 등록
                            </div>
                            <label htmlFor='file'>
                                <div className={styles.imageUploadDiv} >
                                    <div style={{
                                        marginRight: "0.8rem"
                                    }}>
                                        <PhotoOutlined />
                                    </div>
                                    <div>
                                        {
                                            uploadFileName ? <span>{uploadFileName}</span> : "가로 세로 같은 사이즈 사진"
                                        }
                                    </div>
                                </div>
                            </label>
                            <input onChange={(e) => fileOnChange(e)} type="file" name="file" id="file" accept="image/*" hidden />
                        </div>
                        <div>
                            <div className={styles.title}>
                                결제 기한
                            </div>
                            <div style={{ height: "0.6rem" }}></div>
                            <div>
                                <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        value={startDateTime}
                                        onChange={(e) => { setStartDateTime(e) }}
                                        renderInput={(params) => <TextField {...params} />}
                                        label="결제 시작"
                                    />
                                </LocalizationProvider>
                            </div>
                            <div style={{ marginTop: "1rem" }}>
                                <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        value={endDateTime}
                                        onChange={(e) => { setEndDateTime(e) }}
                                        renderInput={(params) => <TextField {...params} />}
                                        label="결제 종료"
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div>
                            <div className={styles.title}>
                                결제 수단
                            </div>
                            <div>
                                <div>
                                    <RadioGroup value={paymentKind} onChange={(e) => { setPaymentKind(e.target.value) }} orientation='horizontal'>
                                        <Radio value="sunbae" label={<span className={styles.radioText}>선배페이 (앱결제)</span>} />
                                        <Radio value="card" label={<span className={styles.radioText}>카드결제 (데스크)</span>} />
                                    </RadioGroup>
                                </div>
                            </div>
                        </div>
                        <div className={styles.justHeight2}>

                        </div>
                    </div>
                    <ModalBottomForMoney disabled={disabled} buttonName={modalType === "create" ? "생성하기" : modalType === "edit" ? "수정하기" : ""} handleClick={modalType === "create" ? submitNewMenu : modalType === "edit" ? submitEditMenu : () => { }} />
                </Sheet>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <ImageModal
                open={open3}
                handleClose={() => { setOpen3(false) }}
                src={imageSrc}
            />

        </div>
    )

}

export default MenuTable;