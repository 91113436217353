import React, { useEffect, useState, useRef } from "react";
import { IonModal } from "@ionic/react";
import InnerHeaderForModal from "./innerheaderformodal";
import styles from "../restaurant/mobilerestaurantstatus.module.css";
import TextField from '@mui/material/TextField';
import BottomButton from "./bottomButton";
import { IonToast } from '@ionic/react';
import { useHistory } from "react-router-dom";

import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

const MobileModalCoin: React.FC<any> = (props) => {

    const [isExcessive, setIsExcessive] = useState(false);
    const [valueString, setValueString] = useState("");

    const [value, setValue] = useState("");
    const [name, setName] = useState("");

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const handleChange = (e: any, type: string) => {


        switch (type) {
            case "amount":
                const amountValue = e.target.value.replaceAll(",", "");

                if (e.target.value.length === 1 && e.target.value === "0") {
                    return;
                }
                if (+amountValue > +props.availableCharge) {
                    setIsExcessive(true);
                } else {
                    setIsExcessive(false);
                }

                setValue(amountValue);
                break;
            case "name":
                setName(e.target.value);
                break;
        }

    }

    const submit = async () => {

        if (!value) {
            props.handleToastMessage("🥵 충전금액을 입력해주세요");
            return;
        }

        if (isExcessive) {
            props.handleToastMessage("🥵 최대 충전 한도를 초과한 금액입니다");
            return;
        }

        if (!name) {
            props.handleToastMessage("🥵 입금자명을 입력해주세요");
            return;
        }

        setLoading(true);

        var token: any = "";

        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        if (Capacitor.isNativePlatform()) {
            const { value } = await Preferences.get({ key: "token" });
            token = value;
        }


        fetch("https://peetsunbae.com/dashboard/restaurant/charge", {
            method: "POST",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                amount: value,
                name: name
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {

                    if (result.message === "success") {
                        history.push({
                            pathname: "/mobiledashboard/rechargetext",
                            state: {
                                amount: value,
                                name: name,
                            }
                        })

                        setLoading(false);
                    } else {

                        setLoading(false);
                    }
                })
        })

    }

    useEffect(() => {
        if (value) {
            const amountString = value.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setValueString(amountString);
        } else {
            setValueString("");
        }

    }, [value]);

    const handleClose = () => {
        setValue("");
        setIsExcessive(false);
        setName("");
        props.handleClose();
    }

    return (
        <div>
            <div className={styles.modalBody}>
                <div className={styles.modalTitle}>
                    얼마를 충전할까요?
                </div>
                <div className={styles.inputDiv}>
                    <TextField color={isExcessive ? "error" : "primary"}
                        value={valueString ? valueString : ""}
                        onChange={(e: any) => { handleChange(e, "amount") }}
                        type="tel"
                        inputProps={{ style: { fontSize: 20, color: isExcessive ? "#d32f2f" : "black" } }}
                        InputLabelProps={{ style: { fontSize: 20 } }}
                        id="standard-basic"
                        label="충전금액 입력"
                        variant="standard"
                        helperText={props.availableChargeString}
                        fullWidth
                    />
                </div>
                <div className={styles.inputDiv}>
                    <TextField
                        value={name}
                        onChange={(e: any) => { handleChange(e, "name") }}
                        inputProps={{ style: { fontSize: 20 } }}
                        InputLabelProps={{ style: { fontSize: 20 } }}
                        id="standard-basic"
                        label="입금자명"
                        variant="standard"
                        helperText="실제 입금 내역과 금액, 입금자명이 일치해야 반영됩니다"
                        fullWidth
                    />
                </div>
            </div>

            <BottomButton text="충전 신청하기" submit={submit} />

        </div>

    )
}

export default MobileModalCoin;