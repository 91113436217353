import React, { useEffect, useState } from "react";
import styles from "../money.module.css";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PrintList from "./print";

const width = "1500px";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AdditionalConfig: React.FC<any> = (props) => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    return (
        <div>
            <div style={{ width: width, marginTop: "35px" }}>
                <Box sx={{ width: "100%", marginBottom: "0px" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="계좌이체 현황" {...a11yProps(0)} />
                            <Tab label="카드결제 현황" {...a11yProps(1)} />
                            <Tab label="프린트 관리" {...a11yProps(2)} />   
                        </Tabs>
                    </Box>
                </Box>
            </div>

            {
                value === 2
                ?
                <PrintList user={props.user} />
                :
                ""
            }

        </div>
    )
}

export default AdditionalConfig;