import React, { useEffect, useState } from 'react';
import styles from "./mobilemodal.module.css";
import InnerHeaderForModal from './innerheaderformodal';

const MobileModal: React.FC<any> = (props) => {

    useEffect(() => {

        console.log("props.isOpen", props.isOpen);

    }, [props.isOpen]);

    return (
        <div>
            {
                props.isOpen
                    ?
                    <div className={styles.modal_overlay}
                    onClick={() => {
                        props.handleClose();
                    }}
                    >
                        <div className={styles.modal_content}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        >
                            <InnerHeaderForModal handleClose={props.handleClose} />
                            {
                                props.returnChildren()
                            }
                        </div>
                    </div>
                    :
                    ""
            }

        </div>
    )
}

export default MobileModal;