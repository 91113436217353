import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ManageRegisterPrice from '../configcomponents/manageregisterprice';
import ManageRefundRatio from '../configcomponents/managerefundratio';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// 매월 자리별 가격 설정, 환불 정책 설정, 현금 영수증 정보 설정, 몇개 이하 됬을때 결제하라고 뜰것인가

const width = "1500px";

const PaymentConfig: React.FC<any> = (props) => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    return (
        <div>
            <div style={{ width: width, marginTop : "35px" }}>
                <Box sx={{ width: "100%", marginBottom: "0px" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="등록비 설정하기" {...a11yProps(0)} />
                            <Tab label="환불 정책 설정" {...a11yProps(1)} />
                            <Tab label="현금 영수증 정보 설정" {...a11yProps(2)} />
                            <Tab label="결제 설정" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                </Box>
            </div>

            {
                value === 0
                ?
                <ManageRegisterPrice user={props.user}
                width={width}
                />
                :
                ""
            }
            {
                value === 1
                ?
                <ManageRefundRatio user={props.user}
                width={width}
                />
                :
                ""
            }
        </div>
    )

}

export default PaymentConfig;