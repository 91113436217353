import React, { useEffect, useRef, useState } from 'react';
import InnerHeader from '../etc/innerheader';
import { getFetch } from '../../fetch/fetch';
import { addZeroIfOne } from '../../dashboard/components/controls/etc/etcfunctions';
import styles from "./mobilecontents.module.css";
import MenuBar from '../etc/\bmovingUnderbarMenu';
import MobileContentsSubmit from './mobilecontentssubmit';
import CoinModal from '../restaurant/modal/coinmodal';
import { IonApp, IonToast } from '@ionic/react';
import MobileModal from '../etc/mobilemodal';
import MobileModalCoin from '../etc/mobilemodalcoin';
import MobileContentsStatus from './mobilecontentsstatus';

interface MenuItem {
    key: string;
    label: string;
}

const menuItems1: MenuItem[] = [
    { key: "submit", label: "모의고사 신청" },
    { key: "status", label: "신청 현황" },
];

const MobileContents: React.FC<any> = (props) => {

    const headerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const [currentMenu, setCurrentMenu] = useState("submit");
    const [bodyHeight, setBodyHeight] = useState(0);
    const [selectedContent, setSelectedContent] = useState<any>(null);

    const [chargedMoney, setChargedMoney] = useState(0);

    const [availableCharge, setAvailableCharge] = useState(0);
    const [availableChargeString, setAvailableChargeString] = useState("");

    const [isCoinModalOpen, setIsCoinModalOpen] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const [showToast2, setShowToast2] = useState(false);
    const [toastMessage2, setToastMessage2] = useState("");

    const [update, setUpdate] = useState(0);

    const [statusDivHeight, setStatusDivHeight] = useState(0);
    
    const topDivRef = useRef<any>(null);


    const caculateBodyHeight = () => {
        setTimeout(() => {

            if (!headerRef || !headerRef.current) {
                console.log("headerRef is null2");
                return;
            }

            if (!menuRef || !menuRef.current) {
                console.log("menuRef is null2");
                return;
            }

            const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            console.log(headerRef.current);
            const headerHeight = headerRef.current.offsetHeight;
            const menuHeight = menuRef.current.offsetHeight;

            const lastHeight = totalHeight - safeareaTop - headerHeight - menuHeight;



            setBodyHeight(lastHeight);

        }, 50);
    }


    useEffect(() => {

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [headerRef, menuRef]);

    useEffect(() => {

        if (!topDivRef || !topDivRef.current) {
            return;
        }

        const calculateStatusDivHeight = () => {

            setTimeout(() => {

                if (!topDivRef || !topDivRef.current) {
                    return;
                }

                const totalHeight = window.innerHeight;
                const topDivHeight = topDivRef.current.offsetHeight;

                const statusDivHeight = totalHeight - topDivHeight;


                console.log("statusDivHeight", statusDivHeight);

                setStatusDivHeight(statusDivHeight);

            }, 20);

        }

        calculateStatusDivHeight();

        window.addEventListener("resize", () => calculateStatusDivHeight());

        return () => window.removeEventListener("resize", () => calculateStatusDivHeight());

    }, [topDivRef]);




    const handleCurrentMenu = (menu: string) => {
        setCurrentMenu(menu);
    }

    useEffect(() => {

        getCoin();

    }, [update]);

    const getCoin = async () => {

        try {

            const result: any = await getFetch("/dashboard/restaurant/getcoin");

            if (result.message === "success") {

                setChargedMoney(result.chargedMoney);
                setAvailableCharge(100000 - result.chargedMoney);
                setAvailableChargeString(`최대 보유 금액 100,000원. 현재 충전 가능액 ${(100000 - result.chargedMoney).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`);

            }

        } catch (e) {
        }

    }

    const handleCoinModalOpen = () => {
        console.log("handleCoinModalOpen");
        setIsCoinModalOpen(true);
    }

    const handleClose = () => {
        setIsCoinModalOpen(false);
    }

    const handleToastMessage = (message: string) => {
        setToastMessage(message);
        setShowToast(true);
    }

    const handleToastMessage2 = (message: string) => {
        setToastMessage2(message);
        setShowToast2(true);
    }

    const letsUpdate = () => {
        setUpdate(Math.random());
    }




    return (
        <div>
            <div ref={topDivRef}>
                <InnerHeader title="모의고사 신청" headerRef={headerRef}
                    isRestaurantSubmitPage={true}
                    isCoin={true}
                    chargedMoney={chargedMoney}
                    handleClickRestaurantSubmit={() => {
                        handleCoinModalOpen();
                    }}
                />
                <MenuBar menuItems={menuItems1} handleCurrentMenu={handleCurrentMenu} menuRef={menuRef} />
            </div>


            <div style={{
                height: bodyHeight,
                overflowY: "auto",
                overflowX: "hidden",
                paddingTop: "1rem"
            }} className={styles.body}>
                {
                    currentMenu === "submit" ? <MobileContentsSubmit
                        bodyHeight={bodyHeight}
                    /> : null
                }
                {
                    currentMenu === "status" ?
                        <MobileContentsStatus
                            socket={props.socket}
                            statusDivHeight={statusDivHeight}
                            chargedMoney={chargedMoney}
                            handleCoinModalOpen={handleCoinModalOpen}
                            handleToastMessage={handleToastMessage}
                            handleToastMessage2={handleToastMessage2}
                            letsUpdate={letsUpdate}
                        /> : null
                }
            </div>


            <MobileModal
                isOpen={isCoinModalOpen}
                handleClose={handleClose}
                returnChildren={() => {
                    return (
                        <MobileModalCoin
                            availableCharge={availableCharge}
                            availableChargeString={availableChargeString}
                            handleToastMessage={handleToastMessage}
                        />
                    )
                }}
            />



            <IonToast
                isOpen={showToast}
                onDidDismiss={() => { setShowToast(false); setToastMessage(""); }}
                message={toastMessage}
                duration={1500}
                position="top"
                color="danger"
            />

        </div>
    )
}

export default MobileContents;