import React, { useState, useEffect } from 'react';
import styles from "../contentsstyle.module.css";
import { getFetch, postFetch } from '../../../../fetch/fetch';
import { dateToDateString } from '../../controls/etc/etcfunctions';
import { Button, Select, Option } from '@mui/joy';
import { tableUseStyle } from '../../controls/etc/tableusestyle';
import { DataGridPremium, GridFilterModel } from '@mui/x-data-grid-premium';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import totalLocationData from '../../../../data/locationdata';
import { Backdrop, CircularProgress, ToggleButton, TextField } from '@mui/material';
import CustomInput from '../../controls/etc/custominput';

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import Autocomplete from '@mui/material/Autocomplete';


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContentsTotalStatus: React.FC<any> = (props) => {

    const [rows, setRows] = useState<any>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [userLoading, setUserLoading] = useState<boolean>(false);
    const [update, setUpdate] = useState<number>(0);
    const [disabled3, setDisabled3] = useState<boolean>(true);

    const [columns, setColumns] = useState([
        { field: "location", headerName: "지점", width: 100 },
        { field: "name", headerName: "신청자", width: 100 },
        { field: "contentName", headerName: "모의고사 이름", width: 250 },
        { field: "price", headerName: "가격", width: 100 },
        { field: "createdAt", headerName: "신청일", width: 200 },
        { field: "selectedOptions", headerName: "선택한 옵션", width: 500 },
        { field: "cancelDue", headerName: "취소기한", width: 200 },
    ]);

    const [alignment2, setAlignment2] = useState("total");

    const classes = tableUseStyle();
    const [name, setName] = useState<string>("");

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            { id: 1, field: 'name', operator: 'contains', value: "" },
            { id: 2, field: "location", operator: "isAnyOf", value: [] },
            { id: 3, field: "contentName", operator: "contains", value: "" }
        ],
    });

    const [selectedContentId, setSelectedContentId] = useState<any>(0);
    const [contentIdList, setContentIdList] = useState<any>([]);
    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

    const [totalContents, setTotalContents] = useState<any>([]);


    const [targetContentId, setTargetContentId] = useState<any>(0);
    const [targetContentUserName, setTargetContentUserName] = useState<any>("");

    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<any>([]);

    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any>(null);

    const [passiveAddTargetContentId, setPassiveAddTargetContentId] = useState<any>(0);
    const [content, setContent] = useState<any>({});

    const [disabled, setDisabled] = useState<boolean>(true);

    const notify1 = () => toast.success("취소 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const notify5 = () => toast.success("주문 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const notify2 = () => toast.error("😔 이미 주문한 상품입니다.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    })

    const notify3 = () => toast.error("😔 결제기한이 지났습니다.",
        {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            theme: "light"
        });

    const notify4 = () => toast.error("😔 잔액이 부족합니다.",
        {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            theme: "light"
        });

    useEffect(() => {

        if (!content) {
            return;
        }

        if (!content.options) {
            return;
        }

        var isComplete = true;

        //isMandatory가 true인 옵션들이 maxNumber만큼 선택되었는지 확인
        content.options.forEach((option: any) => {

            if (option.isMandantory) {

                var currentNumber = 0;

                selectedOptions.forEach((selectedOption: any) => {
                    if (selectedOption.optionId === option.id) {
                        currentNumber++;
                    }
                });

                if (currentNumber < option.maxNumber) {
                    isComplete = false;
                }
            }

        });

        setDisabled(!isComplete);

    }, [selectedOptions, content]);


    useEffect(() => {

        setLoading(true);

        const start = async () => {

            try {

                const result: any = await getFetch("/dashboard/payments/content/checkTotal");

                if (result.message === "success") {
                    console.log(result.totalData);

                    const data = result.totalData;
                    const contents = result.contents;

                    console.log(contents);

                    setTotalContents([...contents]);

                    if (!data) {
                        setLoading(false);
                        return;
                    }

                    const newRows: any = [];

                    const contentNameList: any = [];

                    data.forEach((eachData: any) => {
                        //contentNameList에 없으면 추가
                        //indexOf로 확인
                        if (contentNameList.indexOf(eachData.contentId) === -1) {
                            contentNameList.push(eachData.contentId);
                        }
                    });

                    setContentIdList([...contentNameList]);


                    data.forEach((eachData: any) => {

                        const oneRow: any = {};
                        oneRow.id = eachData.id;
                        oneRow.location = eachData.location;
                        oneRow.name = eachData.name;
                        oneRow.contentName = eachData.content.menuName;
                        oneRow.price = eachData.price ? eachData.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0 + "원";

                        const createdAtDate = new Date(eachData.createdAt);

                        oneRow.createdAt = dateToDateString(createdAtDate, true);

                        var selectedOptions = "";

                        if (eachData.selectedOptions) {
                            eachData.selectedOptions.forEach((eachOption: any, index: number) => {
                                selectedOptions += `${eachOption.optionName} : ${eachOption.optionItem}`;

                                if (index !== eachData.selectedOptions.length - 1) {
                                    selectedOptions += " / ";
                                }
                            })
                        }

                        oneRow.selectedOptions = selectedOptions;

                        const endDate = new Date(eachData.content.endDateTime);

                        const cancelDue = dateToDateString(endDate, true);

                        oneRow.cancelDate = endDate;
                        oneRow.cancelDue = cancelDue;

                        newRows.push(oneRow);

                    })

                    setRows([...newRows]);

                    setLoading(false);
                }

            } catch (e) {
                setLoading(false);
                console.log(e)
            }

        }

        start();

    }, [update]);

    const handleChange = (e: any, contentId: number) => {

        if (contentId === undefined || contentId === null) {
            return;
        }

        const selectedContent = totalContents.find((eachData: any) => eachData.id === contentId);

        if (contentId === 0) {
            const newFilterModel: any = filterModel;

            newFilterModel.items.forEach((each: any) => {
                if (each.id === 3) {
                    each.value = "";
                }
            })

            setFilterModel({ ...newFilterModel });

            return;
        }

        if (!selectedContent) {
            return;
        }

        const newFilterModel: any = filterModel;

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 3) {
                each.value = selectedContent.menuName;
            }
        })

        setFilterModel({ ...newFilterModel });


    }

    const handleChange2 = (e: any, newAlignment: string) => {



        if (!newAlignment) {
            return;
        }

        setAlignment2(newAlignment);

        const newFilterModel: any = filterModel;

        const locationList: any = [];

        totalLocationData.forEach((location) => {

            if (newAlignment === "total") {


            } else if (location.english === newAlignment) {

                locationList.push(location.korean);
            }

        });

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 2) {
                each.value = locationList;
            }
        })



        setFilterModel({ ...newFilterModel });

    }

    const nameChange = (e: any) => {
        setName(e.target.value);

        const newFilterModel: any = filterModel;

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 1) {
                each.value = e.target.value;
            }
        })

        setFilterModel({ ...newFilterModel });

    }

    const handleDelete = async () => {

        console.log(targetContentId);

        if (!targetContentId) {
            return;
        }

        if (!window.confirm(`${targetContentUserName}님의 신청을 취소하시겠습니까?`)) {
            return;
        }

        setSubmitLoading(true);

        try {

            const result: any = await postFetch("/dashboard/payments/content/forcedelete", {
                id: targetContentId
            });

            if (result.message === "success") {
                notify1();
                setUpdate(Math.random());
            }

        } catch (e) {
            console.log(e);
        }

        setSubmitLoading(false);

    }

    const handleAdd = () => {

        setOpen(true);

    }

    const handleCloseAdd = () => {

        setSelectedUser(null);
        setPassiveAddTargetContentId(0);
        setSelectedOptions([]);
        setContent({});

        setOpen(false);
    }


    useEffect(() => {

        setUserLoading(true);

        async function start() {

            var token = "";

            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            fetch("https://peetsunbae.com/dashboard/restaurant/users", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {

                        const rows: any = [];

                        result.data?.forEach((each: any, index: number) => {
                            var data: any = {};
                            data.id = each.id;
                            data.label = `${each.name} - 보유금액 ${each.amount ? each.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}원`;
                            data.phoneNumber = each.phoneNumber;
                            data.amount = each.amount;
                            data.value = each.value;
                            data.key = index;
                            rows.push(data);
                        })
                        setUsers([...rows]);
                        setUserLoading(false);

                    })
            })
        }

        start();

    }, [update]);

    const onchange = async (e: any, value: any) => {

        if (!users) {
            return;
        }

        setSelectedUser(value);
    }

    const handleOptionClick = (optionId: string, optionName: string, optionItem: string, isExist: boolean, maxNumber: number, order: number) => {

        var newSelectedOptions = [...selectedOptions];

        if (isExist) {

            newSelectedOptions = newSelectedOptions.filter((option: any) => !(option.optionId === optionId && option.optionItem === optionItem));

        } else {

            var currentNumber = 0;

            selectedOptions.forEach((option: any) => {
                if (option.optionId === optionId) {
                    currentNumber++;
                }
            });

            console.log(currentNumber);

            if (currentNumber >= maxNumber) {

                const firstOption = selectedOptions.find((option: any) => option.optionId === optionId);
                const firstOptionId = firstOption.optionId;
                const firstOptionItem = firstOption.optionItem;

                newSelectedOptions = newSelectedOptions.filter((option: any) => !(option.optionId === firstOptionId && option.optionItem === firstOptionItem));


            }
            newSelectedOptions.push({
                optionId: optionId,
                optionItem: optionItem,
                optionName: optionName,
                order: order
            });

        }

        newSelectedOptions.sort((a, b) => a.order - b.order);

        setSelectedOptions([...newSelectedOptions]);

    }

    const submitOrder = async () => {

        try {

            if (disabled) {
                return;
            }

            if (!content) {
                return;
            }

            if(!selectedUser){
                return;
            }

            console.log(selectedUser);

            const selectedUserId = selectedUser.id;

            if(!selectedUserId){
                return;
            }

            setSubmitLoading(true);

            const body = {
                content,
                selectedOptions,
                contentId: content.id,
                userId: selectedUserId
            }

            console.log(body);

            const result: any = await postFetch("/dashboard/payments/content/forceorder", body);

            setSubmitLoading(false);


            if (result.message === "LACK_BALANCE") {
                notify4();
                return;
            }

            if (result.message === "TIME_OUT") {
                notify3();
                return;
            }

            if (result.message === "DUPLICATE") {
                notify2();
                return;
            }

            if (result.message === "success") {

                notify5();
                setUpdate(Math.random());
                handleCloseAdd();

            }

            setLoading(false);

        } catch (e) {
            console.error(e);
        }

    }



    return (
        <div>
            <div style={{
                height: "40px"
            }}></div>
            <div style={{
                marginBottom: "24px"
            }}>
                <Select
                    sx={{
                        width: "350px"
                    }}
                    value={selectedContentId}
                    onChange={(e, newValue: any) => {
                        if (newValue === undefined || newValue === null) {
                            return;
                        }
                        setSelectedContentId(newValue);
                        handleChange(e, newValue);
                    }
                    }>
                    <Option value={0}>전체 모의고사</Option>
                    {
                        contentIdList.map((each: any, index: number) => {

                            const contentId = each;

                            const selectedContent = totalContents.find((eachData: any) => eachData.id === contentId);

                            if (!selectedContent) {
                                return;
                            }

                            const contentName = selectedContent.menuName;

                            return (
                                <Option value={contentId} key={index}>{contentName}</Option>
                            )

                        })
                    }
                </Select>
            </div>
            <div style={{
                marginBottom: "18px",
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "96%",
                    alignItems: "center"
                }}>
                    <div>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment2}
                            exclusive
                            onChange={handleChange2}
                        >
                            {
                                props.user && totalLocationData.filter((each) => each.academy.includes(props.user.academy)).map((each, index) => {
                                    return (
                                        <ToggleButton value={each.english} key={index}>{each.korean}</ToggleButton>
                                    )
                                })
                            }
                        </ToggleButtonGroup>
                        <span style={{
                            marginLeft: "30px"
                        }}>
                            <CustomInput value={name} onChange={nameChange} placeholder="이름 검색" />
                        </span>
                    </div>
                    <div>
                        <span style={{
                            fontFamily: "Apple_R",
                            marginRight: "12px"
                        }}>
                            {targetContentUserName}
                        </span>
                        <Button onClick={handleDelete} variant="soft" color="danger"
                            disabled={!targetContentId}
                        >
                            취소시키기
                        </Button>
                        <Button onClick={handleAdd} variant="soft" color="primary"
                            style={{
                                marginLeft: "12px"
                            }}
                        >
                            추가하기
                        </Button>
                    </div>
                </div>

            </div>



            <div className={classes.root} style={{ width: "96%", height: "900px", backgroundColor: "white", marginTop: "30px" }}>
                <DataGridPremium
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    density='compact'
                    filterModel={filterModel}
                    onFilterModelChange={(model) => {
                        setFilterModel(model);
                    }}
                    onRowClick={(params) => {
                        setTargetContentId(params.row.id);
                        setTargetContentUserName(params.row.name);
                    }}
                />
            </div>

            <Backdrop
                sx={{ color: '#fff', zIndex: 999999999 }}
                open={submitLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Modal open={open} onClose={() => {
                handleCloseAdd();
            }}>
                <ModalDialog
                    aria-labelledby="basic-modal-dialog-title"
                    aria-describedby="basic-modal-dialog-description"
                    sx={{
                        width : 450
                    }}
                >
                    <Typography id="basic-modal-dialog-title" component="h2">
                        모의고사 수동 추가하기
                    </Typography>
                    <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                        모의고사를 수동으로 추가 할 학생을 선택해주세요
                    </Typography>
                    <form
                        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            setOpen(false);
                            setDisabled3(true);
                            setSelectedUser(null);
                        }}
                    >
                        <Stack spacing={3}>
                            <FormControl>
                                <Autocomplete
                                    onChange={onchange}
                                    value={selectedUser}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => <TextField {...params} placeholder="학생을 선택하세요" />}
                                    size='small'
                                    loading={userLoading}
                                />
                            </FormControl>
                        </Stack>
                    </form>
                    <Typography id="basic-modal-dialog-description" textColor="text.tertiary" sx={{
                        marginTop: "12px"
                    }}>
                        어떤 모의고사를 추가할까요?
                    </Typography>
                    <Select
                        value={passiveAddTargetContentId}
                        onChange={(e, newValue: any) => {

                            console.log(newValue);

                            setPassiveAddTargetContentId(newValue);

                            const selectedContent = totalContents.find((eachData: any) => eachData.id === newValue);

                            if (!selectedContent) {
                                return;
                            }

                            console.log(selectedContent);

                            setContent({ ...selectedContent });

                        }}
                        sx={{
                            width: "100%"
                        }}
                    >

                        {
                            totalContents.map((each: any, index: number) => {

                                console.log(each.id);

                                return (
                                    <Option value={each.id} key={index}>{each.menuName}</Option>
                                )
                            }
                            )
                        }
                    </Select>
                    <div>
                        {
                            (content && content.id) ?
                                <div style={{
                                    maxHeight: "45vh",
                                    overflowY: "scroll",
                                    position: "relative",
                                    paddingBottom: "4.5rem"
                                }}
                                    className={styles.body}
                                >
                                    <div>
                                        <img src={"https://peetsunbae.com" + content.src} alt={content.title}
                                            style={{
                                                width: "100%",
                                                aspectRatio: "1/1",
                                                objectFit: "cover"
                                            }}
                                        />
                                    </div>
                                    <div style={{
                                        width: "90.4%",
                                        margin: "0 auto",
                                        paddingBottom: "1rem",
                                        paddingTop: "0.5rem"
                                    }}>
                                        <div style={{
                                            fontFamily: "Apple_B",
                                            letterSpacing: "-0.5px",
                                            lineHeight: "1.2",
                                            fontSize: "1.2rem",
                                        }}>
                                            {
                                                content.menuName
                                            }
                                        </div>
                                        <div style={{
                                            color: "#6b7583",
                                            marginTop: "0.7rem",
                                            fontSize: "0.9rem",
                                            fontFamily: "Apple_R"
                                        }}>
                                            <div>
                                                - {
                                                    content.subDescription1
                                                }
                                            </div>
                                            <div style={{
                                                marginTop: "0.3rem",
                                                fontFamily: "Apple_R"
                                            }}>
                                                - {
                                                    content.subDescription2
                                                }
                                            </div>
                                            <div style={{
                                                marginTop: "0.3rem",
                                                fontFamily: "Apple_R"
                                            }}>
                                                - 결제기한 : {
                                                    new Date(content.endDateTime).getMonth() + 1
                                                }월 {
                                                    new Date(content.endDateTime).getDate()
                                                }일 {
                                                    new Date(content.endDateTime).getHours()
                                                }시 {
                                                    new Date(content.endDateTime).getMinutes()
                                                }분
                                            </div>
                                        </div>
                                        <div style={{
                                            marginTop: "1.2rem",
                                            fontFamily: "Apple_B",
                                            letterSpacing: "-0.5px",
                                            lineHeight: "1.2",
                                            fontSize: "1.4rem",
                                            display: "flex",
                                            justifyContent: "flex-end"
                                        }}>
                                            {
                                                content.price ? content.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
                                            }원
                                        </div>
                                        <div style={{
                                            marginTop: "1.2rem"
                                        }}>
                                            {
                                                content.options
                                                    ?
                                                    content.options.map((option: any, index: number) => {
                                                        return (
                                                            <div key={
                                                                index
                                                            }>
                                                                <div style={{
                                                                    color: "#6B7583",
                                                                    fontFamily: "Apple_R"
                                                                }}>
                                                                    {
                                                                        option.optionName
                                                                    }{
                                                                        option.maxNumber > 1 ?
                                                                            ` (${option.maxNumber}개 선택)`
                                                                            :
                                                                            ""
                                                                    } {
                                                                        option.isMandantory ?
                                                                            <span style={{
                                                                                color: "#FF9C00"
                                                                            }}>필수</span>
                                                                            :
                                                                            ""
                                                                    }
                                                                </div>
                                                                <div style={{
                                                                    //한줄에 3개씩 같은 높이, 넓이로
                                                                    display: "grid",
                                                                    gridTemplateColumns: "1fr 1fr 1fr",
                                                                    gridGap: "0.5rem",
                                                                    marginTop: "0.8rem",
                                                                    marginBottom: "1.5rem"
                                                                }}>
                                                                    {
                                                                        option.optionItems.map((item: any, optionIndex: number) => {

                                                                            var isSelected = false;

                                                                            const optionId = option.id;
                                                                            const optionItem = item;
                                                                            const optionName = option.optionName;
                                                                            const order = index;

                                                                            selectedOptions.forEach((selectedOption: any) => {
                                                                                if (selectedOption.optionId === optionId && selectedOption.optionItem === optionItem) {
                                                                                    isSelected = true;
                                                                                }
                                                                            });

                                                                            return (
                                                                                <div style={{
                                                                                    backgroundColor: isSelected ? "#333d4b" : "#f2f4f6",
                                                                                    borderRadius: "0.5rem",
                                                                                    padding: "0.5rem",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    color: isSelected ? "white" : "#333d4b",
                                                                                    fontFamily: "Apple_R",
                                                                                    whiteSpace: "nowrap",
                                                                                    overflowX: "hidden",
                                                                                }}
                                                                                    onClick={() => handleOptionClick(optionId, optionName, optionItem, isSelected, option.maxNumber, order)}
                                                                                    key={
                                                                                        optionIndex
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    ""
                                            }
                                        </div>
                                        <div style={{
                                            width: "90.4%",
                                            backgroundColor: "#3d50b0",
                                            color: "white",
                                            position: "fixed",
                                            height: "3rem",
                                            bottom: "1.5rem",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "0.5rem",
                                            left: "50%",
                                            transform: "translateX(-50%)",
                                            fontFamily: "Apple_SB",
                                            opacity: disabled ? 0.35 : 1
                                        }}
                                            onClick={() => submitOrder()}
                                        >
                                            {
                                                content.price ? content.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
                                            }원 결제시키기
                                        </div>

                                    </div>

                                </div>
                                :
                                ""
                        }
                    </div>

                </ModalDialog>
            </Modal>

        </div>
    )
}

export default ContentsTotalStatus;