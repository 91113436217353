import React, { useEffect, useState } from "react";
import styles from "../../componentsStyle/restaurant.module.css";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { Socket } from 'socket.io-client';
import { Autocomplete, CircularProgress, FormControlLabel, FormHelperText, Radio, RadioGroup } from "@mui/material";

import { ReactComponent as Gargabe } from "../../svg/totalmenu/trash-solid.svg";

import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import koLocale from 'date-fns/locale/ko';
import { Button } from "@mui/joy";
import { getFetch, postFetch } from "../../../fetch/fetch";
import { da } from "date-fns/locale";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RadioPositionEnd } from "../../../mobile/restaurant/modal/mealticketmodal";
import { set } from "lodash";

const styleCharge = {
    borderRadius: "6px",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
};

const SubmitMeals2 = (props: any) => {

    const [dateValue, setDateValue] = useState(new Date().setDate(new Date().getDate() + 2));
    const [whenType, setWhenType] = useState("lunch");
    const [lunchBool, setLunchBool] = useState(true);
    const [dinnerBool, setDinnerBool] = useState(true);
    const [chargedMealTicket, setChargedMealTicket] = useState(0);
    const [loading, setLoading] = useState(false);
    const [openCharge, setOpenCharge] = useState(false);

    const [amount, setAmount] = useState(0);
    const [data, setData] = useState<any>([]);
    const [chargedBool, setChargedBool] = useState(false);

    const [selectedLunch, setSelectedLunch] = useState<any>([]);
    const [selectedDinner, setSelectedDinner] = useState<any>([]);

    const [disabled, setDisabled] = useState(true);
    const [disabled2, setDisabled2] = useState(true);

    const [update, setUpdate] = useState(0);

    const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);

    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");

    const notify1 = (message: string) => toast.success(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const notify2 = (message: string) => toast.warning(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    useEffect(() => {

        if (!data) {
            setDisabled(true);
            return;
        }

        var isSelectedLunchAllUndefined = true;

        selectedLunch.forEach((element: any) => {
            if (element) {
                isSelectedLunchAllUndefined = false;
            }
        })

        var isSelectedDinnerAllUndefined = true;

        selectedDinner.forEach((element: any) => {
            if (element) {
                isSelectedDinnerAllUndefined = false;
            }
        })

        console.log(selectedLunch);
        console.log(selectedDinner);
        console.log(isSelectedLunchAllUndefined);
        console.log(isSelectedDinnerAllUndefined);

        if (whenType === "lunch") {
            if (!selectedLunch || selectedLunch.length === 0 || isSelectedLunchAllUndefined) {
                setDisabled(true);
                return;
            }
        } else {
            if (!selectedDinner || selectedDinner.length === 0 || isSelectedDinnerAllUndefined) {
                setDisabled(true);
                return;
            }
        }



        console.log("false !!! ");

        setDisabled(false);

    }, [selectedLunch, selectedDinner, data, whenType]);

    useEffect(() => {

        if (selectedPhoneNumber && amount) {
            setDisabled2(false);
        } else {
            setDisabled2(true);
        }

    }, [selectedPhoneNumber, amount]);

    const disableWeekends = (date: Date) => {

        const academy = props.user.academy;

        if(!academy){
            return true;
        }

        //오늘이랑 내일은 신청 못하게 막기
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        const todayString = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        const tomorrowString = `${tomorrow.getFullYear()}-${tomorrow.getMonth() + 1}-${tomorrow.getDate()}`;

        const targetDateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

        if (date.getDay() === 0) {
            return true;
        }

        if (todayString === targetDateString ) {
            return true;
        }

        if(academy === "sunbae" && tomorrowString === targetDateString){
            return true;
        }

        return false;
    }

    const submitMeal = async (forced: boolean = false) => {

        try {

            if (chargedMealTicket === undefined || chargedMealTicket === null) {
                return;
            }

            if (chargedMealTicket < 1) {
                notify2("😔 식권이 부족합니다");
                return;
            }

            if (!whenType) {
                notify2("😔 점심 또는 저녁을 선택해주세요");
                return;
            }

            setLoading(true);

            const targetDate = new Date(dateValue);

            const targetDateString = `${targetDate.getFullYear()}-${targetDate.getMonth() + 1}-${targetDate.getDate()}`;
            const targetDateTime = targetDate.getTime();

            const data = {
                targetDateTime,
                whenType: whenType,
                forced
            }

            const result: any = await postFetch("/dashboard/restaurant/regularmeal/order", data);


            if (result.message === "TIME_OUT") {
                notify2("😔 주문 시간이 지났습니다.");
            } else if (result.message === "LACK_BALANCE") {
                notify2("😔 식권이 부족합니다");
            } else if (result.message === "success") {
                notify1("😊 주문이 완료되었습니다");
            } else if (result.message === "DUPLICATE") {
                notify2("😔 해당 날짜에 이미 주문했습니다");
            } else if (result.message === "DUPLICATE2") {
                if (window.confirm("해당 날짜에 특식 주문이 있습니다. 그대로 진행하시겠습니까?")) {
                    submitMeal(true);
                }
            }

            setUpdate(Math.random());

            setLoading(false);


        } catch (e) {

        }

    }

    const handleOpenCharge = () => {
        setOpenCharge(true);
    }

    const handleCloseCharge = () => {

        setAmount(0);
        setSelectedPhoneNumber("");
        setOpenCharge(false);
    }

    const handleChangeAmount = (e: any) => {
        setAmount(e.target.value);
    }

    useEffect(() => {

        getMealTicketNumber();

    }, [update]);


    const getMealTicketNumber = async () => {

        try {

            const result: any = await getFetch("/dashboard/restaurant/regularmeal/getchargedmealticket");

            console.log(result);

            if (result.message === "success") {
                setChargedMealTicket(result.amount);
            }

        } catch (e) {

        }

    }

    useEffect(() => {

        getRegularMealMenu();

    }, []);

    const getRegularMealMenu = async () => {

        try {

            setLoading(true);

            const result: any = await getFetch("/dashboard/restaurant/regularmeal/get");

            console.log(result);

            if (result.message === "success") {
                setData(result.data);
            }

            setLoading(false);

        } catch (e) {

        }

    }

    useEffect(() => {

        if (!data) {
            return;
        }

        if (data.length === 0) {
            return;
        }

        const date = new Date(dateValue);

        const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

        const targetData = data.find((element: any) => element.date === dateString);


        if (targetData && targetData.data && targetData.data.lunch) {
            setSelectedLunch(targetData.data.lunch);
        } else {
            setSelectedLunch([]);
        }

        if (targetData && targetData.data && targetData.data.dinner) {
            setSelectedDinner(targetData.data.dinner);
        } else {
            setSelectedDinner([]);
        }

    }, [dateValue, data]);

    useEffect(() => {

        getPhoneNumbers();

    }, []);

    const getPhoneNumbers = async () => {

        try {

            const result: any = await getFetch("/dashboard/restaurant/getphonenumbers");


            const phoneNumbers: string[] = [];

            if (result.myPhoneNumber) {
                phoneNumbers.push(result.myPhoneNumber);
            }

            if (result.parentPhoneNumbers) {
                //동일한게 있으면 추가하지 않음
                result.parentPhoneNumbers.forEach((item: any) => {
                    if (phoneNumbers.indexOf(item) === -1) {
                        phoneNumbers.push(item);
                    }
                });
            }


            setPhoneNumbers([...phoneNumbers]);

        } catch (e) {
        }

    }

    const handleSelectedPhoneNumber = (phoneNumber: string) => {
        setSelectedPhoneNumber(phoneNumber);
    }

    const submitLink = async () => {

        try {


            if (!selectedPhoneNumber) {
                alert("전화번호를 선택해주세요");
                return;
            }

            if (!amount) {
                alert("수량을 선택해주세요");
                return;
            }

            setLoading(true);

            const data = {
                selectedPhoneNumber: selectedPhoneNumber,
                numbers: amount
            }


            const result: any = await postFetch("/dashboard/restaurant/sendmealticketlink", data);


            if (result.message === "success") {

                handleCloseCharge();
                notify1("😊 링크가 전송되었습니다");

            }

            setLoading(false);

        } catch (e) {
        }

    }


    return (
        <div>
            {
                props.user &&
                <>
                    <div className={styles.board}>

                        <div className={styles.schedule}>
                            <div className={styles.selectedMealMenu}>
                                - 선택메뉴 : <span>{disabled ? "" : "정기식"}</span>
                            </div>
                            <div className={styles.calendarDiv}>
                                <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                                    <StaticDatePicker
                                        shouldDisableDate={disableWeekends}
                                        displayStaticWrapperAs="desktop"
                                        openTo="day"
                                        value={dateValue}
                                        disablePast
                                        onChange={(newValue: any) => {
                                            setDateValue(newValue);
                                        }}
                                        renderInput={(params: any) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>



                            <div className={styles.submitDiv}>
                                <div className={styles.whenDiv}>
                                    <div className={`${styles.when} ${whenType === "lunch" ? styles.active : ""}  ${lunchBool ? "" : styles.disabled}`} onClick={(e: any) => { if (lunchBool) { setWhenType("lunch") } }}>
                                        점심
                                    </div>
                                    <div className={`${styles.when} ${whenType === "dinner" ? styles.active : ""} ${dinnerBool ? "" : styles.disabled}`} onClick={(e: any) => { if (dinnerBool) { setWhenType("dinner") } }}>
                                        저녁
                                    </div>
                                </div>
                                <div className={styles.priceDiv}>
                                    <span>금액 : {disabled ? "" : "식권 1장"}</span>
                                </div>


                                {/* <div onClick={submitMeal} className={styles.submitBtn}>
                                신청하기 <img src="img/chevron-right-regular.svg" alt="chevronRight"></img>
                            </div> */}
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "20px 0"
                                }}>
                                    <Button disabled={disabled} endDecorator={<img src="img/chevron-right-regular.svg" alt="chevronRight"></img>} onClick={() => { submitMeal() }}
                                        fullWidth
                                        sx={{
                                            width: "80%"
                                        }}
                                        size="lg"
                                        color="primary"
                                        variant="soft"
                                    >
                                        신청하기
                                    </Button>

                                </div>

                            </div>


                        </div>



                        <div className={styles.menu}>

                            <div className={styles.moneyCharge}>
                                <div>
                                    - 정기식 식권 : <span className={styles.money}>{chargedMealTicket}장</span>
                                </div>
                                {/* <div onClick={handleOpenCharge} className={styles.handleChargeBtn}>
                                충전하기 <img src="img/navigate_next_black_24dp.svg" alt="right" />
                            </div> */}
                                <Button onClick={handleOpenCharge} endDecorator={<img src="img/navigate_next_black_24dp.svg" alt="right" />}>
                                    충전하기
                                </Button>
                            </div>


                            <div style={{
                                marginTop: "46px",
                            }}>
                                {
                                    loading &&
                                    <div className={styles.loading}>
                                        <CircularProgress />
                                    </div>
                                }
                                {
                                    !loading &&
                                    (whenType === "lunch" ? selectedLunch : selectedDinner).map((element: any, index: number) => {

                                        if (index > 5) {
                                            return;
                                        }

                                        if (!element) {
                                            return;
                                        }

                                        return (
                                            <div key={index} style={{
                                                display: "flex",
                                                alignItems: "center",
                                                fontFamily: "Apple_R",
                                                fontSize: "20px",
                                                padding: "8px 0",
                                                margin: "8px 0",
                                                borderBottom: "1px solid #e0e0e0"
                                            }}>
                                                <div style={{
                                                    fontFamily: "Apple_B",
                                                    marginRight: "80px"
                                                }}>
                                                    {
                                                        ["메인", "서브", "반찬", "반찬", "김치", "국물"][index]
                                                    }
                                                </div>
                                                <div>
                                                    {element}
                                                </div>
                                            </div>
                                        )
                                    }
                                    )
                                }
                            </div>
                        </div>

                    </div>


                    {/* 충전금 잔액 충전용 모달 */}
                    <Modal
                        open={openCharge}
                        onClose={handleCloseCharge}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={styleCharge}>
                            <div className={styles.chargeTitle}>
                                충전하기
                            </div>
                            <>
                                <div className={styles.chargeBoard}>
                                    <FormControl fullWidth>
                                        <div className={styles.chargeAmount} style={{
                                            marginBottom: "10px",
                                            fontFamily: "Apple_SB"
                                        }}>정기식 식권 충전</div>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            value={amount}
                                            onChange={handleChangeAmount}
                                            endAdornment={<InputAdornment position="start">개</InputAdornment>}
                                        />
                                        <FormHelperText>
                                            <span style={{
                                                fontSize: "16px"
                                            }}>
                                                총 금액 {(amount * 8000).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                                            </span>
                                        </FormHelperText>
                                    </FormControl>
                                    <div className={styles.chargeAmount} style={{
                                        marginBottom: "10px",
                                        marginTop: "20px",
                                        fontFamily: "Apple_SB"
                                    }}>결제 링크 전송 번호</div>
                                    <div>
                                        {RadioPositionEnd(phoneNumbers, handleSelectedPhoneNumber)}
                                    </div>
                                </div>

                                <div className={styles.chargeBtnDiv} style={{
                                    paddingRight: "20px",
                                }}>
                                    <Button
                                        disabled={disabled2}
                                        loading={loading}
                                        onClick={submitLink}
                                    >
                                        충전하기
                                    </Button>
                                </div>
                            </>


                        </Box>
                    </Modal>
                </>

            }

        </div>
    )
}

export default SubmitMeals2;
