import React, { useEffect, useState, useRef } from "react";
import styles from "../restaurant/mobilerestaurantstatus.module.css";
import { ReactComponent as Trash } from "../../../src/svg/trash-can-regular.svg";
import { ReactComponent as CircleDollar } from "../../../src/svg/wallet-outline.svg";
import { ReactComponent as Ticket } from "../../../src/svg/ticket-regular.svg";

import { returnDayString } from "../etc/functions";

import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

import { useIonActionSheet } from '@ionic/react';
import { Skeleton } from "@mui/material";
import { getFetch, postFetch } from "../../fetch/fetch";
import { addZeroIfOne } from "../../dashboard/components/controls/etc/etcfunctions";



const MobileContentStatus: React.FC<any> = (props) => {


    const [loading, setLoading] = useState(false);
    const topRef = useRef<any>(null);

    const [today, setToday] = useState(new Date());
    const [checkResult, setCheckResult] = useState([]);
    const [descriptionHeight, setDescriptionHeight] = useState(0);

    const [update, setUpdate] = useState(0);

    const [present] = useIonActionSheet();


    useEffect(() => {


        if (!topRef || !topRef.current || !props.statusDivHeight) {
            return;
        }

        const caculateBodyHeight = () => {



            if (!topRef || !topRef.current || !props.statusDivHeight) {
                return;
            }

            const totalHeight = props.statusDivHeight;
            const topHeight = topRef.current.offsetHeight;

            const descriptionHeight = totalHeight - topHeight - (2.469 * 16);





            setDescriptionHeight(descriptionHeight);
        }

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());


    }, [topRef, props.statusDivHeight]);



    useEffect(() => {

        setLoading(true);

        const start = async () => {

            try{

                const result : any = await getFetch("/dashboard/payments/content/checkMobile");

                if(result.message === "success"){
                    setCheckResult(result.totalData);
                    setLoading(false);
                }

            }catch(e){
                setLoading(false);
                console.log(e)
            }

        }

        start();

    }, [update]);


    const confirmDelete = (id: number, isRegular : any = false) => {
        present({
            header: "모의고사 신청 취소",
            subHeader: "모의고사 신청을 취소할까요?",
            buttons: [{
                text: "취소",
                role: "destructive"
            },
            {
                role: "cancel",
                text: "아니오",
            }
            ],
            onDidDismiss: ({ detail }) => {

                switch (detail.role) {
                    case "destructive":
                        deleteMeal(id, isRegular);
                        break;
                    case "cancel":
                        break;
                }
            },
        })
    }


    const deleteMeal = async (id: number, isRegular : any = false) => {

        if (!id) {
            alert("id가 없습니다");
            return;
        }

        setLoading(true);

        try{

            const result : any = await postFetch("/dashboard/payments/content/delete", {
                id : id}
            );

            if(result.message === "success"){
                props.handleToastMessage2("🗑️ 모의고사 신청 취소가 정상 처리되었습니다");
                setUpdate(Math.random());
                props.letsUpdate();
            }

            if(result.message === "TIME_OUT"){
                props.handleToastMessage("🥵 취소 시간이 지났습니다");
                setLoading(false);
            }

        }catch(e){
            setLoading(false);
            console.log(e);
        }
    }

    return (
        <div className={styles.statusBody}>
            <div className={styles.statusTop} ref={topRef}>
                <div className={styles.statusTitle}>
                    모의고사 신청 현황
                </div>
                <div className={styles.statusNumber}>
                    {today.getMonth() + 1}월 {today.getDate()}일 {returnDayString(today.getDay())}요일 기준
                </div>
            </div>

            {
                loading
                &&
                <div style={{ width: "90.6%", margin: "0 auto" }}>
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                    <Skeleton variant="rectangular" width="100%" height={140} />
                    <div style={{ height: "20px" }}>
                    </div>
                </div>
            }


            <div className={styles.description} style={{ height: `${descriptionHeight}px`, overflow: "scroll" }}>
                <div className={styles.descriptionBody}>

                    <div className={`${styles.eachDescriptionBox} ${styles.cash}`}>
                        <div className={styles.iconDiv}>
                            <CircleDollar fill="#304ffe" className={styles.circleDollar} />
                        </div>
                        <div className={styles.cashDescriptionDiv}>
                            <div className={styles.cashDescription}>
                                {props.isNeedRegularMeal ? "특식용 충전금 (선배페이)" : "충전금 잔액 (선배페이)"}
                            </div>
                            <div className={styles.cashTitle}>
                                {props.chargedMoney !== undefined ? props.chargedMoney.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}원
                            </div>
                        </div>
                        <div className={styles.btnDiv} onClick={(e: any) => {
                            props.handleCoinModalOpen();
                        }}>
                            <div className={styles.cashBtn}>
                                충전
                            </div>
                        </div>
                    </div>

                    {
                        props.isNeedRegularMeal &&
                        <div className={`${styles.eachDescriptionBox} ${styles.cash}`}>
                            <div className={styles.iconDiv}>
                                <Ticket className={styles.circleDollar}
                                style={{
                                    transform : "rotate(-45deg)"
                                }}
                                />
                            </div>
                            <div className={styles.cashDescriptionDiv}>
                                <div className={styles.cashDescription}>
                                    정기식 식권
                                </div>
                                <div className={styles.cashTitle}>
                                    {props.chargedMealTicket}장
                                </div>
                            </div>
                            <div className={styles.btnDiv} onClick={(e: any) => {
                                props.handleMealTicketModalOpen();
                            }}>
                                <div className={styles.cashBtn}>
                                    구매
                                </div>
                            </div>
                        </div>
                    }



                    {
                        (checkResult && checkResult.length > 0) &&
                        checkResult.map((eachCheckResult: any, index: number) => {


                            const targetDate = new Date(eachCheckResult.createdAt);

                            var targetDateDay = returnDayString(targetDate.getDay());

                            const targetDateString = `${targetDate.getMonth() + 1}월 ${targetDate.getDate()}일 ${targetDateDay}요일 신청`;

                            const endDate = new Date(eachCheckResult.content.endDateTime);

                            var canDelete = true;

                            const endTime = endDate.getTime();
                            const currentTime = new Date().getTime();

                            if (endTime < currentTime) {
                                canDelete = false;
                            }

                            //endDateString 8월 24일 22:00
                            const endDateString = `${endDate.getMonth() + 1}월 ${endDate.getDate()}일 ${addZeroIfOne(endDate.getHours())}:${addZeroIfOne(endDate.getMinutes())}`;

                            return (
                                <div key={eachCheckResult.id}>
                                    <div className={`${styles.eachDescription} ${checkResult.length - 1 === index ? styles.last : ""}`}>
                                        <div className={styles.eachDescriptionDate}>
                                            {targetDateString}
                                        </div>
                                        <div className={styles.eachDescriptionBox}>
                                            <div className={styles.eachDescriptionText}>
                                                {eachCheckResult.content.menuName.length > 30 ? eachCheckResult.content.menuName.substring(0, 30) + "..." : eachCheckResult.content.menuName}
                                            </div>
                                            <div className={styles.eachDescriptionTargetDate}>
                                                취소 기한 : {endDateString}
                                            </div>
                                            <div style={{
                                                marginBottom : "0.853rem"
                                            }}>
                                                {
                                                    eachCheckResult.selectedOptions
                                                        ?
                                                        eachCheckResult.selectedOptions.map((eachOption: any, index: number) => {
                                                            return (
                                                                <div key={index} className={styles.eachDescriptionOption}>
                                                                    {eachOption.optionName} : {eachOption.optionItem}
                                                                </div>
                                                            );
                                                        })
                                                        :
                                                        ""
                                                }
                                            </div>
                                            <div className={styles.eachDescriptionKindDiv}>
                                                <div className={styles.eachDescriptionKindText}>
                                                    {eachCheckResult.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                                                </div>
                                            </div>
                                            {
                                                canDelete ?
                                                <div className={styles.trashCanDiv} onClick={(e: any) => { confirmDelete(eachCheckResult.id); }}>
                                                    <Trash fill="#251e1c" className={styles.trash} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>

                                </div>
                            );

                        })
                    }

                </div>
            </div>



        </div>
    );
}

export default MobileContentStatus;