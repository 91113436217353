import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box';
import { DataGridPremium, GridRowsProp, GridColDef, GridToolbar, useGridApiRef, GridFilterModel, } from '@mui/x-data-grid-premium';
import {  makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
// import Button from '@mui/material/Button';
import { Button, CssVarsProvider} from '@mui/joy';

import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import { Alert, FormControlLabel, MenuItem, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import renderCellExpand from '../data/rendercellexpand';

import CalendarModal from './calendarmodal';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';



import styles from "../../componentsStyle/upload.module.css"

import { GridRowModel } from '@mui/x-data-grid';
import RegularSchedule2 from './regularSchedule2';

import TestResult from "../testresult";
import Profile from '../profile';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import totalLocationData from '../../../data/locationdata';
import SkeletonComp from './etc/skeletoncomp';
import { TimePicker } from '@mui/x-date-pickers';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { postFetch } from '../../../fetch/fetch';
import CustomInput from './etc/custominput';



const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "24px",
    boxShadow: 24,
    p: 3,
    paddingLeft: 5,
    paddingRight: 5
};

const style2 = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1420px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    backgroundColor: "#f5f5f5",
    display: "flex",
    justifyContent: "center",
    pt: 1,
    pb: 4,
    borderRadius: "8px",
};

const style3 = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    borderRadius: "8px",
};

const style4 = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1420px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    backgroundColor: "#f5f5f5",
    pt: 1,
    pb: 4,
    p: 4,
    borderRadius: "8px",
};

const style5 = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1420px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    backgroundColor: "#f5f5f5",
    pt: 1,
    pb: 4,
    p: 4,
    borderRadius: "8px",
};


const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
            },
        };
    },
    { defaultTheme },
);






const AllStudents: React.FC<any> = (props) => {
    const classes = useStyles2();
    const [rows, setRows] = useState<any>([]);

    const [studentRows, setStudentRows] = useState<any>([]);
    const [teacherRows, setTeacherRows] = useState<any>([]);

    const [clickedId, setClickedId] = useState<any>();


    const [loading, setLoading] = useState(false);
    const [demeritLoading, setDemeritLoading] = useState(false);

    const [editRowsModel, setEditRowsModel] = React.useState({});

    const [selectedUserId, setSelectedUserId] = useState();
    const [selectedUserFingerprintId, setSelectedUserFingerprintId] = useState();
    const [selectedUserName, setSelectedUserName] = useState();
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);

    const [uploadBool, setUploadBool] = useState(false);
    const [active, setActive] = useState(true);


    const [message, setMessage] = useState("");
    const [score, setScore] = useState(0);
    const [totalScore, setTotalScore] = useState(0);

    const [update, setUpdate] = useState(0);
    const [name, setName] = useState("");

    const apiRef = useGridApiRef();

    const [alignment, setAlignment] = useState("all");
    const [alignment2, setAlignment2] = useState("total");
    const [alignment3, setAlignment3] = useState("current");

    const [sendCardLoading, setSendCardLoading] = useState(false);

    const [profileLoading, setProfileLoading] = useState(false);

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            { id: 2, field: 'name', operator: 'contains', value: "" },
            { id: 3, field: "status", operator: "is", value: "true" },
            { id: 4, field: "kind", operator: "contains", value: "" },
            { id: 5, field: "location", operator: "isAnyOf", value: [] },
            { id: 6, field: "phoneNumber", operator: "contains", value: "" }
        ],
    });

    const [newLoading, setNewLoading] = useState(false);
    const [teacherList, setTeacherList] = useState<any>([]);



    useEffect(() => {

        fetchTeacherList();

    }, []);

    const fetchTeacherList = async () => {

        try {

            const response = await fetch("https://peetsunbae.com/dashboard/avatar/teachers", {
                method: "GET",
                credentials: "include"
            });

            const result = await response.json();

            const data = result.data;

            if (result.message === "success") {

                const teacherArray: any = [];

                teacherArray.push({
                    value: 1,
                    label: "상담X"
                })

                data.forEach((each: any) => {

                    const newRow = {
                        value: each.id,
                        label: each.name
                    }

                    teacherArray.push(newRow);

                })

                setTeacherList([...teacherArray]);

            }

        } catch (e) {
            
        }

    }



    // function SelectEditInputCell(props: GridRenderCellParams) {
    //     const { id, value, field } = props;
    //     const apiRef = useGridApiContext();
    //     const [teachersList, setTeachersList] = useState<any>([{ id: 1, name: "고용범" }]);

    //     useEffect(() => {
    //         const start = async () => {
    //             var token = "";
    //             if (window.electron) {
    //                 token = await window.electron.sendMessageApi.getToken();
    //             }

    //             fetch("https://peetsunbae.com/dashboard/avatar/teachers", {
    //                 method: "GET",
    //                 headers: { "Authorization": token },
    //                 credentials: "include",
    //             }).then((response: any) => {
    //                 response.json()
    //                     .then((result: any) => {

    //                         // result.data.push({id : 1, name : "없음"})
    //                         // 
    //                         // 

    //                         

    //                         setTeachersList(result.data);
    //                     })
    //             })
    //         }

    //         start();
    //     }, [])

    //     const handleChange = async (event: SelectChangeEvent) => {
    //         

    //         fetch("https://peetsunbae.com/dashboard/avatar/teacher", {
    //             method: "POST",
    //             headers: { "content-type": "application/json" },
    //             credentials: "include",
    //             body: JSON.stringify({
    //                 userId: +id,
    //                 teacherId: +event.target.value
    //             })
    //         }).then((response: any) => {
    //             response.json()
    //                 .then((result: any) => {
    //                     
    //                     setUpdate(Math.random());
    //                 })
    //         })

    //         
    //         if (!event.target.value) {
    //             event.target.value = "";
    //         }
    //         var teacherName = "";
    //         teachersList.forEach((each: any) => {
    //             if (+each.id === +event.target.value) {
    //                 teacherName = each.name;
    //             }
    //         })

    //         if(event.target.value === "1"){
    //             teacherName = "상담X";
    //         }

    //         await apiRef.current.setEditCellValue({ id, field, value: teacherName });
    //         apiRef.current.stopCellEditMode({ id, field });
    //     };

    //     return (
    //         <Select
    //             value={value}
    //             onChange={handleChange}
    //             size="small"
    //             sx={{ height: 1 }}
    //             native
    //             autoFocus
    //         >
    //             <option key={0} value={0}>
    //                 ----------
    //             </option>
    //             <option key={1} value={1}>
    //                 상담X
    //             </option>
    //             {
    //                 teachersList.map((each: any) => {
    //                     return (
    //                         <option key={each.id} value={each.id}>
    //                             {each.name}
    //                         </option>
    //                     )
    //                 })
    //             }
    //         </Select>
    //     );
    // }

    // const renderSelectEditInputCell: GridColDef['renderCell'] = (params) => {
    //     return <SelectEditInputCell {...params} />;
    // };
    const [columns, setColumns] = useState<GridColDef[]>([
        { field: 'userId', headerName: '서버ID', width: 60, filterable: true, editable: false },
        { field: 'name', headerName: '이름', width: 120, filterable: true, editable: false },
        { field: 'kind', headerName: '구분', width: 50, filterable: true, editable: false },
        { field: 'status', headerName: '재원중', width: 50, filterable: true, editable: true, type: "boolean" },
        { field: 'phoneNumber', headerName: '전화번호', width: 120, filterable: true, editable: false },
        {
            field: 'location', headerName: '호점', width: 80, filterable: true, editable: true, type: "singleSelect", valueOptions:
                [...totalLocationData.filter((each) => {
                    if (each.english === "total") {
                        return false;
                    }

                    if (!props.user) {
                        return true;
                    }

                    if (each.academy.includes(props.user.academy)) {
                        return true;
                    }

                    return true;
                }).map((each) => {

                    return {
                        value: each.korean,
                        label: each.korean
                    }
                }), { value: "운영진", label: "운영진" }],
        },
        // { field: 'room', headerName: '열람실', width: 120, filterable: true, editable: true },
        {
            field: "job", headerName: "직책", width: 80, filterable: true, editable: true, type: "singleSelect", valueOptions: [
                { value: "mentor", label: "멘토" },
                { value: "sagam1", label: "주간사감" },
                { value: "sagam2", label: "야간사감" },
                { value: "desk1", label: "오전데스크" },
                { value: "desk2", label: "오후데스크" },
                { value: "desk3", label: "저녁데스크" },
                { value: "etc", label: "기타근무" },
                { value: "manager", label: "관리자" }
            ]
        },
        { field: 'seat', headerName: '자리', width: 80, filterable: true, editable: true },
        { field: 'demerit', headerName: '벌점', width: 80, filterable: true, editable: false },
        { field: 'parentPhoneNumber', headerName: '부모님번호', width: 120, filterable: true, editable: true },
        { field: 'teacher', headerName: '담임', width: 120, filterable: true, editable: true, type: "singleSelect", valueOptions: teacherList },
        { field: 'teacherDescription', headerName: '담임 배정 이유', width: 120, filterable: true, editable: true },
        { field: 'chargedMoney', headerName: '충전금잔액', width: 120, filterable: true, editable: false },
        { field : 'chargedMealTicket', headerName : "식권보유", width : 100, filterable : true, editable : false},
        { field: 'fingerprintId', headerName: '지문인식ID', width: 120, filterable: true, editable: true },
        { field: 'firstCome', headerName: '첫 등원', width: 120, type: "date", filterable: true, editable: true },
        { field: 'createdAt', headerName: '회원가입일', width: 120, filterable: true, editable: false },
        { field: "email", headerName: "이메일", width: 120, filterable: false, editable: false },
    ]);

    const [direction, setDirection] = useState("");
    const [time, setTime] = useState("");
    const [targetDate, setTargetDate] = useState(new Date());


    useEffect(() => {

        

        if (props.user) {

            

            const newColumns: any = columns;

            newColumns.forEach((each: any) => {
                if (each.field === "location") {
                    each.valueOptions = [...totalLocationData.filter((each) => {
                        if (each.english === "total") {
                            return false;
                        }

                        if (!props.user) {
                            return true;
                        }

                        if (each.academy.includes(props.user.academy)) {
                            return true;
                        }

                        return false;
                    }).map((each) => {

                        

                        return {
                            value: each.korean,
                            label: each.korean
                        }
                    }), { value: "운영진", label: "운영진" }];
                }
            })

            if (props.user.academy === "mom") {

                const newRow = {
                    field: "phoneSeat",
                    headerName: "핸드폰 자리",
                    width: 100,
                    sortable: true,
                }

                newColumns.splice(7, 0, newRow);

                const newRow2 = {
                    field: "grade",
                    headerName: "학년",
                    width: 100,
                    sortable: true,
                    type: "singleSelect",
                    valueOptions: [
                        { value: "middle3", label: "중3" },
                        { value: "high1", label: "고1" },
                        { value: "high2", label: "고2" },
                        { value: "high3", label: "고3" },
                        { value: "n", label: "N수생" }
                    ],
                    editable: true
                }

                newColumns.splice(3, 0, newRow2);

                //계획표 검사 여부 열도 하나 추가
                const newRow3 = {
                    field: "checkSchedule",
                    headerName: "계획표 검사",
                    width: 100,
                    sortable: true,
                    type: "boolean",
                    filterable: true,
                    editable: true
                }

                newColumns.splice(4, 0, newRow3);

            }



            setColumns([...newColumns]);
        }

    }, [props.user]);

    useEffect(() => {

        const newColums: any = columns;

        newColums.forEach((each: any) => {

            if (each.field === "teacher") {
                each.valueOptions = teacherList;
            }
        })

        setColumns([...newColums]);

    }, [teacherList]);


    useEffect(() => {


    }, []);






    const notify1 = () => toast.success("전송 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify2 = () => toast.success("이미 리스트에 추가된 학생입니다!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify3 = () => toast.success("설문 전송 기록이 없는 학생입니다!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify4 = () => toast.success("설문 제출이 아직 안되었습니다.!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "dark"
    });

    const handleChange = (e: any, newAlignment: string) => {
        setAlignment(newAlignment);

        const newFilterModel: any = filterModel;


        newFilterModel.items.forEach((each: any) => {
            if (each.id === 4) {
                switch (newAlignment) {
                    case "all":
                        each.value = "";
                        
                        break;
                    case "teacher":
                        each.value = "직원";
                        
                        break;
                    case "student":
                        each.value = "학생";
                        
                        break;
                }
            }
        })

        setFilterModel({ ...newFilterModel });
    }

    const handleChange2 = (e: any, newAlignment: string) => {

        

        if (!newAlignment) {
            return;
        }

        setAlignment2(newAlignment);

        const newFilterModel: any = filterModel;

        const locationList: any = [];

        totalLocationData.forEach((location) => {

            if (newAlignment === "total") {


            } else if (location.english === newAlignment) {
                //     
                //     
                //     

                // newFilterModel.items.forEach((each : any) => {
                //     if(each.id === 5){
                //         each.value = location.filterValue_contains;
                //     }});

                locationList.push(location.korean);
            }

        });

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 5) {
                each.value = locationList;
            }
        })



        setFilterModel({ ...newFilterModel });

    }

    const handleChange3 = (e : any, newAlignment : string) => {

        if(!newAlignment){
            return;
        }

        setAlignment3(newAlignment);

        const newFilterModel: any = filterModel;

        newFilterModel.items.forEach((each : any) => {
            if(each.id === 3){
                switch(newAlignment){
                    case "current":
                        each.value = "true";
                        break;
                    case "not":
                        each.value = "false";
                        break;
                }
            }
        })

        setFilterModel({...newFilterModel});

    }

    const nameChange = (e: any) => {

        var isZero = false;
        var isNumber = false;

        if (e.target.value.length === 0) {
            isZero = true;
        }

        //e.target.value의 첫번째 글자가 숫자인지 확인
        if (!isZero && !isNaN(+e.target.value[0])) {
            isNumber = true;
        }

        setName(e.target.value);
        

        const newFilterModel: any = filterModel;

        if (!isZero && !isNumber) {
            newFilterModel.items.forEach((each: any) => {
                if (each.id === 2) {
                    each.value = e.target.value;
                }
            })
        }
        if (!isZero && isNumber) {
            newFilterModel.items.forEach((each: any) => {
                if (each.id === 6) {
                    each.value = e.target.value;
                }
            })
        }
        if (isZero) {
            newFilterModel.items.forEach((each: any) => {
                if (each.id === 2 || each.id === 6) {
                    each.value = "";
                }
            })
        }


        setFilterModel({ ...newFilterModel });
    }


    const handleClick = (e: any) => { setSelectedUserFingerprintId(e.row.fingerprintId); setSelectedUserId(e.id); setSelectedUserName(e.row.name); setTotalScore(e.row.demerit); setClickedId(e.row.id); }
    const handleOpen = () => { if (selectedUserFingerprintId) setOpen(true); }
    const handleClose = () => { setActive(true); setOpen(false); setScore(0); setMessage(""); if (clickedId) { setTotalScore(apiRef.current.getCellValue(clickedId, "demerit")); } }

    const handleOpen2 = () => { if (selectedUserFingerprintId) setOpen2(true); }
    const handleClose2 = () => { setOpen2(false); }

    const handleOpen3 = () => { if (selectedUserId) setOpen3(true); }
    const handleClose3 = () => { setOpen3(false); }

    const handleOpen4 = () => { if (selectedUserId) setOpen4(true); }
    const handleClose4 = () => { setOpen4(false); }


    const handleOpen5 = async () => {

        if (!selectedUserId) {
            return;
        }

        setProfileLoading(true);

        const response = await fetch(`https://peetsunbae.com/dashboard/avatar/studentProfileId?userId=${selectedUserId}`, {
            method: "GET",
            credentials: "include",
        });

        const result = await response.json();

        setProfileLoading(false);

        if (result.message === "notSended") {
            notify3();
            return;
        }

        if (result.message === "notSubmitted") {
            notify4();
            return;
        }

        if (result.message === "success") {

            const id = result.data.id;
            const code = result.data.code;

            const url = `https://suneungsunbae.com/studentcard?id=${id}&code=${code}&isView=true#2`;

            window.open(url, "_blank");

        }

    }

    // const handleOpen5 = () => { if (selectedUserId) setOpen5(true); }
    const handleClose5 = () => { setOpen5(false); }


    const changeMessage = (e: any) => {
        setMessage(e.target.value);
        if (e.target.value) {
            setActive(false);
        }
    }

    const submit = async (e: any) => {
        setDemeritLoading(true);
        
        
        
        

        var token = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        fetch(`https://peetsunbae.com/dashboard/avatar/addDemerit`, {
            method: "post",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                userId: selectedUserId,
                score: score,
                description: message,
                totalScore: totalScore
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        setDemeritLoading(false);
                        setUploadBool(true);
                        setTimeout(() => {
                            setUploadBool(false);
                        }, 1000);
                        setScore(0);
                        setMessage("");
                        setUpdate(Math.random());
                    }
                })
        })
    }



    useEffect(() => {
        setLoading(true);

        const start = async () => {
            var token = "";
            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            fetch("https://peetsunbae.com/dashboard/avatar/users", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {

                        
                        const newRows: any = [];
                        const teacherNewRows: any = [];
                        const studentNewRows: any = [];
                        result.data.forEach((each: any) => {
                            const oneRow: any = {};
                            const date = new Date(+each.createdAt);
                            oneRow.id = each.id;
                            oneRow.userId = each.id;
                            oneRow.name = each.name;
                            oneRow.phoneSeat = each.phoneSeat;
                            oneRow.grade = each.grade;
                            oneRow.job = each.job;
                            oneRow.email = each.email;
                            oneRow.chargedMealTicket = each.mealTicketAmount ? each.mealTicketAmount : 0;
                            if (each.checkSchedule) {
                                oneRow.checkSchedule = true;
                            }
                            var value;
                            switch (each.value) {
                                case "teacher":
                                    value = "직원";
                                    break;
                                case "parent":
                                    value = "학부모";
                                    var studentName = "";

                                    if (each.studentPhoneNumber) {

                                        result.data.forEach((eachUser: any) => {

                                            if (each.studentPhoneNumber === eachUser.phoneNumber) {
                                                oneRow.name += ` (${eachUser.name} 학부모)`
                                            }

                                        })

                                    }

                                    break;
                                case "student":
                                    value = "학생";
                                    break;
                            }
                            oneRow.kind = value;
                            if (each.isNow === 0) {
                                oneRow.status = false;
                            }
                            if (each.isNow === null) {
                                oneRow.status = true;
                            }
                            if (each.isNow === 1) {
                                oneRow.status = true;
                            }
                            oneRow.phoneNumber = each.phoneNumber;
                            oneRow.location = each.location;
                            oneRow.locationId = each.locationId;
                            oneRow.room = each.room;
                            oneRow.seat = each.seat;
                            if (oneRow.seat > 1000) {
                                oneRow.seat = "자유석 미지정"
                            }

                            oneRow.demerit = each.demeritScore ? each.demeritScore : 0;
                            oneRow.parentPhoneNumber = each.parentPhoneNumber;
                            oneRow.chargedMoney = each.amount ? each.amount : 0;
                            oneRow.fingerprintId = each.fingerprintId;
                            oneRow.firstCome = each.firstCome;
                            if (each.firstCome) {
                                oneRow.firstCome = new Date(each.firstCome);
                            }
                            oneRow.createdAt = `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
                            oneRow.teacher = each.teacherId;
                            oneRow.teacherId = each.teacherId;
                            if (each.teacherId === 1) {
                                oneRow.teacher = "상담X";
                            }
                            oneRow.teacherDescription = each.description;
                            newRows.push(oneRow);
                            if (each.value === "teacher") {
                                teacherNewRows.push(oneRow);
                            } else {
                                studentNewRows.push(oneRow);
                            }
                        })
                        setRows([...newRows]);
                        setTeacherRows([...teacherNewRows]);
                        setStudentRows([...studentNewRows]);
                        setLoading(false);
                    })
            })
        }

        start();
    }, [update]);




    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {
            var field = "";
            

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                    
                }
            }

            
            


            if (field === "status" || field === "teacher" || field === "checkSchedule") {
                
                

                fetch("https://peetsunbae.com/dashboard/avatar/userDetail", {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    credentials: "include",
                    body: JSON.stringify({
                        userId: newRow.id,
                        value: newRow[field] ? newRow[field] : false,
                        field
                    })
                }).then((response: any) => {
                    response.json()
                        .then((result: any) => {
                            
                        })
                })
            }


            if (field === "parentPhoneNumber" || field === "fingerprintId") {

                fetch("https://peetsunbae.com/dashboard/avatar/userDetail", {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    credentials: "include",
                    body: JSON.stringify({
                        userId: newRow.id,
                        value: newRow[field] ? newRow[field] : null,
                        field
                    })
                }).then((response: any) => {
                    response.json()
                        .then((result: any) => {
                            
                        })
                })

            }

            if (field === "firstCome") {

                var value: any = null;

                const rowValue = newRow[field];

                if (rowValue) {
                    const date = new Date(rowValue);
                    value = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`
                }

                fetch("https://peetsunbae.com/dashboard/avatar/userDetail", {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    credentials: "include",
                    body: JSON.stringify({
                        userId: newRow.id,
                        value: value,
                        field
                    })
                }).then((response: any) => {
                    response.json()
                        .then((result: any) => {
                            
                        })
                })
            }

            //mysql상 user table 아니고 teacher table 이라 분리해놨음
            if (field === "teacherDescription") {
                fetch("https://peetsunbae.com/dashboard/avatar/userDetail", {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    credentials: "include",
                    body: JSON.stringify({
                        userId: newRow.id,
                        value: newRow[field] ? newRow[field] : "",
                        field
                    })
                }).then((response: any) => {
                    response.json()
                        .then((result: any) => {
                            
                        })
                })
            }


            if (field === "location" || field === "room" || field === "seat" || field === "grade" || field === "job") {
                fetch("https://peetsunbae.com/dashboard/avatar/userDetail", {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    credentials: "include",
                    body: JSON.stringify({
                        userId: newRow.id,
                        value: newRow[field] ? newRow[field] : "",
                        field
                    })
                }).then((response: any) => {
                    response.json()
                        .then((result: any) => {
                            
                        })
                })
            }


            return newRow;
        }, []
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        
        alert(error.message);
    }, []);

    const sendCardUrl = () => {

        if (!selectedUserId || !selectedUserName) {
            return;
        }

        if (!window.confirm(`${selectedUserName} 학생에게 학생카드 설문 링크를 보내시겠습니까?`)) {
            return;
        }

        setSendCardLoading(true);

        fetch("https://peetsunbae.com/dashboard/avatar/sendcardurl", {
            method: "POST",
            headers: { "content-type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                studentId: selectedUserId
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if (result.message === "success") {
                        setSendCardLoading(false);
                        notify1();

                        if (!result.roomNumber) {
                            
                            return;
                        }

                        const chatRoomId = result.roomNumber;

                        props.socket.emit("newTeacherMessage", chatRoomId);
                    }
                })
        })

    }

    const sendNewTodo = async () => {

        if (!selectedUserId) {
            return;
        }

        if (!window.confirm(selectedUserName + "학생의 신규 TODO 시작할까요?")) {
            return;
        }

        setNewLoading(true);

        try {

            const result = await fetch("https://peetsunbae.com/dashboard/avatar/newtodo", {
                method: "POST",
                headers: { "content-type": "application/json" },
                credentials: "include",
                body: JSON.stringify({
                    userId: selectedUserId
                })
            })

            const data = await result.json();

            if (data.message === "success") {
                setNewLoading(false);
                notify1();
            }

            if (data.message === "alreadyExist") {
                setNewLoading(false);
                notify2();
            }

        } catch (e) {
            
        }

    }

    const exitTodo = async () => {

        if (!selectedUserId) {
            return;
        }

        if (!window.confirm(selectedUserName + "학생의 퇴원 TODO 시작할까요?")) {
            return;
        }

        setNewLoading(true);

        try {

            const result = await fetch("https://peetsunbae.com/dashboard/avatar/exittodo", {
                method: "POST",
                headers: { "content-type": "application/json" },
                credentials: "include",
                body: JSON.stringify({
                    userId: selectedUserId
                })
            })

            const data = await result.json();

            if (data.message === "success") {
                setNewLoading(false);
                notify1();
            }

            if (data.message === "alreadyExist") {
                setNewLoading(false);
                notify2();
            }

        } catch (e) {
            
        }

    }

    const handleClose6 = () => {
        setDirection("");
        setOpen6(false);
    }

    const handleOpen6 = () => {

        if (!selectedUserId) {
            return;
        }

        setDirection("");
        setOpen6(true);
    }

    const submit2 = async () => {

        const userId = selectedUserFingerprintId;

        if(!userId || !direction){
            alert("필수 항목을 입력해주세요.");
            return;
        }

        try{

            const data = {
                userId,
                direction,
                permission : 1
            }

            const result : any = await postFetch("/fingerprint/stampForManual", data);

            if(result.message === "success"){
                handleClose6();
            }else{
                alert("실패했습니다.");
            }

        }catch(e){
            alert(e);
            
        }

    }



    return (

        <div>
            {
                loading ?
                    <SkeletonComp />
                    :
                    <>
                        <div style={{ marginBottom: "18px" }}>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment3}
                                exclusive
                                onChange={handleChange3}
                            >
                                <ToggleButton value="current">재원생</ToggleButton>
                                <ToggleButton value="not">비재원생</ToggleButton>
                            </ToggleButtonGroup>
                        </div>

                        <div style={{ marginBottom: "18px" }}>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment2}
                                exclusive
                                onChange={handleChange2}
                            >
                                {
                                    props.user && totalLocationData.filter((each) => each.academy.includes(props.user.academy)).map((each, index) => {
                                        return (
                                            <ToggleButton value={each.english} key={index}>{each.korean}</ToggleButton>
                                        )
                                    })
                                }
                            </ToggleButtonGroup>
                        </div>
                        <div style={{ marginBottom: "24px" }}>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                            >
                                <ToggleButton value="all">ALL</ToggleButton>
                                <ToggleButton value="student">학생</ToggleButton>
                                <ToggleButton value="teacher">선생님</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div style={{ marginBottom: "12px", display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <CssVarsProvider>
                                    <Button onClick={handleOpen} sx={{ marginRight: "8px" }} variant="outlined">벌점 추가</Button>
                                    <Button onClick={handleOpen2} sx={{ marginRight: "8px" }} variant="outlined">출입기록 및 벌점</Button>
                                    <Button onClick={handleOpen3} sx={{ marginRight: "8px" }} variant="outlined">정기일정</Button>
                                    <Button onClick={handleOpen5} sx={{ marginRight: "8px" }} variant="outlined" loading={profileLoading}>프로필 보기</Button>
                                    <Button onClick={sendCardUrl} sx={{ marginRight: "8px" }} variant="outlined" loading={sendCardLoading}>프로필 작성 링크 발송</Button>
                                    <Button onClick={handleOpen4} sx={{ marginRight: "8px" }} variant="outlined">시험결과</Button>
                                    <Button onClick={sendNewTodo} sx={{ marginRight: "8px" }} variant="outlined">신규TODO</Button>
                                    <Button onClick={exitTodo} variant="outlined">퇴원TODO</Button>
                                    <Button onClick={handleOpen6} sx={{ marginLeft: "8px" }} variant="outlined">수동 출입 넣기</Button>
                                </CssVarsProvider>
                            </div>
                            <div>
                                {/* <input value={name} onChange={nameChange} id="standard-basic" placeholder="이름 or 전화번호"  /> */}
                                <CustomInput value={name} onChange={nameChange} placeholder="이름 or 전화번호" />
                            </div>
                        </div>
                        <div className={classes.root} style={{ height: 800, width: '100%', backgroundColor: "white" }}>
                            <DataGridPremium loading={loading} rows={rows} columns={columns}
                                components={{ Toolbar: GridToolbar }}
                                density='compact'
                                hideFooter
                                filterModel={filterModel}
                                onFilterModelChange={(model) => setFilterModel(model)}
                                onRowClick={handleClick}
                                processRowUpdate={handleEditCommit}
                                onProcessRowUpdateError={handleProcessRowUpdateError}
                                isCellEditable={(params: any) => {
                                    if (params.field === "teacherDescription") {
                                        if (!apiRef.current.getCellValue(params.id, "teacher")) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    } else if (params.field === "status" || params.field === "parentPhoneNumber" || params.field === "fingerprintId" || params.field === "firstCome" || params.field === "location" || params.field === "room" || params.field === "seat") {
                                        if (params.row.kind === "직원") {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    } else if (params.field === "job") {
                                        if (params.row.kind === "직원") {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    } else {
                                        return true
                                    }
                                }}
                                apiRef={apiRef}
                            />
                        </div>
                        <div className={styles.mysearchDescription}>
                            * 미답변 메세지 - 파랑색<br />
                        </div>

                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div style={{ fontFamily: "Apple_B", marginBottom: "12px" }}>
                                    {selectedUserName}
                                </div>
                                <div className={styles.messageTitle}>
                                    벌점
                                </div>
                                <div className={styles.autocompleteDiv}>
                                    <TextField
                                        type="number"
                                        fullWidth
                                        margin='dense'
                                        value={score}
                                        onChange={(e: any) => {
                                            var newTotalScore: any = totalScore;
                                            if (apiRef.current.getCellValue(clickedId, "demerit") === 0 || (apiRef.current.getCellValue(clickedId, "demerit") && e.target.value)) {
                                                newTotalScore = parseInt(apiRef.current.getCellValue(clickedId, "demerit")) + parseInt(e.target.value);
                                                setScore(e.target.value);
                                                
                                                setTotalScore(newTotalScore.toString());
                                            } else {
                                                setScore(e.target.value);
                                                setTotalScore(apiRef.current.getCellValue(clickedId, "demerit"));
                                            }
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: "8px" }} className={styles.messageTitle}>
                                    누적 벌점
                                </div>
                                <div className={styles.textfieldDiv}>
                                    <TextField margin='dense' type="number" value={totalScore} onChange={(e: any) => { setTotalScore(e.target.value); }} fullWidth id="outlined-basic" placeholder="월별 누적 벌점" variant="outlined" />
                                </div>
                                <div style={{ marginTop: "8px" }} className={styles.messageTitle}>
                                    사유
                                </div>
                                <div className={styles.textfieldDiv}>
                                    <TextField margin='dense' value={message} onChange={(e) => { changeMessage(e) }} fullWidth id="outlined-basic" placeholder="벌점 부여 사유" variant="outlined" />
                                </div>


                                {demeritLoading &&
                                    <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
                                        <LinearProgress />
                                    </Box>
                                }



                                {uploadBool &&
                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                        <Alert severity="info" sx={{ marginTop: 2, marginBottom: 2 }}><span className={styles.spanStyles2}>업로드 성공 !</span></Alert>
                                    </Stack>
                                }

                                <div style={{ display: "flex", justifyContent: "end", marginTop: "16px" }}>
                                    <CssVarsProvider>
                                        <Button onClick={submit} disabled={active} variant="soft"><span style={{ fontFamily: "Apple_R" }}>전송하기</span></Button>
                                    </CssVarsProvider>
                                </div>
                            </Box>
                        </Modal>

                        <Modal
                            open={open2}
                            onClose={handleClose2}
                        >
                            <Box sx={style2}>
                                <CalendarModal user={{ id: selectedUserId }} />
                            </Box>
                        </Modal>


                        <Modal
                            open={open3}
                            onClose={handleClose3}
                        >
                            <Box sx={style3}>
                                <RegularSchedule2 selectedUser={{ id: selectedUserId }} name={selectedUserName} isAvatar={true} />
                            </Box>
                        </Modal>


                        <Modal
                            open={open4}
                            onClose={handleClose4}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style4}>
                                <div className={styles.cancelBtn}>
                                    <img onClick={handleClose4} src="img/times-circle-light.svg" alt="cancel" />
                                </div>
                                <div className={styles.modalFirstDiv}>
                                    <TestResult selectedUser={{ id: selectedUserId }} name={selectedUserName} />
                                </div>
                            </Box>
                        </Modal>

                        <Modal
                            open={open5}
                            onClose={handleClose5}
                        >
                            <Box sx={style5}>
                                <div>
                                    <Profile
                                        selectedUser={{ id: selectedUserId }}
                                        handleClose={handleClose5}
                                    />
                                </div>
                            </Box>
                        </Modal>

                        <Modal
                            open={open6}
                            onClose={handleClose6}
                        >
                            <Box sx={style}>
                                <div style={{ fontFamily: "Apple_B", marginBottom: "12px" }}>
                                    {selectedUserName}
                                </div>
                                <div style={{ marginTop: "8px" }} className={styles.messageTitle}>
                                    방향
                                </div>
                                <div className={styles.textfieldDiv}>
                                    <RadioGroup
                                        value={direction}
                                        onChange={(e) => { setDirection(e.target.value) }}
                                        row>
                                        <FormControlLabel value="outside" control={<Radio />} label="입실" />
                                        <FormControlLabel value="inside" control={<Radio />} label="퇴실" />
                                    </RadioGroup>
                                </div>

                                <div style={{ display: "flex", justifyContent: "end", marginTop: "16px" }}>
                                    <CssVarsProvider>
                                        <Button onClick={submit2} disabled={!direction} variant="soft"><span style={{ fontFamily: "Apple_R" }}>전송하기</span></Button>
                                    </CssVarsProvider>
                                </div>
                            </Box>
                        </Modal>


                    </>

            }

        </div>



    )
}

export default AllStudents;

