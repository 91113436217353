import React, { useEffect, useState } from "react";
import { Button, Dropdown, IconButton, Input, Menu, MenuButton, Modal, Option, Radio, RadioGroup, Sheet, Select, ModalDialog, Textarea } from '@mui/joy';
import { AddTaskBySyncApi } from "./function";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewTodoModal: React.FC<any> = (props) => {

    const [description, setDescription] = useState<string>("");
    const [disabled, setDisabled] = useState<boolean>(true);
    const [registerLoading, setRegisterLoading] = useState<boolean>(false);
    const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
    const [selectedSectionId, setSelectedSectionId] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const notify1 = () => toast.success("업로드 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });



    const submit = async () => {

        try {

            console.log(selectedProjectId);
            console.log(selectedSectionId);
            console.log(description);

            setLoading(true);

            await AddTaskBySyncApi(description, selectedProjectId, selectedSectionId, props.apiKey);

            setLoading(false);

            notify1();

        } catch (e) {
            console.log(e);
        }

    }



    return (
        <Modal open={props.open} onClose={() => {
            setSelectedProjectId(null);
            setSelectedSectionId(null);
            setDescription("");

            props.handleClose();
        }}>
            <ModalDialog>
                <div style={{
                    fontFamily: "Apple_B",
                    fontSize: "18px",
                    marginBottom: "24px"
                }}>
                    새로운 TODO 등록하기
                </div>

                <div>
                    <div style={{
                        marginBottom: "12px",
                        fontSize: "17px",
                        fontFamily: "Apple_SB",
                    }}>
                        프로젝트 선택
                    </div>
                    <div style={{
                        display: "flex",
                        width: "400px",
                        flexWrap: "wrap"
                    }}>
                        <RadioGroup value={selectedProjectId} onChange={(e: any) => {
                            console.log(e.target.value);
                            setSelectedProjectId(e.target.value);
                            setSelectedSectionId("none");
                        }}
                        >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexWrap: "wrap"
                            }}>
                                {
                                    props.projects && props.projects.map((each: any) => {
                                        return (
                                            <div style={{
                                                marginBottom: "10px",
                                                marginRight: "18px",
                                                width: "110px"
                                            }}
                                            key={each.v2_id}
                                            >
                                                <Radio label={
                                                    <span
                                                        style={{
                                                            fontSize: "16px",
                                                            fontFamily: "Apple_R"
                                                        }}
                                                    >{each.name}</span>
                                                } value={each.v2_id} />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </RadioGroup>
                    </div>

                    <div style={{
                        marginBottom: "12px",
                        fontSize: "17px",
                        fontFamily: "Apple_SB",
                        marginTop: "12px"
                    }}>
                        섹션 선택
                    </div>

                    <div style={{

                        width: "400px",

                    }}>
                        <RadioGroup value={selectedSectionId} onChange={(e: any) => {
                            console.log(e.target.value);
                            setSelectedSectionId(e.target.value);
                        }}
                        >
                            <div style={{
                                width: "100%",
                                display: "grid",
                                //한줄당 2개씩
                                gridTemplateColumns: "1fr 1fr",
                                minHeight: "100px"
                            }}>
                                {
                                    (props.projects && selectedProjectId && props.projects.find((each: any) => each.v2_id === selectedProjectId).sections) && props.projects.find((each: any) => each.v2_id === selectedProjectId).sections.map((each: any, index: number) => {
                                        return (
                                            <div style={{
                                                marginBottom: "12px",
                                                marginRight: "18px",
                                                width: "150px",
                                            }}
                                            key={each.v2_id}
                                            >
                                                <Radio label={
                                                    <span
                                                        style={{
                                                            fontSize: "16px",
                                                            fontFamily: "Apple_R"
                                                        }}
                                                    >{each.name}</span>
                                                } value={
                                                    index === 0 ? "none" : each.v2_id
                                                } />
                                            </div>
                                        );
                                    }
                                    )
                                }
                            </div>
                        </RadioGroup>
                    </div>



                    <div style={{
                        marginBottom: "12px",
                        fontSize: "17px",
                        fontFamily: "Apple_SB",
                        marginTop: "12px"
                    }}>
                        전달사항
                    </div>
                    <div>
                        <Textarea minRows={2} value={description} onChange={(e: any) => { setDescription(e.target.value) }} />
                    </div>
                </div>

                <div style={{
                    marginTop: "32px"
                }}>
                    <Button variant="solid" size="sm" fullWidth onClick={submit} loading={loading} disabled={
                        !selectedProjectId || !selectedSectionId || !description
                    }>
                        등록하기
                    </Button>
                </div>
            </ModalDialog>
        </Modal>
    )

}

export default NewTodoModal;