import { Chip, CircularProgress, Divider, TextareaAutosize, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "../../../../componentsStyle/check.module.css";
import Picker from "emoji-picker-react";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { ReactComponent as ShapeLogoSvg } from '../../../../../svg/onlyshape.svg';

import SendIcon from '@mui/icons-material/Send';
import { Socket } from "socket.io-client";
import { each, throttle } from "lodash";
import { IonAvatar } from "@ionic/react";
import { Call } from "@mui/icons-material";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'white',
    outline: "none",
    borderRadius: "8px",
    boxShadow: "0 3px 25px -5px rgba(0,0,0,.35)"
};

// var savingContainer : any;

var newIndex = 0;
var isTop = false;

var previousNode: any;

const ChatForPhone: React.FC<any> = (props) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [data, setData] = useState<any>();
    const [message, setMessage] = useState("");

    const [update, setUpdate] = useState(0);

    const [isFocus, setIsFoucs] = useState(false);

    const [rows, setRows] = useState(1);

    const [showPicker, setShowPicker] = useState(false);

    const [fileName, setFileName] = useState<any>("");
    const [file, setFile] = useState<any>();

    const [link, setLink] = useState("");

    const [imgLoading, setImgLoading] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const [imgId, setImgId] = useState(0);

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);

    const [index, setIndex] = useState(0);

    const [chatRoomId, setChatRoomId] = useState();

    const chatRef = useRef<any>();
    const chatLastRef = useRef<any>();
    const voidRef = useRef<any>();

    const firstRef = useRef<any>(null);

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const [imageSrc, setImageSrc] = useState("");

    const [onDrag, setOnDrag] = useState(true);


    const twentythNode = useCallback((node: any) => {
        if (node !== null) {
            // 
            // 
            // 
            // chatRef.current.scrollTop = node.offsetTop - 20;
        }
    }, []);


    const scrollTopCallback = useCallback((node: any) => {


        if (node !== null && !isTop) {

            chatRef.current.scrollTop = chatRef.current.scrollHeight;

            previousNode = node;
        }

        if (isTop) {
            isTop = false;
        }


    }, []);

    const onImageLoad = () => {

        if (chatRef && chatRef.current) {

            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }

    const handleOpen2 = () => setOpen2(true);


    const handleFocus = (e: any) => {


        setIsFoucs(true);
    }

    const handleBlur = (e: any) => {

        setIsFoucs(false);
    }

    const handleChange = (e: any) => {
        setMessage(e.target.value);
    }

    const handleEmojiClick = (e: any, emojiObject: any) => {
        e.stopPropagation();
        setMessage((prevMessage: any) => prevMessage + emojiObject.emoji);
        setShowPicker(false);
    }

    const handleEmojiOpen = (e: any) => {
        e.stopPropagation();
        setShowPicker(!showPicker);
    }

    const mainClick = (e: any) => {
        setShowPicker(false);

    }

    const handleKeydown = (e: any) => {

        const keyCode = e.which || e.keyCode;

        if (keyCode === 13 && !e.shiftKey) {
            e.preventDefault();

            handleSubmit("e", "text");
        }

    }



    const handleSubmit = (e: any, kind: string) => {

        console.log("submit");
        console.log(message);

        const selectedPhoneNumber = props.selectedPhoneNumber;
        const selectedMyPhoneNumber = props.selectedMyPhoneNumber;
        const selectedMyPhoneNumberName = props.selectedMyPhoneNumberName;

        console.log(selectedPhoneNumber);
        console.log(selectedMyPhoneNumber);

        if(!message || !selectedPhoneNumber || !selectedMyPhoneNumber){
            return;
        }

        fetch("http://211.37.149.74:3007/sendsms", {
            method : "post",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({
                targetPhoneNumber : selectedPhoneNumber,
                myPhoneNumber : selectedMyPhoneNumber,
                text : message
            })
        })

        setMessage("");
        
        if(props.addFilteredRows){
            props.addFilteredRows({
                kind : "text",
                text : message,
                owner : "academy",
                myPhoneNumber : selectedMyPhoneNumber,
                myPhoneNumberName : selectedMyPhoneNumberName,
                phoneNumber : selectedPhoneNumber,
            });
        }

    }

    const handleOpenModal = (src: string) => {

        if (!src) {
            return;
        }


        setImageSrc(src);
        setOpen3(true);
    }

    function convertLinks(text: string) {

        if (!text) {
            return "";
        }

        var urlRegex = /(https?:\/\/\S+)/g;
        return text.replace(urlRegex, function (url: string) {
            // 마지막 문자가 알파벳이나 숫자가 아닌 경우, URL에서 제외합니다.
            var lastIndex = url.length - 1;
            var punctuation = url[lastIndex];
            if (!(/[a-zA-Z0-9]/.test(punctuation))) {
                url = url.substring(0, lastIndex);
            }
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        });
    }


    return (
        <div className={styles.chatMain} onClick={mainClick}
        >
            <div className={styles.chatHeader}>
                <div className={styles.profileAnchor}>
                    <div className={`${styles.profileContainer} ${styles.ofStudent}`}>
                        <img src="img/user-solid.svg" alt="user" className={styles.avatar} />
                    </div>
                    <span className={`${styles.userName} ${styles.chatHeaderName}`}>
                        {props.studentName && props.studentName}
                    </span>
                </div>
            </div>
            <div className={styles.chatBody} ref={chatRef}>
                <div className={styles.firstRef}>
                    {
                        loading &&
                        <div className={styles.loadingDiv}>
                            <CircularProgress />
                        </div>
                    }
                </div>
                {
                    props.data &&
                    props.data.map((eachData: any, indexNumber: number) => {

                        var timestring = eachData.dateOnlyTime;

                        if (eachData.kind !== "date") {
                            return (
                                <div key={eachData.id} className={styles.eachMessage} ref={(props.data && props.data.length - 1 === indexNumber && props.data.length !== 0) ? scrollTopCallback : (40 === indexNumber) ? twentythNode : (indexNumber === 5) ? firstRef : voidRef}>
                                    {
                                        eachData.owner === "academy" &&
                                        <>
                                            <div className={styles.profileAnchor}>
                                                <div className={styles.profileContainer}>
                                                    <ShapeLogoSvg className={styles.shapeLogo} />
                                                </div>
                                                <span className={styles.userName}>
                                                    {eachData.myPhoneNumberName ? eachData.myPhoneNumberName : eachData.myPhoneNumber}
                                                </span>
                                            </div>
                                            <div className={styles.msg_container}>
                                                <div className={styles.msg_item}>
                                                    {
                                                        eachData.kind === "text" &&
                                                        <span className={styles.msg_text_box}>
                                                            {eachData.text}
                                                        </span>
                                                    }
                                                    {
                                                        (eachData.kind === "img" && eachData.text) &&
                                                        <img style={{ cursor: "pointer" }} onClick={() => { handleOpenModal(eachData.message); }} onLoad={onImageLoad} src={`https://peetsunbae.com/${eachData.text.split("/public/")[1]}`} className={styles.msg_img}>
                                                        </img>
                                                    }
                                                    {
                                                        eachData.kind === "link" &&
                                                        <a href={eachData.message} target="_blank" className={styles.link}>
                                                            {eachData.text}
                                                        </a>
                                                    }
                                                    {
                                                        eachData.kind === "call" &&
                                                        <span className={styles.msg_text_box}>
                                                            <div style={{
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }}>
                                                                    <Call fontSize="small" style={{
                                                                        marginRight: "12px"
                                                                    }} /> 통화시간 : {eachData.duration}초 / 대기시간 : {eachData.waitTime}초
                                                                </div>
                                                                    <div>
                                                                        <div style={{
                                                                            marginTop : eachData.contents ? "24px" : "0"
                                                                        }}>
                                                                            {
                                                                                eachData.contents &&
                                                                                eachData.contents.map((eachContent: any, index: number) => {

                                                                                    var isOdd = index % 2 === 0;

                                                                                    return (
                                                                                        <div key={index} style={{
                                                                                            display: "flex",
                                                                                            marginBottom: "4px"
                                                                                        }}>
                                                                                            <div style={{
                                                                                                padding: "8px",
                                                                                                borderRadius: "8px",
                                                                                                color : isOdd ? "#12467b" : "#7d1212",
                                                                                            }}>
                                                                                                {eachContent.msg}
                                                                                            </div>
                                                                                        </div>
                                                                                    )

                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                        </span>
                                                    }
                                                </div>
                                                <div className={styles.msg_extra}>
                                                    <div className={styles.msg_extra_action}>
                                                        {/* {
                                                            ((props.user.value === "teacher" || props.user.value === "staff") && eachData.isRead === 0) &&
                                                            <div className={styles.unReadNumber}>
                                                            1
                                                            </div>
                                                        } */}

                                                        <div className={styles.timeString}>
                                                            {
                                                                timestring
                                                                    ?
                                                                    timestring
                                                                    :
                                                                    <CircularProgress size="10px" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    }
                                    {
                                        eachData.owner === "client" &&
                                        <>
                                            <div className={styles.profileAnchor}>
                                                {
                                                    !eachData.avatar ?
                                                        <div className={`${styles.profileContainer} ${styles.ofStudent}`}>
                                                            <img src="img/user-solid.svg" alt="user" className={styles.avatar} />
                                                        </div>
                                                        :
                                                        ""
                                                }
                                                {
                                                    eachData.avatar ?
                                                        <IonAvatar className={styles.profileContainer}>
                                                            <img alt="" src={`/img/avatar/Avatar-${eachData.avatar}.png`} className={styles.ionAvatarImg} />
                                                        </IonAvatar>
                                                        :
                                                        ""
                                                }
                                                <span className={styles.userName}>
                                                    {eachData.phoneNumberName ? eachData.phoneNumberName : eachData.phoneNumber}
                                                </span>
                                            </div>
                                            <div className={styles.msg_container}>
                                                <div className={`${styles.msg_item} ${styles.ofStudent}`}>
                                                    <span className={styles.msg_text_box}>
                                                        {
                                                            eachData.kind === "text" &&
                                                            <span className={styles.msg_text_box}>
                                                                <div dangerouslySetInnerHTML={{ __html: convertLinks(eachData.text) }} />
                                                            </span>
                                                        }
                                                        {
                                                            (eachData.kind === "img" && eachData.text) &&
                                                            <img style={{ cursor: "pointer" }} onLoad={onImageLoad} onClick={() => { handleOpenModal(eachData.text); }} src={`https://peetsunbae.com/${eachData.text.split("/public/")[1]}`} className={styles.msg_img}>
                                                            </img>
                                                        }
                                                        {
                                                            eachData.kind === "link" &&
                                                            <a href={eachData.message} target="_blank">
                                                                {eachData.text}
                                                            </a>
                                                        }
                                                        {
                                                            eachData.kind === "call" &&
                                                            <span className={styles.msg_text_box}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }}>
                                                                    <Call fontSize="small" style={{
                                                                        marginRight: "12px"
                                                                    }} /> 통화시간 : {eachData.duration}초 / 대기시간 : {eachData.waitTime}초
                                                                </div>
                                                                    <div>
                                                                        <div style={{
                                                                            marginTop : eachData.contents ? "24px" : "0"
                                                                        }}>
                                                                            {
                                                                                eachData.contents &&
                                                                                eachData.contents.map((eachContent: any, index: number) => {

                                                                                    var isOdd = index % 2 === 0;

                                                                                    return (
                                                                                        <div key={index} style={{
                                                                                            display: "flex",
                                                                                            marginBottom: "4px"
                                                                                        }}>
                                                                                            <div style={{
                                                                                                padding: "8px",
                                                                                                borderRadius: "8px",
                                                                                                color : isOdd ? "#12467b" : "#7d1212",
                                                                                            }}>
                                                                                                {eachContent.msg}
                                                                                            </div>
                                                                                        </div>
                                                                                    )

                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                            </span>
                                                        }
                                                    </span>
                                                </div>
                                                <div className={styles.msg_extra}>
                                                    <div className={styles.msg_extra_action}>
                                                        <div className={styles.timeString}>
                                                            {
                                                                timestring
                                                                    ?
                                                                    timestring
                                                                    :
                                                                    <CircularProgress size="10px" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            )
                        } else {
                            return (
                                <div key={eachData.id} ref={indexNumber === 40 ? twentythNode : voidRef} className={`${styles.divider} ${(indexNumber === 0 && loading) ? styles.dividerNotShown : ""} `}>
                                    <Divider light={true}>
                                        <Chip label={eachData.date} />
                                    </Divider>
                                </div>
                            )
                        }
                    })
                }
            </div>

            <div className={styles.chatFooter}>
                <div className={`${styles.inputDiv} ${isFocus ? styles.focus : ""}`}>
                    <div className={styles.messageDiv}>
                        {/* <textarea value={message} onChange={handleChange} rows={rows} className={styles.textarea} onFocus={handleFocus} onBlur={handleBlur} /> */}
                        <TextareaAutosize
                            autoFocus
                            placeholder="메시지를 입력하세요 (Shift + Enter로 줄바꿈, Ctrl + V로 이미지 넣기, Drag Drop으로 이미지 넣기 가능합니다.)"
                            className={styles.textarea}
                            value={message}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onKeyDown={handleKeydown}
                            onPaste={(e) => { }}
                        />
                    </div>
                    <div className={styles.messageBtns}>
                        <div className={styles.emoticons}>
                            <div className={styles.smile}>
                                <img onClick={handleEmojiOpen} src="img/face-smile-light.svg" className={styles.smileFace} />
                            </div>
                        </div>
                        <div onClick={(e: any) => { handleSubmit(e, "text") }} className={`${styles.sendBtn} ${message ? styles.active : ""}`}>
                            {
                                !loading2 &&
                                <SendIcon
                                color={
                                    message ?
                                        "primary" :
                                        "inherit"
                                }
                            />
                            }
                            {
                                loading2 &&
                                <CircularProgress size="20px" />

                            }
                            
                        </div>
                    </div>
                    {
                        showPicker &&
                        <div className={styles.emojiBox} onClick={(e: any) => { e.stopPropagation(); }}>
                            <Picker
                                onEmojiClick={handleEmojiClick}
                                pickerStyle={{ width: "100%" }}
                            />
                        </div>
                    }

                </div>
            </div>


        </div>
    )
}

export default ChatForPhone;