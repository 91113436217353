import { Button, IconButton, Option, Radio, RadioGroup, Select, Modal, Sheet } from '@mui/joy';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import styles from "../money.module.css";
import ModalHeaderForMoney from '../etc/modalheader';

import { Backdrop, CircularProgress, FormControl, MenuItem, OutlinedInput, Switch, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { getFetch, postFetch } from '../../../../../fetch/fetch';

import { Category, ControlPoint, ControlPointSharp, DeleteOutline, Filter, PhotoOutlined } from '@mui/icons-material';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { dateToDateString } from '../../etc/etcfunctions';
import { set } from 'lodash';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
};

const ManageRefundRatio: React.FC<any> = (props) => {

    const [rows, setRows] = useState<any>([]);
    const [columns, setColumns] = useState([
        { field: "startDate", headerName: "환불 정책 적용 시기", width: 200 },
        { field: "refund", headerName: "환불 정책", width: 700 },
        { field: "author", headerName: "작성자", width: 100 },
        { field: "createdAt", headerName: "작성일", width: 200 }
    ]);
    const [open, setOpen] = useState(false);

    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);

    const [submitLoading, setSubmitLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const [selectedRowId, setSelectedRowId] = useState<any>(0);

    const [update, setUpdate] = useState(0);
    const [loading, setLoading] = useState(false);

    const [optionList, setOptionList] = useState<any>([]);

    const notify1 = () => toast.success("전송 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const notify2 = () => toast.success("삭제 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const handleClose = () => {

        setYear(new Date().getFullYear());
        setMonth(new Date().getMonth() + 1);
        setOptionList([]);
        setOpen(false);
    }

    useEffect(() => {

        start();

    }, [update]);

    const start = async () => {

        try {

            setLoading(true);

            const result: any = await getFetch("/dashboard/payments/getrefundinfo");

            if (result.message === "success") {

                const data = result.data;

                const newRows: any = [];

                data.forEach((item: any) => {

                    const oneRow: any = {};

                    oneRow.id = item.id;

                    const year = item.year;
                    const month = item.month;

                    oneRow.startDate = year + "년 " + month + "월";

                    oneRow.author = item.name;

                    const createdAtDate = new Date(item.createdAt);

                    oneRow.createdAt = dateToDateString(createdAtDate, true);

                    const optionList = item.optionList;

                    var refundText = "";

                    optionList.forEach((option: any, index: number) => {

                        if(option.type === "date") {
                            refundText += option.date + "일 이내 일할 환불 가능";
                        }

                        if(option.type === "ratio") {
                            refundText += option.numerator + "/" + option.denominator + " 경과 시점 환불 가능";
                        }

                        if(index !== optionList.length - 1) {
                            refundText += " , ";
                        }


                    });

                    oneRow.refund = refundText;


                    newRows.push(oneRow);

                })

                setRows([...newRows]);

            }

            setLoading(false);

        } catch (e) {
            console.error(e);
        }

    }

    const submit = async () => {



        if (!year) {
            alert("년도를 입력해주세요");
            return;
        }

        if (!month) {
            alert("월을 입력해주세요");
            return;
        }

        setSubmitLoading(true);


        try {

            const body = {
                year: year,
                month: month,
                optionList
            }

            console.log(body);

            const result: any = await postFetch("/dashboard/payments/registerrefundinfo", body);

            if (result.message === "success") {

                notify1();
                setSubmitLoading(false);
                handleClose();
                setUpdate(Math.random());

            }

        } catch (e) {
            console.error(e);
        }

    }

    const handleDelete = async () => {

        try {

            if (!window.confirm("정말 삭제하시겠습니까?")) {
                return;
            }

            if (!selectedRowId) {
                return;
            }

            setDeleteLoading(true);

            const body = {
                id: selectedRowId
            }

            const result: any = await postFetch("/dashboard/payments/deleterefundinfo", body);

            if (result.message === "success") {

                setUpdate(Math.random());
                setSelectedRowId(0);
                notify2();

            }

            setDeleteLoading(false);

        } catch (e) {
            console.error(e);
        }

    }

    return (
        <div style={{ width: props.width, height: "500px", marginTop: "50px" }}>
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "20px"
            }}>
                <Button variant='soft' sx={{
                    marginRight: "15px"
                }}
                    color='neutral'
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    환불규정 설정하기
                </Button>
                <Button variant='soft'
                    color='neutral'
                    disabled={!selectedRowId}
                    onClick={handleDelete}
                    loading={deleteLoading}
                >
                    삭제하기
                </Button>
            </div>
            <DataGridPremium
                rows={rows}
                columns={columns}
                loading={loading}
                density='compact'
                onRowClick={(params) => {
                    setSelectedRowId(params.id);
                }}
            />


            <Modal
                open={open}
                onClose={handleClose}
                title="환불 규정 설정하기"
            >
                <Sheet sx={style}>
                    <ModalHeaderForMoney title="환불규정 설정하기" />
                    <div className={styles.modalBody}>
                        <div>
                            <div className={styles.title}>
                                환불 규정 설정하기
                            </div>
                            <div style={{ height: "8px" }}></div>
                            <div style={{

                            }}>
                                {
                                    optionList.map((item: any, index: number) => {

                                        return (
                                            <div key={index}
                                                style={{
                                                    marginTop: index !== 0 ? "16px" : "0px"
                                                }}
                                            >
                                                <div>
                                                    <RadioGroup
                                                        value={item.type}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            const newOptions = optionList;
                                                            newOptions[index].type = value;
                                                            setOptionList([...newOptions]);
                                                        }}
                                                        orientation='horizontal'
                                                    >
                                                        <Radio value="date" label={<span className={styles.radioText}>날짜</span>} />
                                                        <Radio value="ratio" label={<span className={styles.radioText}>비율</span>} />
                                                    </RadioGroup>
                                                </div>
                                                <div>
                                                    {
                                                        item.type === "date" ?
                                                            <div style={{
                                                                marginTop: "16px",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <div>
                                                                    <FormControl variant='outlined'>
                                                                        <OutlinedInput
                                                                            value={optionList[index].date} onChange={(e) => {

                                                                                var value = e.target.value;

                                                                                value = value.replace(/[^0-9]/g, '');
                                                                                const numberValue = Number(value);

                                                                                if (numberValue > 31) {
                                                                                    return;
                                                                                }

                                                                                const newOptions = optionList;
                                                                                newOptions[index].date = numberValue;
                                                                                setOptionList([...newOptions]);
                                                                            }}
                                                                            size='small'
                                                                            endAdornment={<div>일</div>}
                                                                            sx={{ width: "100px", marginRight: "1rem" }}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                                <div>
                                                                    까지 일할 환불 가능
                                                                </div>
                                                                <div className={styles.deleteDiv}>
                                                                    <DeleteOutline onClick={() => {
                                                                        const newOptionList = optionList;
                                                                        newOptionList.splice(index, 1);
                                                                        setOptionList([...newOptionList]);
                                                                    }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            :
                                                            ""
                                                    }
                                                    {
                                                        item.type === "ratio" ?
                                                            <div style={{
                                                                marginTop: "16px",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <div>
                                                                    <FormControl variant='outlined'>
                                                                        <OutlinedInput
                                                                            value={optionList[index].numerator} onChange={(e) => {

                                                                                var value = e.target.value;

                                                                                value = value.replace(/[^0-9]/g, '');
                                                                                const numberValue = Number(value);

                                                                                const newOptions = optionList;
                                                                                newOptions[index].numerator = numberValue;
                                                                                setOptionList([...newOptions]);

                                                                            }}
                                                                            size='small'
                                                                            sx={{ width: "100px", marginRight: "0.5rem" }}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                                <div>
                                                                    /
                                                                </div>
                                                                <div>
                                                                    <FormControl variant='outlined'>
                                                                        <OutlinedInput
                                                                            value={optionList[index].denominator} onChange={(e) => {

                                                                                var value = e.target.value;

                                                                                value = value.replace(/[^0-9]/g, '');
                                                                                const numberValue = Number(value);

                                                                                const newOptions = optionList;
                                                                                newOptions[index].denominator = numberValue;
                                                                                setOptionList([...newOptions]);

                                                                            }}
                                                                            size='small'
                                                                            sx={{
                                                                                width: "100px", marginRight: "1rem",
                                                                                marginLeft: "0.5rem"
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                                <div>
                                                                    경과 시점
                                                                </div>
                                                                <div className={styles.deleteDiv}>
                                                                    <DeleteOutline onClick={() => {
                                                                        const newOptionList = optionList;
                                                                        newOptionList.splice(index, 1);
                                                                        setOptionList([...newOptionList]);
                                                                    }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            :
                                                            ""
                                                    }
                                                </div>
                                            </div>
                                        )

                                    })
                                }
                                <div className={styles.clickPlus}
                                    style={{
                                        marginTop: "16px"
                                    }}
                                    onClick={(e) => {
                                        const newOptionList = optionList;
                                        const newOption = {
                                            type: "ratio",
                                            date: 0,
                                            numerator: 0,
                                            denominator: 0
                                        }
                                        newOptionList.push(newOption);
                                        setOptionList([...newOptionList]);
                                    }}>
                                    <div className={styles.plusIcon}>
                                        <ControlPointSharp className={styles.plusIcon} />
                                    </div>
                                    <div className={styles.plusIconText}>
                                        환불 규정 추가하기
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={styles.title}>
                            언제부터 적용할지 선택해주세요
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "12px"
                        }}>
                            <div>
                                <FormControl variant='outlined'>
                                    <OutlinedInput
                                        value={year} onChange={(e) => {

                                            var value = e.target.value;

                                            if (value.length > 4) {
                                                return;
                                            }
                                            //0-9만 입력 가능
                                            //value에 0-9가 아닌 문자가 포함되어 있으면 제거
                                            value = value.replace(/[^0-9]/g, '');
                                            setYear(Number(value));
                                        }}
                                        size='small'
                                        endAdornment={<div>년</div>}
                                        sx={{ width: "100px", marginLeft: "0rem", marginRight: "1rem" }}
                                        placeholder='2024'
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl variant='outlined'>
                                    <OutlinedInput
                                        value={month} onChange={(e) => {
                                            var value = e.target.value;

                                            if (value.length > 2) {
                                                return;
                                            }

                                            if (+value > 12) {
                                                return;
                                            }
                                            //0-9만 입력 가능
                                            //value에 0-9가 아닌 문자가 포함되어 있으면 제거
                                            value = value.replace(/[^0-9]/g, '');
                                            setMonth(Number(value));
                                        }}
                                        size='small'
                                        endAdornment={<div>월</div>}
                                        sx={{ width: "100px" }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div style={{
                            marginTop: "24px",
                            paddingBottom: "24px"
                        }}>
                            <Button
                                fullWidth
                                onClick={submit}
                                loading={submitLoading}
                            >
                                설정하기
                            </Button>
                        </div>
                    </div>
                </Sheet>
            </Modal>
        </div>
    )

}

export default ManageRefundRatio;