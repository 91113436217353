import React, { useEffect, useState } from 'react';
import styles from "./contentsstyle.module.css";
import SubmitContents from './components/submitcontents';
import ContentsStatus from './components/contentsstatus';
import ContentsTotalStatus from './components/contentstotalstatus';
import MenuManagement from '../controls/money/page/menu';




const ContentMain: React.FC<any> = (props) => {

    const [selectMenu, setSelectMenu] = useState("submit");

    useEffect(() => {

        props.activateMenuList("contents");

    }, []);

    const changeSelectedMenu = (e: any, menu: string) => {
        setSelectMenu(menu);
    }



    return (
        <div className={styles.main}>
            <div className={styles.mainTitle}>
                <img className={styles.mainImg} src="img/off/contents.svg" /> 모의고사 신청
            </div>

            <div className={styles.selectMenu}>
                <div onClick={(e) => { changeSelectedMenu(e, "submit") }} className={`selectedMenu ${selectMenu === "submit" ? "active" : ""}`}>
                    모의고사 신청
                </div>
                <div onClick={(e) => { changeSelectedMenu(e, "status") }} className={`selectedMenu ${selectMenu === "status" ? "active" : ""}`}>
                    나의 신청 현황
                </div>
                {
                    (props.user && (props.user.value === "teacher" || props.user.value === "staff"))
                        ?
                        <div onClick={(e) => { changeSelectedMenu(e, "totalstatus") }} className={`selectedMenu ${selectMenu === "totalstatus" ? "active" : ""}`}>
                            전체 신청 현황
                        </div>
                        :
                        ""
                }
                                {
                    (props.user && (props.user.value === "teacher" || props.user.value === "staff"))
                        ?
                        <div onClick={(e) => { changeSelectedMenu(e, "newmenu") }} className={`selectedMenu ${selectMenu === "newmenu" ? "active" : ""}`}>
                            모의고사 메뉴 관리
                        </div>
                        :
                        ""
                }
            </div>

            {
                selectMenu === "submit"
                    ?
                    <SubmitContents
                        socket={props.socket}
                        user={props.user}
                    />
                    :
                    ""
            }
            {
                selectMenu === "status"
                    ?
                    <ContentsStatus
                        socket={props.socket}
                        user={props.user}
                    />
                    :
                    ""
            }
            {
                selectMenu === "totalstatus"
                ?
                <ContentsTotalStatus
                    socket={props.socket}
                    user={props.user}
                />
                :
                ""
            }
            {
                selectMenu === "newmenu"
                ?
                <MenuManagement />
                :
                ""
            }


        </div>
    )
}

export default ContentMain;