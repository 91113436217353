import React, {useEffect, useState} from "react";
import styles from "./money.module.css";
import { getFetch } from "../../../../../fetch/fetch";
import { tableUseStyle } from "../../etc/tableusestyle";
import StyledDataGrid from "../../newtodo/styles/tablestyles";

const ManagePayment: React.FC<any> = (props) => {

    const [rows, setRows] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([
        {field : "location", headerName : "지점", width : 100},
        {field : "name", headerName : "이름", width : 100},
        {field : "seat", headerName : "자리", width : 100},
        {field : "status", headerName : "결제상태", width : 150},
        {field : "totalPrice", headerName : "청구금액", width : 150},
        {field : "remain", headerName : "미납금액", width : 150},
        {field : "firstCome", headerName : "첫등원일", width : 150},
        {field : "lastCome", headerName : "마지막등원일", width : 150},
        {field : "memo", headerName : "메모", width : 150},
    ]);
    const classes = tableUseStyle();

    const getTotalUserList = async () => {

        //수강료 리스트 가져오기
        //환불내역 정보 가져오기
        //학생들 리스트 가져오기 (재원생)
        //미납 리스트 가져오기

        try{

            const result = await getFetch("/dashboard/payments/managepayment/gettotaluserlist");

            console.log(result);

        }catch(e){
            console.log(e);
        }

    }

    useEffect(() => {

        getTotalUserList();

    }, []);


    return (
        <div style={{
            paddingTop : "40px"
        }}>
            <div className={classes.root} style={{ height : "900px", width: '96%', backgroundColor: "white", minWidth: "1500px" }}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    density='compact'
                    hideFooter
                />
            </div>
        </div>
    )
}

export default ManagePayment;