
import React, { useEffect, useState } from "react";
import { GridRenderCellParams, DataGridPro, GridRowsProp, GridColDef, GridToolbar, useGridApiRef, GridFilterModel, DataGridPremium } from '@mui/x-data-grid-premium';
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { returnPatrolTime } from "../../../data/locationFuntion";



const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .timeout': {
                    color: theme.palette.error.main,
                },
            },
        };
    },
    { defaultTheme },
);

interface row {
    field? : string;
    headerName? : string;
    width? : number;
    filterable? : boolean;
}


// const columns: GridColDef[] = [
//     { field: 'name', headerName: '위치', width: 120, filterable: true },
//     { field: 'one\_first', headerName: '1교시\_1', width: 100, filterable: false },
//     { field: 'one\_second', headerName: '1교시\_2', width: 100, filterable: false },
//     { field: 'one\_third', headerName: '1교시\_3', width: 100, filterable: false },
//     { field: 'two\_first', headerName: '2교시\_1', width: 100, filterable: false },
//     { field: 'three\_first', headerName: '3교시\_1', width: 100, filterable: false },
//     { field: 'four\_first', headerName: '4교시\_1', width: 100, filterable: false },
//     { field: 'five\_first', headerName: '5교시\_1', width: 100, filterable: false },
//     { field: 'six\_first', headerName: '6교시\_1', width: 100, filterable: false },
// ];






const QrCheck: React.FC<any> = (props) => {
    const [loading, setLoading] = useState(false);
    const [columns, setCoulumns] = useState<any[]>([]);
    const [rows, setRows] = useState<any>([]);
    const classes = useStyles2();
    const [targetTime, setTargetTime] = useState(new Date().getTime());
    const [targetDate, setTargetDate] = useState(new Date());

    //column 만들기
    useEffect(() => {

        if(!props.user){
            return;
        }

        makeColumns();
        makeRows(targetTime);
        setTargetDate(new Date(targetTime));

    }, [targetTime, props.user]);

    const makeColumns = () => {

        const newColumns = [{ field: 'name', headerName: '위치', width: 120, filterable: true }];

        const academy = props.user.academy;

        if(!academy){
            return;
        }

        const classData = returnPatrolTime(academy);

        classData.forEach((each : any, index : number) => {
            each.time.forEach((eachTime : string, indexNumber : number) => {

                var index_ = index;

                if(props.user.academy === "ubase"){
                    index_ = index + 1;
                }

                const newOneColumn : any = {};
                newOneColumn.field = `${index}\_${indexNumber}`;
                newOneColumn.headerName = `${index_}교시\_${indexNumber + 1}`;
                newOneColumn.width = 80;
                newOneColumn.filterable = false;
                newColumns.push(newOneColumn);
            })
        })

        
        setCoulumns([...newColumns]);

    }

    const makeRows = async (time : number) => {

        const academy = props.user.academy;

        if(!academy){
            return;
        }

        const classData = returnPatrolTime(academy);

        const unFilteredNewRows : any = [
            { id: "fivea", name: "5층 A", academy : "sunbae" },
            { id: "fiveb", name: "5층 B", academy : "sunbae"},
            { id: "fivec", name: "5층 C", academy : "sunbae"},
            { id: "fived", name: "5층 D", academy : "sunbae"},
            { id: "fivee", name: "5층 E", academy : "sunbae" },
            { id: "fivef", name: "5층 F", academy : "sunbae"},
            { id: "fiveg", name :  "5층 G", academy : "sunbae" },
            { id: "fiveh", name : "5층 H", academy : "sunbae" },
            { id: "fivei", name : "5층 I", academy : "sunbae" },
            { id: "fivej", name : "5층 J", academy : "sunbae"},
            { id : "daechi1", name : "대치 1", academy : "sunbae"},
            { id : "daechi2", name : "대치 2", academy : "sunbae"},
            { id : "daechi3", name : "대치 3", academy : "sunbae"},
            { id : "daechi4", name : "대치 4", academy : "sunbae"},
            { id : "daechi5", name : "대치 5", academy : "sunbae"},
            { id : "daechi6", name : "대치 6", academy : "sunbae"},
            { id : "daechi7", name : "대치 7", academy : "sunbae"},
            { id : "daechi8", name : "대치 8", academy : "sunbae"},
            { id : "daechi9", name : "대치 9", academy : "sunbae"},
            { id : "daechi10", name : "대치 10", academy : "sunbae"},
            { id : "daechi11", name : "대치 11", academy : "sunbae"},
            { id : "daechi21", name : "대치3F 1", academy : "sunbae"},
           { id : "daechi22", name : "대치3F 2", academy : "sunbae"},
           { id : "daechi23", name : "대치3F 3", academy : "sunbae"},
           { id : "daechi24", name : "대치3F 4", academy : "sunbae"},
           { id : "daechi25", name : "대치3F 5", academy : "sunbae"},
           { id : "daechi26", name : "대치3F 6", academy : "sunbae"},
           { id : "daechi27", name : "대치3F 7", academy : "sunbae"},
           { id : "daechi28", name : "대치3F 8", academy : "sunbae"},
           { id : "daechi29", name : "대치3F 9", academy : "sunbae"},
           { id : "daechi210", name : "대치3F 10", academy : "sunbae"},
            { id : "daechi211", name : "대치3F 11", academy : "sunbae"},
            { id : "daechi31", name : "대치6F 1", academy : "sunbae"},
           { id : "daechi32", name : "대치6F 2", academy : "sunbae"},
           { id : "daechi33", name : "대치6F 3", academy : "sunbae"},
           { id : "daechi34", name : "대치6F 4", academy : "sunbae"},
           { id : "daechi35", name : "대치6F 5", academy : "sunbae"},
           { id : "daechi36", name : "대치6F 6", academy : "sunbae"},
           { id : "daechi37", name : "대치6F 7", academy : "sunbae"},
           { id : "daechi38", name : "대치6F 8", academy : "sunbae"},
           { id : "daechi39", name : "대치6F 9", academy : "sunbae"},
           { id : "daechi310", name : "대치6F 10", academy : "sunbae"},
            { id : "daechi311", name : "대치6F 11", academy : "sunbae"},
            { id : "songdo_fixed1", name : "송도 1", academy : "mom"},
            { id : "songdo_fixed2", name : "송도 2", academy : "mom"},
            { id : "songdo_fixed3", name : "송도 3", academy : "mom"},
            { id : "songdo_fixed4", name : "송도 4", academy : "mom"},
            { id : "songdo_fixed5", name : "송도 5", academy : "mom"},
            { id : "songdo_fixed6", name : "송도 6", academy : "mom"},
            { id : "songdo_fixed7", name : "송도 7", academy : "mom"},
            { id : "songdo_fixed8", name : "송도 8", academy : "mom"},
            { id : "molip1", name : "몰입 1", academy : "molip"},
            { id : "molip2", name : "몰입 2", academy : "molip"},
            { id : "molip3", name : "몰입 3", academy : "molip"},
            { id : "molip4", name : "몰입 4", academy : "molip"},
            { id : "molip5", name : "몰입 5", academy : "molip"},
            { id : "molip6", name : "몰입 6", academy : "molip"},
            { id : "molip7", name : "몰입 7", academy : "molip"},
            { id : "molip8", name : "몰입 8", academy : "molip"},
            { id : "molip9", name : "몰입 9", academy : "molip"},
            { id : "molip10", name : "몰입 10", academy : "molip"},
            { id : "molip11", name : "몰입 11", academy : "molip"},
            //academy : bundangsunbae   는 1에서 20까지
            { id : "bundang1", name : "분당 1", academy : "bundangsunbae"},
            { id : "bundang2", name : "분당 2", academy : "bundangsunbae"},
            { id : "bundang3", name : "분당 3", academy : "bundangsunbae"},
            { id : "bundang4", name : "분당 4", academy : "bundangsunbae"},
            { id : "bundang5", name : "분당 5", academy : "bundangsunbae"},
            { id : "bundang6", name : "분당 6", academy : "bundangsunbae"},
            { id : "bundang7", name : "분당 7", academy : "bundangsunbae"},
            { id : "bundang8", name : "분당 8", academy : "bundangsunbae"},
            { id : "bundang9", name : "분당 9", academy : "bundangsunbae"},
            { id : "bundang10", name : "분당 10", academy : "bundangsunbae"},
            { id : "bundang11", name : "분당 11", academy : "bundangsunbae"},
            { id : "bundang12", name : "분당 12", academy : "bundangsunbae"},
            { id : "bundang13", name : "분당 13", academy : "bundangsunbae"},
            { id : "bundang14", name : "분당 14", academy : "bundangsunbae"},
            { id : "bundang15", name : "분당 15", academy : "bundangsunbae"},
            { id : "bundang16", name : "분당 16", academy : "bundangsunbae"},
            { id : "bundang17", name : "분당 17", academy : "bundangsunbae"},
            { id : "bundang18", name : "분당 18", academy : "bundangsunbae"},
            { id : "bundang19", name : "분당 19", academy : "bundangsunbae"},
            { id : "bundang20", name : "분당 20", academy : "bundangsunbae"},
            { id : "songpa1", name : "송파 1", academy : "sunbae"},
            //송파 1에서 12까지
            { id : "songpa2", name : "송파 2", academy : "sunbae"},
            { id : "songpa3", name : "송파 3", academy : "sunbae"},
            { id : "songpa4", name : "송파 4", academy : "sunbae"},
            { id : "songpa5", name : "송파 5", academy : "sunbae"},
            { id : "songpa6", name : "송파 6", academy : "sunbae"},
            { id : "songpa7", name : "송파 7", academy : "sunbae"},
            { id : "songpa8", name : "송파 8", academy : "sunbae"},
            { id : "songpa9", name : "송파 9", academy : "sunbae"},
            { id : "songpa10", name : "송파 10", academy : "sunbae"},
            { id : "songpa11", name : "송파 11", academy : "sunbae"},
            { id : "songpa12", name : "송파 12", academy : "sunbae"},
            //ubase 1에서 5까지
            { id : "ubase1", name : "ubase 1", academy : "ubase"},
            { id : "ubase2", name : "ubase 2", academy : "ubase"},
            { id : "ubase3", name : "ubase 3", academy : "ubase"},
            { id : "ubase4", name : "ubase 4", academy : "ubase"},
            { id : "ubase5", name : "ubase 5", academy : "ubase"},
        ]

        const newRows = unFilteredNewRows.filter((each : any) => {

            if(props.user.academy === each.academy){
                return true;
            }else{
                return false;
            }

        });
        

        newRows.forEach((each : any, index : number) => {

            const academy = props.user.academy;


            classData.forEach((eachClass : any, indexNumber : number) => {
                eachClass.time.forEach((eachTime : any, indexNumber2 : number) => {
                    each[`${indexNumber}\_${indexNumber2}`] = [];
                })
            })
        })

        
        

        var token = "";

        fetch(`https://peetsunbae.com/dashboard/report/qrcheck?time=${time}`, {
            method: "GET",
            credentials: "include",
        }).then((response) => {
            response.json()
                .then((result) => {
                    


                    result.data.forEach((qrcheck: any) => {
                        const qrcheckTime = new Date(qrcheck.createdAt);
                        const evaluateTime = qrcheckTime.getHours() * 60 + qrcheckTime.getMinutes();

                        newRows.forEach((eachRow : any, index : number) => {

                            

                            if(qrcheck.location + qrcheck.room === eachRow.id){

                                classData.forEach((eachClass : any, indexNumber : number) => {

                                    eachClass.time.forEach((eachTime : any, indexNumber2 : number) => {
                                        const targetHour = +eachTime.split(":")[0];
                                        const targetMinute = +eachTime.split(":")[1];
                                        const targetTime = targetHour * 60 + targetMinute;

                                        const targetMinimum = targetTime - 5;
                                        const targetMaximum = targetTime + 15;

                                        if(evaluateTime > targetMinimum && evaluateTime <= targetMaximum){
                                            eachRow[`${indexNumber}\_${indexNumber2}`].push(qrcheck);
                                        }
                                    })

                                })

                            }
                        })

                    })

                    newRows.forEach((eachRow : any) => {
                        for (const [key, value] of Object.entries(eachRow)) {
                            if (key !== "name" && key !== "id") {
                                if (eachRow[key].length > 0) {
                                    const length = eachRow[key].length;
                                    const lastQr = eachRow[key][length - 1];
                                    eachRow[key] = `${new Date(lastQr.createdAt).getHours() < 10 ? "0" + new Date(lastQr.createdAt).getHours() : new Date(lastQr.createdAt).getHours()}:${new Date(lastQr.createdAt).getMinutes() < 10 ? "0" + new Date(lastQr.createdAt).getMinutes() : new Date(lastQr.createdAt).getMinutes()}:${new Date(lastQr.createdAt).getSeconds() < 10 ? "0" + new Date(lastQr.createdAt).getSeconds() : new Date(lastQr.createdAt).getSeconds()}`;
                                } else {
                                    eachRow[key] = "";
                                }
                            }
                        }
                    })

                    
                    setRows(newRows);

                })
        }).catch((e) => {
            
        })
    }



    // const start = (time : number) => {
    //     
    //     const newRows = [
    //         { id: "sixone", name: "sixone", one: [], two: [], three: [], four: [], five: [], six: [], seven: [], eight: [], nine: [] },
    //         { id: "sixtwo", name: "sixtwo", one: [], two: [], three: [], four: [], five: [], six: [], seven: [], eight: [], nine: [] },
    //         { id: "sixthree", name: "sixthree", one: [], two: [], three: [], four: [], five: [], six: [], seven: [], eight: [], nine: [] },
    //         { id: "sixfour", name: "sixfour", one: [], two: [], three: [], four: [], five: [], six: [], seven: [], eight: [], nine: [] },
    //         { id: "sixrest", name: "sixrest", one: [], two: [], three: [], four: [], five: [], six: [], seven: [], eight: [], nine: [] },
    //         { id: "sixwait", name: "sixwait", one: [], two: [], three: [], four: [], five: [], six: [], seven: [], eight: [], nine: [] },
    //         { id: "foura", name: "foura", one: [], two: [], three: [], four: [], five: [], six: [], seven: [], eight: [], nine: [] },
    //         { id: "fourb", name: "fourb", one: [], two: [], three: [], four: [], five: [], six: [], seven: [], eight: [], nine: [] },
    //         { id: "fourc", name: "fourc", one: [], two: [], three: [], four: [], five: [], six: [], seven: [], eight: [], nine: [] },
    //         { id: "fourd", name: "fourd", one: [], two: [], three: [], four: [], five: [], six: [], seven: [], eight: [], nine: [] },
    //     ]

    //     var token = "";

    //     fetch(`https://peetsunbae.com/dashboard/report/qrcheck?time=${time}`, {
    //         method: "GET",
    //         credentials: "include",
    //     }).then((response) => {
    //         response.json()
    //             .then((result) => {
    //                 

    //                 result.data.forEach((qrcheck: any) => {
    //                     const qrcheckTime = new Date(qrcheck.createdAt);
    //                     const evaluateTime = qrcheckTime.getHours() * 60 + qrcheckTime.getMinutes();

    //                     newRows.forEach((eachRow: any) => {
    //                         if (qrcheck.location + qrcheck.room === eachRow.name) {
    //                             if (evaluateTime >= 540 && evaluateTime <= 620) {
    //                                 eachRow.one.push(qrcheck);
    //                             } else if (evaluateTime >= 630 && evaluateTime <= 710) {
    //                                 eachRow.two.push(qrcheck);
    //                             } else if (evaluateTime >= 800 && evaluateTime <= 880) {
    //                                 eachRow.three.push(qrcheck);
    //                             } else if (evaluateTime >= 890 && evaluateTime <= 970) {
    //                                 eachRow.four.push(qrcheck);
    //                             } else if (evaluateTime >= 980 && evaluateTime <= 1040) {
    //                                 eachRow.five.push(qrcheck);
    //                             } else if (evaluateTime >= 1120 && evaluateTime <= 1150) {
    //                                 eachRow.six.push(qrcheck);
    //                             } else if (evaluateTime >= 1170 && evaluateTime <= 1200) {
    //                                 eachRow.seven.push(qrcheck);
    //                             } else if (evaluateTime >= 1200 && evaluateTime <= 1290) {
    //                                 eachRow.eight.push(qrcheck);
    //                             } else if (evaluateTime >= 1290 && evaluateTime <= 1320) {
    //                                 eachRow.nine.push(qrcheck);
    //                             }
    //                         }
    //                     })
    //                 })

    //                 newRows.forEach((eachRow: any) => {
    //                     switch (eachRow.name) {
    //                         case "sixone":
    //                             eachRow.name = "6층 1열람실";
    //                             break;
    //                         case "sixtwo":
    //                             eachRow.name = "6층 2열람실";
    //                             break;
    //                         case "sixthree":
    //                             eachRow.name = "6층 3열람실";
    //                             break;
    //                         case "sixfour":
    //                             eachRow.name = "6층  4열람실";
    //                             break;
    //                         case "sixwait":
    //                             eachRow.name = "6층 대기실";
    //                             break;
    //                         case "sixrest":
    //                             eachRow.name = "6층 휴게실";
    //                             break;
    //                         case "foura":
    //                             eachRow.name = "4층 A열람실";
    //                             break;
    //                         case "fourb":
    //                             eachRow.name = "4층 B열람실";
    //                             break;
    //                         case "fourc":
    //                             eachRow.name = "4층 C열람실";
    //                             break;
    //                         case "fourd":
    //                             eachRow.name = "4층 D열람실";
    //                             break;
    //                         case "fours":
    //                             eachRow.name = "4층 S열람실";
    //                             break;
    //                         case "secondleftone":
    //                             eachRow.name = "2호점 칸막이1";
    //                             break;
    //                         case "secondlefttwo":
    //                             eachRow.name = "2호점 칸막이2";
    //                             break;
    //                         case "secondleftthree":
    //                             eachRow.name = "2호점 칸막이3";
    //                             break;
    //                         case "secondopenone":
    //                             eachRow.name = "2호점 오픈석1";
    //                             break;
    //                         case "secondopentwo":
    //                             eachRow.name = "2호점 오픈석2";
    //                             break;
    //                         case "secondopenthree":
    //                             eachRow.name = "2호점 오픈석3";
    //                             break;
    //                     }


    //                     for (const [key, value] of Object.entries(eachRow)) {
    //                         if (key !== "name" && key !== "id") {
    //                             if (eachRow[key].length > 0) {
    //                                 const length = eachRow[key].length;
    //                                 const lastQr = eachRow[key][length - 1];
    //                                 eachRow[key] = `${new Date(lastQr.createdAt).getHours() < 10 ? "0" + new Date(lastQr.createdAt).getHours() : new Date(lastQr.createdAt).getHours()}:${new Date(lastQr.createdAt).getMinutes() < 10 ? "0" + new Date(lastQr.createdAt).getMinutes() : new Date(lastQr.createdAt).getMinutes()}:${new Date(lastQr.createdAt).getSeconds() < 10 ? "0" + new Date(lastQr.createdAt).getSeconds() : new Date(lastQr.createdAt).getSeconds()}`;
    //                             } else {
    //                                 eachRow[key] = "";
    //                             }
    //                         }
    //                     }

    //                 })


    //                 
    //                 
    //                 setRows(newRows);
    //                 setLoading(false);

    //             })
    //     })

    // }


    const previousDay = () => {
        var newDate = new Date(targetTime);
        newDate.setDate(newDate.getDate()-1);
        setTargetTime(newDate.getTime());
    }

    const nextDay = () => {
        var newDate = new Date(targetTime);
        newDate.setDate(newDate.getDate()+1);
        setTargetTime(newDate.getTime());
    }


    return (
        <div>
            <div style={{display : "flex", justifyContent : "space-between",  marginTop: "48px", marginBottom: "12px"}}>
                <div>
                    <div style={{fontFamily: "Apple\_B" }}>
                        순찰 가이드라인
                    </div>
                    <div>
                        1. QR CODE 태깅 전 열람실에 자는 학생은 깨워주시고 딴짓 하는 사람한테는 주의주세요.
                    </div>
                    <div>
                        2. 순찰 돌 때는 항상 출석부 판 들고 돌아주세요.
                    </div>
                </div>
                <div>
                    <div style={{marginBottom : "6px", fontFamily : "Apple\_B"}}>
                        {targetDate.getFullYear()}-{targetDate.getMonth()+1}-{targetDate.getDate()}
                    </div>
                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                        <Button onClick={previousDay}>◁</Button>
                        <Button onClick={nextDay}>▷</Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className={classes.root} style={{ height: 500, width: '100%', backgroundColor: "white", marginTop: "12px" }}>

                <DataGridPremium loading={loading} rows={rows} columns={columns}
                    density='compact'
                    disableRowSelectionOnClick={true}
                    getCellClassName={(params: any) => {
                        if (!params.value) {
                            return "super-app-theme--확인";
                        } else {
                            return "";
                        }
                    }}
                />

            </div>
        </div>
    )
}

export default QrCheck;