import React, { useCallback, useEffect, useState } from "react";
import { getFetch } from "../../../../fetch/fetch";
import { filterAdminRows, insertDateRow, makeRows, mmsImageToNewRow, postNameToPhoneNumber, refinedPhoneNumber, sortRows } from "./components/phonefunctions";
import { tableUseStyle } from "../etc/tableusestyle";
import { DataGridPremium, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef, gridDetailPanelExpandedRowIdsSelector, GridFilterModel, GridRenderCellParams, useGridApiContext, useGridSelector } from "@mui/x-data-grid-premium";
import { IconButton, InputAdornment, SwipeableDrawer, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Call, CallOutlined, CheckCircle, ExpandMore, Sms, SmsOutlined } from "@mui/icons-material";
import { renderCellExpand } from "../newattendance/infos/suddenData";
import { fil } from "date-fns/locale";
import { set } from "lodash";
import ChatForTodos from "../chatforTodos";
import ChatForPhone from "./components/chatforPhone";
import { englishLocationToKorean } from "../../../../data/locationFuntion";
import totalLocationData from "../../../../data/locationdata";

const ManagePhoneMain: React.FC<any> = (props) => {

    const classes = tableUseStyle();
    const [columns, setColumns] = useState<GridColDef[]>([
        { field: "direction", headerName: "방향", width: 100 },
        {
            field: "typeName", headerName: "종류", width: 60, filterable : true
            //     renderCell : (params) => {

            //     if(params.value === "문자"){
            //         return (
            //             <div style={{
            //                 display : "flex",
            //                 justifyContent : "center",
            //                 alignItems : "center",
            //                 width : "100%",
            //                 height : "100%"
            //             }}>
            //                 <SmsOutlined />
            //             </div>
            //         );
            //     }else if(params.value === "통화"){
            //         return (
            //             <div style={{
            //                 display : "flex",
            //                 justifyContent : "center",
            //                 alignItems : "center",
            //                 width : "100%",
            //                 height : "100%"
            //             }}>
            //                 <CallOutlined />
            //             </div>
            //         );
            //     }

            //     return ""

            // } 
        },
        { field: "phoneNumberName", headerName: "상대방", width: 140 },
        { field: "myPhoneNumberName", headerName: "책임자", width: 140 },
        { field: "description", headerName: "내용", width: 700 },
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            renderCell: (params) => {
                return <CustomDetailPanelToggle id={params.id} value={params.value} />
            }
        },
        {
            field: "durationString", headerName: "통화시간", width: 100
        },
        {
            field: "waitTimeString", headerName: "대기시간", width: 100
        },
        { field: "date", headerName: "날짜", width: 200 },
    ]);
    const [rows, setRows] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [filteredRows, setFilteredRows] = useState<any>([]);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>("");
    const [selectedMyPhoneNumber, setSelectedMyPhoneNumber] = useState<string>("");
    const [selectedMyPhoneNumberName, setSelectedMyPhoneNumberName] = useState<string>("");
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [chatTitle, setChatTitle] = useState<string>("");
    const [alignment, setAlignment] = useState<string>("전체");
    const [alignment2, setAlignment2] = useState<string>("전체");

    const [numberList, setNumberList] = useState<any>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items : [
            {
                id : 1,
                field : "myPhoneNumberName",
                operator : "contains",
                value : ""
            },
            {
                id : 2,
                field : "typeName",
                operator : "contains",
                value : ""
            },
            {
                id : 3,
                field : "phoneNumberName",
                operator : "contains",
                value : ""
            }
        ],
    });

    useEffect(() => {

        if(!props.user) return;

        getData();

    }, [props.user]);

    function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
        const { id, value: isExpanded }: any = props;
        const apiRef = useGridApiContext();


        // To avoid calling ´getDetailPanelContent` all the time, the following selector
        // gives an object with the detail panel content for each row id.
        const contentCache = useGridSelector(
            apiRef,
            gridDetailPanelExpandedRowIdsSelector,
        );

        const currentRow = apiRef.current.getRow(id);

        if (!currentRow) {
            return null;
        }

        var isSms = false;

        if (currentRow.type === "smsManager" || currentRow.type === "mmsManager") {
            isSms = true;
        }

        var isDurationZero = true;
        //currentRow.description 이 '통화시간 - 0s' 안 포함하고 있으면 isDurationZero = false;
        if (currentRow.contents) {
            isDurationZero = false;
        }

        // If the value is not a valid React element, it means that the row has no detail panel.
        const hasDetail = React.isValidElement(contentCache[id]);

        if (!isSms && !isDurationZero) {

            return (
                <IconButton
                    size="small"
                    tabIndex={-1}
                    disabled={false}
                    aria-label={isExpanded ? 'Close' : 'Open'}
                >
                    <ExpandMore
                        sx={{
                            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                            transition: (theme) =>
                                theme.transitions.create('transform', {
                                    duration: theme.transitions.duration.shortest,
                                }),
                        }}
                        fontSize="inherit"
                    />
                </IconButton>
            );
        } else {
            return (
                <></>
            )
        }

    }

    const getDetailPanelContent = useCallback(
        ({ row }: any) => {

            return (
                <div>
                    <div
                        style={{
                            paddingTop: "15px",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            paddingBottom: "15px",
                        }}
                    >
                        {
                            (row && row.contents && row.contents.length > 0) && row.contents.map((eachContent: any, index: number) => {

                                return (
                                    <div key={Math.random()} style={{
                                        marginBottom: "15px",
                                        fontFamily: "Apple_R",
                                        lineHeight: 1.3,
                                        paddingLeft: "500px"
                                    }}>
                                        <span
                                            style={{
                                                fontFamily: "Apple_R",
                                                color: index % 2 === 0 ? "blue" : "red"
                                            }}
                                        >화자{index % 2 === 0 ? "A" : "B"}</span> : {eachContent.msg}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        },
        [],
    );

    const getData = async (needLoading : Boolean = true) => {

        try {

            if(needLoading){
                setLoading(true);
            }

            console.log("getData");

            const fetchPromises: any[] = [];

            fetchPromises.push(getPhoneData);
            fetchPromises.push(getTotalUsers);

            const result = await Promise.all(fetchPromises.map((fetchPromise) => fetchPromise()));

            console.log(result);

            const phoneData = result[0];
            const totalUsers = result[1];

            const callLogData = result[0].callLogData;
            const smsManagerData = result[0].smsManagerData;
            const mmsManagerData = result[0].mmsManagerData;

            const locationPhoneNumber = result[0].locationPhoneNumber;
            const staffPhone = result[0].staffPhone;
            console.log(1);
            refinedPhoneNumber(callLogData, smsManagerData, mmsManagerData);
            console.log(2);
            postNameToPhoneNumber(totalUsers, callLogData, smsManagerData, mmsManagerData, locationPhoneNumber, staffPhone);
            console.log(3);
            const rows = makeRows(callLogData, smsManagerData, mmsManagerData);
            console.log(4);
            sortRows(rows);

            const numberList : any = [];

            const academy = props.user ? props.user.academy : "";


            if(!academy) return;

            console.log("gogo");

            totalLocationData.forEach((eachLocation: any) => {

                
                const eachLocationAcademyArray = eachLocation.academy;

                if(!eachLocationAcademyArray.includes(academy)) return;

                numberList.push(eachLocation.korean);

            });

            console.log("gogoog");


            if(props.forAdmin){
                
                staffPhone.forEach((eachStaff: any) => {

                    numberList.push(eachStaff.name);

                });
            
            }

            setNumberList([...numberList]);

            if(!props.forAdmin || !props.user.todoistApiKey){

                const filteredAdminRows = filterAdminRows(rows);
                setRows([...filteredAdminRows]);
                setLoading(false);
                return;

            }

            setRows([...rows]);
            setLoading(false);

        } catch (e) {
            console.log(e);
        }

    }


    const getPhoneData = async () => {
        const result: any = await getFetch("/dashboard/report/envelope/managephone");

        if (result.message === "success") {
            return result.data;
        } else {
            new Error("getPhoneData error");
        }
    }

    const getTotalUsers = async () => {
        const result: any = await getFetch("/dashboard/report/envelope/totalusers");

        if (result.message === "success") {
            return result.data;
        } else {
            new Error("getTotalUsers error");
        }

    }

    useEffect(() => {

        if (!selectedPhoneNumber) {
            setFilteredRows([]);
            return;
        }

        const newRows = rows.filter((row: any) => {
            return row.phoneNumber === selectedPhoneNumber && row.myPhoneNumber === selectedMyPhoneNumber;
        });

        //newRows 뒤집기
        newRows.reverse();

        mmsImageToNewRow(newRows);
        insertDateRow(newRows);

        console.log(newRows);

        setFilteredRows([...newRows]);


    }, [selectedPhoneNumber, rows]);

    const addFilteredRows = (newRow : any) => {

        const newFilteredRows = [...filteredRows];

        newFilteredRows.push(newRow);

        setFilteredRows([...newFilteredRows]);

    }


    const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {

        console.log(newAlignment);

        if(!newAlignment) return;

        console.log(filterModel);

        const newFilterModel : any = {...filterModel};

        if (newAlignment === "전체") {
            newFilterModel.items[0].value = "";
        } else {
            newFilterModel.items[0].value = newAlignment;
        }

        console.log(newFilterModel);

        setFilterModel(newFilterModel);
        setAlignment(newAlignment);
    };

    const handleAlignmentChange2 = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {

        console.log(newAlignment);

        if(!newAlignment) return;

        console.log(filterModel);

        const newFilterModel : any = {...filterModel};

        if (newAlignment === "전체") {
            newFilterModel.items[1].value = "";
        } else {
            newFilterModel.items[1].value = newAlignment;
        }

        console.log(newFilterModel);

        setFilterModel(newFilterModel);
        setAlignment2(newAlignment);
    }
    
    const handleSearchTextChange = (e: any) => {
        setSearchText(e.target.value);

        const newFilterModel : any = {...filterModel};

        newFilterModel.items[2].value = e.target.value;

        setFilterModel(newFilterModel);
    }

    useEffect(() => {

        if(!props.socket) return;

        props.socket.on("newPhoneData", (data: any) => {
            console.log("newPhoneData");
            getData(false);
        });

        return () => {
            props.socket.off("newPhoneData");
        }

    }, [props.socket]);

    return (
        <div>
            <div style={{
                marginTop : "20px",
                marginBottom : "20px",
                display : "flex",
                justifyContent : "space-between",
                width : "1700px"
            }}>
                <div>
                    <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        color="primary"
                        onChange={handleAlignmentChange}
                    >
                    {
                        numberList.map((eachNumber: any) => {
                            return (
                                <ToggleButton
                                    value={eachNumber}
                                    >
                                    {eachNumber}
                                    </ToggleButton>
                            )
                        })
                    }
                    </ToggleButtonGroup>
                    <TextField sx={{ fontSize: "24px", marginLeft : "35px" }} label="상대방 검색" variant="standard" value={searchText} onChange={handleSearchTextChange} InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                               <CheckCircle />
                            </InputAdornment>
                        ),
                    }} />
                </div>
                <div>
                    <ToggleButtonGroup
                    value={alignment2}
                    exclusive
                    color="primary"
                    onChange={handleAlignmentChange2}
                    >
                        <ToggleButton
                            value="전체"
                            >
                            전체
                        </ToggleButton>
                        <ToggleButton
                            value="문자"
                            >
                            문자
                        </ToggleButton>
                        <ToggleButton
                            value="통화"
                            >
                            통화
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
            <div className={classes.root} style={{
                backgroundColor: "white",
                width: "1700px",
                height: "600px"
            }}>
                <DataGridPremium
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    density="compact"
                    getDetailPanelContent={getDetailPanelContent}
                    getDetailPanelHeight={({ row }) => 'auto'}
                    hideFooter
                    filterModel={filterModel}
                    onFilterModelChange={(model) => {
                        setFilterModel(model);
                    }}
                    onRowClick={(params) => {

                        const phoneNumber = params.row.phoneNumber;
                        const myPhoneNumber = params.row.myPhoneNumber;

                        if (phoneNumber) {
                            setSelectedPhoneNumber(phoneNumber);
                            setSelectedMyPhoneNumber(params.row.myPhoneNumber);
                            setSelectedMyPhoneNumberName(params.row.myPhoneNumberName);
                            setDrawerOpen(true);
                        }

                        var newChatTitle = "";

                        newChatTitle += params.row.myPhoneNumberName ? params.row.myPhoneNumberName : params.row.myPhoneNumber;
                        newChatTitle += " - ";
                        newChatTitle += params.row.phoneNumberName ? params.row.phoneNumberName : params.row.phoneNumber;

                        setChatTitle(newChatTitle);

                    }}
                    getCellClassName={(params) => {
                        if (params.field === "description") {
                            if (params.row.typeName === "문자") {
                                if (params.row.direction === "수신") {
                                    return "softblue";
                                } else if (params.row.direction === "발신") {
                                    return "softred";
                                }
                            }
                        }

                        if (params.field === "direction") {
                            if (params.value === "수신") {
                                return "softblue";
                            } else if (params.value === "발신") {
                                return "softred";
                            } else if (params.row.direction === "수신(거절)" || params.row.direction === "수신(부재중)") {
                                return "bluetext";
                            }
                        }

                        return ""
                    }}

                />
            </div>

            <div style={{
                width: "1700px",
                display : "flex",
                justifyContent : "flex-end",
                marginTop : "12px",
                color : "gray",
                fontSize : "14px"
            }}>
                <div>
                    * 표 클릭하면 상세보기 가능
                </div>
            </div>

            <SwipeableDrawer
                anchor="right"
                open={drawerOpen}
                onClose={() => { setDrawerOpen(false) }}
                onOpen={() => { setDrawerOpen(true) }}
            >
                <div
                    style={{ width: "550px", height: "100%" }}
                    role="presentation"
                // onClick={(e) => { toggleDrawer(false) }}
                // onKeyDown={(e) => { toggleDrawer(false) }}
                >
                    <ChatForPhone
                        openControl={drawerOpen}
                        user={props.user}
                        studentName={chatTitle}
                        data={filteredRows}
                        unreadMessage={() => { }}
                        apiKey={props.apiKey}
                        userName={props.userName ? props.userName : "나"}
                        selectedPhoneNumber={selectedPhoneNumber}
                        selectedMyPhoneNumber={selectedMyPhoneNumber}
                        selectedMyPhoneNumberName={selectedMyPhoneNumberName}
                        addFilteredRows={addFilteredRows}
                    />
                </div>
            </SwipeableDrawer>
        </div>
    )
}

export default ManagePhoneMain;