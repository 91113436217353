import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import "../componentsStyle/edit.css";
import { Button, Textarea } from '@mui/joy';
import { CloseFullscreen, Fullscreen, OpenInFull } from '@mui/icons-material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { Backdrop, CircularProgress, Switch, ToggleButton, ToggleButtonGroup } from '@mui/material';
import totalLocationData from '../../data/locationdata';
import { getFetch, postFetch } from '../../fetch/fetch';
import { set } from 'lodash';
import IotWindow from './controls/iotwindow';
import Reserveriot from './controls/reserveriot';
import RegularIot from './controls/newtodo/components/regulariot';

const defaultTheme = createTheme();
const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .open': {
                    color: "blue"
                },
                '& .close': {
                    color: "red"
                },
                '& .error': {
                    color: "red"
                },
            },
        };
    },
    { defaultTheme },
);

type currentSideBarMenuList = "home" | "notification" | "alarm" | "edit" | "book" | "question" | "restaurant" | "envelope" | "search" | "chart" | "attendance" | "출석 관리 보고" | "wifipermitregister" | "iot";

type location = {
    name: string;
    english: "daechi" | "daechi2" | "daechi3" | "gangnam" | "songpa";
    noWindow?: boolean;
};



const locationInfo: location[] = [
    {
        name: "강남점",
        english: "gangnam",
        noWindow: true
    },
    {
        name: "대치 2층",
        english: "daechi",
    },
    {
        name: "대치 3층",
        english: "daechi2"
    },
    {
        name: "대치 6층",
        english: "daechi3"
    },
]


var totalWidth = 1200;

interface editProps extends RouteComponentProps {
    activateMenuList: any;
    // classes: any;
    user: any;
}

const Iot: React.FC<editProps> = (props) => {

    const classes = useStyles2();
    const [update, setUpdate] = useState(0);
    const [alignment2, setAlignment2] = useState("");
    const [description, setDescription] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [alignment3, setAlignment3] = useState("total");

    const [rows, setRows] = useState<any[]>([]);
    const columns = [
        { field: "location", headerName: "위치", width: 150 },
        { field: "name", headerName: "작동자", width: 150 },
        { field: "direction", headerName: "방향", width: 150 },
        { field: "isError", headerName: "-", width: 100 },
        { field: "createdAt", headerName: "작동 시간", width: 250 },
        { field: "status", headerName: "상태", width: 500 },
    ]

    const [rows2, setRows2] = useState<any[]>([]);
    const columns2 = [
        { field: "location", headerName: "위치", width: 100 },
        { field: "name", headerName: "방송한 사람", width: 100 },
        { field: "description", headerName: "내용", width: 500 },
        { field: "createdAt", headerName: "방송 시간", width: 250 },
    ]
    const [bellSwitch, setBellSwitch] = useState<any>({});
    const [broadcastStatus, setBroadcastStatus] = useState<any>({});
    const [selectMenu, setSelectMenu] = useState<string>("submit");

    useEffect(() => {
        props.activateMenuList("iot");
    }, []);

    useEffect(() => {

        if (!alignment2 || !description) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

    }, [alignment2, description]);

    const notify1 = () => toast.success("전송 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify2 = () => toast.error("전송 실패!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored"
    });

    useEffect(() => {

        getWindowStatus();
        getWindowOpenLog();
        getBroadcastLog();
        getBellStatus();

    }, [update]);

    const getBellStatus = async () => {

        try {

            const result: any = await getFetch("/dashboard/report/bellstatus");

            if (result.message !== "success") {
                alert("종소리 데이터를 가져오는데 실패했습니다.");

                return;
            }

            const data = result.data;

            data.forEach((each: any) => {

                const status = each.result.status;

            })



            const newBellSwitch: any = {};

            data.forEach((each: any) => {

                const location = each.location;
                const isPlaying = each.result.date.isPlaying;

                newBellSwitch[location] = isPlaying;

            })



            setBellSwitch({ ...newBellSwitch });

        } catch (e) {

        }

    }

    const getBroadcastLog = async () => {

        try {

            const result: any = await getFetch("/dashboard/report/broadcastlog");

            if (result.message !== "success") {

                return;
            }



            const newRows: any = [];

            const data = result.data;

            data.forEach((each: any) => {

                const oneRow: any = {};

                oneRow.id = each.id;
                oneRow.name = each.name;

                var location = "";

                const thisLocation = totalLocationData.find((locationData) => locationData.english === each.location);

                if (!thisLocation) {
                    return;
                }

                oneRow.location = thisLocation.korean;
                oneRow.description = each.description;

                const date = new Date(each.createdAt);
                const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

                oneRow.createdAt = dateString;

                newRows.push(oneRow);

            })

            setRows2([...newRows]);

        } catch (e) {

        }

    }

    const getWindowStatus = async () => {

        try {

            const response = await fetch("https://peetsunbae.com/dashboard/report/windowstatus", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include"
            });

            const result = await response.json();



        } catch (e) {

        }


    }

    const getWindowOpenLog = async () => {

        try {

            const response = await fetch("https://peetsunbae.com/dashboard/report/windowopenlog", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include"
            });

            const result = await response.json();



            if (result.message !== "success") {
                return;
            }

            const data = result.data;

            const newRows: any = [];

            data.forEach((item: any) => {

                const oneRow: any = {};
                oneRow.id = item.id;
                oneRow.name = item.name;

                var location = "";

                const thisLocation = totalLocationData.find((locationData) => locationData.english === item.location);

                if (!thisLocation) {
                    return;
                }

                oneRow.location = thisLocation.korean;

                var status = "";

                for (const key in item.status) {

                    const portStatus = item.status[key];

                    if (portStatus) {
                        status += `정상/`;
                    } else {
                        status += `비정상/`;
                    }

                }

                //status에서 마지막 / 제거
                status = status.slice(0, -1);

                oneRow.status = status;
                oneRow.direction = item.direction === "open" ? "열기" : item.direction === "close" ? "닫기" : "알 수 없음";
                oneRow.isError = item.isError ? "X" : "O";

                const date = new Date(item.createdAt);
                const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

                oneRow.createdAt = dateString;

                newRows.push(oneRow);


            });

            setRows([...newRows]);

        } catch (e) {

        }
    }


    const sendOpen = async (kind: "inside" | "outside") => {

        try {

            var deviceId = 0;

            switch (kind) {
                case "inside":
                    deviceId = 17;
                    break;
                case "outside":
                    deviceId = 16;
                    break;
            }

            const response = await fetch("https://peetsunbae.com/fingerprint/opendoor2?kind=" + deviceId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            });

            const result = await response.json();



        } catch (e) {

        }

    }

    const sendOpen2 = async (kind: "inside" | "outside") => {

        try {

            var deviceId = 0;

            switch (kind) {
                case "inside":
                    deviceId = 21;
                    break;
                case "outside":
                    deviceId = 22;
                    break;
            }

            const response = await fetch("https://peetsunbae.com/fingerprint/opendoor2?kind=" + deviceId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            });

            const result = await response.json();



        } catch (e) {

        }

    }

    const sendOpen3 = async (kind: "inside" | "outside") => {

        try {

            var deviceId = 0;

            switch (kind) {
                case "inside":
                    deviceId = 24;
                    break;
                case "outside":
                    deviceId = 23;
                    break;
            }

            const response = await fetch("https://peetsunbae.com/fingerprint/opendoor2?kind=" + deviceId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            });

            const result = await response.json();



        } catch (e) {

        }

    }

    const sendOpen4 = async (kind: "inside" | "outside") => {

        try {

            var deviceId = 0;

            switch (kind) {
                case "inside":
                    deviceId = 32;
                    break;
                case "outside":
                    deviceId = 31;
                    break;
            }

            const response = await fetch("https://peetsunbae.com/fingerprint/opendoor2?kind=" + deviceId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            });

            const result = await response.json();



        } catch (e) {

        }

    }



    const sendeRequest = async (location: "daechi" | "daechi2" | "daechi3" | "gangnam" | "songpa", direction: "open" | "close") => {


        if (!props.user) {
            return;
        }

        if (props.user.academy !== "sunbae") {
            alert("수능선배 직원만 사용 가능합니다.");
            return;
        }


        if (window.confirm(`창문을 작동시킬까요?`)) {

        } else {
            return;
        }

        var ipAddr = "http://";

        switch (location) {
            case "daechi":
                ipAddr += "59.187.152.157:3005/";
                break;
            case "daechi2":
                ipAddr += "182.220.139.154:3009/";
                break;
            case "daechi3":
                ipAddr += "182.220.139.141:5001/"
                break;
        }

        ipAddr += direction;

        const fullIpAddr = ipAddr;

        const response = await fetch("https://peetsunbae.com/dashboard/report/windowopen", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                fullIpAddr,
                location
            })
        });

        const result = await response.json();



        if (result.message === "success") {
            notify1();
        }

        setUpdate(Math.random());

    }

    const handleAlignmentChange2 = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {

        if (!newAlignment) {
            return;
        }

        setAlignment2(newAlignment);

    };

    const letsBroadcast = async () => {

        const data = {
            description,
            location: alignment2
        }

        try {

            setLoading(true);

            const response: any = await postFetch("/dashboard/report/broadcast", data);

            if (response.message !== "success") {
                notify2();
                return;
            }

            setLoading(false);
            setUpdate(Math.random());

            notify1();

        } catch (e) {

            alert(e);
        }
    }

    const controlBell = async (location: string, isPlaying: boolean) => {

        var direction = "";

        if (isPlaying) {
            direction = "start";
        } else {
            direction = "stop";
        }

        const data = {
            location,
            direction
        }



        try {

            setLoading(true);

            const response: any = await postFetch("/dashboard/report/bellcontrol", data);

            if (response.message !== "success") {
                notify2();
                return;
            }

            setLoading(false);
            setUpdate(Math.random());

            notify1();


        } catch (e) {

            alert(e);
        }
    }

    const changeSelectedMenu = (e: any, menu: string) => {
        e.preventDefault();
        e.stopPropagation();

        setSelectMenu(menu);

    }

    return (
        <div className="main22">
            <div className="title" style={{
                display: "flex", alignItems: "center",
            }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "6px",
                        marginRight: "10px"
                    }}
                >
                    <img src="img/off/iot.svg" alt="playlist" style={{ width: "18px" }} />
                </div>
                <div>IOT 작동</div>
            </div>

            <div style={{
                display: "flex",
                marginTop: "40px",
                marginBottom: "30px",
            }}>
                <div onClick={(e) => { changeSelectedMenu(e, "submit") }} className={`selectedMenu ${selectMenu === "submit" ? "active" : ""}`}>
                    IOT 작동
                </div>
                {
                    props.user && (props.user.academy === "sunbae" || props.user.academy === "bundangsunbae") &&
                    <div onClick={(e) => { changeSelectedMenu(e, "reservation") }} className={`selectedMenu ${selectMenu === "reservation" ? "active" : ""}`}>
                        작동 예약
                    </div>
                }

            </div>

            {

                (props.user && props.user.academy === "sunbae") &&
                    (selectMenu === "submit") ?
                    <div>
                        <div>
                            <div style={{
                                marginTop: "30px",
                            }}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={alignment3}
                                    exclusive
                                    onChange={(e, value) => {
                                        setAlignment3(value);
                                    }}
                                >
                                    {
                                        props.user && totalLocationData.filter(locationData => locationData.academy.includes(props.user.academy)).map((each, index: number) => {

                                            return (
                                                <ToggleButton value={each.english} key={index}>{each.korean}</ToggleButton>
                                            )
                                        })
                                    }
                                </ToggleButtonGroup>
                            </div>
                            {
                                props.user && totalLocationData.filter(locationData => locationData.academy.includes(props.user.academy)).map((each, index: number) => {

                                    var isNeed = false;

                                    if (each.english === alignment3) {
                                        isNeed = true;
                                    }

                                    if (alignment3 === "total") {
                                        isNeed = true;
                                    }

                                    if (each.english === "total") {
                                        return;
                                    }

                                    if (!isNeed) {
                                        return;
                                    }

                                    return (
                                        <div>
                                            <div style={{
                                                marginTop: "40px",
                                                marginBottom: "28px",
                                                fontSize: "20px",
                                                fontFamily: "Apple_SB"
                                            }}>
                                                {each.korean === "대치점" ? "대치2층" : each.korean} 창문 조절
                                            </div>
                                            <div>
                                                <IotWindow location={each.english} />
                                            </div>
                                        </div>
                                    )
                                })

                            }
                            <div style={{
                                marginTop: "30px",
                                marginBottom: "18px",
                                fontSize: "24px",
                                fontFamily: "Apple_SB"
                            }}>
                                - 방송하기
                            </div>
                            <div style={{
                                display: "flex",
                                marginBottom: "12px"
                            }}>
                                {
                                    props.user && totalLocationData.filter(locationData => locationData.academy.includes(props.user.academy)).map((each, index: number) => {

                                        if (each.english === "total") {
                                            return;
                                        }

                                        return (
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}>
                                                <div style={{
                                                    width: "8px",
                                                    height: "8px",
                                                    borderRadius: "50%",
                                                    backgroundColor: bellSwitch[each.english] ? "blue" : "red",
                                                    marginRight: "6px"
                                                }}>
                                                </div>
                                                <div style={{
                                                    marginRight: "18px"
                                                }}>
                                                    {each.korean}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: `${totalWidth}px`,
                                alignItems: "center"
                            }}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={alignment2}
                                    exclusive
                                    onChange={handleAlignmentChange2}
                                >
                                    {
                                        props.user && totalLocationData.filter(locationData => locationData.academy.includes(props.user.academy)).map((each, index: number) => {

                                            if (each.english === "total") {
                                                return;
                                            }

                                            return (
                                                <ToggleButton value={each.english} key={index}>{each.korean}</ToggleButton>
                                            )
                                        })
                                    }
                                </ToggleButtonGroup>
                            </div>
                            <div style={{
                                marginTop: "12px",
                                width: "1200px"
                            }}>
                                <Textarea
                                    placeholder='방송할 내용을 입력해주세요.'
                                    minRows={5}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                                <div style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: "12px",
                                }}>
                                    <Button size='lg' variant="soft" color="primary" onClick={() => {
                                        letsBroadcast();
                                    }}
                                        disabled={disabled}
                                    >방송하기</Button>
                                </div>
                            </div>
                            <div className={classes.root} style={{ height: 300, width: '1200px', backgroundColor: "white", marginTop: "32px" }}>
                                <DataGridPremium
                                    rows={rows2}
                                    columns={columns2}
                                    density='compact'
                                    getCellClassName={(params) => {
                                        return ""
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }

            {
                (props.user && props.user.academy === "mom")
                &&
                <div>
                    <div style={{
                        marginTop: "30px",
                        marginBottom: "28px",
                        fontSize: "20px",
                        fontFamily: "Apple_SB"
                    }}>
                        턴게이트 조절
                    </div>
                    <div style={{

                    }}>
                        <Button startDecorator={<OpenInFull />} variant="soft" color="primary" onClick={() => sendOpen("outside")}>입문 열기</Button>
                        <Button style={{ marginLeft: "28px" }} startDecorator={<OpenInFull />} variant="soft" color="danger" onClick={() => sendOpen("inside")}>출문 열기</Button>
                    </div>
                </div>
            }

            {
                (props.user && props.user.academy === "molip")
                &&
                <div>
                    <div style={{
                        marginTop: "30px",
                        marginBottom: "28px",
                        fontSize: "20px",
                        fontFamily: "Apple_SB"
                    }}>
                        턴게이트 조절
                    </div>
                    <div style={{

                    }}>
                        <Button startDecorator={<OpenInFull />} variant="soft" color="primary" onClick={() => sendOpen2("outside")}>입문 열기</Button>
                        <Button style={{ marginLeft: "28px" }} startDecorator={<OpenInFull />} variant="soft" color="danger" onClick={() => sendOpen2("inside")}>출문 열기</Button>
                    </div>
                </div>
            }

            {
                (props.user && props.user.academy === "bundangsunbae")
                &&
                <div>
                    <div style={{
                        marginTop: "30px",
                        marginBottom: "28px",
                        fontSize: "20px",
                        fontFamily: "Apple_SB"
                    }}>
                        턴게이트 조절
                    </div>
                    <div style={{

                    }}>
                        <Button startDecorator={<OpenInFull />} variant="soft" color="primary" onClick={() => sendOpen3("outside")}>입문 열기</Button>
                        <Button style={{ marginLeft: "28px" }} startDecorator={<OpenInFull />} variant="soft" color="danger" onClick={() => sendOpen3("inside")}>출문 열기</Button>
                    </div>
                </div>
            }

            {
                (props.user && props.user.academy === "bundangsunbae")
                &&
                <div>
                    <div style={{
                        marginTop: "40px",
                        marginBottom: "28px",
                        fontSize: "20px",
                        fontFamily: "Apple_SB"
                    }}>
                        창문 조절
                    </div>
                    <div>
                        <IotWindow location="bundang" />
                    </div>
                </div>
            }


            {
                (props.user && props.user.academy === "ubase")
                &&
                <div>
                    <div style={{
                        marginTop: "30px",
                        marginBottom: "28px",
                        fontSize: "20px",
                        fontFamily: "Apple_SB"
                    }}>
                        턴게이트 조절
                    </div>
                    <div style={{

                    }}>
                        <Button startDecorator={<OpenInFull />} variant="soft" color="primary" onClick={() => sendOpen4("outside")}>입문 열기</Button>
                        <Button style={{ marginLeft: "28px" }} startDecorator={<OpenInFull />} variant="soft" color="danger" onClick={() => sendOpen4("inside")}>출문 열기</Button>
                    </div>
                </div>
            }

            {
                (props.user && (props.user.academy === "sunbae" || props.user.academy === "bundangsunbae") && selectMenu === "reservation") &&
                <RegularIot user={props.user} />
            }







            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>




        </div>
    )
}

export default Iot;