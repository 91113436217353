import { Add } from "@mui/icons-material";
import { Button } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { tableUseStyle } from "../../etc/tableusestyle";
import { DataGridPremium, GridFilterModel } from "@mui/x-data-grid-premium";
import NewTodoModal from "./newtodomodal";
import NewTodoModalForRegular from "./newtodomodalforregular";
import { getFetch, postFetch } from "../../../../../fetch/fetch";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const RegularTodos: React.FC<any> = (props) => {

    const [open, setOpen] = useState<boolean>(false);
    const [columns, setColumns] = useState<any>([
        {
            field : "regular",
            headerName : "주기",
            width : 80,
        },
        {
            field : "time",
            headerName : "상세시간",
            width : 100
        },
        {
            field : "projectName",
            headerName : "프로젝트",
            width : 100
        },
        {
            field : "sectionName",
            headerName : "섹션",
            width : 150
        },
        {
            field : "description",
            headerName : "내용",
            width : 500
        },
        {
            field : "author",
            headerName : "작성자",
            width : 100
        },
        {
            field : "createdAt",
            headerName : "작성일",
            width : 100
        }
    ]);
    const [rows, setRows] = useState<any>([]);
    const classes = tableUseStyle();
    const [update, setUpdate] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
    const [alignment, setAlignment] = useState("total");
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
            items: [
                {
                    id: 1,
                    field: "projectName",
                    operator: "contains",
                    value: ""
                },
            ],
        });

    useEffect(() => {

        console.log("gogogo");

        if(!props.projects){
            console.log("no projects");
            return;
        }

        getRegularTodos();

    }, [props.projects, update]);

    const getRegularTodos = async () => {

        try{

            setLoading(true);

            const result : any = await getFetch("/dashboard/todoist/getRegularTodos");

            console.log(result);

            const data = result.data;

            const newRows : any = [];

            console.log(3);

            data.forEach((each : any) => {

                const oneRow : any = {};

                oneRow.id = each.id;

                oneRow.projectName = props.projects.find((project: any) => project.v2_id === each.selectedProjectId)?.name;

                var sectionName = "";

                sectionName = props.projects.find((project: any) => project.v2_id === each.selectedProjectId)?.sections.find((section: any) => section.v2_id === each.selectedSectionId)?.name;

                console.log(sectionName);

                if(each.selectedSectionId === "none") sectionName = "미배정";

                oneRow.sectionName = sectionName;

                oneRow.regular = each.regularType === "daily" ? "매일" : each.regularType === "weekly" ? "매주" : each.regularType === "monthly" ? "매월" : "매년";

                if(each.regularType === "weekly"){
                    var koreanDayString = "";

                    switch(each.dayString){
                        case "mon" :
                            koreanDayString = "월요일";
                            break;
                        case "tue" :
                            koreanDayString = "화요일";
                            break;
                        case "wed" :
                            koreanDayString = "수요일";
                            break;
                        case "thu" :
                            koreanDayString = "목요일";
                            break;
                        case "fri" :
                            koreanDayString = "금요일";
                            break;
                        case "sat" :
                            koreanDayString = "토요일";
                            break;
                        case "sun" :
                            koreanDayString = "일요일";
                            break;
                    }

                    oneRow.time = koreanDayString
                }

                if(each.regularType === "monthly"){
                    oneRow.time = `${each.dateString}일`;
                }


                oneRow.author = each.name;

                oneRow.description = each.description;

                const createdAtDate = new Date(each.createdAt);

                oneRow.createdAt = `${createdAtDate.getFullYear()}-${createdAtDate.getMonth() + 1}-${createdAtDate.getDate()}`;

                newRows.push(oneRow);

            })

            console.log(newRows);

            setRows([...newRows]);

            setLoading(false);

        }catch(e){
            console.log(e);
        }

    }

    const letsUpdate = () => {
        setUpdate(Math.random());
    }

    const letsDelte = async () => {

        if(!window.confirm("정말 삭제하시겠습니까?")) return;

        try{

            setLoading(true);

            await postFetch("/dashboard/todoist/deleteRegularTodo", {
                id : selectedRowId
            });

            setLoading(false);

            letsUpdate();

        }catch(e){
            console.log(e);
        }

    }

    const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    
            if (!newAlignment) return;
    
            const newFilterModel: any = filterModel;
    
            newFilterModel.items.forEach((item: any) => {
    
                if (item.field === "projectName") {
                    item.value = newAlignment;
                }
    
                if (newAlignment === "total" && item.field === "projectName") {
                    item.value = "";
                }
    
            })
    
            setFilterModel({ ...newFilterModel });
    
            setAlignment(newAlignment);
    
        };


    return (
        <div style={{
            width : "96%"
        }}>
            <div style={{
                fontFamily: "Apple_B",
                marginTop : "50px",
                fontSize: "18px",
                display: "flex",
                alignItems: "center",
            }}>
                <div style={{
                    marginRight: "18px"
                }}>
                    반복 TODO 등록 내역
                </div>
                <div>
                    <Button variant="solid" startDecorator={<Add />} size="sm" onClick={(e: any) => { setOpen(true); }}>
                        추가하기
                    </Button>
                </div>
            </div>
            <div style={{
                height : "30px"
            }}></div>
            <div className={classes.root} style={{height : "500px", width : "100%"}}>
                <div style={{
                    width : "100%",
                    display : "flex",
                    justifyContent : "space-between",
                    marginBottom : "12px"
                }}>
                <div>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                    >
                        {
                            props.projects.map((project: any, index: number) => {
                                return (
                                    <>
                                        {
                                            index === 0 ? <ToggleButton key={99999} value="total" style={{ borderRadius: "0px" }}>
                                                전체
                                            </ToggleButton> : null
                                        }
                                        <ToggleButton key={project.id} value={project.name} style={{ borderRadius: "0px" }}>
                                            {project.name}
                                        </ToggleButton>
                                    </>

                                )
                            })
                        }
                    </ToggleButtonGroup>
                </div>
                    <Button variant="soft" color="danger"
                    disabled={!selectedRowId}
                    onClick={letsDelte}
                    >
                        삭제하기
                    </Button>
                </div>
                <DataGridPremium
                    rows={rows}
                    columns={columns}
                    density="compact"
                    loading={loading}
                    onRowClick={(e: any) => {
                        console.log(e.id);
                        setSelectedRowId(e.id);
                    }}
                    filterModel={filterModel}
                    onFilterModelChange={(model) => {
                        setFilterModel(model);
                    }}
                />
            </div>

            <NewTodoModalForRegular open={open} handleClose={() => { setOpen(false); }} apiKey={props.apiKey}
            projects={props.projects} letsUpdate={letsUpdate}
            />
        </div>
    )
}

export default RegularTodos;