import React, { useEffect, useState, useRef } from "react";
import styles from "./mobilerestaurant.module.css";
import RadioGroup from '@mui/joy/RadioGroup';
import Radio, { radioClasses } from '@mui/joy/Radio';

import { returnDayString } from "../etc/functions";

import Sheet from '@mui/joy/Sheet';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { CssVarsProvider } from '@mui/joy/styles';

import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { CircularProgress } from "@mui/material";

import { IonLoading, IonToast } from '@ionic/react';
import { getFetch, postFetch } from "../../fetch/fetch";
import { set } from "lodash";
import { useIonActionSheet } from '@ionic/react';




const MobileRestaurantSubmit2: React.FC<any> = (props) => {

    const [firstWeekInfo, setFirstWeekInfo] = useState<any>();
    const [secondWeekInfo, setSecondWeekInfo] = useState<any>();
    const [today, setToday] = useState(new Date());

    const [height, setHeight] = useState(120);
    const [isSafeArea, setIsSafeArea] = useState(false);

    const [mealLoading, setMealLoading] = useState(false);

    const [loading, setLoading] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const [showToast2, setShowToast2] = useState(false);

    const [data, setData] = useState<any>();
    const [selectedLunch, setSelectedLunch] = useState<any>([]);
    const [selectedDinner, setSelectedDinner] = useState<any>([]);

    const [disabled, setDisabled] = useState(false);
    const [present] = useIonActionSheet();

    useEffect(() => {

        if(!props.user){
            console.log("no user");
            return;
        }

        var targetDateIndexMax = 2;

        if(props.user.academy === "ubase"){
            targetDateIndexMax = 1;
        }

        if (props.targetDateIndex < targetDateIndexMax) {
            setDisabled(true);
            return;
        }

        if (!data) {
            setDisabled(true);
            return;
        }

        var isSelectedLunchAllUndefined = true;

        selectedLunch.forEach((element : any) => {
            if(element){
                isSelectedLunchAllUndefined = false;
            }
        })

        var isSelectedDinnerAllUndefined = true;

        selectedDinner.forEach((element : any) => {
            if(element){
                isSelectedDinnerAllUndefined = false;
            }
        })

        if (props.whenType === "lunch") {
            if (!selectedLunch || selectedLunch.length === 0 || isSelectedLunchAllUndefined) {
                setDisabled(true);
                return;
            }
        } else {
            if (!selectedDinner || selectedDinner.length === 0 || isSelectedDinnerAllUndefined) {
                setDisabled(true);
                return;
            }
        }

        setDisabled(false);

    }, [props.targetDateIndex, selectedLunch, selectedDinner, data, props.whenType, props.user]);

    useEffect(() => {

        const valueBottom = +getComputedStyle(document.documentElement).getPropertyValue("--sab").split("p")[0];

        if (valueBottom > 0) {
            setIsSafeArea(true);
        }

        const height = Math.floor((window.innerWidth * 120) / 375);

        setHeight(height);

    }, []);

    useEffect(() => {
        const newDate = new Date();
        const newFirstWeekInfo = [];

        for (var i = 0; i < 7; i++) {
            const oneRow: any = {};

            oneRow.date = newDate.getDate();
            oneRow.dayString = returnDayString(newDate.getDay());
            oneRow.today = false;
            if (i === 0) {
                oneRow.today = true;
            }
            newFirstWeekInfo.push(oneRow);
            newDate.setDate(newDate.getDate() + 1);
        }

        setFirstWeekInfo([...newFirstWeekInfo]);

        const newSecondWeekInfo = [];

        for (var i = 0; i < 7; i++) {
            const oneRow: any = {};
            oneRow.date = newDate.getDate();
            oneRow.dayString = returnDayString(newDate.getDay());
            oneRow.today = false;
            newSecondWeekInfo.push(oneRow);
            newDate.setDate(newDate.getDate() + 1);
        }

        setSecondWeekInfo([...newSecondWeekInfo]);

    }, []);

    useEffect(() => {
        const newDate = new Date();
        const newFirstWeekInfo = [];

        for (var i = 0; i < 7; i++) {
            const oneRow: any = {};

            oneRow.date = newDate.getDate();
            oneRow.dayString = returnDayString(newDate.getDay());
            oneRow.today = false;
            if (i === 0) {
                oneRow.today = true;
            }
            newFirstWeekInfo.push(oneRow);
            newDate.setDate(newDate.getDate() + 1);
        }

        setFirstWeekInfo([...newFirstWeekInfo]);

        const newSecondWeekInfo = [];

        for (var i = 0; i < 7; i++) {
            const oneRow: any = {};
            oneRow.date = newDate.getDate();
            oneRow.dayString = returnDayString(newDate.getDay());
            oneRow.today = false;
            newSecondWeekInfo.push(oneRow);
            newDate.setDate(newDate.getDate() + 1);
        }

        setSecondWeekInfo([...newSecondWeekInfo]);

    }, []);


    useEffect(() => {

        getInfo();

    }, []);


    const getInfo = async () => {

        setMealLoading(true);

        try {

            const result: any = await getFetch("/dashboard/restaurant/regularmeal/get");


            if (result.message === "success") {
                setData(result.data);
            }

        } catch (e) {
        }

        setMealLoading(false);

    }

    useEffect(() => {

        if (!data) {
            return;
        }

        if (data.length === 0) {
            return;
        }

        const date = new Date();
        date.setDate(date.getDate() + props.targetDateIndex);
        const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

        const targetData = data.find((element: any) => element.date === dateString);


        if (targetData && targetData.data && targetData.data.lunch) {
            setSelectedLunch(targetData.data.lunch);
        } else {
            setSelectedLunch([]);
        }

        if (targetData && targetData.data && targetData.data.dinner) {
            setSelectedDinner(targetData.data.dinner);
        } else {
            setSelectedDinner([]);
        }

    }, [props.targetDateIndex, data]);


    const submit = async (forced : boolean = false) => {

        try {

            var targetDateIndexMax = 2;

            if(props.user.academy === "ubase"){
                targetDateIndexMax = 1;
            }

            if (disabled) {

                if (props.targetDateIndex < targetDateIndexMax) {
                    alert(`정기식 신청은 ${targetDateIndexMax}일 전까지 가능합니다`);
                }

                return;
            }

            if (props.chargedMealTicket === undefined || props.chargedMealTicket === null) {
                return;
            }


            if (props.chargedMealTicket < 1) {
                setToastMessage("😔 식권이 부족합니다");
                setShowToast(true);
                return;
            }

            if (!props.whenType) {
                setToastMessage("😔 점심 또는 저녁을 선택해주세요");
                setShowToast(true);
                return;
            }

            setLoading(true);

            const targetDate = new Date();
            targetDate.setDate(targetDate.getDate() + props.targetDateIndex);

            const targetDateString = `${targetDate.getFullYear()}-${targetDate.getMonth() + 1}-${targetDate.getDate()}`;
            const targetDateTime = targetDate.getTime();

            const data = {
                targetDateTime,
                whenType : props.whenType,
                forced
            }

            const result: any = await postFetch("/dashboard/restaurant/regularmeal/order", data);


            if (result.message === "TIME_OUT") {
                setToastMessage("😔 주문 시간이 지났습니다.");
                setShowToast(true);
            } else if (result.message === "LACK_BALANCE") {
                setToastMessage("😔 식권이 부족합니다");
                setShowToast(true);
            } else if (result.message === "success") {
                setShowToast2(true);
            } else if (result.message === "DUPLICATE") {
                setToastMessage("😔 해당 날짜에 이미 주문했습니다");
                setShowToast(true);
            } else if (result.message === "DUPLICATE2"){
                present({
                    header: "도시락 중복 주문",
                    subHeader: "해당 날짜에 특식 주문이 있습니다. 그대로 진행하시겠습니까?",
                    buttons: [{
                        text: "네",
                        data : {
                            action : "yes"
                        }
                    },
                    {
                        role: "cancel",
                        text: "아니오",
                        data : {
                            action : "no"
                        }
                    }
                    ],
                    onDidDismiss: ({ detail }) => {
                        switch (detail.data.action) {
                            case "yes":
                                submit(true);
                                break;
                            case "no":
                                break;
                        }
                    },
                })
            }

            props.letsUpdate();

            setLoading(false);


        } catch (e) {
        }


    }

    return (
        <div>
            <div className={styles.body}>

                <div className={styles.menuWrapper}>
                    {
                        (!mealLoading) &&
                        <div className={styles.menuDiv}>
                            {
                                (props.whenType === "lunch" ? selectedLunch : selectedDinner).map((eachMenu: any, index: number) => {

                                    if (index > 5) {
                                        return;
                                    }

                                    if (!eachMenu) {
                                        return;
                                    }

                                    return (
                                        <div key={index} className={`${styles.eachMenuDiv} ${index === 0 ? styles.firstMenu : ""} ${index === 5 ? styles.lastMenu : ""}`}>
                                            <div className={styles.eachMenuDivDiv}>
                                                <div className={styles.eachMenuName}>
                                                    {
                                                        ["메인", "서브", "반찬", "반찬", "김치", "국물"][index]
                                                    }
                                                </div>
                                                <div className={styles.eachMenuTitle}>
                                                    {eachMenu}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                    {
                        mealLoading &&
                        <div className={styles.loadingDiv} style={{ height: height }}>
                            <CircularProgress />
                        </div>
                    }

                </div>
            </div>

            <div onClick={() => {submit()}} className={`${styles.submitButton} ${isSafeArea ? styles.safeArea : ""} ${disabled ? styles.disabled : ""}`}>
                정기식 주문하기
            </div>

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => { setShowToast(false); setToastMessage(""); }}
                message={toastMessage}
                duration={1500}
                position="top"
                color="danger"
            />

            <IonToast
                isOpen={showToast2}
                onDidDismiss={() => { setShowToast2(false); }}
                message="😃 주문에 성공했습니다 !"
                duration={1500}
                position="top"
                color="primary"
            />

            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            />

        </div>
    );
}

export default MobileRestaurantSubmit2;