import React, { useEffect, useState } from "react";
import { getFetch, postFetch } from "../../../../fetch/fetch";
import useStyles2 from "../money/etc/useStyles";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import totalLocationData from "../../../../data/locationdata";
import { englishLocationToKorean } from "../../../../data/locationFuntion";
import CustomInput from "../etc/custominput";
import { Button } from "@mui/joy";
import { toast } from 'react-toastify';

const RegularMealRefund: React.FC<any> = (props) => {

    const classes = useStyles2();
    const [loading, setLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([
        {
            field: "location",
            headerName: "지점",
            width: 80
        },
        {
            field: "name",
            headerName: "이름",
            width: 120
        },
        {
            field: "phoneNumber",
            headerName: "전화번호",
            width: 120
        },
        {
            field: "amount",
            headerName: "식권 보유량",
            width: 100
        }

    ]);
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [selectedUserName, setSelectedUserName] = useState<string>("");
    const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
    const [alignment, setAlignment] = React.useState("total");
    const [filterModel, setFilterModel] = React.useState<any>({
        items: [
            { field: 'location', operator: 'contains', value: '', id: 1 },
            { field: 'name', operator: 'contains', value: '', id: 2 },
        ]
    });
    const [name, setName] = useState<string>("");
    const [loading2, setLoading2] = useState<boolean>(false);

    const [rows2, setRows2] = useState<any[]>([]);
    const [columns2, setColumns2] = useState<any[]>([
        {
            field: "kind",
            headerName: "종류",
            width: 80
        },
        {
            field: "targetDate",
            headerName: "날짜",
            width: 180
        },
        {
            field: "whenType",
            headerName: "시간",
            width: 80
        },
        {
            field: "numbers",
            headerName: "식권 수",
            width: 80
        }
    ]);
    const [loading3, setLoading3] = useState<boolean>(false);

    useEffect(() => {

        if (!selectedUserId) {
            return;
        }

        getUserInfo();
    }, [selectedUserId]);

    const notify1 = () => toast.success("저장 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const notify2 = () => toast.error("저장 실패!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const getUserInfo = async () => {

        try {

            setLoading2(true);

            const result: any = await getFetch("/dashboard/restaurant/regularmeal/userdetail?id=" + selectedUserId);

            console.log(result);

            const data1 = result.data1 || [];
            const data2 = result.data2 || [];

            const rows: any[] = [];

            data1.forEach((item: any) => {

                const oneRow: any = {};
                oneRow.id = item.id;
                oneRow.kind = "식권 사용"
                oneRow.whenType = item.whenType === "lunch" ? "점심" : "저녁";

                const targetDate = new Date(item.targetDate);

                const year = targetDate.getFullYear();
                const month = targetDate.getMonth() + 1;
                const date = targetDate.getDate();
                const hours = item.whenType === "lunch" ? 12 : 18;

                oneRow.targetDate = year + "년 " + month + "월 " + date + "일 ";

                const orderedDate = new Date(year, month - 1, date, hours);
                const orderedTime = orderedDate.getTime();
                oneRow.orderedTime = orderedTime;
                oneRow.numbers = "-1"

                rows.push(oneRow);

            })

            data2.forEach((item: any) => {

                if (!item.isPaid) {
                    return;
                }

                if (item.isRefund) {
                    return;
                }

                const oneRow: any = {};
                oneRow.id = item.id + "/pay";
                oneRow.kind = "식권 구매"
                oneRow.numbers = "+" + item.numbers;

                const targetDate = new Date(item.paidAt);

                const year = targetDate.getFullYear();
                const month = targetDate.getMonth() + 1;
                const date = targetDate.getDate();
                const hours = 0;

                oneRow.targetDate = year + "년 " + month + "월 " + date + "일 ";

                const orderedDate = new Date(year, month - 1, date, hours);
                const orderedTime = orderedDate.getTime();

                oneRow.orderedTime = orderedTime;
                rows.push(oneRow);
            });

            rows.sort((a, b) => {
                return b.orderedTime - a.orderedTime;
            })

            setRows2([...rows]);

            setLoading2(false);

        } catch (e) {
            console.log(e);
        }

    }

    useEffect(() => {
        getTotalUserTickets();
    }, [])

    const getTotalUserTickets = async () => {

        try {

            setLoading(true);

            const result: any = await getFetch("/dashboard/restaurant/regularmeal/ticket");

            console.log(result);

            if (result.message === "success") {

                const data = result.data;

                const rows: any[] = [];

                data.forEach((item: any) => {
                    rows.push({
                        id: item.id,
                        location: item.location,
                        name: item.name,
                        phoneNumber: item.phoneNumber,
                        amount: item.amount
                    })
                })

                setRows([...rows]);

                setLoading(false);

            }

        } catch (e) {
            console.log(e);
        }
    }

    const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {

        if (!newAlignment) {
            return;
        }

        console.log(newAlignment);

        const newFilterModel = filterModel;

        console.log(newFilterModel);

        if (newAlignment === "total") {

            newFilterModel.items[0].value = "";

        } else {

            const korean = englishLocationToKorean(newAlignment);

            newFilterModel.items[0].value = korean;

        }

        setFilterModel({ ...newFilterModel });


        setAlignment(newAlignment);
    }

    const nameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);

        const newFilterModel = filterModel;

        newFilterModel.items[1].value = e.target.value;

        setFilterModel({ ...newFilterModel });
    }

    const makeZero = async () => {

        if (!selectedUserId) {
            return;
        }

        if (!window.confirm(`${selectedUserName}님의 식권을 0개로 만드시겠습니까?`)) {
            return;
        }

        try {

            setLoading3(true);

            const result: any = await postFetch("/dashboard/restaurant/regularmeal/makezero", {
                userId: selectedUserId
            });

            console.log(result);

            if (result.message === "success") {
                notify1();
                setSelectedUserId(null);
                setSelectedUserName("");
                setSelectedAmount(null);
                getTotalUserTickets();
                setRows2([]);
            }else{
                notify2();
            }

            setLoading3(false);

        } catch (e) {
            console.log(e);
        }


    }


    return (
        <div>
            <div style={{
                width: "1600px",
                marginTop: "40px"
            }}>
                <div style={{
                    width: "1000px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <div>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleAlignmentChange}
                        >
                            {
                                props.user && totalLocationData.filter(locationData => locationData.academy.includes(props.user.academy)).map((each, index: number) => (
                                    <ToggleButton value={each.english} key={index}>{each.korean}</ToggleButton>
                                ))
                            }
                        </ToggleButtonGroup>
                        <span style={{
                            marginLeft: "24px"
                        }}>
                            <CustomInput value={name} onChange={nameChange} placeholder="이름을 검색하세요" />
                        </span>
                    </div>
                    <div>
                        <div style={{
                            display: "flex",
                            alignItems: "center"
                        }}>
                            <span style={{
                                marginRight: "20px"
                            }}>
                                {selectedUserName} {selectedAmount ? ("(" + selectedAmount + "개)") : ""}
                            </span>
                            <Button
                                variant="soft"
                                color="danger"
                                disabled={selectedUserId === null}
                                onClick={makeZero}
                                loading={loading3}
                            >
                                식권 0개로 만들기
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center"
            }}>
                <div className={classes.root}
                    style={{
                        width: "1000px",
                        height: "800px",
                        marginTop: "20px"
                    }}
                >
                    <DataGridPremium
                        rows={rows}
                        columns={columns}
                        density="compact"
                        loading={loading}
                        onRowClick={(params) => {
                            console.log(params.row.id);
                            setSelectedUserId(params.row.id);
                            setSelectedUserName(params.row.name);
                            setSelectedAmount(params.row.amount);
                        }}
                        filterModel={filterModel}
                        onFilterModelChange={(model) => {
                            setFilterModel(model);
                        }}
                    />
                </div>
                <div className={classes.root}
                    style={{
                        width: "800px",
                        height: "800px",
                        marginTop: "20px",
                        marginLeft: "50px"
                    }}
                >
                    <DataGridPremium
                        rows={rows2}
                        columns={columns2}
                        density="compact"
                        loading={loading2}
                        getCellClassName={(params) => {

                            if (params.field === "kind") {

                                if (params.value === "식권 구매") {
                                    return "redtext"
                                }

                            }

                            return ""
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default RegularMealRefund;