import React, { useEffect, useRef, useState } from 'react';
import { getFetch } from '../../fetch/fetch';
import { addZeroIfOne } from '../../dashboard/components/controls/etc/etcfunctions';
import styles from "./mobilecontents.module.css";
import { CircularProgress } from '@mui/joy';
import { useHistory } from 'react-router-dom';

const MobileContentsSubmit: React.FC<any> = (props) => {


    const [contents, setContents] = useState<any[]>([]);
    const [height, setHeight] = useState(0);
    const [loading, setLoading] = useState(false);
    const history = useHistory();


    useEffect(() => {
        const handleResize = () => {
            const newHeight = Math.floor((window.innerWidth * 447) / 1080);
            setHeight(newHeight);
        };

        // 처음 마운트될 때 한 번 실행
        handleResize();

        // 윈도우 리사이즈 이벤트 등록
        window.addEventListener('resize', handleResize);

        // 언마운트 시 이벤트 제거
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    useEffect(() => {

        getContents();

    }, []);

    const getContents = async () => {

        try {

            setLoading(true);

            const result: any = await getFetch("/dashboard/payments/getContents");
            console.log(result);

            if (result.message !== "success") {
                return;
            }

            const data = result.data;

            setContents([...data]);

            setLoading(false);

        } catch (e) {
            console.error(e);
        }

    }



    return (
        <div style={{
            display: "flex",
            flexWrap: "wrap",
            width: "90.4%",
            margin: "0 auto",
            justifyContent: "space-between",
            overflowY: "auto",
            paddingBottom: "1rem",
        }}
            className={styles.body}
        >
            {
                !loading ? contents.map((content, index) => {

                    const endDate = new Date(content.endDateTime);
                    //endDateString 8월 24일 22:00
                    const endDateString = `${endDate.getMonth() + 1}월 ${endDate.getDate()}일 ${addZeroIfOne(endDate.getHours())}:${addZeroIfOne(endDate.getMinutes())}`;

                    return (
                        <div key={content.id} style={{
                            width: `${height}px`,
                            marginBottom: "1.4rem",
                        }}
                            onClick={() => {
                                history.push({
                                    pathname: "/mobiledashboard/contentdetail",
                                    state: { content }
                                })
                            }}
                        >
                            <div style={{
                                width: "100%",
                                height: `${height}px`,
                                overflow: "hidden",
                                borderRadius: "0.5rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <img src={
                                    "https://peetsunbae.com" + content.src
                                } alt="content" style={{
                                    width: "100%",
                                    borderRadius: "0.5rem",
                                }} />
                            </div>
                            <div style={{
                                marginTop: "0.4rem",
                            }}>
                                <div style={{
                                    fontFamily: "Apple_B",
                                    fontSize: "1rem",
                                    letterSpacing: "-0.5px",
                                    lineHeight: "1.2",
                                }}>
                                    {content.menuName}
                                </div>
                                <div style={{
                                    marginTop: "0.2rem",
                                    fontFamily: "Apple_M",
                                    color: "#6B7583"
                                }}>
                                    {content.price ? `${content.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}원
                                </div>
                                <div style={{
                                    fontFamily: "Apple_L",
                                    color: "#6B7583",
                                    marginTop: "0.15rem",
                                    fontSize: "0.7rem",
                                }}>
                                    신청마감 {endDateString}
                                </div>
                            </div>
                        </div>
                    )

                })
                    :
                    ""
            }
            {
                loading ?
                    <div style={{
                        width: "100%",
                        height: `${props.bodyHeight}px`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <CircularProgress />
                    </div>
                    :
                    ""
            }
        </div>
    )

};

export default MobileContentsSubmit;