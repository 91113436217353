import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import InnerHeader from '../etc/innerheader';
import styles from "./mobilecontents.module.css";
import { Button } from '@mui/material';
import { IonLoading, IonToast } from '@ionic/react';
import { getFetch, postFetch } from '../../fetch/fetch';
import MobileModal from '../etc/mobilemodal';
import MobileModalCoin from '../etc/mobilemodalcoin';

const MobileContentsDetail: React.FC<any> = (props) => {

    const [content, setContent] = useState<any>();
    const headerRef = useRef<HTMLDivElement>(null);
    const [bodyHeight, setBodyHeight] = useState(100);

    const location = useLocation<any>();

    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

    const [disabled, setDisabled] = useState(true);

    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showToast2, setShowToast2] = useState(false);

    const [chargedMoney, setChargedMoney] = useState(0);

    const [availableCharge, setAvailableCharge] = useState(0);
    const [availableChargeString, setAvailableChargeString] = useState("");

    const [isCoinModalOpen, setIsCoinModalOpen] = useState(false);

    const [update, setUpdate] = useState(0);

    useEffect(() => {

        if (!content) {
            return;
        }

        var isComplete = true;

        //isMandatory가 true인 옵션들이 maxNumber만큼 선택되었는지 확인
        content.options.forEach((option: any) => {

            if (option.isMandantory) {

                var currentNumber = 0;

                selectedOptions.forEach((selectedOption: any) => {
                    if (selectedOption.optionId === option.id) {
                        currentNumber++;
                    }
                });

                if (currentNumber < option.maxNumber) {
                    isComplete = false;
                }
            }

        });

        setDisabled(!isComplete);

    }, [selectedOptions, content]);

    useEffect(() => {

        caculateBodyHeight();

        window.addEventListener("resize", () => caculateBodyHeight());

        return window.removeEventListener("resize", () => caculateBodyHeight());

    }, [headerRef]);

    const caculateBodyHeight = () => {
        setTimeout(() => {

            if (!headerRef || !headerRef.current) {
                console.log("headerRef is null2");
                return;
            }


            const value = +getComputedStyle(document.documentElement).getPropertyValue("--sat").split("p")[0];
            const totalHeight = window.innerHeight;
            const safeareaTop = value;
            console.log(headerRef.current);
            const headerHeight = headerRef.current.offsetHeight;

            const lastHeight = totalHeight - safeareaTop - headerHeight;

            setBodyHeight(lastHeight);

        }, 50);
    }

    useEffect(() => {

        if (!location.state) {
            return;
        }

        console.log(location.state.content);

        setContent(location.state.content);

    }, []);

    const handleOptionClick = (optionId: string, optionName: string, optionItem: string, isExist: boolean, maxNumber: number, order : number) => {

        var newSelectedOptions = [...selectedOptions];

        if (isExist) {

            newSelectedOptions = newSelectedOptions.filter((option: any) => !(option.optionId === optionId && option.optionItem === optionItem));

        } else {

            var currentNumber = 0;

            selectedOptions.forEach((option: any) => {
                if (option.optionId === optionId) {
                    currentNumber++;
                }
            });

            console.log(currentNumber);

            if (currentNumber >= maxNumber) {

                const firstOption = selectedOptions.find((option: any) => option.optionId === optionId);
                const firstOptionId = firstOption.optionId;
                const firstOptionItem = firstOption.optionItem;

                newSelectedOptions = newSelectedOptions.filter((option: any) => !(option.optionId === firstOptionId && option.optionItem === firstOptionItem));


            }
            newSelectedOptions.push({
                optionId: optionId,
                optionItem: optionItem,
                optionName: optionName,
                order: order
            });

        }

        newSelectedOptions.sort((a, b) => a.order - b.order);

        setSelectedOptions([...newSelectedOptions]);

    }

    const submitOrder = async () => {

        try {

            if (disabled) {
                return;
            }

            if (!content) {
                return;
            }

            setLoading(true);

            const body = {
                content,
                selectedOptions,
                contentId: content.id
            }

            console.log(body);

            const result: any = await postFetch("/dashboard/payments/content/order", body);

            setLoading(false);


            if (result.message === "LACK_BALANCE") {
                setToastMessage("😔 잔액이 부족합니다.");
                setShowToast(true);
                return;
            }

            if (result.message === "TIME_OUT") {
                setToastMessage("😔 신청기한이 아닙니다.");
                setShowToast(true);
                return;
            }

            if (result.message === "DUPLICATE") {
                setToastMessage("😔 이미 신청한 내역입니다.");
                setShowToast(true);
                return;
            }

            if (result.message === "success") {

                setShowToast2(true);
                setUpdate(Math.random());

            }

            setLoading(false);

        } catch (e) {
            console.error(e);
        }

    }

    const handleCoinModalOpen = () => {
        console.log("handleCoinModalOpen");
        setIsCoinModalOpen(true);
    }

    const handleClose = () => {
        setIsCoinModalOpen(false);
    }

    useEffect(() => {

        getCoin();

    }, [update]);

    const getCoin = async () => {

        try {

            const result: any = await getFetch("/dashboard/restaurant/getcoin");

            if (result.message === "success") {

                setChargedMoney(result.chargedMoney);
                setAvailableCharge(100000 - result.chargedMoney);
                setAvailableChargeString(`최대 보유 금액 100,000원. 현재 충전 가능액 ${(100000 - result.chargedMoney).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`);

            }

        } catch (e) {
        }

    }

    const handleToastMessage = (message: string) => {
        setToastMessage(message);
        setShowToast(true);
    }


    return (
        <div>
            <InnerHeader headerRef={headerRef} title=""
                isRestaurantSubmitPage={true}
                isCoin={true}
                chargedMoney={chargedMoney}
                handleClickRestaurantSubmit={() => {
                    handleCoinModalOpen();
                }}
            />
            {
                content ?
                    <div style={{
                        height: bodyHeight,
                        overflowY: "scroll",
                        position: "relative",
                        paddingBottom: "4.5rem"
                    }}
                        className={styles.body}
                    >
                        <div>
                            <img src={"https://peetsunbae.com" + content.src} alt={content.title}
                                style={{
                                    width: "100%",
                                    aspectRatio: "1/1",
                                    objectFit: "cover"
                                }}
                            />
                        </div>
                        <div style={{
                            width: "90.4%",
                            margin: "0 auto",
                            paddingBottom: "1rem",
                            paddingTop: "0.5rem"
                        }}>
                            <div style={{
                                fontFamily: "Apple_B",
                                letterSpacing: "-0.5px",
                                lineHeight: "1.2",
                                fontSize: "1.2rem",
                            }}>
                                {
                                    content.menuName
                                }
                            </div>
                            <div style={{
                                color: "#6b7583",
                                marginTop: "0.7rem",
                                fontSize: "0.9rem",
                                fontFamily: "Apple_R"
                            }}>
                                <div>
                                    - {
                                        content.subDescription1
                                    }
                                </div>
                                <div style={{
                                    marginTop: "0.3rem",
                                    fontFamily: "Apple_R"
                                }}>
                                    - {
                                        content.subDescription2
                                    }
                                </div>
                                <div style={{
                                    marginTop: "0.3rem",
                                    fontFamily: "Apple_R"
                                }}>
                                    - 신청시작 : {
                                        new Date(content.startDateTime).getMonth() + 1
                                    }월 {
                                        new Date(content.startDateTime).getDate()
                                    }일 {
                                        new Date(content.startDateTime).getHours()
                                    }시 {
                                        new Date(content.startDateTime).getMinutes()
                                    }분
                                </div>
                                <div style={{
                                    marginTop: "0.3rem",
                                    fontFamily: "Apple_R"
                                }}>
                                    - 신청마감 : {
                                        new Date(content.endDateTime).getMonth() + 1
                                    }월 {
                                        new Date(content.endDateTime).getDate()
                                    }일 {
                                        new Date(content.endDateTime).getHours()
                                    }시 {
                                        new Date(content.endDateTime).getMinutes()
                                    }분
                                </div>
                            </div>
                            <div style={{
                                marginTop: "1.2rem",
                                fontFamily: "Apple_B",
                                letterSpacing: "-0.5px",
                                lineHeight: "1.2",
                                fontSize: "1.4rem",
                                display: "flex",
                                justifyContent: "flex-end"
                            }}>
                                {
                                    content.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }원
                            </div>
                            <div style={{
                                marginTop: "1.2rem"
                            }}>
                                {
                                    content.options
                                        ?
                                        content.options.map((option: any, index: number) => {
                                            return (
                                                <div key={
                                                    index
                                                }>
                                                    <div style={{
                                                        color: "#6B7583",
                                                        fontFamily: "Apple_R"
                                                    }}>
                                                        {
                                                            option.optionName
                                                        }{
                                                            option.maxNumber > 1 ?
                                                                ` (${option.maxNumber}개 선택)`
                                                                :
                                                                ""
                                                        } {
                                                            option.isMandantory ?
                                                                <span style={{
                                                                    color: "#FF9C00"
                                                                }}>필수</span>
                                                                :
                                                                ""
                                                        }
                                                    </div>
                                                    <div style={{
                                                        //한줄에 3개씩 같은 높이, 넓이로
                                                        display: "grid",
                                                        gridTemplateColumns: "1fr 1fr 1fr",
                                                        gridGap: "0.5rem",
                                                        marginTop: "0.8rem",
                                                        marginBottom: "1.5rem"
                                                    }}>
                                                        {
                                                            option.optionItems.map((item: any, optionIndex: number) => {

                                                                var isSelected = false;

                                                                const optionId = option.id;
                                                                const optionItem = item;
                                                                const optionName = option.optionName;
                                                                const order = index;

                                                                selectedOptions.forEach((selectedOption: any) => {
                                                                    if (selectedOption.optionId === optionId && selectedOption.optionItem === optionItem) {
                                                                        isSelected = true;
                                                                    }
                                                                });

                                                                return (
                                                                    <div style={{
                                                                        backgroundColor: isSelected ? "#333d4b" : "#f2f4f6",
                                                                        borderRadius: "0.5rem",
                                                                        padding: "0.5rem",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        color: isSelected ? "white" : "#333d4b",
                                                                        fontFamily: "Apple_R",
                                                                        whiteSpace: "nowrap",
                                                                        overflowX: "hidden",
                                                                    }}
                                                                        onClick={() => handleOptionClick(optionId, optionName, optionItem, isSelected, option.maxNumber, order)}
                                                                        key={
                                                                            optionIndex
                                                                        }
                                                                    >
                                                                        {
                                                                            item
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        ""
                                }
                            </div>
                            <div style={{
                                width: "90.4%",
                                backgroundColor: "#3d50b0",
                                color: "white",
                                position: "fixed",
                                height: "3rem",
                                bottom: "1.5rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "0.5rem",
                                left: "50%",
                                transform: "translateX(-50%)",
                                fontFamily: "Apple_SB",
                                opacity: disabled ? 0.35 : 1
                            }}
                                onClick={() => submitOrder()}
                            >
                                {
                                    content.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }원 결제하기
                            </div>

                        </div>

                    </div>
                    :
                    ""
            }

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => { setShowToast(false); setToastMessage(""); }}
                message={toastMessage}
                duration={1500}
                position="top"
                color="danger"
            />

            <IonToast
                isOpen={showToast2}
                onDidDismiss={() => { setShowToast2(false); }}
                message="😃 신청 완료 !"
                duration={1500}
                position="top"
                color="primary"
            />

            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'잠시만 기다려주세요'}
            />

            <MobileModal
                isOpen={isCoinModalOpen}
                handleClose={handleClose}
                returnChildren={() => {
                    return (
                        <MobileModalCoin
                            availableCharge={availableCharge}
                            availableChargeString={availableChargeString}
                            handleToastMessage={handleToastMessage}
                        />
                    )
                }}
            />
        </div>
    )
}

export default MobileContentsDetail;