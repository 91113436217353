import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import koLocale from 'date-fns/locale/ko'
import styles from "../../componentsStyle/totaledit.module.css"
import { GridRenderCellParams, DataGridPro, GridRowsProp, GridColDef, GridToolbar, useGridApiRef, GridFilterModel, DataGridPremium } from '@mui/x-data-grid-premium';
import { eachDayOfInterval } from 'date-fns';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { IonItemSliding } from '@ionic/react';
import totalLocationData from '../../../data/locationdata';
import CustomInput from './etc/custominput';
import { Button } from '@mui/joy';
import { postFetch } from '../../../fetch/fetch';
import { toast } from 'react-toastify';



interface GridCellExpandProps {
  value: string;
  width: number;
}


const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignItems: 'center',
      lineHeight: '24px',
      width: '100%',
      height: '100%',
      position: 'relative',
      display: 'flex',
      '& .cellValue': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  }),
);


const defaultTheme = createTheme();
const useStyles2 = makeStyles(
  (theme) => {
    const getBackgroundColor = (color: any) =>
      theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getHoverBackgroundColor = (color: any) =>
      theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    return {
      root: {
        '& .super-app-theme--처리완료': {
          backgroundColor: getBackgroundColor(theme.palette.info.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
          },
        },
        '& .super-app-theme--Filled': {
          backgroundColor: getBackgroundColor(theme.palette.success.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
          },
        },
        '& .super-app-theme--확인': {
          backgroundColor: getBackgroundColor(theme.palette.warning.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
          },
        },
        '& .super-app-theme--미확인': {
          backgroundColor: getBackgroundColor(theme.palette.error.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
          },
        },
        '& .pending': {
          color: theme.palette.warning.main,
        },
        '& .permit': {
          color: theme.palette.primary.main,
        },
        '& .decline': {
          color: theme.palette.error.main,
        },
      },
    };
  },
  { defaultTheme },
);


function isOverflown(element: Element): boolean {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}


const GridCellExpand = React.memo(function GridCellExpand(
  props: GridCellExpandProps,
) {
  const { width, value } = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <div
      ref={wrapper}
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <div ref={cellValue} className="cellValue">
        {value}
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </div>
  );
});

function renderCellExpand(params: GridRenderCellParams<any>) {
  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
  );
}






















const Totaledits: React.FC<any> = (props) => {
  const [value, setValue] = React.useState<DateRange<Date>>([new Date(), new Date()]);
  const [submitBool, setSubmitBool] = useState(false);
  const [rows, setRows] = useState<any>();
  const classes = useStyles2();

  const [alignment, setAlignment] = useState("total");

  const [textfieldValue, setTextfieldValue] = useState("");

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [
      {
        id: 2, field: "location", operator: "contains", value: "",
      },
      {
        id: 3, field: "name", operator: "contains", value: ""
      }
    ],
  });
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const columns: GridColDef[] = [
    { field: 'name', headerName: '이름', width: 100 },
    { field: 'location', headerName: '위치', width: 100 },
    { field: 'date', headerName: '날짜', width: 150, filterable: false },
    { field: 'type', headerName: "종류", width: 150 },
    { field: 'agree', headerName: "학부모 승인", width: 150 },
    { field: 'endTime', headerName: "출발 시간", width: 150 },
    { field: 'startTime', headerName: "도착 시간", width: 150 },
    { field: 'reason', headerName: "사유", width: 150, filterable: false, renderCell: renderCellExpand },
    { field: 'time', headerName: "제출시각", width: 150, filterable: false },
    { field : "submit", headerName : "직원승인", width : 150, renderCell : (params) => {
      if(params.row.agree === "대기중"){
  
        const id = params.row.id;
        const name = params.row.name;
  
        return (
          <div>
            <Button
            size='sm'
            onClick={() => {
  
              if(!window.confirm(`${name} 학생의 직원 승인을 진행할까요?`)){
                return;
              }

              submitByStaff(id);
  
            }}
            >
              직원 승인
            </Button>
          </div>
        )
      }else{
        return (
          <div>
  
          </div>
        )
      }
    }},
    { field : "byStaff", headerName : "직원 승인 여부", width : 100 }
  ];

  const notify1 = () => toast.success("승인 처리 성공!", {
    position : "bottom-right",
    autoClose : 3000,
    hideProgressBar : true,
    theme : "light"
});

  const submitByStaff = async (id : number) => {

    try{

      if(!id){
        return;
      }

      setLoading(true);

      const result : any = await postFetch("/dashboard/edit/staffpermit", {
        id
      });

      if(result.message === "success"){

        setLoading(false);
        notify1();
        submit();

      }

    }catch(e){
      console.log(e);
    }

  }



  useEffect(() => {

    submit();

  }, []);

  const submit = async () => {


    var token = "";
    if (window.electron) {
      token = await window.electron.sendMessageApi.getToken();
    }

    setTableLoading(true);

    fetch("https://peetsunbae.com/dashboard/edit/total", {
      method: "POST",
      headers: { "Authorization": token, "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        message: value
      })
    }).then((response: any) => {
      response.json()
        .then((result: any) => {
          
          var data: any = [];
          result.data.forEach((each: any, index: number) => {
            var type;
            switch (each.type) {
              case 'long': type = "지각"; break;
              case 'absent': type = "결석"; break;
              case 'early': type = "조기하원"; break;
              case 'among': type = "외출"; break;
            }

            if (each.type === "among") {
              var sudden;
              sudden = each.startHours;
              each.startHours = each.endHours;
              each.endHours = sudden;

              sudden = each.startMinutes;
              each.startMinutes = each.endMinutes;
              each.endMinutes = sudden;
            }

            if (each.startMinutes != -1 && each.startMinutes < 10) {
              each.startMinutes = "0" + each.startMinutes;
            }

            if (each.endMinutes != -1 && each.endMinutes < 10) {
              each.endMinutes = "0" + each.endMinutes;
            }

            var oneRow: any = {};
            oneRow.id = each.id;
            oneRow.name = each.name;
            oneRow.location = each.location;
            oneRow.date = `${new Date(each.targetDate).getMonth() + 1}월 ${new Date(each.targetDate).getDate()}일`;
            oneRow.createdAt = each.DateInserted;
            oneRow.type = type;
            oneRow.startTime = each.startHours === -1 ? `-` : `${each.startHours} : ${each.startMinutes}`;
            oneRow.endTime = each.endHours === -1 ? '-' : `${each.endHours} : ${each.endMinutes}`;
            oneRow.reason = each.reason;
            oneRow.time = `${(new Date(+each.time).getMonth() + 1) < 10 ? "0" + (new Date(+each.time).getMonth() + 1) : (new Date(+each.time).getMonth() + 1)}/${new Date(+each.time).getDate() < 10 ? "0" + new Date(+each.time).getDate() : new Date(+each.time).getDate()} ${new Date(+each.time).getHours() < 10 ? "0" + new Date(+each.time).getHours() : new Date(+each.time).getHours()}:${new Date(+each.time).getMinutes() < 10 ? "0" + new Date(+each.time).getMinutes() : new Date(+each.time).getMinutes()}:${new Date(+each.time).getSeconds() < 10 ? "0" + new Date(+each.time).getSeconds() : new Date(+each.time).getSeconds()}`;

            if (each.parentpermit === 1) {
              oneRow.agree = "승인완료";
            }
            if (each.parentpermit === 2) {
              oneRow.agree = "승인거절";
            }
            if (each.parentpermit === 0) {
              oneRow.agree = "대기중";
            }

            if(each.byStaff){
              oneRow.byStaff = "O"
            }

            data.push(oneRow);
          })
          

          //data를 new Date(each.targetDate).getTime()으로 내림차순 정렬
          data.sort((a: any, b: any) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          })

          setRows([...data]);

          setTableLoading(false);

        })
    })

  }

  const handleChange = (event : React.MouseEvent, newAlignment : string) => {

    

    setAlignment(newAlignment);

    const newFilterModel = filterModel;

    // if(newAlignment === "total"){
    //   newFilterModel.items.forEach((each : any) => {
    //     if(each.id === 2){
    //       each.value = ""
    //     }
    //   })
    // }
    // if(newAlignment === "gangnam"){
    //   newFilterModel.items.forEach((each : any) => {
    //     if(each.id === 2){
    //       each.value = "강남점"
    //     }
    //   })
    // }
    // if(newAlignment === "daechi"){
    //   newFilterModel.items.forEach((each : any) => {
    //     if(each.id === 2){
    //       each.value = "대치점"
    //     }
    //   })
    // }
    // if(newAlignment === "daechi2"){
    //   newFilterModel.items.forEach((each : any) => {
    //     if(each.id === 2){
    //       each.value = "대치3층"
    //     }
    //   })
    // }

    totalLocationData.forEach((each) => {
      if(each.english === newAlignment){
        newFilterModel.items.forEach((each2 : any) => {
          if(each2.id === 2){
            each2.value = each.filterValue_contains;
          }
        })
      }
    })

    setFilterModel({...newFilterModel});

  }

  const textFieldChange = (e : any) => {
    
    setTextfieldValue(e.target.value);

    const newFilterModel = filterModel;

    newFilterModel.items.forEach((each : any) => {
      if(each.id === 3){
        each.value = e.target.value;
      }
    })

    setFilterModel({...newFilterModel});

  }

  return (
    <div>
      <div className={styles.datePicker}>
        <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
          <DateRangePicker
            startText="시작일"
            endText="마지막일"
            value={value}
            onChange={(newValue: any) => {
              setValue(newValue);
              if (newValue[0] && newValue[1]) {
                setSubmitBool(true);
              }
            }}
            renderInput={(startProps: any, endProps: any) => (
              <React.Fragment>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </React.Fragment>
            )}

          />
        </LocalizationProvider>
      </div>

      {submitBool ?
        <div onClick={submit} className={styles.totalCheckBtn}>
          조회하기
        </div>
        :
        <div className={styles.disableTotalCheckBtn}>
          조회하기
        </div>
      }

      <div style={{ marginTop: "24px", display: "flex", justifyContent: "space-between", width : "1500px" }}>
        <div>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
          >
            {/* <ToggleButton value="total">ALL</ToggleButton>
            <ToggleButton value="gangnam">강남점</ToggleButton>
            <ToggleButton value="daechi">대치점</ToggleButton>
            <ToggleButton value="daechi2">대치3층</ToggleButton> */}
            {
              props.user && totalLocationData.filter(locationData => locationData.academy.includes(props.user.academy)).map((each, index) => {
                return <ToggleButton key={index} value={each.english}>{each.korean}</ToggleButton>
              })
            }
          </ToggleButtonGroup>
        </div>
        <div>
          <CustomInput value={textfieldValue} onChange={textFieldChange} placeholder="이름을 검색하세요" />
        </div>
      </div>

      <div className={styles.dataGrid}>
        {rows &&
          <div style={{ height: 400, width: '1500px' }}>
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ flexGrow: 1 }} className={classes.root}>
                <DataGridPremium
                  density='compact'
                  filterModel={filterModel}
                  onFilterModelChange={(newModel) => { setFilterModel(newModel); }}
                  rowHeight={40}
                  rows={rows}
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                  getCellClassName={(params: any) => {
                    if (params.field != "agree") {
                      return '';
                    } else if (!params.value) {
                      return '';
                    } else {
                      
                      if (params.value === "승인완료") {
                        
                        return "permit"
                      } else if (params.value === "승인거절") {
                        return "decline"
                      } else {
                        return "pending"
                      }
                    }
                  }}
                  hideFooter
                />
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default Totaledits;