import { Button, Modal, Sheet } from '@mui/joy';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import styles from "../money.module.css";
import ModalHeaderForMoney from '../etc/modalheader';

import { Backdrop, CircularProgress, FormControl, MenuItem, OutlinedInput, Switch, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { getFetch, postFetch } from '../../../../../fetch/fetch';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { dateToDateString } from '../../etc/etcfunctions';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
};

const ManageRegisterPrice: React.FC<any> = (props) => {

    const [rows, setRows] = useState<any>([]);
    const [columns, setColumns] = useState([
        { field: "startDate", headerName: "등록비 적용 시기", width: 200 },
        { field: "price", headerName: "등록비", width: 200 },
        { field: "author", headerName: "작성자", width: 100 },
        { field : "createdAt", headerName : "작성일", width : 200}
    ]);
    const [open, setOpen] = useState(false);

    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);

    const [price, setPrice] = useState("0");

    const [submitLoading, setSubmitLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const [selectedRowId, setSelectedRowId] = useState<any>(0);

    const [update, setUpdate] = useState(0);
    const [loading, setLoading] = useState(false);

    const notify1 = () => toast.success("전송 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const notify2 = () => toast.success("삭제 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {

        start();

    }, [update])

    

    const start = async () => {

        try{

            setLoading(true);

            const result : any = await getFetch("/dashboard/payments/getregisteredprice");

            if(result.message === "success"){

                const data = result.data;

               const newRows : any = [];

                data.forEach((item : any) => {

                    const oneRow : any = {};

                    oneRow.id = item.id;

                    const year = item.year;
                    const month = item.month;

                    oneRow.startDate = year + "년 " + month + "월";

                    oneRow.author = item.name;

                    const price = item.price ? item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원" : "0원";

                    const createdAtDate = new Date(item.createdAt);

                    oneRow.createdAt = dateToDateString(createdAtDate, true);

                    oneRow.price = price;

                    newRows.push(oneRow);

                })

                setRows([...newRows]);

            }

            setLoading(false);

        }catch(e){
            console.error(e);
        }

    }

    const submit = async () => {

        if (!price) {
            alert("가격을 입력해주세요");
            return;
        }

        if (!year) {
            alert("년도를 입력해주세요");
            return;
        }

        if (!month) {
            alert("월을 입력해주세요");
            return;
        }

        setSubmitLoading(true);

        const priceNumber = Number(price.replace(/[^0-9]/g, ''));

        try {

            const body = {
                price: priceNumber,
                year: year,
                month: month
            }

            console.log(body);

            const result : any = await postFetch("/dashboard/payments/registerprice", body);

            if(result.message === "success"){

                notify1();
                setSubmitLoading(false);
                handleClose();
                setUpdate(Math.random());

            }

        } catch (e) {
            console.error(e);
        }

    }

    const handleDelete = async () => {

        try{

            if(!window.confirm("정말 삭제하시겠습니까?")){
                return;
            }

            if(!selectedRowId){
                return;
            }

            setDeleteLoading(true);

            const body = {
                id : selectedRowId
            }

            const result : any = await postFetch("/dashboard/payments/deleteregisteredprice", body);

            if(result.message === "success"){

                setUpdate(Math.random());
                setSelectedRowId(0);
                notify2();

            }

            setDeleteLoading(false);

        }catch(e){
            console.error(e);
        }

    }


    return (
        <div style={{ width: props.width, height: "500px", marginTop: "50px" }}>
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "20px"
            }}>
                <Button variant='soft' sx={{
                    marginRight: "15px"
                }}
                    color='neutral'
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    등록비 설정하기
                </Button>
                <Button variant='soft'
                    color='neutral'
                    disabled={!selectedRowId}
                    onClick={handleDelete}
                    loading={deleteLoading}
                >
                    삭제하기
                </Button>
            </div>
            <DataGridPremium
                rows={rows}
                columns={columns}
                loading={loading}
                density='compact'
                onRowClick={(params) => {
                    setSelectedRowId(params.id);
                }}
            />


            <Modal
                open={open}
                onClose={handleClose}
                title="등록비 설정하기"
            >
                <Sheet sx={style}>
                    <ModalHeaderForMoney title="등록비 추가하기" />
                    <div className={styles.modalBody}>
                        <div className={styles.title}>
                            등록비로 설정할 금액을 입력해주세요
                        </div>
                        <div>
                            <FormControl variant='outlined'>
                                <OutlinedInput
                                    size='small'
                                    endAdornment={<div>원</div>}
                                    sx={{ width: "100%" }}
                                    value={price}
                                    onChange={(e) => {
                                        var value = e.target.value;

                                        //value가 2글자 이상인데 0으로 시작하면 0을 제거
                                        if (value.length > 1 && value[0] === '0') {
                                            value = value.slice(1, value.length);
                                        }

                                        //0-9만 입력 가능
                                        //value에 0-9가 아닌 문자가 포함되어 있으면 제거
                                        value = value.replace(/[^0-9]/g, '');

                                        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

                                        setPrice(value);
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className={styles.title}>
                            언제부터 적용할지 선택해주세요
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "12px"
                        }}>
                            <div>
                                <FormControl variant='outlined'>
                                    <OutlinedInput
                                        value={year} onChange={(e) => {

                                            var value = e.target.value;

                                            if (value.length > 4) {
                                                return;
                                            }
                                            //0-9만 입력 가능
                                            //value에 0-9가 아닌 문자가 포함되어 있으면 제거
                                            value = value.replace(/[^0-9]/g, '');
                                            setYear(Number(value));
                                        }}
                                        size='small'
                                        endAdornment={<div>년</div>}
                                        sx={{ width: "100px", marginLeft: "0rem", marginRight: "1rem" }}
                                        placeholder='2024'
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl variant='outlined'>
                                    <OutlinedInput
                                        value={month} onChange={(e) => {
                                            var value = e.target.value;

                                            if (value.length > 2) {
                                                return;
                                            }

                                            if (+value > 12) {
                                                return;
                                            }
                                            //0-9만 입력 가능
                                            //value에 0-9가 아닌 문자가 포함되어 있으면 제거
                                            value = value.replace(/[^0-9]/g, '');
                                            setMonth(Number(value));
                                        }}
                                        size='small'
                                        endAdornment={<div>월</div>}
                                        sx={{ width: "100px" }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div style={{
                            marginTop: "24px",
                            paddingBottom: "24px"
                        }}>
                            <Button
                                fullWidth
                                onClick={submit}
                                loading={submitLoading}
                            >
                                설정하기
                            </Button>
                        </div>
                    </div>
                </Sheet>
            </Modal>
        </div>
    )
}

export default ManageRegisterPrice;