import { IonAvatar } from "@ionic/react";
import { Alert, Autocomplete, Avatar, Box, Button, LinearProgress, Modal, Stack, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from '../../componentsStyle/chatforteacher.module.css';
import Chat from "./chat";
import ChatRoom from "./chatroom";
import TotalMessagesTable from "./totalMessagesTable";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Backdrop, CircularProgress } from "@mui/material";
import TotalMessageSend from "./totalmessagesend";
import { Button as Button2, Checkbox, Input } from "@mui/joy";
import { FilterAltOutlined, Search, StarPurple500Sharp } from "@mui/icons-material";
import totalLocationData from "../../../data/locationdata";
import { postFetch } from "../../../fetch/fetch";
import { toast } from 'react-toastify';
import { koreanLocationToEnglish } from "../../../data/locationFuntion";
import ManagePhoneMain from "./managephone/managephonemain";
import { each } from "lodash";
import { addZeroIfOne } from "./etc/etcfunctions";



const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "12px",
    boxShadow: 24,
    p: 3,
    paddingLeft: 5,
    paddingRight: 5
};

const style2 = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1050,
    height: 787.5,
    bgcolor: 'background.paper',
    boxShadow: 10,
    outline: 'none'
};




const ChatForTeacher: React.FC<any> = (props) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [searchMenu, setSearchMenu] = useState("write");
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const open5 = Boolean(anchorEl);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);


    const [selectedUser, setSelectedUser] = useState<any>();
    const [selectedStudentName, setSelectedStudentName] = useState("");
    const [selectedFavorite, setSelectedFavorite] = useState<any>(false);

    const [active, setActive] = useState(true);
    const [users, setUsers] = useState<any>([]);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [uploadBool, setUploadBool] = useState(false);
    const [update, setUpdate] = useState(0);

    const [data, setData] = useState<any>([]);

    const [selectedChatRoomId, setSelectedChatRoomId] = useState<any>();
    const [selectedUserName, setSelectedUserName] = useState<any>("");
    const [selectedChatRoomMemo, setSelectedChatRoomMemo] = useState<any>("");

    const handleOpen = () => setOpen(true);
    const handleClose = () => { setActive(true); setOpen(false); }

    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => { setOpen2(false); getChatRoom(); }

    const [selectedChatRoomId2, setSelectedChatRoomId2] = useState<any>();

    const [filteredLocation, setFilteredLocation] = useState<any>([]);
    const [btnLoading, setBtnLoading] = useState(false);

    const [searchName, setSearchName] = useState("");

    useEffect(() => {

        if (!props.user) return;
        if (!props.user.academy) return;

        const filteredLocation = props.user.filteredLocation;

        if (filteredLocation) {
            setFilteredLocation([...filteredLocation]);
        } else {

            //전부 선택
            const preData = totalLocationData.map((each: any) => {

                const academy = props.user.academy;

                if (!each.academy.includes(academy)) {
                    return;
                }

                if (each.english === "total") {
                    return;
                }

                return each.english;
            });

            const data = preData.filter((each: any) => {
                return each !== undefined;
            });

            setFilteredLocation([...data]);

        }

    }, [props.user]);

    const notify1 = () => toast.success("저장 성공!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });


    const handleClose5 = () => {
        setAnchorEl(null);
    }

    const getChatRoom = () => {

        fetch("https://peetsunbae.com/dashboard/envelope/getChatRooms", {
            method: "GET",
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {


                    result.data.sort(function (a: any, b: any) {
                        if (new Date(a.lastMessage.createdAt).getTime() > new Date(b.lastMessage.createdAt).getTime()) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });

                    //unreadNumber가 0인 애들보다 큰 애들이 앞으로 오게 하기
                    result.data.sort(function (a: any, b: any) {
                        if (a.unreadNumber !== 0 && b.unreadNumber === 0) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });

                    const data = result.data;

                    setData([...data]);
                })
        })

    }

    useEffect(() => {
        getChatRoom()
    }, [props.messageUpdate, update, searchMenu]);


    useEffect(() => {

        async function start() {
            var token = "";
            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            fetch("https://peetsunbae.com/dashboard/chart/users", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {

                        const rows: any = [];
                        if (result.data) {
                            result.data.forEach((each: any, index: number) => {
                                var data: any = {};
                                data.id = each.id;
                                data.label = each.name;
                                data.phoneNumber = each.phoneNumber;
                                data.value = each.value;
                                data.key = index;
                                if (data.value === "student" || data.value === "parent") {
                                    rows.push(data);
                                }
                            })

                            //학생 리스트가 학부모 리스트보다 먼저 나오게 하기
                            rows.sort(function (a: any, b: any) {
                                if (a.value === "student" && b.value === "parent") {
                                    return -1;
                                } else {
                                    return 1;
                                }
                            });

                            setUsers([...rows]);
                        }
                    })
            })
        }

        start();

    }, [props.user]);

    const onchange = (e: any, value: any) => {

        if (value) {
            setSelectedStudentName(value.label);
        } else {
            setSelectedStudentName("");
        }
        setSelectedUser(value);
        if (value) {
            setActive(false);
        } else {
            setActive(true);
        }
    }

    const openChatRoom = (e: any) => {

        if (!selectedUser) {
            alert("학생을 먼저 선택해주세요.");
            return;
        }

        fetch("https://peetsunbae.com/dashboard/envelope/createroom", {
            method: "post",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                studentId: selectedUser.id
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {

                    if (result.message === "created") {
                        handleClose();
                        setSelectedChatRoomId(result.roomNumber);
                        handleOpen2();
                    }
                    if (result.message === "already") {
                        handleClose();
                        setSelectedChatRoomId(result.roomNumber);
                        handleOpen2();
                    }
                })
        })

    }

    const handleRead = () => {

        setAnchorEl(null);

        if (window.confirm(`${selectedUserName}방의 메세지를 읽음 처리 하시겠습니까?`)) {

        } else {
            return;
        }

        setLoading2(true);



        fetch("https://peetsunbae.com/dashboard/envelope/readMessage", {
            method: "post",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                chatRoomId: selectedChatRoomId2
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {


                    if (result.message === "success") {
                        setUpdate(Math.random());
                        setLoading2(false);
                    }

                })
        })

    }


    const handleRightClick = (e: any, chatRoomId: number, studentName: string) => {

        if (!props.user || props.user.value === "student" || props.user.value === "parent") {
            return;
        }

        if (!props.user.todoistApiKey) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();
        setSelectedChatRoomId2(chatRoomId);
        setSelectedUserName(studentName);
        setAnchorEl(e.currentTarget);

    }

    const filterSubmit = async () => {

        if (!props.user) return;

        console.log(filteredLocation);

        try {

            setBtnLoading(true);

            const result: any = await postFetch("/dashboard/envelope/filterLocation", {
                filteredLocation
            });

            if (result.message === "success") {
                setBtnLoading(false);
                setOpen3(false);
                notify1();

                if (props.unreadMessage) {
                    props.unreadMessage();
                }

            }

            console.log("error");




        } catch (e) {
            console.log(e);
        }
    }

    const sendMessageWithoutRead = async () => {
    }


    const notFavorite = async (id: number) => {

        const newData = [...data];

        newData.forEach((each: any) => {
            if (each.id === id) {
                each.isFavorite = false;
            }
        });

        setData([...newData]);

        const body = {
            chatRoomId: id,
            favorite: false
        }

        try {

            const result: any = await postFetch("/dashboard/envelope/favorite", body);

            console.log(result);

        } catch (e) {
            console.log(e);
        }

    }

    const letsSaveMemo = async (id: number, e: any) => {


        if (!props.user) {
            return;
        }

        if (props.user.value === "student" || props.user.value === "parent") {
            return;
        }

        if (!id) {
            return;
        }

        const data = {
            chatRoomId: id,
            memo: e.target.value ? e.target.value : ""
        }

        console.log(data);

        try {

            const result: any = await postFetch("/dashboard/envelope/chatroommemo", data);

            console.log(result);

        } catch (e) {
            console.log(e);
        }

    }


    return (
        <div className={styles.main}>
            <div className={styles.mainBoard}>
                <div className={styles.title}>
                    <img src="img/off/envelope.svg" alt="envelope" />
                    나에게 온 메세지
                </div>

                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <div className={styles.searchMenu}>
                        <div onClick={(e) => { setSearchMenu("write") }} className={`${styles.searchMenuDiv} ${searchMenu === "write" ? styles.active : ""}`}>
                            메세지함
                        </div>
                        <div onClick={(e) => { setSearchMenu("watch") }} className={`${styles.searchMenuDiv} ${searchMenu === "watch" ? styles.active : ""}`}>
                            전체 메세지
                        </div>
                        <div onClick={(e) => { setSearchMenu("totalSend") }} className={`${styles.searchMenuDiv} ${searchMenu === "totalSend" ? styles.active : ""}`}>
                            단체 보내기
                        </div>
                        <div onClick={(e) => { setSearchMenu("phone") }} className={`${styles.searchMenuDiv} ${searchMenu === "phone" ? styles.active : ""}`}>
                            통화문자목록
                        </div>
                    </div>
                    <div>
                        {
                            searchMenu === "write"
                            &&
                            <Button2 startDecorator={<FilterAltOutlined />}
                                onClick={(e) => { setOpen3(true) }}
                            >
                                지점 필터 설정
                            </Button2>
                        }

                    </div>
                </div>
                {
                    searchMenu === "phone"
                    &&
                    <ManagePhoneMain user={props.user}
                        socket={props.socket}
                    />
                }

                {
                    searchMenu !== "phone"
                    &&
                    <div className={styles.chatroomListDiv}>
                        {
                            searchMenu === "write" &&
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "1800px",
                                margin: "0 auto"
                            }}>
                                <div style={{
                                    display: "flex",
                                }}>
                                    <div style={{
                                        marginRight: "32px"
                                    }}>
                                        <div className={styles.chatRoomSearch}>
                                            <Input
                                                startDecorator={<Search />}
                                                fullWidth
                                                placeholder="이름 검색"
                                                value={searchName}
                                                onChange={(e: any) => { setSearchName(e.target.value) }}
                                                color="neutral"
                                            />
                                        </div>

                                        <div className={styles.chatRoomList}>
                                            <div className={styles.innerChatRoomList}>

                                                {
                                                    data &&
                                                    data.filter((eachChatRoom: any) => {
                                                        if (!searchName) {
                                                            return true;
                                                        }

                                                        if (!eachChatRoom.name) {
                                                            return false;
                                                        }

                                                        if (eachChatRoom.name.includes(searchName) || (eachChatRoom.location && eachChatRoom.location.includes(searchName))) {
                                                            return true;
                                                        } else {
                                                            return false;
                                                        }

                                                    }
                                                    )
                                                        .map((eachChatRoom: any) => {

                                                            if (!filteredLocation) {
                                                                return;
                                                            }

                                                            const englishLocation = koreanLocationToEnglish(eachChatRoom.location);

                                                            if (!filteredLocation.includes(englishLocation) && englishLocation) {
                                                                return;
                                                            }

                                                            const lastMessageCreatedAt = eachChatRoom.lastMessage.createdAt;
                                                            const date = new Date(lastMessageCreatedAt);
                                                            const lastMessageMonth = date.getMonth() + 1;
                                                            const lastMessageDate = date.getDate();
                                                            const amPm = date.getHours() >= 12 ? "오후" : "오전";
                                                            const lastMessageHours = date.getHours() % 12 === 0 ? 12 : date.getHours() % 12;
                                                            const lastMessageMinutes = date.getMinutes();

                                                            const now = new Date();
                                                            const nowTime = now.getTime();
                                                            const dateTime = date.getTime();
                                                            const diff = nowTime - dateTime;
                                                            const diffTotalMinutes = Math.floor(diff / 1000 / 60);

                                                            const diffHours = Math.floor(diffTotalMinutes / 60);
                                                            const diffMinutes = diffTotalMinutes % 60;
                                                            const lastMessageDirection = eachChatRoom.lastMessageDirection;

                                                            var isToday = false;

                                                            if(now.getDate() === date.getDate() && now.getMonth() === date.getMonth() && now.getFullYear() === date.getFullYear()) {
                                                                isToday = true;
                                                            }



                                                            return (
                                                                <div key={eachChatRoom.id} className={styles.eachChatRoomList}
                                                                    onClick={(e: any) => {
                                                                        setSelectedChatRoomId(eachChatRoom.id); setSelectedStudentName(`${eachChatRoom.name} (${eachChatRoom.location})`);
                                                                        setSelectedFavorite(eachChatRoom.isFavorite);
                                                                        setSelectedChatRoomMemo(eachChatRoom.memo);
                                                                        handleOpen2();
                                                                    }}
                                                                    onContextMenu={(e: any) => {
                                                                        handleRightClick(e, eachChatRoom.id, `${eachChatRoom.name} (${eachChatRoom.location})`);
                                                                    }}
                                                                >
                                                                    <div className={styles.innerEachChatRoomList}>
                                                                        <div className={styles.item_info}>
                                                                            <span className={styles.wrap_thumb}>
                                                                                {
                                                                                    eachChatRoom.avatar ?
                                                                                        <IonAvatar style={{ width: "40px", height: "40px" }}>
                                                                                            <img alt="avatar" src={`/img/avatar/Avatar-${eachChatRoom.avatar}.png`} />
                                                                                        </IonAvatar>
                                                                                        :
                                                                                        <Avatar sx={{ bgcolor: "#b0dbf1" }}><img src="img/user-solid.svg" alt="user" className="avatarImg" /></Avatar>
                                                                                }
                                                                            </span>
                                                                            <strong className={styles.tit_info}>
                                                                                <span className={styles.txt_name}>{eachChatRoom.name} ({eachChatRoom.location ? eachChatRoom.location : "미지정"})</span>
                                                                                <span className={`${styles.num_round} ${eachChatRoom.unreadNumber === 0 ? styles.numTransparent : ""}`}>{eachChatRoom.unreadNumber}</span>
                                                                            </strong>
                                                                            <div className={styles.bubble_g}>
                                                                                <p className={styles.txt_info}>{eachChatRoom.lastMessage.message}</p>
                                                                                <span className={styles.ico_arr}>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <span className={styles.txt_date}><span>
                                                                                {
                                                                                    isToday ?
                                                                                    `${amPm} ${addZeroIfOne(lastMessageHours)}:${addZeroIfOne(lastMessageMinutes)}`
                                                                                    :
                                                                                    `${lastMessageMonth}월 ${lastMessageDate}일`
                                                                                }
                                                                    
                                                                            <span style={{
                                                                                color :  (lastMessageDirection === "teacher" && diffTotalMinutes > 10) ? "red" : "#999999",
                                                                                display : "inline-block",
                                                                                textAlign : "right",
                                                                                }}>
        
                                                                                    {
                                                                                        isToday  
                                                                                        ?
                                                                                        (` (+${diffTotalMinutes}분)`)
                                                                                        :
                                                                                        ""
                                                                                    }
                                                                                </span>
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={styles.envelopeTodo}
                                        style={{
                                            width: "400px",
                                            backgroundColor: "#f9f9f9",
                                            borderRadius: "12px",

                                            paddingTop: "17px",
                                            paddingBottom: "17px",
                                        }}>
                                        <div>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}>
                                                <div style={{
                                                    fontSize: "20px",
                                                    fontWeight: 500,
                                                    color: "#565656",
                                                    fontFamily: "Apple_SB",
                                                    marginBottom: "16px",
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}>
                                                    미해결 <span style={{

                                                    }}>{
                                                            data &&
                                                            data.filter((eachChatRoom: any) =>
                                                                eachChatRoom.isFavorite
                                                            ).length
                                                        }</span>
                                                    <StarPurple500Sharp
                                                        className={styles.starIcon}
                                                        fontSize="medium"
                                                        sx={{
                                                            color: "#9A5B13"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles.envelopeTodoList}
                                                style={{
                                                    overflowY: "auto",
                                                    height: "580px",
                                                    paddingLeft: "20px",
                                                    paddingRight: "20px",
                                                }}
                                            >
                                                {
                                                    data &&
                                                    data.filter((eachChatRoom: any) =>
                                                        eachChatRoom.isFavorite
                                                    ).map((eachChatRoom: any, index: number) => {

                                                        if (!filteredLocation) {
                                                            return;
                                                        }

                                                        const englishLocation = koreanLocationToEnglish(eachChatRoom.location);

                                                        if (!filteredLocation.includes(englishLocation) && englishLocation) {
                                                            return;
                                                        }

                                                        return (
                                                            <div key={eachChatRoom.id} className={styles.eachChatRoomList}
                                                                onClick={(e: any) => {
                                                                    setSelectedChatRoomId(eachChatRoom.id); setSelectedStudentName(`${eachChatRoom.name} (${eachChatRoom.location})`);
                                                                    setSelectedFavorite(eachChatRoom.isFavorite);
                                                                    setSelectedChatRoomMemo(eachChatRoom.memo);
                                                                    handleOpen2();
                                                                }}
                                                                style={{
                                                                    borderTop: index === 0 ? "1px solid #b0b0b0" : ""
                                                                }}
                                                            >
                                                                <div className={styles.innerEachChatRoomList}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center"
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignItems: "center"
                                                                        }}>
                                                                            <div style={{
                                                                                marginRight: "12px"
                                                                            }}>
                                                                                {
                                                                                    eachChatRoom.avatar ?
                                                                                        <IonAvatar style={{ width: "40px", height: "40px" }}>
                                                                                            <img alt="avatar" src={`/img/avatar/Avatar-${eachChatRoom.avatar}.png`} />
                                                                                        </IonAvatar>
                                                                                        :
                                                                                        <Avatar sx={{ bgcolor: "#b0dbf1" }}><img src="img/user-solid.svg" alt="user" className="avatarImg" /></Avatar>
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                {eachChatRoom.name} ({eachChatRoom.location ? eachChatRoom.location : "미지정"})
                                                                            </div>
                                                                        </div>
                                                                        <div onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            e.preventDefault();
                                                                        }}

                                                                        >
                                                                            <Input
                                                                                sx={{
                                                                                    width: "120px",
                                                                                    color: "gray"
                                                                                }}
                                                                                variant="soft"
                                                                                value={eachChatRoom.memo}
                                                                                onChange={(e) => {
                                                                                    console.log(e.target.value);
                                                                                    const newData = [...data];
                                                                                    const selectedData = newData.find((each: any) => {
                                                                                        return each.id === eachChatRoom.id;
                                                                                    });
                                                                                    if (!selectedData) {
                                                                                        return;
                                                                                    }
                                                                                    selectedData.memo = e.target.value;
                                                                                    setData([...newData]);
                                                                                }}
                                                                                onBlur={(e) => {
                                                                                    letsSaveMemo(eachChatRoom.id, e);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )

                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            searchMenu === "watch" &&
                            <div>
                                <TotalMessagesTable />
                            </div>
                        }

                        {
                            searchMenu === "totalSend" &&
                            <div>
                                <TotalMessageSend socket={props.socket} user={props.user} />
                            </div>
                        }

                    </div>

                }

            </div>


            {
                searchMenu === "write" &&
                <div onClick={handleOpen} className={`${styles.message} qnaWrite`}
                    style={{
                        zIndex: 0
                    }}
                >
                    <img src="./img/pencil.svg" alt="pencil" />
                    채팅방 열기
                </div>
            }



            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={styles.messageTitle}>
                        채팅방 열기
                    </div>
                    <div className={styles.autocompleteDiv}>
                        <Autocomplete
                            onChange={onchange}
                            disablePortal
                            id="combo-box-demo"
                            options={users}
                            sx={{ width: "100%", borderRadius: "40px !important" }}
                            renderInput={(params) => <TextField {...params} sx={{ borderRadius: "24px" }} label={<span className={styles.nameText}>이름</span>} />}
                        />
                    </div>
                    {/* <div className={styles.textfieldDiv}>
                        <TextField value={message} onChange={(e) => { changeMessage(e) }} fullWidth id="outlined-basic" label={<span className={styles.nameText}>메세지</span>} variant="outlined" />
                    </div> */}


                    {loading &&
                        <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
                            <LinearProgress />
                        </Box>
                    }



                    {/* {uploadBool &&
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="info" sx={{ marginTop: 2, marginBottom: 2 }}><span className={styles.spanStyles2}>업로드 성공 !</span></Alert>
                        </Stack>
                    } */}

                    <div className={styles.buttonDiv}>
                        <Button onClick={openChatRoom} disabled={active} variant="contained"><span className={styles.buttonText}>시작하기</span></Button>
                    </div>
                </Box>
            </Modal>



            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open5}
                onClose={handleClose5}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleRead}>읽음 처리하기</MenuItem>
                <MenuItem onClick={() => { sendMessageWithoutRead() }}>읽음 처리 없이 메시지 보내기</MenuItem>
            </Menu>

            <Modal
                open={open2}
                onClose={handleClose2}
            >
                <Box sx={style2}>
                    <Chat messageUpdate={props.messageUpdate} unreadMessage={props.unreadMessage} socket={props.socket} studentName={selectedStudentName} user={props.user} chatRoomId={selectedChatRoomId}
                        isFavorite={selectedFavorite}
                        memo={selectedChatRoomMemo}
                    />
                </Box>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading2}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Modal
                open={open3}
                onClose={() => { setOpen3(false) }}
            >
                <Box sx={style}>
                    <div className={styles.messageTitle}>
                        메세지를 확인할 지점을 선택해주세요
                    </div>
                    <div>
                        {
                            totalLocationData.map((eachLocation: any) => {

                                if (!props.user || !props.user.academy) {
                                    return;
                                }

                                if (!eachLocation.academy.includes(props.user.academy)) {
                                    return;
                                }

                                if (eachLocation.english === "total") {
                                    return;
                                }

                                var korean = eachLocation.korean;

                                if (korean === "대치점") {
                                    korean = "대치2층"
                                }

                                return (
                                    <div style={{
                                        marginBottom: "6px"
                                    }}>
                                        <Checkbox label={<span>{korean}</span>} value={eachLocation.english}
                                            checked={filteredLocation.includes(eachLocation.english)}
                                            onChange={(e: any) => {
                                                const value = e.target.value;
                                                if (filteredLocation.includes(value)) {
                                                    const data = filteredLocation.filter((each: any) => {
                                                        return each !== value;
                                                    });
                                                    setFilteredLocation([...data]);
                                                } else {
                                                    setFilteredLocation([...filteredLocation, value]);
                                                }
                                            }}
                                        />
                                    </div>
                                )

                            })
                        }
                    </div>
                    <div style={{
                        marginTop: "20px"
                    }}>
                        <Button2
                            fullWidth
                            loading={btnLoading}
                            onClick={() => {
                                filterSubmit();
                            }}
                        >
                            저장하기
                        </Button2>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ChatForTeacher;