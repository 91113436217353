import { ro } from "date-fns/locale";
import { englishLocationToKorean } from "../../../../../data/locationFuntion";
import { dateToDateString } from "../../etc/etcfunctions";

export const refinedPhoneNumber = (callLogData: any, smsManagerData: any, mmsManagerData: any) => {

    if (callLogData) {

        callLogData.forEach((callLog: any) => {

            var phoneNumber = callLog.number;
            var myPhoneNumber = callLog.myPhoneNumber;

            callLog.phoneNumber = _convertPhoneNumber(phoneNumber);
            callLog.myPhoneNumber = _convertPhoneNumber(myPhoneNumber);

        });

    }

    if (smsManagerData) {

        smsManagerData.forEach((smsManager: any) => {

            var phoneNumber = smsManager.phoneNumber;
            var myPhoneNumber = smsManager.myPhoneNumber;

            smsManager.phoneNumber = _convertPhoneNumber(phoneNumber);
            smsManager.myPhoneNumber = _convertPhoneNumber(myPhoneNumber);

        });

    }

    if (mmsManagerData) {

        mmsManagerData.forEach((mmsManager: any) => {

            var phoneNumber = mmsManager.phoneNumber;
            var myPhoneNumber = mmsManager.myPhoneNumber;

            mmsManager.phoneNumber = _convertPhoneNumber(phoneNumber);
            mmsManager.myPhoneNumber = _convertPhoneNumber(myPhoneNumber);

        });

    }

}

function _convertPhoneNumber(phoneNumber: string) {
    let result = phoneNumber.trim();

    // 1) +82로 시작하는 경우
    if (result.startsWith('+82')) {
        // +82 제거
        result = result.slice(3);

        // 맨 앞에 0이 없다면 0을 붙인다
        if (!result.startsWith('0')) {
            result = '0' + result;
        }
    }
    // 2) *28로 시작하는 경우
    else if (result.startsWith('*28')) {
        // *28 제거
        result = result.slice(3);

        // 제거 후 첫 글자가 1이면 그 1도 제거
        if (result.startsWith('1')) {
            result = result.slice(1);
        }
    }

    // 그 외에는 별도 변환 없음
    return result;
}


export const postNameToPhoneNumber = (totalUsers: any, callLogData: any, smsManagerData: any, mmsManagerData: any, locationPhoneNumber: any, staffPhone: any) => {

    if (callLogData) {

        callLogData.forEach((callLog: any) => {

            var phoneNumber = callLog.phoneNumber;
            callLog.phoneNumberName = _findUserByPhoneNumber(phoneNumber, totalUsers, locationPhoneNumber, staffPhone);

            var myPhoneNumber = callLog.myPhoneNumber;
            callLog.myPhoneNumberName = _findUserByPhoneNumber(myPhoneNumber, totalUsers, locationPhoneNumber, staffPhone);
        });

    }

    if (smsManagerData) {

        smsManagerData.forEach((smsManager: any) => {

            var phoneNumber = smsManager.phoneNumber;
            smsManager.phoneNumberName = _findUserByPhoneNumber(phoneNumber, totalUsers, locationPhoneNumber, staffPhone);

            var myPhoneNumber = smsManager.myPhoneNumber;
            smsManager.myPhoneNumberName = _findUserByPhoneNumber(myPhoneNumber, totalUsers, locationPhoneNumber, staffPhone);
        });

    }

    if (mmsManagerData) {

        mmsManagerData.forEach((mmsManager: any) => {

            var phoneNumber = mmsManager.phoneNumber;
            mmsManager.phoneNumberName = _findUserByPhoneNumber(phoneNumber, totalUsers, locationPhoneNumber, staffPhone);

            var myPhoneNumber = mmsManager.myPhoneNumber;
            mmsManager.myPhoneNumberName = _findUserByPhoneNumber(myPhoneNumber, totalUsers, locationPhoneNumber, staffPhone);
        });

    }
}

function _findUserByPhoneNumber(phoneNumber: string, totalUsers: any, locationPhoneNumber: any, staffPhone: any) {

    var name;

    if (staffPhone) {
        staffPhone.forEach((staff: any) => {
            if (phoneNumber === staff.phoneNumber) {
                name = staff.name + " 관리자";
                return;
            }
        });
    }

    if (name) {
        return name;
    }

    //locationPhoneNumber는 key : value 형태로 되어있음
    for (const [key, value] of Object.entries(locationPhoneNumber)) {
        if (phoneNumber === value) {

            const englishName = key;
            name = englishLocationToKorean(englishName);
            return name + " 데스크";
        }
    }

    if (name) {
        return name;
    }

    totalUsers.forEach((user: any) => {

        if (phoneNumber === user.phoneNumber) {

            if (user.value !== "parent") {

                name = user.name;
                return;

            } else {

                const studentPhoneNumber = user.studentPhoneNumber;
                totalUsers.forEach((student: any) => {
                    if (studentPhoneNumber === student.phoneNumber) {
                        name = student.name + " 학부모";
                        return;
                    }
                });

            }
        }
    });

    return name;

}

export const makeRows = (callLogData: any, smsManagerData: any, mmsManagerData: any) => {

    const rows: any = [];

    if (callLogData) {

        callLogData.forEach((callLog: any, index: number) => {

            const oneRow: any = {};

            oneRow.id = callLog.id + "/callLog" + index;
            oneRow.type = "callLog";
            oneRow.myPhoneNumber = callLog.myPhoneNumber;
            oneRow.phoneNumber = callLog.phoneNumber;
            oneRow.phoneNumberName = callLog.phoneNumberName;
            oneRow.myPhoneNumberName = callLog.myPhoneNumberName;
            oneRow.src = callLog.src;
            oneRow.duration = callLog.duration;

            oneRow.durationString = callLog.duration + "초";

            const waitTimeMilliSecond = (+callLog.last_modified) - (+callLog.dateTime) - (+callLog.duration * 1000);
            const waitTimeSecond = Math.floor(waitTimeMilliSecond / 1000);
            oneRow.waitTime = waitTimeSecond;

            oneRow.waitTimeString = waitTimeSecond + "초";

            const date = new Date(+callLog.dateTime);
            const dateString = dateToDateString(date, true);
            const dateStringOnlyTime = dateToDateString(date, true, true);
            const onlyDateString = dateToDateString(date, false);
            const timeNumber = date.getTime();

            oneRow.date = dateString;
            oneRow.timeNumber = timeNumber;
            oneRow.title = callLog.title;
            oneRow.contents = callLog.contents;
            oneRow.onlyDateString = onlyDateString;
            oneRow.recordDuration = callLog.recordDuration;
            oneRow.sendType = callLog.type;
            oneRow.dateOnlyTime = dateStringOnlyTime;

            rows.push(oneRow);
        })

    }

    if (smsManagerData) {

        smsManagerData.forEach((smsManager: any, index: number) => {

            const oneRow: any = {};

            oneRow.id = smsManager.id + "/smsManager" + index;
            oneRow.type = "smsManager";
            oneRow.myPhoneNumber = smsManager.myPhoneNumber;
            oneRow.phoneNumber = smsManager.phoneNumber;
            oneRow.phoneNumberName = smsManager.phoneNumberName;
            oneRow.myPhoneNumberName = smsManager.myPhoneNumberName;
            oneRow.text = smsManager.text;

            const date = new Date(+smsManager.timeValue);
            const dateString = dateToDateString(date, true);
            const onlyDateString = dateToDateString(date, false);
            const dateStringOnlyTime = dateToDateString(date, true, true);
            const timeNumber = date.getTime();
            oneRow.date = dateString;
            oneRow.onlyDateString = onlyDateString;
            oneRow.timeNumber = timeNumber;
            oneRow.sendType = smsManager.type;
            oneRow.dateOnlyTime = dateStringOnlyTime;

            rows.push(oneRow);
        })

    }

    if (mmsManagerData) {

        mmsManagerData.forEach((mmsManager: any, index: number) => {

            const oneRow: any = {};

            oneRow.id = mmsManager.id + "/mmsManager" + index;
            oneRow.type = "mmsManager";
            oneRow.myPhoneNumber = mmsManager.myPhoneNumber;
            oneRow.phoneNumber = mmsManager.phoneNumber;
            oneRow.phoneNumberName = mmsManager.phoneNumberName;
            oneRow.myPhoneNumberName = mmsManager.myPhoneNumberName;
            oneRow.text = mmsManager.text;
            oneRow.src = mmsManager.src;

            const date = new Date(+mmsManager.timeValue);
            const dateString = dateToDateString(date, true);
            const dateStringOnlyTime = dateToDateString(date, true, true);
            const onlyDateString = dateToDateString(date, false);
            const timeNumber = date.getTime();
            oneRow.date = dateString;
            oneRow.onlyDateString = onlyDateString;
            oneRow.dateOnlyTime = dateStringOnlyTime;
            oneRow.timeNumber = timeNumber;
            oneRow.sendType = mmsManager.type;

            rows.push(oneRow);
        })

    }

    rows.forEach((row: any, index: number) => {

        if (row.sendType === 1 || row.sendType === "1") {
            row.direction = "수신";
        } else if (row.sendType === 2 || row.sendType === "2") {
            row.direction = "발신";
        } else if (row.sendType === 3 || row.sendType === "3") {
            row.direction = "수신(부재중)";
        } else if (row.sendType === 5 || row.sendType === "5") {
            row.direction = "수신(거절함)";
        }

        if (row.type === "smsManager" || row.type === "mmsManager") {
            row.typeName = "문자";
        } else if (row.type === "callLog") {
            row.typeName = "통화";
        }

        if(row.phoneNumberName){
            row.phoneNumberName += " (" + row.phoneNumber + ")";
        }

        if(row.myPhoneNumberName){
            row.myPhoneNumberName += " (" + row.myPhoneNumber + ")";
        }

        if (!row.phoneNumberName) {
            row.phoneNumberName = row.phoneNumber;
        }

        if (!row.myPhoneNumberName) {
            row.myPhoneNumberName = row.myPhoneNumber;
        }

        if (row.type === "smsManager" || row.type === "mmsManager") {
            row.description = row.text;
        }

        if (row.sendType === 1 || row.sendType === "1" || row.sendType === 3 || row.sendType === "3" || row.sendType === 5 || row.sendType === "5") {

            const sender = row.phoneNumberName;
            const receiver = row.myPhoneNumberName;

            row.sender = sender;
            row.receiver = receiver;

        }

        if (row.sendType === 2 || row.sendType === "2") {

            const sender = row.myPhoneNumberName;
            const receiver = row.phoneNumberName;

            row.sender = sender;
            row.receiver = receiver;

        }

        //myPhoneNumberName에 관리자나 데스크가 들어가면 row.direction = "student", 없으면 row.direction = "teacher"
        if (row.sendType === 1 || row.sendType === "1" || row.sendType === 3 || row.sendType === "3" || row.sendType === 5 || row.sendType === "5") {
            row.owner = "client";
        } else {
            row.owner = "academy";
        }

        row.kind = "text";

        if (row.type === "callLog") {
            row.kind = "call";
        }

    });


    return rows;

}

export const mmsImageToNewRow = (rows: any) => {

    for (var i = 0; i < rows.length; i++) {

        const row = rows[i];

        if (row.type === "mmsManager" && row.src) {

            const newRow = {
                ...row,
                kind: "img",
                text: row.src,
                id: row.id + "/img",
                src: null,
            }

            rows.splice(i + 1, 0, newRow);

            i++;

        }
    }

}

export const insertDateRow = (rows: any) => {

    //맨 처음 날짜 row 추가
    const firstRow = rows[0];
    //date에서 띄어쓰기 앞만 사용

    if (!firstRow) {
        return;
    }

    const firstDate = firstRow.date ? firstRow.date.split(" ")[0] : firstRow.onlyDateString;

    const makeDateRow = {
        id: "date" + firstDate + Math.random(),
        kind: "date",
        date: firstDate,
    }

    //맨 처음에 추가
    rows.unshift(makeDateRow);

    //돌면서 날짜가 바뀌는 부분에 날짜 row 추가

    var previousDate = firstDate;

    for (var i = 1; i < rows.length; i++) {

        const row = rows[i];
        const date = row.date ? row.date.split(" ")[0] : row.onlyDateString;

        if (date !== previousDate) {

            const makeDateRow = {
                id: "date" + date + Math.random(),
                kind: "date",
                date: date,
            }

            rows.splice(i, 0, makeDateRow);

            previousDate = date;

            i++;

        }

    }
}

export const sortRows = (rows: any) => {

    rows.sort((a: any, b: any) => {

        if (a.timeNumber > b.timeNumber) {
            return -1;
        } else if (a.timeNumber < b.timeNumber) {
            return 1;
        } else {
            return 0;
        }

    });

}

export const filterAdminRows = (rows: any) => {

    //myPhoneNumberName에 관리자 들어가면 제외시킨다

    const filteredRows = rows.filter((row: any) => {
        if (row && row.myPhoneNumber && row.myPhoneNumberName.includes("관리자")) {
            return false;
        } else {
            return true;
        }
    });

    return filteredRows;

}

