import React, { useEffect, useState } from 'react';
import { tableUseStyle } from '../../etc/tableusestyle';
import { DataGridPremium, gridFilteredSortedRowEntriesSelector, GridFilterModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { Button } from '@mui/joy';
import { dateToDateString } from '../../etc/etcfunctions';
import { Autocomplete, Backdrop, Box, CircularProgress, Modal, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import totalLocationData from '../../../../../data/locationdata';
import CustomInput from '../../etc/custominput';
import { fil } from 'date-fns/locale';
import { postFetch } from '../../../../../fetch/fetch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const today = new Date();

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "24px",
    boxShadow: 24,
    p: 3,
    paddingLeft: 5,
    paddingRight: 5
};

const PrintList: React.FC<any> = (props) => {

    const classes = tableUseStyle();
    const [rows, setRows] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([
        { field: "location", headerName: "지점", width: 100 },
        { field: "isConnected", headerName: "연결 여부", width: 120 },
        { field: "name", headerName: "윈도우 계정", width: 100 },
        { field: "userId", headerName: "연결된 유저ID", width: 120 },
        { field: "userName", headerName: "연결된 유저 이름", width: 120 },
        { field: "price", headerName: "비용", width: 100 },
        { field: "pages", headerName: "페이지 수", width: 100 },
        { field: "duplex", headerName: "단면/양면", width: 100 },
        { field: "document", headerName: "문서 이름", width: 200 },
        { field: "printTime", headerName: "프린트 시간", width: 180 },
        { field: "paid", headerName: "결제 적용 여부", width: 150 },
        { field: "isPaid", headerName: "결제 적용 일시", width: 100 },
    ]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alignment, setAlignment] = useState("total");
    const [alignment2, setAlignment2] = useState("notPaid");
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            { id: 2, field: 'name', operator: 'contains', value: "" },
            { id: 5, field: "location", operator: "isAnyOf", value: [] },
            { id: 7, field: "paid", operator: "contains", value: "X" }
        ],
    });

    const [update, setUpdate] = useState(0);
    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState(dateToDateString(new Date(today.getFullYear(), today.getMonth() - 2, today.getDate())));
    const [endDate, setEndDate] = useState(dateToDateString(today));
    const [unmatchedCount, setUnmatchedCount] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    const [selectedIds, setSelectedIds] = useState<any[]>([]);

    const [submitLoading, setSubmitLoading] = useState(false);

    const apiRef = useGridApiRef();

    const [open, setOpen] = useState(false);

    const [users, setUsers] = useState<any>([]);
    const [userLoading, setUserLoading] = useState(false);

    const [selectedUser, setSelectedUser] = useState<any>(null);

    useEffect(() => {

        if (!startDate || !endDate) {
            return;
        }

        getPrintData();

    }, [update, startDate, endDate]);

    useEffect(() => {

        setUserLoading(true);

        async function start() {

            var token = "";

            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            fetch("https://peetsunbae.com/dashboard/restaurant/users", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {

                        const rows: any = [];

                        result.data?.forEach((each: any, index: number) => {
                            var data: any = {};
                            data.id = each.id;
                            data.label = `${each.name} (${each.id})`;
                            data.phoneNumber = each.phoneNumber;
                            data.amount = each.amount;
                            data.value = each.value;
                            data.key = index;
                            rows.push(data);
                        })
                        setUsers([...rows]);
                        setUserLoading(false);

                    })
            })
        }

        start();

    }, [update]);

    const notify1 = () => toast.success("저장 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });


    const getPrintData = async () => {

        try {

            const response = await fetch("https://peetsunbae.com/dashboard/payments/print/totallog", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include"
            });

            const result = await response.json();

            const data = result.data;

            const startDateTime = new Date(startDate).getTime();
            const endDateTime = new Date(endDate).getTime();

            const newRows: any = [];

            data.forEach((item: any, index: number) => {

                if (item.name === "user") {
                    return;
                }

                const oneRow: any = {};

                oneRow.id = item.id;
                oneRow.location = item.location;
                oneRow.name = item.name;
                oneRow.price = (item.pages * 50).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
                oneRow.priceNumber = item.pages * 50;
                oneRow.pages = item.pages;
                oneRow.duplex = item.duplex === "DUPLEX" ? "양면" : "단면";
                oneRow.document = item.document;
                oneRow.excepted = item.excepted;

                const printDate = new Date(item.printTime);

                oneRow.printDateTime = printDate.getTime();

                if (oneRow.printDateTime < startDateTime || oneRow.printDateTime > endDateTime) {
                    return;
                }

                const printDateString = dateToDateString(printDate, true);

                oneRow.printTime = printDateString;

                if (item.userName) {
                    oneRow.isConnected = "계정 연결됨";
                } else {
                    oneRow.isConnected = "계정 연결 안됨";
                }

                oneRow.userId = item.userId;
                oneRow.userName = item.userName;

                if (item.appliedAt) {

                    oneRow.paid = "O";

                    const appliedDate = new Date(item.appliedAt);
                    const appliedDateString = dateToDateString(appliedDate, true);
                    oneRow.isPaid = appliedDateString;

                } else {
                    oneRow.paid = "X";
                }

                newRows.push(oneRow);

            });

            newRows.sort((a: any, b: any) => {
                return b.printDateTime - a.printDateTime;
            });

            setRows([...newRows]);


        } catch (e) {
            console.log(e);
        }

    }

    const submit = async () => {

        try {

            setBtnLoading(true);

            const result = await fetch("http://211.37.149.74:9001/printlog527", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                }
            });


            setBtnLoading(false);

            setUpdate(Math.random());


        } catch (e) {
            console.log(e);
        }

    }

    const handleChange = (e: any, newAlignment: string) => {



        if (!newAlignment) {
            return;
        }

        setAlignment(newAlignment);

        const newFilterModel: any = filterModel;

        const locationList: any = [];

        totalLocationData.forEach((location) => {

            if (newAlignment === "total") {


            } else if (location.english === newAlignment) {
                //     
                //     
                //     

                // newFilterModel.items.forEach((each : any) => {
                //     if(each.id === 5){
                //         each.value = location.filterValue_contains;
                //     }});

                locationList.push(location.korean);
            }

        });

        newFilterModel.items.forEach((each: any) => {
            if (each.id === 5) {
                each.value = locationList;
            }
        })



        setFilterModel({ ...newFilterModel });

    }

    const nameChange = (e: any) => {

        setName(e.target.value);

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each: any) => {

            if (each.id === 2) {
                each.value = e.target.value;
            }

        })

        setFilterModel({ ...newFilterModel });

    }

    const handleStateChange = (e: any) => {

        const filteredSortedEntries = gridFilteredSortedRowEntriesSelector(apiRef);

        var unmatchedCount = 0;
        var totalCost = 0;

        filteredSortedEntries.forEach((each: any) => {

            if (!each || !each.model) {
                return;
            }

            const isConnected = each.model.isConnected;

            if (isConnected === "계정 연결 안됨") {
                unmatchedCount++;
            }

            const price = each.model.priceNumber ? +each.model.priceNumber : 0;

            totalCost += price;

        });


        setUnmatchedCount(unmatchedCount);
        setTotalCost(totalCost);

    }

    const handleChange2 = (e: any, newAlignment: string) => {

        setAlignment2(newAlignment);

        const newFilterModel = filterModel;

        if (newAlignment === "notPaid") {

            newFilterModel.items.forEach((each: any) => {
                if (each.id === 7) {
                    each.value = "X";
                }
            });

        } else if (newAlignment === "paid") {

            newFilterModel.items.forEach((each: any) => {
                if (each.id === 7) {
                    each.value = "O";
                }
            });

        }

        setFilterModel({ ...newFilterModel });

    }

    const letsExclude = async () => {

        try {

            if (!window.confirm(`${selectedIds.length}개의 프린트를 제외시키겠습니까?`)) {
                return;
            }

            setSubmitLoading(true);

            console.log(selectedIds);

            const result: any = await postFetch("/dashboard/payments/print/exclude", { selectedIds });

            if (result.message === "success") {
                setUpdate(Math.random());
            }

            setSubmitLoading(false);

        } catch (e) {
            console.log(e);
        }


    }

    const handleOpen = (e: any) => {

        setOpen(true);

    }

    const handleClose = (e: any) => {

        setSelectedUser(null);
        setOpen(false);

    }

    const onchange = (e: any, newValue: any) => {

        if (!users) {
            return;
        }
        
        setSelectedUser(newValue);

    }

    const updateUserId = async () => {

        try{

            if(!selectedUser){
                return;
            }

            if(!window.confirm(`${selectedIds.length}개의 프린트를 ${selectedUser.label}로 변경하시겠습니까?`)){
                return;
            }

            setSubmitLoading(true);

            console.log(selectedIds, selectedUser);

            const result : any = await postFetch("/dashboard/payments/print/updateuserid", {selectedIds, userId : selectedUser.id});

            if(result.message === "success"){
                notify1();
                setUpdate(Math.random());
            }

            handleClose("");

            setSubmitLoading(false);

        }catch(e){
            console.log(e);
        }

    }

    return (
        <div>

            <div style={{
                marginTop: "50px",
                display: "flex",
                marginBottom: "20px",
                alignItems: "center"
            }}>
                <div style={{
                    borderBottom: "1px solid black"
                }}>
                    <input type='date'
                        value={startDate}
                        style={{
                            border: 0,
                            fontSize: "18px",
                            backgroundColor: "transparent",
                            fontFamily: "Apple_R",
                            marginRight: "0px",
                            color: "gray"
                        }}
                        onChange={(e) => {
                            console.log(e.target.value);
                            setStartDate(e.target.value);
                        }}
                    />
                </div>
                <div style={{
                    width: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    -
                </div>
                <div style={{
                    borderBottom: "1px solid black"
                }}>
                    <input type='date'
                        value={endDate}
                        style={{
                            border: 0,
                            fontSize: "18px",
                            backgroundColor: "transparent",
                            fontFamily: "Apple_R",
                            color: "gray"
                        }}
                        onChange={(e) => {
                            setEndDate(e.target.value);
                        }}
                    />
                </div>
            </div>

            <div style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                width: "1700px"
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",

                }}>
                    <div style={{
                        marginRight: "30px"
                    }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment2}
                            exclusive
                            onChange={handleChange2}
                        >
                            <ToggleButton value="notPaid">미결제</ToggleButton>
                            <ToggleButton value="paid">결제 완료</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div style={{
                        marginRight: "30px"
                    }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                        >
                            {
                                props.user && totalLocationData.filter((each) => each.academy.includes(props.user.academy)).map((each, index) => {
                                    return (
                                        <ToggleButton value={each.english} key={index}>{each.korean}</ToggleButton>
                                    )
                                })
                            }
                        </ToggleButtonGroup>
                    </div>
                    <span style={{
                        marginLeft: "20px"
                    }}>
                        <CustomInput value={name} onChange={nameChange} placeholder="윈도우 계정 검색" />
                    </span>
                </div>
                <div>
                    <Button
                        variant='soft'
                        color='neutral'
                        onClick={handleOpen}
                        loading={btnLoading}
                        disabled={selectedIds.length === 0}
                        sx={{
                            marginRight: "15px"
                        }}
                    >
                        연결된 유저ID 바꾸기
                    </Button>
                    <Button
                        variant='soft'
                        color='danger'
                        onClick={letsExclude}
                        loading={btnLoading}
                        disabled={selectedIds.length === 0}
                    >
                        제외시키기
                    </Button>
                </div>

            </div>
            <div className={classes.root} style={{
                backgroundColor: "white",
                width: "1700px",
                height: "600px",
                marginTop: "10px"
            }}>
                <DataGridPremium
                    rows={rows}
                    columns={columns}
                    density='compact'
                    checkboxSelection
                    apiRef={apiRef}
                    filterModel={filterModel}
                    onFilterModelChange={(model) => {
                        setFilterModel(model);
                    }}
                    getCellClassName={(params: any) => {

                        if (params.field === "isConnected" && params.value === "계정 연결 안됨") {
                            return "redtext";
                        }

                        if (params.row.excepted) {
                            return "deleted";
                        }

                        return "";
                    }}
                    onStateChange={(e) => {
                        handleStateChange(e);
                    }}
                    disableRowSelectionOnClick={true}
                    onRowClick={(e) => {
                        console.log(e);
                    }}
                    onRowSelectionModelChange={(newSelection) => {
                        setSelectedIds([...newSelection]);
                    }}
                    loading={loading}
                    isRowSelectable={(params) => {
                        if (params.row.excepted) {
                            return false;
                        }

                        if(params.row.paid === "O"){
                            return false;
                        }

                        return true;
                    }}
                />
            </div>
            <div style={{
                marginTop: "10px",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                width: "1700px"
            }}>
                <div >
                    <span style={{
                    color: unmatchedCount > 0 ? "red" : "black"
                }}>매칭 안 된 프린트 내역 수 : {unmatchedCount}</span>
                     &nbsp;/ 총 비용 : {totalCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                </div>
                <div>
                    <Button
                        variant='soft'
                        color='primary'
                        onClick={submit}
                        loading={btnLoading}
                    >
                        동기화 시키기
                    </Button>
                </div>
            </div>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={submitLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div style={{
                        fontFamily: "Apple_SB",
                        marginBottom : "12px"
                    }}>
                        연결할 유저를 선택해주세요.
                    </div>

                    <Autocomplete
                        onChange={onchange}
                        value={selectedUser}
                        disablePortal
                        id="combo-box-demo"
                        options={users}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} placeholder="학생을 선택하세요" />}
                        size='small'
                        loading={userLoading}
                    />

                    <div>
                        <Button
                            color='primary'
                            onClick={updateUserId}
                            loading={submitLoading}
                            sx={{
                                marginTop: "24px"
                            }}
                            fullWidth
                        >
                            변경하기
                        </Button>
                    </div>

                </Box>
            </Modal>
        </div>
    )
}

export default PrintList;