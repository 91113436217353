import { CssVarsProvider, Button } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { DataGridPremium, GridColDef, GridFilterModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';
import { GridRowProps } from "@mui/x-data-grid";
import { TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import Autocomplete from '@mui/material/Autocomplete';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomInput from "./etc/custominput";



const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => {
        const getBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

        const getHoverBackgroundColor = (color: any) =>
            theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

        return {
            root: {
                '& .super-app-theme--처리완료': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
                    },
                },
                '& .super-app-theme--Filled': {
                    backgroundColor: getBackgroundColor(theme.palette.success.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
                    },
                },
                '& .super-app-theme--확인': {
                    backgroundColor: getBackgroundColor(theme.palette.warning.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                    },
                },
                '& .super-app-theme--미확인': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                    '&:hover': {
                        backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
                    },
                },
                '& .clickable': {
                    cursor: "pointer",
                    '&:hover': {
                        color: "blue"
                    }
                },
                '& .status3': {

                },
                '& .inactivated': {
                    backgroundColor: getBackgroundColor(theme.palette.grey[500]),
                },
                '& .complete': {
                    backgroundColor: getBackgroundColor(theme.palette.info.main),
                },
                '& .incomplete': {
                    backgroundColor: getBackgroundColor(theme.palette.error.main),
                },
                '& .passivecomplete': {
                    backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
                },
                '& .status5': {
                    backgroudColor: "red"
                }
            },
        };
    },
    { defaultTheme },
);


const columns : GridColDef[] = [
    {field : "name", headerName : "입금자명", width : 100},
    {field : "amount", headerName : "입금액수", width : 100},
    {field : "sendedTime", headerName : "입금시간", width : 160},
    {field : "completed", headerName : "매칭여부", width : 100},
    {field : "completedName", headerName : "매칭된 사람", width : 160},
    {field : "completedTime", headerName : "매칭 시간", width : 160},
    {field : "passiveName", headerName : "수동 매칭 책임자", width : 160}
]

const TotalSendedMoney : React.FC<any> = (props : any) => {

    const [open, setOpen] = useState(false);
    const apiRef = useGridApiRef();
    const [update, setUpdate] = useState(0);
    const [disabled1, setDisabled1] = useState(true);
    const [disabled3, setDisabled3] = useState(true);
    const [disabled5, setDisabled5] = useState(true);
    const [alignment, setAlignment] = useState("total");
    const [findName, setFindName] = useState("");
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState<GridRowProps[]>([]);
    const classes = useStyles2();
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                id: 2,
                field: "name",
                operator: "contains",
                value: ""
            },
            {
                id : 3,
                field : "completed",
                operator : "equals",
                value : ""
            }
        ]
    });
    const [users, setUsers] = useState<any>([]);
    const [selectedUser, setSelectedUser] = useState<any>();

    const [selectedId, setSelectedId] = useState();
    const [selectedAmount, setSelectedAmount] = useState("");
    const [selectedRealAmount, setSelectedRealAmount] = useState(0);
    const [selectedName, setSelectedName] = useState("");

    useEffect(() => {

        setLoading(true);
        
        async function start() {

            var token = "";

            if (window.electron) {
                token = await window.electron.sendMessageApi.getToken();
            }

            fetch("https://peetsunbae.com/dashboard/restaurant/users", {
                method: "GET",
                headers: { "Authorization": token },
                credentials: "include",
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {
                        
                        const rows: any = [];

                        

                        result.data?.forEach((each: any, index: number) => {
                            var data: any = {};
                            data.id = each.id;
                            data.label = `${each.name}(${each.location})(${each.id}) - 보유금액 ${each.amount ? each.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}원`;
                            data.phoneNumber = each.phoneNumber;
                            data.amount = each.amount;
                            data.value = each.value;
                            data.key = index;
                            rows.push(data);
                        })
                        setUsers([...rows]);
                        setLoading(false);

                    })
            })
        }

        start();

    }, [update])

    useEffect(() => {

        getData();

    }, [update]);

    const notify1 = () => toast.success("매칭 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify2 = () => toast.success("비활성화 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });


    const getData = () => {

        setLoading(true);

        fetch("https://peetsunbae.com/dashboard/restaurant/totalsendedmoney", {
            method: "get",
            credentials: "include"
        }).then((response: any) => {
            response.json()
                .then((result: any) => {
                    
                    if(result.message === "success"){

                        const newRows : any = [];
                        const data = result.data;

                        data.forEach((each : any) => {
                            const oneRow : any = {};
                            oneRow.id = each.id;
                            oneRow.name = each.name;
                            oneRow.realAmount = each.amount;
                            oneRow.amount = each.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + " 원";
                            const date1 = new Date(each.createdAt);
                            oneRow.sendedTimeNumber = date1.getTime();
                            oneRow.sendedTime = `${date1.getMonth() + 1}/${date1.getDate()}  ${date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()}:${date1.getMinutes() < 10 ? "0" + date1.getMinutes() : date1.getMinutes()}:${date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds()}`;

                            if(each.completed === 1 || each.completed === 2){
                                if(each.completed === 1){
                                    oneRow.completed = "매칭";
                                }
                                if(each.completed === 2){
                                    oneRow.completed = "수동매칭";
                                    oneRow.passiveName = each.teacherName;
                                }

                                oneRow.completedName = `${each.studentName} (${each.location})`;
                                const date2 = new Date(each.completedTime);
                                oneRow.completedTime =  `${date2.getMonth() + 1}/${date2.getDate()}  ${date2.getHours() < 10 ? "0" + date2.getHours() : date2.getHours()}:${date2.getMinutes() < 10 ? "0" + date2.getMinutes() : date2.getMinutes()}:${date2.getSeconds() < 10 ? "0" + date2.getSeconds() : date2.getSeconds()}`;
                            }

                            if(each.completed === 3){
                                oneRow.completed = "비활성화";
                                const date2 = new Date(each.completedTime);
                                oneRow.completedTime =  `${date2.getMonth() + 1}/${date2.getDate()}  ${date2.getHours() < 10 ? "0" + date2.getHours() : date2.getHours()}:${date2.getMinutes() < 10 ? "0" + date2.getMinutes() : date2.getMinutes()}:${date2.getSeconds() < 10 ? "0" + date2.getSeconds() : date2.getSeconds()}`;
                                oneRow.passiveName = each.teacherName;
                            }

                            if(each.completed === 0){
                                oneRow.completed = "비매칭";
                            }

                            newRows.push(oneRow);
                        })

                        //newRows를 sendedTimeNumber로 내림차순 정렬
                        newRows.sort((a : any, b : any) => {
                            return b.sendedTimeNumber - a.sendedTimeNumber;
                        })

                        setRows([...newRows]);

                        setLoading(false);
                        
                    }
                })
        })

    }


    const handleTextChange = (e : any) => {
        setFindName(e.target.value);

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((item) => {
            if(item.id === 2){
                item.value = e.target.value;
            }
        })

        setFilterModel({...newFilterModel});
    }

    const handleAlignmentChange = (e : any, newAlignment : string) => {

        if(!newAlignment){
            return;
        }

        setAlignment(newAlignment);

        const newFilterModel = filterModel;

        if(newAlignment === "total"){

            newFilterModel.items.forEach((eachFilter : any) => {

                if(eachFilter.id === 3){
                    eachFilter.value = "";
                }

            })

        }

        if(newAlignment === "incomplete"){

            newFilterModel.items.forEach((eachFilter : any) => {

                if(eachFilter.id === 3){
                    eachFilter.value = "비매칭";
                }

            })
        }

        if(newAlignment === "passivecomplete"){

            newFilterModel.items.forEach((eachFilter : any) => {

                if(eachFilter.id === 3){
                    eachFilter.value = "수동매칭";
                }

            })
        }

        setFilterModel({...newFilterModel});

    }

    useEffect(() => {

        if(selectedUser){
            setDisabled3(false);
        }else{
            setDisabled3(true);
        }

    }, [selectedUser]);

    const onchange = async (e: any, value: any) => {

        if (!users) {
            return;
        }

        

        
        setSelectedUser(value);
    }

    const handleInactivate = (e : any) => {


        if(window.confirm("해당 내용을 비활성화시키겠습니까?")){

        }else{
            return;
        }
        

        if(!selectedId){
            alert("선택된 아이디가 없습니다");
            return;
        }

        fetch("https://peetsunbae.com/dashboard/restaurant/inactivate", {
            method : "post",
            credentials : "include",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({
                selectedId
            })
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                
                if(result.message === "success"){
                    notify2();
                    setUpdate(Math.random());
                }
            })
        })


    }

    const handleSubmit = (e : any) => {

        if(!selectedUser){
            alert("유저를 먼저 선택하세요");
            return;
        }

        if(!selectedRealAmount){
            alert("금액이 선택되지 않았습니다.");
            return;
        }

        if(!selectedId){
            alert("선택된 아이디가 없습니다");
            return;
        }

        const data = {
            selectedId : selectedId,
            userId : selectedUser.id,
            amount : selectedRealAmount
        }

        fetch("https://peetsunbae.com/dashboard/restaurant/passiveapply", {
            method : "post",
            credentials : "include",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({
                data
            })
        }).then((response : any) => {
            response.json()
            .then((result : any) => {
                
                if(result.message === "success"){
                    notify1();
                    setUpdate(Math.random());
                }
            })
        })
    }


    return (
        <div>


            <div style={{width : "1500px", justifyContent : "space-between", marginTop : "32px", display : "flex", alignItems : "center"}}>
                <div style={{display : "flex", alignItems : "center"}}>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleAlignmentChange}
                        aria-label="Platform"
                        sx={{ marginRight: "36px" }}
                    >
                        <ToggleButton value="total">전체</ToggleButton>
                        <ToggleButton value="incomplete">비매칭</ToggleButton>
                        <ToggleButton value="passivecomplete">수동매칭</ToggleButton>
                    </ToggleButtonGroup>

                    {/* <TextField placeholder="입금자명" variant="standard" value={findName} sx={{marginLeft : "24px"}} onChange={(e) => {handleTextChange(e);}} /> */}
                    <CustomInput value={findName} onChange={handleTextChange} placeholder="입금자명" />
                </div>
                <div>
                    <Button variant="soft"  onClick={(e : any) => {handleInactivate(e);}} disabled={disabled5} style={{marginRight : "8px"}}>
                        비활성화시키기
                    </Button>
                    <Button variant="soft" onClick={(e : any) => {setOpen(true);}} disabled={disabled1}>
                        수동 매칭시키기
                    </Button>
                </div>
            </div>
            <div className={classes.root} style={{ height: 650, width: '1500px', backgroundColor: "white", marginTop : "12px" }}>
                <DataGridPremium
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                loading={loading}
                density="compact"
                getCellClassName={(params) => {
                    if(params.field === "completed"){
                        if(params.value === "매칭"){
                            return "complete"
                        }
                        if(params.value === "비매칭"){
                            return "incomplete"
                        }
                        if(params.value === "수동매칭"){
                            return "passivecomplete"
                        }
                        if(params.value === "비활성화"){
                            return "inactivated"
                        }
                    }
                    return "";
                }}
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                onRowSelectionModelChange={(newSelectionModel) => {
                    const data: any = apiRef.current.getSelectedRows();
                    if (!data) {
                        return;
                    }
                    
                    
                    data.forEach((value: any, index: number) => {
                        
                        
                        setSelectedId(value.id);
                        setSelectedAmount(value.amount);
                        setSelectedName(value.name);
                        setSelectedRealAmount(value.realAmount);

                        if(value.completed === "비매칭"){
                            setDisabled1(false);
                            setDisabled5(false);
                        }else{
                            setDisabled1(true);
                            setDisabled5(true);
                        }
                    })
                }}
                />
            </div>



            <Modal open={open} onClose={() => {setOpen(false); setDisabled3(true); setSelectedUser(null);}}>
                <ModalDialog
                    aria-labelledby="basic-modal-dialog-title"
                    aria-describedby="basic-modal-dialog-description"
                    sx={{ maxWidth: 800 }}
                >
                    <Typography id="basic-modal-dialog-title" component="h2">
                        입금내역 수동 매칭하기
                    </Typography>
                    <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                        입금내역을 수동으로 매칭할 학생을 선택해주세요.
                    </Typography>
                    <Typography id="basic-modal-dialog-description" textColor="text.tertiary" sx={{ marginBottom: "12px" }}>
                        매칭시킬 입금내역 : {selectedName} ({selectedAmount})
                    </Typography>
                    <form
                        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            setOpen(false);
                            setDisabled3(true); 
                            setDisabled5(true);
                            setSelectedUser(null);
                        }}
                    >
                        <Stack spacing={3}>
                            <FormControl>
                                <Autocomplete
                                    onChange={onchange}
                                    value={selectedUser}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    sx={{ width: "350px" }}
                                    renderInput={(params) => <TextField {...params} placeholder="학생을 선택하세요" />}
                                    
                                />
                            </FormControl>
                            <Button type="submit" onClick={handleSubmit} disabled={disabled3}>매칭시키기</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>


        </div>
    );

}

export default TotalSendedMoney;