import React, { useState, useEffect } from 'react';
import NewTodo from './components/check';
import styles from "./styles/newtodo.module.css";
import { TodoistApi } from "@doist/todoist-api-typescript";
import { Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import TodoTable from './components/table';
import { getFetch } from '../../../../fetch/fetch';
import { reorderTaskBySyncApi, updateTaskBySyncApi } from './components/function';
import RegularTodos from './components/regulartodos';
import ManagePhoneMain from '../managephone/managephonemain';
import RegularKakao from './components/regularkakao';


const NewTodoMain: React.FC<any> = (props: any) => {

    const [projects, setProjects] = useState<any>([]);
    const [menu, setMenu] = useState<any>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [update, setUpdate] = useState<number>(0);

    useEffect(() => {

        props.activateMenuList("circle-check");

    }, []);

    useEffect(() => {

        if(!props.user) return;

        if(!props.user.todoistApiKey) return;

        getTodoistData();

    }, [props.user, update]);

    const getTodoistData = async () => {

        try{

            const result : any = await getFetch("/dashboard/todoist/todoistData");

            console.log(result.data);

            console.log(result.data);

            setProjects([...result.data]);

        }catch(e){
            console.log(e);
        }

    }

    const letsUpdate = () => {
        setUpdate(Math.random());
    }

    const moveTaskToTop = async (taskId: number) => {

        console.log("moveTaskToTop");
        console.log(taskId);

        const newProjects = [...projects];

        console.log(newProjects);

        const items : any = [];

        newProjects.forEach((project: any) => {

            project.sections.forEach((section: any) => {

                const task = section.tasks.find((task: any) => task.id === taskId);

                if(!task) return;

                const currentIndex = section.tasks.indexOf(task);

                if(currentIndex === 0) return;

                section.tasks.splice(currentIndex, 1);
                section.tasks.unshift(task);

                console.log(section.tasks);



                section.tasks.forEach((task : any, index : number) => {

                    const child_order = index + 1;

                    const oneRow : any = {};

                    oneRow.id = task.v2_id;
                    oneRow.child_order = child_order;

                    items.push(oneRow);

                })

                console.log(items);

            });

        });

        console.log(newProjects);

        setProjects([...newProjects]);

        try{

            if(!props.user) return;
            if(!props.user.todoistApiKey) return;

            await reorderTaskBySyncApi("item_reorder", items, props.user.todoistApiKey);

        }catch(e){
            console.log(e);
        }

    }

    const addNoteToTask = async (projectV2Id : string, sectionV2Id : string | undefined, taskV2Id : string, note : any) => {

        console.log("addNoteToTask");
        console.log(projectV2Id);
        console.log(sectionV2Id);
        console.log(taskV2Id);
        console.log(note);

        const NewProjects = [...projects];

        NewProjects.forEach((project: any) => {
            if(project.v2_id !== projectV2Id) return;
            console.log(project);

            project.sections.forEach((section: any) => {

                if(sectionV2Id && section.v2_id !== sectionV2Id) return;

                console.log(section);

                section.tasks.forEach((task: any) => {

                    console.log(task);

                    if(task.v2_id === taskV2Id){
                        console.log("selected");
                        console.log(task);
                        if(task.notes){
                            task.notes.push(note);
                            return;
                        }
                    }

                    if(!task.subTasks) return;

                    task.subTasks.forEach((subTask: any) => {
                        if(subTask.v2_id === taskV2Id){

                            console.log("selected");
                            console.log(subTask);

                            if(subTask.notes){
                                subTask.notes.push(note);
                                return;
                            }
                        }

                        if(!subTask.subTasks) return;

                        subTask.subTasks.forEach((subSubTask: any) => {
                            if(subSubTask.v2_id === taskV2Id){

                                console.log("selected");
                                console.log(subSubTask);

                                if(subSubTask.notes){
                                    subSubTask.notes.push(note);
                                    return;
                                }
                            }
                        })
                    })
                
                })

            })

        })

        setProjects([...NewProjects]);

    }

    

    return (
        <div className={styles.main}>

            <Tabs aria-label="Basic tabs" defaultValue={0} sx={{
                width: "1750px",
                backgroundColor: "transparent"
            }}
                onChange={(e: any, value: any) =>
                    setMenu(value)
                }
            >
                <TabList
                >
                    <Tab>TODO 테이블</Tab>
                    <Tab>핸드폰 관리</Tab>
                    <Tab>반복 TODO 등록</Tab>
                    <Tab>반복 카톡 메시지 등록</Tab>
                    <Tab>페이지 보기</Tab>
                </TabList>
            </Tabs>

            {
                menu === 2 ?
                <RegularTodos
                projects={projects}
                apiKey={props.user ? props.user.todoistApiKey : ""}
                />
                :
                ""
            }

            {
                menu === 1 ?
                <ManagePhoneMain
                forAdmin={true}
                user={props.user}
                socket={props.socket}
                />
                :
                ""
            }

            {
                menu === 3 ?
                <RegularKakao 
                user={props.user}
                socket={props.socket}
                />
                :
                ""
            }
            
            {
                menu === 4 ?
                <NewTodo projects={projects} menu={menu} />
                :
                ""
            }

            {
                menu === 0 ?
                <TodoTable projects={projects}
                loading={loading}
                apiKey={props.user ? props.user.todoistApiKey : ""}
                letsUpdate={letsUpdate}
                moveTaskToTop={moveTaskToTop}
                userName={props.user ? props.user.name : ""}
                addNoteToTask={addNoteToTask}
                />
                :
                ""
            }

        </div>
    )
}

export default NewTodoMain;