import React, { useEffect, useState } from "react";
import styles from "./styles/regularmeal.module.css";

import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import koLocale from 'date-fns/locale/ko'
import TextField from "@mui/material/TextField";
import RegularMealTable from "./component/regularmealtable";
import { getFetch, postFetch } from "../../../../fetch/fetch";
import { koreanLocationToEnglish } from "../../../../data/locationFuntion";
import { DataGridPremium, GridFilterModel, GridToolbar } from "@mui/x-data-grid-premium";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import totalLocationData from "../../../../data/locationdata";
import CustomInput from "../etc/custominput";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import Autocomplete from "@mui/material/Autocomplete";
import RadioGroup from "@mui/joy/RadioGroup";
import Radio from "@mui/joy/Radio";
import { set } from "lodash";
import { notify } from "../etc/etcfunctions";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { tableUseStyle } from "../etc/tableusestyle";

const howManyDays = 1;

const RegularMealCalendar: React.FC<any> = (props) => {

    const [columns, setColumns] = useState<any[]>([
        { field: "location", headerName: "위치", width: 80 },
        { field: "whenType", headerName: "시간", width: 80 },
        { field: "date", headerName: "배송 날짜", width: 80 },
        { field: "name", headerName: "이름", width: 80 },
        { field: "createdAt", headerName: "주문 시각", width: 150 },
        { field: "teacherName", headerName: "수동 추가 책임자", width: 120 },
        { field: "teacherName2", headerName: "수동 취소 책임자", width: 120 }
    ]);
    const classes = tableUseStyle();
    const [dateValue, setDateValue] = useState<Date>(new Date());
    const [rows1, setRows1] = useState<any[]>([]);
    const [rows2, setRows2] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [sendLoading, setSendLoading] = useState<boolean>(false);
    const [alignment2, setAlignment2] = useState("total");
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            { id: 2, field: 'whenType', operator: 'contains', value: "" },
            { id: 3, field: 'location', operator: 'contains', value: "" },
            { id: 4, field: 'name', operator: 'contains', value: "" },
        ],
    });
    const [alignment, setAliment] = useState("total");
    const [name, setName] = useState("");
    const [selectedName, setSelectedName] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [selectedId, setSelectedId] = useState<number>(0);

    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any>(null);

    const [users, setUsers] = useState<any>([]);
    const [selectedTime, setSelectedTime] = useState("");
    const [finalDisabled, setFinalDisabled] = useState(true);
    const [selectedTeacher2, setSelectedTeacher2] = useState("");
    const [disabledForCancel, setDisabledForCancel] = useState(true);
    const [totalNumber, setTotalNumber] = useState(0);

    const [update, setUpdate] = useState(0);

    useEffect(() => {

        var newDisabled = true;

        if (!selectedTeacher2 && selectedId) {
            newDisabled = false;
        }

        setDisabledForCancel(newDisabled);

    }, [selectedTeacher2, selectedId, update])


    useEffect(() => {

        start(dateValue);

    }, [dateValue, update]);

    useEffect(() => {
        getUsers();
    }, [update]);

    useEffect(() => {

        if (!selectedUser) {
            setFinalDisabled(true);
            return;
        }

        if (!selectedTime) {
            setFinalDisabled(true);
            return;
        }

        setFinalDisabled(false);

    }, [selectedUser, selectedTime]);

    const getUsers = async () => {

        fetch("https://peetsunbae.com/dashboard/restaurant/users3", {
            method: "GET",
            credentials: "include",
        }).then((response: any) => {
            response.json()
                .then((result: any) => {

                    const rows: any = [];

                    if (!result.data) {
                        return;
                    }

                    result.data.forEach((each: any, index: number) => {
                        var data: any = {};
                        data.id = each.id;
                        data.label = `${each.name}${each.location ? " (" + each.location + ")" : ""} - ${each.amount ? each.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}개`;
                        data.phoneNumber = each.phoneNumber;
                        data.amount = each.amount;
                        data.value = each.value;
                        data.location = each.location;
                        data.key = index;
                        rows.push(data);
                    })
                    setUsers([...rows]);
                })
        })

    }

    const start = async (date: Date) => {

        const data = await getData();

        dataToRows1(data, date);
        dataToRows2(data, date);

    }

    const dataToRows2 = (data: any, date: Date) => {

        const newRows: any = [];

        var count = 0;

        data.forEach((element: any) => {

            const oneRow: any = {};
            oneRow.id = element.id;
            oneRow.whenType = element.whenType === "lunch" ? "점심" : element.whenType === "dinner" ? "저녁" : "";

            const date = new Date(element.targetDate);
            const dateString = `${date.getMonth() + 1}/${date.getDate()}(${["일", "월", "화", "수", "목", "금", "토"][date.getDay()]})`;

            oneRow.date = dateString;
            oneRow.name = element.name;
            oneRow.userId = element.userId;
            oneRow.location = element.location;
            oneRow.teacherName = element.teacherName;
            oneRow.teacherName2 = element.teacherName2;

            const createdAt = new Date(element.dateInserted);
            const createdAtString = `${createdAt.getMonth() + 1}/${createdAt.getDate()}(${["일", "월", "화", "수", "목", "금", "토"][createdAt.getDay()]}) ${createdAt.getHours()}:${createdAt.getMinutes()}`;

            oneRow.createdAt = createdAtString;

            if(!oneRow.teacherName2){
                count++;
            }

            newRows.push(oneRow);

        });

        //점심 먼저 나오고 저녁 나중에 나오게
        newRows.sort((a: any, b: any) => {
            if (a.whenType === "점심" && b.whenType === "저녁") {
                return -1;
            }
            if (a.whenType === "저녁" && b.whenType === "점심") {
                return 1;
            }
            return 0;
        });

        console.log("newRows");
        console.log(newRows);

        setRows2([...newRows]);
        setTotalNumber(count);

    }

    const dataToRows1 = (data: any, date: Date) => {

        const newDateArray = [];
        const startDate = date;

        for (var i = 0; i < howManyDays * 2; i++) {
            const startDateString = startDate.getFullYear() * 10000 + (startDate.getMonth() + 1) * 100 + startDate.getDate();
            const whenType = i % 2 === 0 ? "lunch" : "dinner";
            const oneRow: any = {};
            oneRow.whenType = whenType;
            oneRow.id = startDateString;
            newDateArray.push(oneRow);
        }

        console.log(newDateArray);

        const rows: any[] = [];

        newDateArray.forEach((date: any) => {

            const oneRow: any = {};
            oneRow.id = date.id;
            oneRow.whenType = date.whenType;
            oneRow.date = dateNumberToString(date.id, date.whenType);

            data.forEach((element: any) => {

                if (element.isManualSubtract) {
                    return;
                }

                const englishLocation = koreanLocationToEnglish(element.location);

                if (!englishLocation) {
                    console.log("noLocation");
                    return;
                }

                if (!oneRow[englishLocation]) {
                    oneRow[englishLocation] = [];
                }

                if (element.targetDateString === date.id && element.whenType === date.whenType) {
                    oneRow[englishLocation].push(element);
                }
            })

            rows.push(oneRow);
        });

        setRows1(rows);

    }

    const dateNumberToString = (dateNumber: number, whenType: string) => {

        //20210701 -> 7/1 (월요일)

        const year = Math.floor(dateNumber / 10000);
        const month = Math.floor((dateNumber % 10000) / 100);
        const date = dateNumber % 100;

        const day = new Date(year, month - 1, date).getDay();
        const dayString = ["일", "월", "화", "수", "목", "금", "토"][day];

        return `${month}/${date} (${dayString}) - ${whenType === "lunch" ? "점심" : "저녁"}`;
    }

    const getData = async () => {

        try {

            setLoading(true);

            const result: any = await getFetch(`/dashboard/restaurant/regularmeal/getspecificdata?targetDate=${dateValue.getFullYear()}-${dateValue.getMonth() + 1}-${dateValue.getDate()}`);

            setLoading(false);

            if (result.message === "success") {

                const data = result.data;

                data.forEach((element: any) => {
                    const targetDate = new Date(element.targetDate);
                    const targetDateString = targetDate.getFullYear() * 10000 + (targetDate.getMonth() + 1) * 100 + targetDate.getDate();

                    element.targetDateString = targetDateString;
                })

                return data;

            }



        } catch (e) {
            console.warn(e);
            setLoading(false);
        }

    }

    const handleAlignmentChange2 = (e: any, value: string) => {

        if (!value) {
            return;
        }




        setAlignment2(value);

        const newFilterModel = filterModel;

        totalLocationData.forEach((each) => {
            if (each.english === value) {
                newFilterModel.items.forEach((each2: any) => {
                    if (each2.id === 3) {
                        each2.value = each.filterValue_contains;
                    }
                })
            }
        })

        setFilterModel({ ...newFilterModel });

    }

    const handleAlignmentChange = (e: any, newAlignment: string) => {

        if (!newAlignment) {
            return;
        }

        setAliment(newAlignment);
    }

    useEffect(() => {

        const newFilterModel = filterModel;

        if (alignment === "total") {

            newFilterModel.items.forEach((each: any) => {
                if (each.id === 2) {
                    each.value = "";
                }
            })

        }

        if (alignment === "lunch") {

            newFilterModel.items.forEach((each: any) => {
                if (each.id === 2) {
                    each.value = "점심";
                }
            })
        }

        if (alignment === "dinner") {

            newFilterModel.items.forEach((each: any) => {
                if (each.id === 2) {
                    each.value = "저녁";
                }
            })
        }

        setFilterModel({ ...newFilterModel });


    }, [alignment]);

    const nameChange = (e: any) => {
        setName(e.target.value);


        const newFilterModel: any = filterModel;
        newFilterModel.items.forEach((each: any) => {
            if (each.id === 4) {
                each.value = e.target.value;
            }
        })
        setFilterModel({ ...newFilterModel });
    }

    const handleOpen = () => {

        setSelectedUser(null);
        setSelectedTime("");

        setOpen(true);
    }

    const forceCancel = async () => {

        try {

            if (!selectedId) {
                return;
            }

            if (!window.confirm(`${selectedName} 학생의 정기식을 정말로 취소하시겠습니까?`)) {
                return;
            }

            setSendLoading(true);

            const data = {
                id: selectedId
            }

            const result: any = await postFetch("/dashboard/restaurant/regularmeal/forcecancel", data);

            if (result.message === "success") {
                setUpdate(Math.random());
                notify("성공적으로 취소되었습니다.", "success");
            }

            if (result.message === "TIME_OUT") {
                notify("수동 취소 가능 시간이 지났습니다.", "error");
            }

            if (result.message === "ALREADY_CANCELED") {
                notify("이미 취소된 주문입니다.", "error");
            }

            setSendLoading(false);


        } catch (e) {
            console.warn(e);
        }

    }

    const handleClose = () => {

        setSelectedUser(null);
        setSelectedTime("");

        setOpen(false);
    }

    const manualOrder = async () => {

        try {

            if (!selectedUser) {
                return;
            }

            if (!selectedTime) {
                return;
            }

            setSendLoading(true);

            const data = {
                userId: selectedUser.id,
                whenType: selectedTime,
                targetDate: `${dateValue.getFullYear()}-${dateValue.getMonth() + 1}-${dateValue.getDate()}`
            }

            const result: any = await postFetch("/dashboard/restaurant/regularmeal/manualorder", data);

            console.log(result);

            if (result.message === "success") {

                setUpdate(Math.random());
                handleClose();
                notify("성공적으로 추가되었습니다.", "success");

            }

            if (result.message === "LACK_BALANCE") {

                notify("식권이 부족합니다.", "error");

            }

            if (result.message === "TIME_OUT") {

                notify("수동 추가 가능 시간이 지났습니다.", "error");

            }

        } catch (e) {
            console.warn(e);
        }

        setSendLoading(false);
    }

    const onchange = async (e: any, value: any) => {

        if (!users) {
            return;
        }

        console.log(value);

        setSelectedUser(value);
    }

    return (
        <div>
            <div className={styles.calendarPaper}>

                <div className={styles.calendarDiv}>
                    <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                        <StaticDatePicker
                            displayStaticWrapperAs="desktop"
                            openTo="day"
                            value={dateValue}
                            // disablePast
                            //minDate={minDate}
                            onChange={(newValue: any) => {
                                setDateValue(newValue);
                            }}
                            renderInput={(params: any) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>

                <div className={styles.calendarDiv2} style={{
                    paddingLeft: "60px"
                }}>
                    {
                        (props.user && props.user.academy === "sunbae") &&
                        <>
                            <div>
                                <RegularMealTable
                                    rows1={rows1}
                                    loading={loading}
                                />
                            </div>
                            <div style={{ height: "60px" }}>

                            </div>
                        </>
                    }

                    <div>
                        <div>
                            <div>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={alignment2}
                                    exclusive
                                    onChange={handleAlignmentChange2}
                                >
                                    {
                                        props.user && totalLocationData.filter(locationData => locationData.academy.includes(props.user.academy)).map((each, index: number) => (
                                            <ToggleButton value={each.english} key={index}>{each.korean}</ToggleButton>
                                        ))
                                    }
                                </ToggleButtonGroup>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: "18px", justifyContent: "space-between" }}>
                        <div>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleAlignmentChange}
                            >
                                <ToggleButton value="total">전체</ToggleButton>
                                <ToggleButton value="lunch">점심</ToggleButton>
                                <ToggleButton value="dinner">저녁</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div>
                            {/* <ReactToPrint
                                onAfterPrint={() => {

                                }}
                                trigger={() => {
                                    return (
                                        <Button size="lg" variant='outlined' color='neutral'
                                            disabled={disable}
                                        >
                                            <Print />
                                        </Button>
                                    )
                                }
                                }
                                content={() => componentRef.current}
                            /> */}
                        </div>
                    </div>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "24px",
                                width: "755px",
                                marginBottom: "24px"
                            }}
                        >
                            <div>
                                <CustomInput value={name} onChange={nameChange} placeholder="이름을 검색하세요" />
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <div style={{
                                    marginRight: "12px"
                                }}>
                                    {selectedName}
                                </div>
                                <div>
                                    <Button size="sm" sx={{ marginRight: "8px" }} onClick={() => { handleOpen(); }}>수동 추가하기</Button>
                                    <Button onClick={forceCancel} disabled={disabledForCancel} size="sm">취소시키기</Button>
                                </div>
                            </div>
                        </div>
                        <div className={classes.root} style={{ height: 650, width: '755px', backgroundColor: "white" }}>
                            <DataGridPremium
                                rows={rows2}
                                columns={columns}
                                density="compact"
                                filterModel={filterModel}
                                components={{ Toolbar: GridToolbar }}
                                onFilterModelChange={(model) => setFilterModel(model)}
                                onRowClick={(e) => {
                                    setSelectedId(e.row.id);
                                    setSelectedName(e.row.name);
                                    setSelectedTeacher2(e.row.teacherName2);
                                }}
                                getCellClassName={(params: any) => {
                                    if (params.field === "teacherName") {
                                        return "bluetext";
                                    }
                                    if (params.field === "teacherName2") {
                                        return "bluetext";
                                    }
                                    if (params.row.teacherName2) {
                                        return "deleted";
                                    }
                                    return "";
                                }}
                                loading={loading}
                            />
                        </div>
                    </div>
                    <div style={{
                        width : "755px",
                        marginTop : "24px",
                        display : "flex",
                        justifyContent : "flex-end",
                        fontFamily : "Apple_SB",
                        fontSize : "18px"
                    }}>
                        총 신청자 수 : &nbsp;<span>{totalNumber}</span>명
                    </div>
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 4,
                        boxShadow: 'lg',
                    }}
                >
                    <div style={{
                        fontFamily: "Apple_SB",
                        fontSize: "18px",
                        marginBottom: "18px"
                    }}>
                        도시락 수동 추가하기 - {dateValue.getFullYear()}/{dateValue.getMonth() + 1}/{dateValue.getDate()} ({["일", "월", "화", "수", "목", "금", "토"][dateValue.getDay()]})
                    </div>

                    <div
                        style={{
                            marginBottom: "16px",
                        }}
                    >
                        <Autocomplete
                            onChange={onchange}
                            value={selectedUser}
                            disablePortal
                            id="combo-box-demo"
                            options={users}
                            sx={{ width: "350px" }}
                            renderInput={(params) => <TextField {...params} placeholder="학생을 선택하세요" />}
                        />
                    </div>

                    <div>
                        <div
                            style={{
                                fontFamily: "Apple_SB",
                                marginBottom: "9px",
                                marginTop: "24px"
                            }}
                        >
                            시간 선택
                        </div>
                        <RadioGroup defaultValue="outlined" name="radio-buttons-group" orientation='horizontal'
                            onChange={(e: any) => {
                                setSelectedTime(e.target.value);
                            }}
                        >
                            <Radio value="lunch" label="점심" variant="soft" />
                            <Radio value="dinner" label="저녁" variant="soft" />
                        </RadioGroup>
                    </div>

                    <Button fullWidth
                        disabled={finalDisabled}
                        sx={{
                            marginTop: "32px"
                        }}
                        onClick={() => {
                            manualOrder();
                        }}
                    >
                        수동 추가하기
                    </Button>

                </Sheet>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={sendLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default RegularMealCalendar;