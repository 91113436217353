import { InputAdornment, MenuItem, SwipeableDrawer, TextField, ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { tableUseStyle } from '../../etc/tableusestyle';
import StyledDataGrid from '../styles/tablestyles';
import { DataGridPremium, GRID_ROOT_GROUP_ID, GridEventListener, GridFilterModel, GridGroupingColDefOverride, GridGroupNode, GridRowModel, useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Add, CalendarTodayTwoTone, CheckCircle, CloseFullscreen, Fullscreen, KeyboardDoubleArrowUpSharp, SubdirectoryArrowRight, SwapVertSharp, Sync } from '@mui/icons-material';
import styles from "../styles/table.module.css";
import { Button, Dropdown, IconButton, Input, Menu, MenuButton, Modal, Option, Radio, RadioGroup, Sheet, Select, ModalDialog, Textarea } from '@mui/joy';
import { render } from 'react-dom';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import koLocale from 'date-fns/locale/ko'
import e from 'express';
import { is } from 'date-fns/locale';
import { addZeroIfOne } from '../../etc/etcfunctions';
import { updateTaskBySyncApi } from './function';
import { ReactComponent as Comment } from "../../../../../svg/comment-light.svg";
import { set } from 'lodash';
import ChatForTodos from './chatforTodos';
import Todos from './todos';
import NewTodoModal from './newtodomodal';
import { getFetch, postFetch } from '../../../../../fetch/fetch';

const isGroupExpandedByDefault = (node: GridGroupNode) => {
    return (
        node.groupingField === "projectName"
        ||
        node.groupingField === "sectionName"
    )
}



const TodoTable: React.FC<any> = (props: any) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [alignment, setAlignment] = useState<string | null>("total");
    const [alignment2, setAlignment2] = useState<"uncompleted" | "total" | "completed">("uncompleted");
    const [selectedContent, setSelectedContent] = useState<any>("");
    const [selectedProjectId, setSelectedProjectId] = useState<any>(null);
    const [selectedSectionId, setSelectedSectionId] = useState<any>(null);
    const [selectedTaskId, setSelectedTaskId] = useState<any>(null);
    const [selectedTaskOriginalId, setSelectedTaskOriginalId] = useState<any>(null);
    const [radioSelectedSectionId, setRadioSelectedSectionId] = useState<any>(null);
    const [selectedNotes, setSelectedNotes] = useState<any>([]);
    const [selectedTaskIdV2, setSelectedTaskIdV2] = useState<any>(null);

    const [selectedProjectIdV2, setSelectedProjectIdV2] = useState<any>(null);
    const [selectedSectionIdV2, setSelectedSectionIdV2] = useState<any>(null);

    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState("");

    const [loading, setLoading] = useState(false);

    const [dropDownOpenRow, setDropDownOpenRow] = useState<any>(null);
    const [columns, setColumns] = useState<any>([
        { field: "projectName", headerName: "프로젝트", width: 150, filterable: true },
        { field: "status", headerName: "상태", width: 100, filterable: true, type: "number" },
        { field: "content", headerName: "내용", width: 980, editable: true, filterable: true },
    ]);
    const [rows, setRows] = useState<any>([]);
    const apiRef = useGridApiRef();
    const classes = tableUseStyle();
    const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                id: 1,
                field: "projectName",
                operator: "contains",
                value: ""
            },
            {
                id: 2,
                field: "status",
                operator: "<",
                value: 2
            },
            {
                id: 3,
                field: "content",
                operator: "contains",
                value: ""
            }
        ],
    });
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const isNarrow = useMediaQuery('(max-width: 1200px)');

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    }


    useEffect(() => {

        console.log("apiKey");
        console.log(props.apiKey);

        if (!props.apiKey) return;

        console.log("lets make columns");

        const columnData = [
            { field: "projectName", headerName: "프로젝트", width: 150, filterable: true },
            { field: "sectionName", headerName: "섹션", width: 100 },
            {
                field: "content", headerName: "내용", width: (isFullScreen && isNarrow) ? 550 : 980
                , editable: true, filterable: true, renderCell: (params: any) => {

                    var isGroupRow = false;

                    if (params && params.id) {
                        isGroupRow = params.id.includes("auto");
                    }

                    var value = params.value;

                    //만약에 앞에 "* "가 붙어있으면 "* " 제거
                    if (value && value.startsWith("* ")) {
                        value = value.substring(2);
                    }

                    return (
                        <div className={styles.eachContent} style={{ paddingLeft: params.row.isSubTask ? "24px" : params.row.isSubSubTask ? "48px" : "0px", display: "flex", alignItems: "center" }}>
                            <div className={styles.contentDescription}>
                                {(params.row.isSubTask || params.row.isSubSubTask) ? <SubdirectoryArrowRight sx={{ fontSize: "16px" }} /> : ""}
                                {value}
                            </div>
                            {
                                (!isGroupRow && !params.row.isSubTask)
                                    ?
                                    <div className={`${styles.hoverIcon}`}>
                                        <div
                                            onClick={() => {
                                                props.moveTaskToTop(params.row.id)
                                            }}
                                        >
                                            <KeyboardDoubleArrowUpSharp style={{ color: "#6d6e6f" }} />
                                        </div>
                                        <div
                                            onClick={() => {
                                                setSelectedProjectId(params.row.projectId);
                                                setSelectedContent(params.row.content);
                                                setSelectedSectionId(params.row.sectionId);
                                                setSelectedTaskId(params.row.v2_id);
                                                setSelectedTaskOriginalId(params.row.id);
                                                setSelectedProjectIdV2(params.row.v2_project_id);
                                                console.log(params.row.v2_section_id);
                                                setSelectedSectionIdV2(params.row.v2_section_id);
                                                handleOpenModal();

                                            }}
                                        >
                                            <SwapVertSharp style={{ color: "#6d6e6f" }} />
                                        </div>
                                    </div>

                                    :
                                    null
                            }

                        </div>
                    )
                }
            },
            {
                field: "due", headerName: "기한", width: 70,
                renderCell: (params: any) => {

                    // const makeDataFormat = (date : Date) => {
                    //     //{date: '2026-02-14', string: '19시00분 2026년2월14일', lang: 'ko', is_recurring: false, datetime: '2026-02-14T19:00:00'}
                    //     const result = {
                    //         due_date : date.toISOString().split("T")[0],
                    //         due_string : `${date.getHours()}시 ${date.getMinutes()}분 ${date.getFullYear()}년${date.getMonth() + 1}월${date.getDate()}일`,
                    //         due_datetime : date.toISOString()
                    //     }

                    //     console.log(result);

                    //     return result;
                    // }

                    var isJustText = false;

                    const value = params.row.content;

                    if (value && value.startsWith("* ")) {
                        isJustText = true;
                    }

                    if (isJustText) {
                        return (
                            <div></div>
                        )
                    }

                    const makeDataFormat = (dateVariable: Date) => {

                        // 2025년 2월 13일 23시 17분(초 0)으로 Date 객체 생성
                        const date = new Date(dateVariable);

                        // 연, 월(01~12), 일(01~31), 시(00~23), 분(00~59)을 포맷팅
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');

                        // "YYYY-MM-DDTHH:MM:SS" 형태로 만들기
                        const dateString = `${year}-${month}-${day}T${hours}:${minutes}:00`;

                        // 원하는 객체 형태 구성
                        const due = {
                            date: dateString,          // "2025-02-13T23:17:00"
                            is_recurring: params.value?.is_recurring || false,
                            lang: "ko",
                            // string: "23시17분 2월13일", // 사용자 지정 표현,
                            string: `${hours}시${minutes}분 ${month}월${day}일`,
                            timezone: null
                        };

                        console.log(due);

                        return due;
                    }

                    var isGroupRow = false;

                    if (params && params.id) {
                        isGroupRow = params.id.includes("auto");
                    }

                    if (isGroupRow) return null;


                    if (!params.value) {
                        return (
                            // <div style={{ display: "flex", alignItems: "center"}}>
                            //     <CalendarTodayTwoTone style={{ cursor: "pointer" }} fontSize="small" color="disabled" />
                            // </div>
                            <div>
                                <input type="date" style={{
                                    width: "24px",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "transparent",
                                    fontSize: "24px",
                                }}
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        apiRef.current.updateRows([{ id: params.id, due: { date: e.target.value } }]);
                                        const data = makeDataFormat(new Date(e.target.value));
                                        // updateTask(params.v2_id, data);
                                        updateTaskBySyncApi('item_update', params.row.v2_id, { due: data }, props.apiKey);
                                    }}
                                />
                            </div>
                        )
                    } else {

                        var isToday = false;

                        const today = new Date();
                        const date = new Date(params.value.date);

                        if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate()) {
                            isToday = true;
                        }

                        const dateString = isToday ? "오늘" : `${date.getMonth() + 1}월 ${date.getDate()}일`;

                        return (
                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <span style={{
                                    width: "60px"
                                }}>
                                    {dateString}
                                </span>
                                <input type="date" style={{
                                    width: "24px",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "transparent",
                                    fontSize: "24px",
                                    paddingTop: "4px"
                                }}
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        apiRef.current.updateRows([{ id: params.id, due: { date: e.target.value } }]);
                                        const data = makeDataFormat(new Date(e.target.value));
                                        //updateTask(params.id, data);
                                        updateTaskBySyncApi('item_update', params.row.v2_id, { due: data }, props.apiKey);
                                    }}
                                />

                            </div>
                        )
                    }
                }
            },
            {
                field: "status", headerName: "상태", width: 100, type: "number", filterable: true, renderCell: (params: any) => {

                    var isGroupRow = false;

                    if (params && params.id) {
                        isGroupRow = params.id.includes("auto");
                    }

                    if (isGroupRow) return null;

                    const content = params.row.content;

                    //content앞에 "* "가 붙어있으면 그냥 return
                    if (content && content.startsWith("* ")) {
                        return null;
                    }

                    var isForCleaning = false;

                    if (params.row.isForCleaning) {
                        isForCleaning = true;
                    }

                    const makeDataFormat = (dateVariable: Date) => {

                        // 2025년 2월 13일 23시 17분(초 0)으로 Date 객체 생성
                        const date = new Date(dateVariable);

                        // 연, 월(01~12), 일(01~31), 시(00~23), 분(00~59)을 포맷팅
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');

                        // "YYYY-MM-DDTHH:MM:SS" 형태로 만들기
                        const dateString = `${year}-${month}-${day}T${hours}:${minutes}:00`;

                        // 원하는 객체 형태 구성
                        const due = {
                            date: dateString,          // "2025-02-13T23:17:00"
                            is_recurring: params.row.due?.is_recurring || false,
                            lang: "ko",
                            // string: "23시17분 2월13일", // 사용자 지정 표현,
                            string: `${hours}시${minutes}분 ${month}월${day}일`,
                            timezone: null
                        };

                        console.log(due);

                        return due;
                    }


                    return (
                        <div style={{
                            width: "100%",
                        }}>
                            <Select defaultValue={`${params.value}`} onChange={(e: any, newValue: any) => {
                                console.log(newValue);

                                if (isForCleaning) {

                                    //newValue가 0이면 7일 전으로 바꾸고 2면 7일 후로 바꾸기

                                    const value = params.row.due;

                                    if (!value || !value.date) return;

                                    const date = new Date();

                                    //시간은 8시로 고정
                                    date.setHours(8);

                                    console.log(newValue);

                                    const dayOfWeek = date.getDay();
                                    let daysToAdd = 0;

                                    if (newValue === "0") {
                                        // 다음 돌아오는 월요일
                                        daysToAdd = (1 - dayOfWeek + 7) % 7;
                                        // 이미 월요일인 경우 당일이 아닌 다음 주 월요일로 맞추기
                                        if (daysToAdd === 0) {
                                            daysToAdd = 7;
                                        }
                                    } else if (newValue === "2") {
                                        // 다다음 돌아오는 월요일
                                        // 먼저 '다음 월요일'까지 이동한 뒤, 7일 더 이동
                                        let nextMonday = (1 - dayOfWeek + 7) % 7;
                                        if (nextMonday === 0) {
                                            nextMonday = 7;
                                        }
                                        daysToAdd = nextMonday + 7;
                                    }

                                    date.setDate(date.getDate() + daysToAdd);

                                    const data = makeDataFormat(date);

                                    //updateTask(params.row.id, data);
                                    updateTaskBySyncApi('item_update', params.row.v2_id, { due: data }, props.apiKey);
                                    apiRef.current.updateRows([{ id: params.id, due: data }]);
                                    updateTaskStatusFrontEnd(params.id, +newValue);

                                    return;
                                }

                                // apiRef.current.updateRows([{ id: params.id, status : +newValue }]);
                                updateTaskStatus(params.row.v2_id, +newValue, params.row.labels, params.row.completed_at);
                                updateTaskStatusFrontEnd(params.id, +newValue);
                            }}
                                size='sm'
                                //"danger", "neutral", "primary", "success", "warning"
                                color={params.value === 0 ? "danger" : params.value === 1 ? 'warning' : params.value === 2 ? "primary" : "danger"}
                                variant='soft'
                            >
                                <Option value="0"
                                    color='danger'
                                >
                                    {
                                        isForCleaning
                                            ?
                                            "미완료"
                                            :
                                            "미확인"
                                    }
                                </Option>
                                {
                                    isForCleaning
                                        ?
                                        ""
                                        :
                                        <Option value="1"
                                            color='warning'
                                        >읽음</Option>
                                }
                                <Option value="2"
                                    color="primary"
                                >완료</Option>
                            </Select>
                        </div>
                    )
                }
            },
            {
                field: "initiator", headerName: "처리한 사람", width: 120
            },
            {
                field: "notes", headerName: "댓글", width: 50, renderCell: (params: any) => {

                    var isGroupRow = false;

                    if (params && params.id) {
                        isGroupRow = params.id.includes("auto");
                    }

                    if (isGroupRow) return null;

                    if (!params.value) return null;

                    const numbers = params.value.length;

                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            width: "30px",
                            justifyContent: numbers ? "space-between" : "center",
                            cursor: "pointer"
                        }}
                            onClick={() => {
                                setSelectedNotes(params.value || []);
                                setSelectedTaskIdV2(params.row.v2_id);
                                setSelectedContent(params.row.content);
                                console.log(params.row.v2_project_id);
                                setSelectedProjectIdV2(params.row.v2_project_id);
                                console.log(params.row.v2_section_id);
                                setSelectedSectionIdV2(params.row.v2_section_id);
                                setDrawerOpen(true);
                            }}
                        >
                            {
                                numbers
                                    ?
                                    <span style={{
                                        color: "#6d6e6f"
                                    }}>
                                        {numbers}
                                    </span>
                                    :
                                    null
                            }
                            {
                                numbers
                                    ?
                                    <Comment
                                        style={{
                                            width: "16px",
                                            height: "16px",
                                        }}
                                    />
                                    :
                                    "-"
                            }

                        </div>
                    )

                }
            },
            { field: "creatorName", headerName: "작성자", width: 80 },
            {
                field: "priority", headerName: "우선순위", width: 120, renderCell: (params: any) => {

                    var isGroupRow = false;

                    if (params && params.id) {
                        isGroupRow = params.id.includes("auto");
                    }

                    if (isGroupRow) return null;

                    const returnButtonName = (value: number) => {

                        var buttonName = "";

                        switch (+params.value) {
                            case 1:
                                buttonName = "낮음";
                                break;
                            case 2:
                                buttonName = "중간";
                                break;
                            case 3:
                                buttonName = "높음";
                                break;
                            case 4:
                                buttonName = "매우높음";
                                break;
                            default:
                                buttonName = "낮음";
                                break;
                        }

                        return buttonName;
                    }

                    const buttonName = returnButtonName(params.value);


                    return (
                        <div style={{
                            width: "100%",
                        }}>
                            <Select defaultValue={`${params.value}`} onChange={(e: any, newValue: any) => {
                                console.log(newValue);
                                apiRef.current.updateRows([{ id: params.id, priority: +newValue }]);
                                //updateTask(params.id, {priority : +newValue});
                                updateTaskBySyncApi('item_update', params.row.v2_id, { priority: +newValue }, props.apiKey);
                            }}
                                size='sm'
                                //"danger", "neutral", "primary", "success", "warning"
                                color={params.value === 1 ? "neutral" : params.value === 2 ? "primary" : params.value === 3 ? "warning" : "danger"}
                                variant='soft'
                            >
                                <Option value="1"
                                    color="neutral"
                                >낮음</Option>
                                <Option value="2"
                                    color="primary"
                                >증긴</Option>
                                <Option value="3"
                                    color="warning"
                                >높음</Option>
                                <Option value="4"
                                    color="danger"
                                >매우 높음</Option>
                            </Select>
                        </div>
                    )

                }
            },
            {
                field: "added_at", headerName: "생성일", width: 80, renderCell: (params: any) => {

                    if (!params.value) return null;

                    const date = new Date(params.value);
                    const dateString = `${date.getMonth() + 1}월 ${date.getDate()}일`;

                    return (
                        <div>
                            {dateString}
                        </div>
                    )

                }
            }

        ];

        setColumns([...columnData]);


    }, [props.apiKey, props.projects, isNarrow, isFullScreen]);



    const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {

        if (!newAlignment) return;

        const newFilterModel: any = filterModel;

        newFilterModel.items.forEach((item: any) => {

            if (item.field === "projectName") {
                item.value = newAlignment;
            }

            if (newAlignment === "total" && item.field === "projectName") {
                item.value = "";
            }

        })

        setFilterModel({ ...newFilterModel });

        setAlignment(newAlignment);

    };

    const handleChange2 = (event: React.MouseEvent<HTMLElement>, newAlignment: "uncompleted" | "completed" | "total") => {

        if (!newAlignment) return;

        const newFilterModel: any = filterModel;

        newFilterModel.items.forEach((item: any) => {

            if (item.field === "status") {
                if (newAlignment === "uncompleted") {
                    item.value = 2;
                    item.operator = "<";
                }
                if (newAlignment === "completed") {
                    item.value = 2;
                    item.operator = "=";
                }
                if (newAlignment === "total") {
                    item.value = "";
                    item.operator = "";
                }
            }

        })

        setFilterModel({ ...newFilterModel });

        setAlignment2(newAlignment);

    };



    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
            rowGrouping: {
                model: [
                    "projectName",
                    "sectionName"
                ]
            }
        }
    })

    useEffect(() => {

        if (!props.projects) return;

        if (props.projects.length === 0) return;

        makeRows(props.projects);

    }, [props.projects]);

    const makeTaskToRow = (task: any, sectionId: any, projectName: any, sectionName: any, isForCleaning: Boolean) => {


        task.projectName = projectName;
        task.sectionName = sectionName;

        task.status = 0;



        if (task.labels && task.labels.includes("read")) {
            task.status = 1;
        }

        if (task.completed_at) {
            task.status = 2;
        }

        const status = task.status;

        if (status === 1 || status === 2) {

            var isToday = false;

            const dateValue = status === 1 ? task.readAt : task.completed_at;

            const today = new Date();
            const date = new Date(dateValue);

            if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate()) {
                isToday = true;
            }

            const dateStringOnlyDate = isToday ? "오늘" : `${date.getMonth() + 1}/${date.getDate()}`;

            const dateString = `(${dateStringOnlyDate} ${addZeroIfOne(date.getHours())}:${addZeroIfOne(date.getMinutes())})`;

            const name = status === 1 ? task.readInitiatorName : task.completedInitiatorName;


            task.initiator = `${name} ${dateString}`;

        }

        if (isForCleaning) {
            task.isForCleaning = true;
            if (task.due && task.due.date) {

                const date = new Date(task.due.date);
                const dateTime = date.getTime();

                const sevenDaysLater = new Date();
                sevenDaysLater.setDate(sevenDaysLater.getDate() + 7);

                const sevenDaysLaterTime = sevenDaysLater.getTime();

                //7주일 이후면 status 2로 아니면 0으로
                if (dateTime > sevenDaysLaterTime) {
                    task.status = 2;
                } else {
                    task.status = 0;
                }

            }
        }

    }

    const makeRows = (data: any) => {

        const newRows: any = [];

        data.forEach((project: any) => {

            const projectId = project.id;
            const projectName = project.name;
            const folder_id = project.folder_id;

            var isForCleaning = false;

            if (folder_id) {
                isForCleaning = true;
            }

            project.sections.forEach((section: any) => {

                const sectionName = section.name;
                const sectionId = section.id;

                section.tasks.forEach((task: any) => {

                    task.projectId = projectId;
                    task.sectionId = sectionId;

                    makeTaskToRow(task, sectionId, projectName, sectionName, isForCleaning);

                    newRows.push(task);

                    task.subTasks.forEach((subTask: any) => {

                        subTask.isSubTask = true;

                        makeTaskToRow(subTask, sectionId, projectName, sectionName, isForCleaning);

                        newRows.push(subTask);

                        subTask.subTasks.forEach((subSubTask: any) => {
                            subSubTask.isSubSubTask = true;

                            makeTaskToRow(subSubTask, sectionId, projectName, sectionName, isForCleaning);

                            newRows.push(subSubTask);

                        })

                    });

                });

            });

        })


        console.log(newRows);
        setRows([...newRows]);

    }

    const handleCloseModal = () => {
        setRadioSelectedSectionId(null);
        setModalOpen(false);
    }

    const handleOpenModal = () => {
        setModalOpen(true);
    }

    const updateATask = async (taskId: any, body: any) => {

        const apiKey = props.apiKey;

        if (!apiKey) return;

        const url = `https://api.todoist.com/rest/v2/tasks/${taskId}`;

        try {

            const result = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${apiKey}`
                },
                body: JSON.stringify(body)
            });

            const data = await result.json();

            console.log(data);

        } catch (e) {
            console.log(e);
        }

    }



    const handleEditCommit = React.useCallback(
        async (newRow: GridRowModel, old: any) => {
            var field = "";

            for (const [key, value] of Object.entries(newRow)) {
                if (value !== old[key]) {
                    field = key;
                }
            }

            const value = newRow[field] ? newRow[field] : null;
            const taskId = newRow.id;
            const taskIdV2 = newRow.v2_id;

            const data: any = {};

            data[field] = value;

            console.log(data);

            // updateTask(taskId, data);
            updateTaskBySyncApi('item_update', taskIdV2, data, props.apiKey);

            return newRow;

        }, [props.apiKey, props.letsUpdate]
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {

        alert(error.message);

    }, [props.apiKey, props.letsUpdate]);

    // const updateTask = async (taskId: any, data: any) => {

    //     fetch(`https://api.todoist.com/rest/v2/tasks/${taskId}`, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${props.apiKey}`
    //         },
    //         body: JSON.stringify(data)
    //     }).then((res) => {
    //         console.log(res);
    //     }).catch((e) => {
    //         console.log(e);
    //         props.letsUpdate();
    //     });

    // }

    const updateTaskStatus = async (taskId: any, status: any, labels: any, completed_at: any) => {

        console.log(taskId);
        console.log(status);
        console.log(labels);
        console.log(completed_at);

        if (status === 0) {

            if (labels && labels.includes("read")) {
                labels = labels.filter((label: any) => label !== "read");
                await updateTaskBySyncApi('item_update', taskId, { labels: labels }, props.apiKey);
            }

            if (completed_at) {
                await updateTaskBySyncApi('item_uncomplete', taskId, {}, props.apiKey);
            }

        }

        if (status === 1) {

            if (!labels) {
                labels = [];
            }

            if (!labels.includes("read")) {
                labels.push("read");
                await updateTaskBySyncApi('item_update', taskId, { labels: labels }, props.apiKey);
            }

            if (completed_at) {
                await updateTaskBySyncApi('item_uncomplete', taskId, {}, props.apiKey);
            }

        }

        if (status === 2) {

            if (!completed_at) {
                await updateTaskBySyncApi('item_close', taskId, {}, props.apiKey);
            }

        }

    }

    async function moveTask(taskId: any, sectionId: any, sectionName: any) {
        try {

            // props.taskSectionChanged(taskId, sectionId);
            console.log(taskId);
            const targetRow = apiRef.current.getRow(selectedTaskOriginalId);
            console.log(targetRow);
            //frontend에서 섹션 변경
            apiRef.current.updateRows([{ id: selectedTaskOriginalId, sectionId }]);
            apiRef.current.updateRows([{ id: selectedTaskOriginalId, sectionName }]);

            console.log(sectionName);


            // 브라우저 환경 지원 시
            const uuidValue = crypto.randomUUID(); // 범용 고유 식별자 생성

            const response = await fetch('https://api.todoist.com/sync/v9/sync', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${props.apiKey}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    commands: JSON.stringify([
                        {
                            type: 'item_move',
                            uuid: uuidValue,   // 위에서 생성한 uuid 값
                            args: {
                                id: taskId,      // 이동할 Task의 ID
                                section_id: sectionId // 새 부모(Parent)의 ID
                            },
                        },
                    ]),
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log('moveTask result:', data);

            const newRows = rows;



        } catch (error) {

            props.letsUpdate();
            console.error('moveTask error:', error);

        }
    }



    const updateTaskStatusFrontEnd = (taskId: any, status: any) => {

        //apiRef.current 이용해서 수정
        apiRef.current.updateRows([{ id: taskId, status }]);

    }

    const submit = () => {

    }

    const handleSearchTextChange = (e: any) => {
        setSearchText(e.target.value);

        const newFilterModel: any = { ...filterModel };

        newFilterModel.items.forEach((item: any) => {
            if (item.field === "content") {
                item.value = e.target.value;
            }
        })

        setFilterModel({ ...newFilterModel });

    }

    const letsSync = async () => {

        try {

            setLoading(true);

            const result = await postFetch("/dashboard/todoist/sync", {});

            console.log(result);

            setLoading(false);
            props.letsUpdate();

        } catch (e) {
            console.log(e);
        }

    }

    const handleBlur = (event: any) => {

        console.log(event.target.value);

        sendMessage(event.target.value, alignment);

    }

    const handleChangeMessage = (event: any) => {

        const value = event.target.value;
        setMessage(value);

        // 이전 타이머가 남아있다면 먼저 clear
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }

        // 1초 후에 sendMessage를 호출하는 타이머 설정
        debounceTimerRef.current = setTimeout(() => {
            sendMessage(value, alignment);
            // 타이머가 끝나면 debounceTimerRef.current를 null로 설정
            debounceTimerRef.current = null;
        }, 1000);

    }

    const sendMessage = async (message: string, alignment: any) => {
        try {

            console.log(alignment);
            console.log("sendMessage");
            console.log(message);

            if (!alignment) return;

            const result = await postFetch("/dashboard/todoist/cleaningmessage", {
                message: message ? message : "",
                projectName: alignment
            });

            console.log(result);

        } catch (e) {
            console.log(e);
        }
    }

    const getMessage = async (alignment: string) => {
        try {

            await setMessage("");

            const result: any = await getFetch("/dashboard/todoist/getcleaningmessage?projectName=" + alignment);
            console.log(result);

            if (result.message !== "success") {
                setMessage("");
            }

            const data = result.data.message;

            setMessage(data);

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {

        if (!alignment) return;

        if (!alignment.includes("관리")) return;

        getMessage(alignment);

    }, [props.apiKey, props.projects, alignment]);


    return (
        <div>

            <div>
                <div style={{
                    height: "24px"
                }}>

                </div>

                <Todos />

                <div style={{
                    fontFamily: "Apple_B",
                    marginTop: "50px",
                    fontSize: "18px",
                    display: "flex",
                    alignItems: "center",
                }}>
                    <div style={{
                        marginRight: "18px"
                    }}>
                        TODO
                    </div>
                    <div style={{
                        marginRight: "18px"
                    }}>
                        <Button variant="solid" startDecorator={<Add />} size="sm" onClick={(e: any) => { setOpen(true); }}>
                            추가하기
                        </Button>
                    </div>
                    <div style={{
                        marginRight: "18px"
                    }}>
                        <Button variant="solid" startDecorator={<Sync />} size="sm" onClick={(e: any) => { letsSync(); }}
                            loading={loading}
                        >
                            새로고침
                        </Button>
                    </div>
                    <div style={{
                        marginRight: "18px"
                    }}>
                        <Button variant="solid" size="sm" onClick={(e: any) => { toggleFullScreen(); }}
                            loading={loading}
                        >
                            <Fullscreen />
                        </Button>
                    </div>
                </div>

                <div style={{
                    width: "96%", minWidth: "1500px", display: "flex", justifyContent: "space-between", marginBottom: "24px", marginTop: "24px"
                }}>
                    <div>
                        <div>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                            >
                                <div>
                                    {
                                        props.projects.map((project: any, index: number) => {

                                            if (project.name && project.name === "체크리스트_원본") {
                                                return null;
                                            }

                                            if (project.folder_id) {
                                                return null;
                                            }

                                            return (
                                                <>
                                                    {
                                                        index === 0 ? <ToggleButton key={99999} value="total" style={{ borderRadius: "0px", marginBottom: "12px" }}>
                                                            전체
                                                        </ToggleButton> : null
                                                    }
                                                    <ToggleButton key={project.id} value={project.name} style={{ borderRadius: "0px", marginBottom: "12px" }}
                                                    >
                                                        {project.name}
                                                    </ToggleButton>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </ToggleButtonGroup>
                        </div>

                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                        >
                            <div>
                                {
                                    props.projects.map((project: any, index: number) => {

                                        if (project.name && project.name === "체크리스트_원본") {
                                            return null;
                                        }

                                        if (!project.folder_id) {
                                            return null;
                                        }

                                        return (
                                            <>
                                                {
                                                    index === 0 ? <ToggleButton key={99999} value="total" style={{ borderRadius: "0px", marginBottom: "12px" }}>
                                                        전체
                                                    </ToggleButton> : null
                                                }
                                                <ToggleButton key={project.id} value={project.name} style={{ borderRadius: "0px", marginBottom: "12px" }}
                                                >
                                                    {project.name}
                                                </ToggleButton>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </ToggleButtonGroup>

                        <TextField sx={{ fontSize: "24px", marginLeft: "48px" }} label="내용 검색" variant="standard" value={searchText} onChange={handleSearchTextChange} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CheckCircle />
                                </InputAdornment>
                            ),
                        }} />
                    </div>
                    <div>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment2}
                            exclusive
                            onChange={handleChange2}
                        >
                            <ToggleButton value="uncompleted" style={{ borderRadius: "0px" }}>
                                미완료
                            </ToggleButton>
                            <ToggleButton value="completed" style={{ borderRadius: "0px" }}>
                                완료
                            </ToggleButton>
                            <ToggleButton value="total" style={{ borderRadius: "0px" }}>
                                전체
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>


                <div className={classes.root} style={{
                    height: "900px", width: '96%', minWidth: "1500px",
                    display: "flex"
                }}>
                    <div
                        className={
                            isFullScreen ? styles.fullScreenContainer : ""
                        }
                        style={{
                            flex: 1,
                            backgroundColor: "white",
                        }}>
                        <StyledDataGrid
                            rows={rows}
                            columns={columns}
                            density='compact'
                            hideFooter
                            disableRowSelectionOnClick
                            apiRef={apiRef}
                            initialState={initialState}
                            isGroupExpandedByDefault={isGroupExpandedByDefault}
                            rowHeight={54}
                            filterModel={filterModel}
                            onFilterModelChange={(model) => setFilterModel(model)}
                            getCellClassName={(params) => {
                                if (params.row.isSubTask) {
                                    if (params.field === "content") {
                                        return "subTask";
                                    }
                                }
                                if (params.row.isSubSubTask) {
                                    if (params.field === "content") {
                                        return "subSubTask";
                                    }
                                }
                                if (params.field === "due") {
                                    if (params.value && params.value.date) {
                                        const today = new Date();
                                        const date = new Date(params.value.date);
                                        if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate()) {
                                            return "today";
                                        }
                                    }
                                }
                                if (params.field === "initiator") {
                                    //오늘 포함되어 있는것
                                    if (params.value && params.value.includes("오늘") && params.row.status === 2) {
                                        return "";
                                    }
                                }
                                return "";
                            }}
                            getRowClassName={(params) => {

                                //initiator에 오늘이 포함되어 있는것
                                if (params.row.initiator && params.row.initiator.includes("오늘") && params.row.status === 2) {
                                    console.log(params.row);
                                    return `super-app-theme--오늘완료`;
                                }

                                return ``
                            }}
                            processRowUpdate={handleEditCommit}
                            onProcessRowUpdateError={handleProcessRowUpdateError}
                            onCellClick={(params) => {
                                console.log(params);
                            }}
                        />
                    </div>
                    {
                        (alignment && alignment.includes("관리"))
                            ?
                            <div style={{
                                flex: 0.2,
                                marginLeft: "32px",
                                height: "100%",
                            }}>
                                <Textarea
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                    }}
                                    value={message}
                                    onChange={(e) => {
                                        handleChangeMessage(e);
                                    }}
                                    placeholder='지점 관리 회의록'
                                    onBlur={(e) => {
                                        handleBlur(e);
                                    }}
                                />
                            </div>
                            :
                            ""
                    }
                </div>



                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    slotProps={{
                        backdrop: {
                            style: {
                                backgroundColor: "transparent",
                                backdropFilter: "blur(0px)"
                            }
                        }
                    }}
                >
                    <Sheet
                        sx={{
                            bgcolor: 'white',      // Sheet 배경색 (예: 흰색)
                            color: 'black',        // 글자색
                            p: 2,                  // 내부 패딩
                            borderRadius: '8px',   // 모서리 둥글게
                            boxShadow: 'lg',       // 그림자,
                            width: "350px"
                        }}
                    >
                        <div style={{
                            fontFamily: "Apple_SB",
                            fontSize: "18px",
                        }}>
                            섹션 간 이동 (
                            {
                                props.projects.map((project: any) => {
                                    if (project.id === selectedProjectId) {
                                        return project.name;
                                    }
                                })
                            }
                            )
                        </div>
                        <div style={{
                            fontFamily: "Apple_R",
                            fontSize: "16px",
                            marginTop: "16px",
                            color: " #555E68",
                            marginBottom: "16px"
                        }}>
                            {/* 
                        15자리까지만 표현
                        */}
                            {
                                selectedContent
                            }
                        </div>
                        <div style={{
                            marginBottom: "24px"
                        }}>
                            <RadioGroup
                                value={radioSelectedSectionId}
                                onChange={(e) => {
                                    setRadioSelectedSectionId(e.target.value);
                                }}
                            >
                                {
                                    props.projects.map((project: any) => {

                                        if (project.id !== selectedProjectId) {
                                            return null;
                                        }

                                        const sections = project.sections;

                                        return sections.map((section: any) => {

                                            if (section.id === selectedSectionId) {
                                                return null;
                                            }

                                            if (!section.id) {
                                                return null;
                                            }

                                            if (section.id.includes("without")) {
                                                return null;
                                            }

                                            return (
                                                <Radio value={section.id}
                                                    label={
                                                        <span style={{
                                                            fontFamily: "Apple_R",
                                                            fontSize: "14px",
                                                            color: " #555E68"
                                                        }}>
                                                            {section.name}
                                                        </span>
                                                    }
                                                />

                                            )
                                        })
                                    })
                                }
                            </RadioGroup>
                        </div>
                        <Button
                            fullWidth
                            disabled={radioSelectedSectionId === null}
                            onClick={() => {

                                if (!radioSelectedSectionId) return;
                                moveTask(selectedTaskId, radioSelectedSectionId,
                                    //sectionName
                                    props.projects.find((project: any) => project.id === selectedProjectId).sections.find((section: any) => section.id === radioSelectedSectionId).name
                                );
                                handleCloseModal();
                            }}

                        >
                            이동시키기
                        </Button>

                    </Sheet>


                </Modal>

                <SwipeableDrawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={() => { setDrawerOpen(false) }}
                    onOpen={() => { setDrawerOpen(true) }}
                >
                    <div
                        style={{ width: "550px", height: "100%" }}
                        role="presentation"
                        className={styles.todoChat}
                    // onClick={(e) => { toggleDrawer(false) }}
                    // onKeyDown={(e) => { toggleDrawer(false) }}
                    >
                        <ChatForTodos
                            openControl={drawerOpen}
                            user={props.user}
                            studentName={selectedContent}
                            selectedNotes={selectedNotes}
                            taskId={selectedTaskIdV2}
                            unreadMessage={() => { }}
                            apiKey={props.apiKey}
                            userName={props.userName ? props.userName : "나"}
                            addNoteToTask={props.addNoteToTask}
                            selectedProjectIdV2={selectedProjectIdV2}
                            selectedSectionIdV2={selectedSectionIdV2}
                        />
                    </div>
                </SwipeableDrawer>


                <NewTodoModal
                    open={open}
                    projects={props.projects}
                    apiKey={props.apiKey}
                    handleClose={() => { setOpen(false); }}
                />
            </div>

            {isFullScreen && (
                <Button
                    onClick={() => setIsFullScreen(false)}
                    style={{
                        zIndex : 9999999,
                        position: 'fixed',
                        top: '10px',
                        right: '65px',
                        opacity: 0.8,
                    }}
                    color='warning'
                >
                    <CloseFullscreen />
                </Button>
            )}




        </div>
    )

}

export default TodoTable;