import React, { useEffect, useState } from "react";
import { postFetch } from "../../../fetch/fetch";

import TextField from '@mui/material/TextField';

import { LocalizationProvider} from '@mui/x-date-pickers-pro';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';

import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, darken, lighten } from '@mui/material/styles';


import Box from '@mui/material/Box';
import styles from "../../componentsStyle/restaurant.module.css";
import koLocale from 'date-fns/locale/ko'
import { GridRowsProp, GridColDef, GridToolbar, useGridApiRef, GridFilterModel, DataGridPremium } from '@mui/x-data-grid-premium';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import totalLocationData from "../../../data/locationdata";
import CustomInput from "./etc/custominput";


const columns: GridColDef[] = [
    { field: 'location', headerName: '위치', width: 60, filterable: true },
    { field: 'name', headerName: '학생이름', width: 80, filterable: true },
    { field: 'range', headerName: "응시 범위", width: 80, filterable: false },
    { field: 'kind', headerName: "응시 종류", width: 100, filterable: false },
    { field: 'book', headerName: "단어책", width: 250, filterable: false },
    { field: 'dueDate', headerName: "숙제일", width: 100, filterable: false },
    { field: 'finish', headerName: '완료여부', width: 80, filterable: true },
    { field: 'date', headerName: '응시 날짜', width: 150, filterable: false },
    { field: 'teacherName', headerName: '출제자', width: 80, filterable: false },
];

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignItems: 'center',
      lineHeight: '24px',
      width: '100%',
      height: '100%',
      position: 'relative',
      display: 'flex',
      '& .cellValue': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  }),
);


const defaultTheme = createTheme();
const useStyles2 = makeStyles(
  (theme) => {
    const getBackgroundColor = (color: any) =>
      theme.palette.mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getHoverBackgroundColor = (color: any) =>
      theme.palette.mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    return {
      root: {
        '& .super-app-theme--처리완료': {
          backgroundColor: getBackgroundColor(theme.palette.info.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
          },
        },
        '& .super-app-theme--Filled': {
          backgroundColor: getBackgroundColor(theme.palette.success.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
          },
        },
        '& .super-app-theme--확인': {
          backgroundColor: getBackgroundColor(theme.palette.warning.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
          },
        },
        '& .super-app-theme--미확인': {
          backgroundColor: getBackgroundColor(theme.palette.error.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
          },
        },
        '& .pending': {
          color: theme.palette.warning.main,
        },
        '& .완료': {
        backgroundColor: getBackgroundColor(theme.palette.info.main),
        },
        '& .미완료': {
          backgroundColor: getBackgroundColor(theme.palette.warning.main),
        },
      },
    };
  },
  { defaultTheme },
);



const TotalMakingTest: React.FC<any> = (props) => {

    const classes = useStyles2();

    const [value, setValue] = React.useState<DateRange<Date>>([new Date(), new Date()]);
    const [submitBool, setSubmitBool] = useState(false);
    const [rows, setRows] = useState<any>([]);
    const [sum, setSum] = useState(0);
    const [name, setName] = useState("");
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            { id: 2, field: 'name', operator: 'contains', value: "" },
            { id: 3, field: 'location', operator: 'contains', value: "" },
            { id: 4, field: 'finish', operator: 'equals', value: "" },
        ],
    });
    const [alignment, setAlignment] = useState("total");
    const [alignment2, setAlignment2] = useState("total");

    const nameChange = (e: any) => {
        setName(e.target.value);

        const newFilterModel = filterModel;

        newFilterModel.items.forEach((each) => {
            if (each.id === 2) {
                each.value = e.target.value
            }
        })

        setFilterModel({ ...newFilterModel });
    }

    useEffect(() => {

        submit();

    }, []);

    const submit = async () => {

        try {

            setRows([]);

            const result: any = await postFetch("/dashboard/words/gettotalmakingrecord", { message: value });
            
            const newRows: any = [];
            if (result.message === "success") {

                const data1 = result.data1;
                const data2 = result.data2;

                data1.forEach((each : any) => {

                    each.isTotal = -1;

                    if(each.type === "total"){
                        each.isTotal = 1;
                    }

                    if(each.type === "incorrect"){
                        each.isTotal = 0;
                    }

                    each.finish = false;

                    data2.forEach((eachEach : any) => {

                        
                        if(each.userId === eachEach.userId && each.day === eachEach.day && each.isTotal === eachEach.isTotal){
                            
                            each.finish = true;
                            each.createdAt = eachEach.createdAt;
                        }

                    })

                })

                
            
                data1.forEach((each: any, index: number) => {

                    if(each.type === "empty"){
                        return;
                    }
                    
                    const row: any = {};
                    row.id = Math.random();
                    row.location = each.location;
                    row.name = each.studentName;
                    row.teacherName = each.teacherName;
                    row.dueDate = `${each.month + 1}월 ${each.date}일`;
                    if(each.createdAt){
                        const createdDate = new Date(each.createdAt);
                        const createdString = `${createdDate.getMonth() + 1}월 ${createdDate.getDate()}일 ${createdDate.getHours()}시 ${createdDate.getMinutes()}분`;
                        row.date = createdString;
                    }
                    row.range = `Day ${each.day}`;
                    row.kind = each.isTotal ? "전체 단어" : "틀린 단어";
                    const kind = each.kind;
                    switch (kind) {
                        case 1:
                            row.book = "워드마스터 수능 2000 (2018 버전)";
                            break;
                        case 2:
                            row.book = "워드마스터 수능 2000 (2022 버전)";
                            break;
                        case 3:
                            row.book = "워드마스터 하이퍼 2000 (2021 버전)";
                            break;
                        case 5:
                            row.book = "워드마스터 EBS 파이널 1200(2020년 버전)";
                            break;
                    }

                    if(each.finish){
                        row.finish = "완료";
                    }else{
                        row.finish = "미완료";
                    }

                    if(each.perfect){
                        row.finish = "완료";
                    }

                    newRows.push(row);
                })

                setRows([...newRows]);
            }

        } catch (e) {
            
        }
    }

    const handleAlignmentChange = (e : any, value : string) => {

        if(!value){
            return;
        }

        setAlignment(value);

        const newFilterModel = filterModel;

        // if(value === "total"){
        //     newFilterModel.items.forEach((each : any) => {
        //         if(each.id === 3){
        //             each.value = "";
        //         }
        //     })
        // }

        // if(value === "daechi"){
        //     newFilterModel.items.forEach((each : any) => {
        //         if(each.id === 3){
        //             each.value = "대치점";
        //         }
        //     })
        // }

        // if(value === "gangnam"){
        //     newFilterModel.items.forEach((each : any) => {
        //         if(each.id === 3){
        //             each.value = "강남점";
        //         }
        //     })
        // }

        // if(value === "daechi2"){
        //     newFilterModel.items.forEach((each : any) => {
        //         if(each.id === 3){
        //             each.value = "대치3층";
        //         }
        //     })
        // }

        // if(value === "daechi3"){
        //     newFilterModel.items.forEach((each : any) => {
        //         if(each.id === 3){
        //             each.value = "대치6층";
        //         }
        //     })
        // }

        totalLocationData.forEach((each) => {
            if(each.english === value){
                newFilterModel.items.forEach((each2 : any) => {
                    if(each2.id === 3){
                        each2.value = each.filterValue_contains;
                    }
                })
            }
        })

        setFilterModel({...newFilterModel});
    }

    const handleAlignmentChange2 = (e : any, value : string) => {

        if(!value){
            return;
        }

        

        setAlignment2(value);

        const newFilterModel = filterModel;

        if(value === "total"){
            newFilterModel.items.forEach((each : any) => {
                if(each.id === 4){
                    each.value = "";
                }
            })
        }

        if(value === "complete"){
            newFilterModel.items.forEach((each : any) => {
                if(each.id === 4){
                    each.value = "완료";
                }
            })
        }

        if(value === "incomplete"){
            newFilterModel.items.forEach((each : any) => {
                if(each.id === 4){
                    each.value = "미완료";
                }
            })
        }

        setFilterModel({...newFilterModel});
    }


    return (
        <div>
            <div className={styles.datePicker}>
                <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                    <DateRangePicker
                        startText="시작일"
                        endText="마지막일"
                        value={value}
                        onChange={(newValue: any) => {
                            setValue(newValue);
                            if (newValue[0] && newValue[1]) {
                                setSubmitBool(true);
                            }
                        }}
                        renderInput={(startProps: any, endProps: any) => (
                            <React.Fragment>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                            </React.Fragment>
                        )}

                    />
                </LocalizationProvider>
            </div>
            {submitBool ?
                <div onClick={submit} className={styles.totalCheckBtn}>
                    조회하기
                </div>
                :
                <div className={styles.disableTotalCheckBtn}>
                    조회하기
                </div>
            }


            
            <div className={styles.dataGrid}>
                {
                    rows &&
                    <div style={{marginBottom : "12px"}}>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleAlignmentChange}
                        >
                            {
                                props.user && totalLocationData.filter(locationData => locationData.academy.includes(props.user.academy)).map((each, index) => {
                                    return <ToggleButton value={each.english} key={index}>{each.korean}</ToggleButton>
                                })
                            }
                        </ToggleButtonGroup>
                    </div>
                }
                {
                    rows &&
                    <div style={{ display: "flex", justifyContent: "space-between", width: "1200px", marginBottom: "18px" }}>
                        <div>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment2}
                                exclusive
                                onChange={handleAlignmentChange2}
                            >
                                <ToggleButton value="total">전체</ToggleButton>
                                <ToggleButton value="complete">완료</ToggleButton>
                                <ToggleButton value="incomplete">미완료</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div>
                            <CustomInput value={name} onChange={nameChange} placeholder="이름을 검색하세요" />
                        </div>
                    </div>
                }
                {rows &&
                    <div style={{ height: 500, width: 1200 }}>
                        <div style={{ display: "flex", height: "100%" }}>
                            <div className={classes.root} style={{ flexGrow: 1 }}>
                                <DataGridPremium rows={rows} columns={columns}
                                    filterModel={filterModel}
                                    onFilterModelChange={(model) => setFilterModel(model)}
                                    density="compact"
                                    getCellClassName={(params) => {

                                        if(params.field === "finish"){
                                            if(params.value === "완료"){
                                                return "완료"
                                            }else{
                                                return "미완료"
                                            }
                                        }else{
                                            return ""
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>


        </div>
    );

}

export default TotalMakingTest;