import React, { useEffect, useState } from "react";
import { Button, Dropdown, IconButton, Input, Menu, MenuButton, Modal, Option, Radio, RadioGroup, Sheet, Select, ModalDialog, Textarea } from '@mui/joy';
import { AddTaskBySyncApi } from "./function";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postFetch } from "../../../../../fetch/fetch";

const NewKakaoMessageModalRegular: React.FC<any> = (props) => {

    const [description, setDescription] = useState<string>("");
    const [disabled, setDisabled] = useState<boolean>(true);
    const [registerLoading, setRegisterLoading] = useState<boolean>(false);
    const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [regularType, setRegularType] = useState<"daily" | "weekly" | "monthly">("daily");
    const [dayString, setDayString] = useState<string>("mon");
    const [dateString, setDateString] = useState<string>("1");

    const [timeString, setTimeString] = useState<string>("");

    const [selectedHours, setSelectedHours] = useState<number>(9);
    const [selectedMinutes, setSelectedMinutes] = useState<number>(0);

    const notify1 = () => toast.success("업로드 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });



    const submit = async () => {

        try {

            setLoading(true);

            console.log(selectedProjectId);
            console.log(description);
            console.log(regularType);
            console.log(dayString);
            console.log(dateString);
            console.log(selectedHours);
            console.log(selectedMinutes);


            const result: any = await postFetch("/dashboard/todoist/addRegularKakao", {
                selectedProjectId,
                description,
                regularType,
                dayString,
                dateString,
                selectedHours,
                selectedMinutes
            });

            console.log(result);

            if (result.message === "success") {
                notify1();
            }

            setLoading(false);

            props.letsUpdate();

        } catch (e) {
            console.log(e);
        }

    }



    return (
        <Modal open={props.open} onClose={() => {
            setSelectedProjectId(null);
            setDescription("");

            props.handleClose();
        }}>
            <ModalDialog>
                <div style={{
                    fontFamily: "Apple_B",
                    fontSize: "18px",
                    marginBottom: "24px"
                }}>
                    새로운 반복 카카오톡 등록하기
                </div>

                <div>
                    <div style={{
                        marginBottom: "12px",
                        fontSize: "17px",
                        fontFamily: "Apple_SB",
                    }}>
                        채팅방 선택
                    </div>
                    <div style={{
                        display: "flex",
                        width: "450px",
                        flexWrap: "wrap"
                    }}>
                        <RadioGroup value={selectedProjectId} onChange={(e: any) => {
                            console.log(e.target.value);
                            setSelectedProjectId(e.target.value);
                        }}
                        >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexWrap: "wrap"
                            }}>
                                {
                                    props.chatRoomNames && props.chatRoomNames.map((each: any, index: number) => {
                                        return (
                                            <div style={{
                                                marginBottom: "10px",
                                                marginRight: "18px",
                                                width: "200px"
                                            }}
                                                key={index}
                                            >
                                                <Radio label={
                                                    <span
                                                        style={{
                                                            fontSize: "16px",
                                                            fontFamily: "Apple_R"
                                                        }}
                                                    >{each}</span>
                                                } value={each} />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </RadioGroup>
                    </div>

                    <div style={{
                        marginBottom: "12px",
                        fontSize: "17px",
                        fontFamily: "Apple_SB",
                        marginTop: "12px"
                    }}>
                        날짜 선택
                    </div>
                    <div>
                        <RadioGroup value={regularType} onChange={(e: any) => {
                            setRegularType(e.target.value);
                        }}
                            orientation="horizontal"
                        >
                            <Radio label="매일" value="daily" />
                            <Radio label="매주" value="weekly" />
                            <Radio label="매월" value="monthly" />
                        </RadioGroup>
                    </div>

                    {
                        regularType === "daily" && (
                            <div style={{
                                height: "16px"
                            }} />
                        )
                    }
                    {
                        regularType === "weekly" && (
                            <div>
                                <div style={{
                                    marginBottom: "12px",
                                    fontSize: "17px",
                                    fontFamily: "Apple_SB",
                                    marginTop: "16px"
                                }}>
                                    상세 날짜 선택
                                </div>
                                <Select value={dayString} onChange={(e: any, value: any) => {
                                    console.log(value);
                                    if (!value) return;
                                    setDayString(value);
                                }}
                                >
                                    <Option value="mon">월요일</Option>
                                    <Option value="tue">화요일</Option>
                                    <Option value="wed">수요일</Option>
                                    <Option value="thu">목요일</Option>
                                    <Option value="fri">금요일</Option>
                                    <Option value="sat">토요일</Option>
                                    <Option value="sun">일요일</Option>
                                </Select>
                            </div>
                        )
                    }
                    {
                        regularType === "monthly" && (
                            <div>
                                <div style={{
                                    marginBottom: "12px",
                                    fontSize: "17px",
                                    fontFamily: "Apple_SB",
                                    marginTop: "16px"
                                }}>
                                    상세 시간 선택
                                </div>
                                <Select value={dateString} onChange={(e: any, value: any) => {
                                    console.log(value);
                                    setDateString(value);
                                }}
                                >
                                    {
                                        new Array(31).fill(undefined).map((each: number, index: number) => {
                                            return (
                                                <Option key={index + 1} value={String(index + 1)}>{String(index + 1)}일</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                        )
                    }

                    <div style={{
                        marginBottom: "12px",
                        fontSize: "17px",
                        fontFamily: "Apple_SB",
                        marginTop: "12px"
                    }}>
                        시간 선택(5분 단위 선택)
                    </div>
                    <div>
                        <input type="time" style={{
                            width: "100%",
                            height: "40px",
                            fontSize: "16px",
                            fontFamily: "Apple_R",
                            borderRadius: "8px",
                            border: "1px solid #D9D9D9",
                            paddingLeft: "12px"
                        }} 
                        value={`${selectedHours < 10 ? "0" + selectedHours : selectedHours}:${selectedMinutes < 10 ? "0" + selectedMinutes : selectedMinutes}`}
                        onChange={(e: any) => {
                            console.log(e.target.value);
                            if(!e.target.value) return;
                            
                            const timeString = e.target.value;
                            const timeArray = timeString.split(":");
                            const hour = Number(timeArray[0]);
                            const minute = Number(timeArray[1]);

                            setSelectedHours(hour);
                            setSelectedMinutes(minute);
                        }}
                        />
                    </div>



                    <div style={{
                        marginBottom: "12px",
                        fontSize: "17px",
                        fontFamily: "Apple_SB",
                        marginTop: "12px"
                    }}>
                        전달사항
                    </div>
                    <div>
                        <Textarea minRows={2} value={description} onChange={(e: any) => { setDescription(e.target.value) }} />
                    </div>
                </div>

                <div style={{
                    marginTop: "32px"
                }}>
                    <Button variant="solid" size="sm" fullWidth onClick={submit} loading={loading} disabled={
                        !selectedProjectId || !description || selectedMinutes % 5 !== 0 || selectedHours < 0 || selectedHours > 23 || selectedMinutes < 0 || selectedMinutes > 59
                    }>
                        등록하기
                    </Button>
                </div>
            </ModalDialog>
        </Modal>
    )

}

export default NewKakaoMessageModalRegular;