import React, { useState, useEffect, useRef } from 'react'
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


// import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';


import Box from '@mui/material/Box';
import styles from "../../componentsStyle/restaurant.module.css";
import koLocale from 'date-fns/locale/ko'
import { DataGridPro, GridRowsProp, GridColDef, GridToolbar, useGridApiRef, GridFilterModel, DataGridPremium, gridFilteredSortedRowEntriesSelector } from '@mui/x-data-grid-premium';
import { Button, Modal, Radio, RadioGroup, Sheet, Textarea } from '@mui/joy';
import { ro } from 'date-fns/locale';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Autocomplete, Backdrop, CircularProgress } from '@mui/material';
import totalLocationData from '../../../data/locationdata';
import ReactToPrint from 'react-to-print';
import { EmailOutlined, Image, Print, Send } from '@mui/icons-material';
import { dayNumberToKorean, koreanLocationToEnglish } from '../../../data/locationFuntion';

import { ReactComponent as MealListSvg } from "../../../svg/meallist.svg";
import { ReactComponent as SunbaeLogoSvg } from "../../../svg/sunbaelogo.svg";
import CustomInput from './etc/custominput';
import { set } from 'lodash';






const columns: GridColDef[] = [
    { field: 'location', headerName: '위치', width: 100 },
    { field: 'whenType', headerName: "시간", width: 100 },
    { field: 'name', headerName: '이름', width: 100 },
    { field: 'date', headerName: '배송 날짜', width: 100, filterable: false },
    { field: 'restaurant', headerName: "업체", width: 100 },
    { field: 'mealName', headerName: "도시락", width: 250 },
    { field: 'price', headerName: "가격", width: 150, filterable: false },
    { field: 'time', headerName: "주문시각", width: 150, filterable: false }
];

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: "24px",
    boxShadow: 24,
    p: 3,
    paddingLeft: 5,
    paddingRight: 5
};


const Totalmeals: React.FC<any> = (props) => {
    const [value, setValue] = React.useState<DateRange<Date>>([new Date(), new Date()]);
    const [submitBool, setSubmitBool] = useState(false);
    const [rows, setRows] = useState<any>();
    const [sum, setSum] = useState(0);
    const apiRef = useGridApiRef();
    const [alignment, setAliment] = useState("total");
    const [alignment2, setAlignment2] = useState("total");
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [
            { id: 2, field: 'whenType', operator: 'contains', value: "" },
            { id: 3, field: 'location', operator: 'contains', value: "" },
            { id: 4, field: 'name', operator: 'contains', value: "" }
        ],
    });
    const [orderedInfo, setOrderedInfo] = useState<any>([]);
    const [disabled, setDisabled] = useState(true);
    const [finalDisabled, setFinalDisabled] = useState(true);
    const [selectedId, setSelectedId] = useState(0);
    const [selectedName, setSelectedName] = useState("");
    const [update, setUpdate] = useState(0);
    const [loading, setLoading] = useState(false);
    const [manualGetLoading, setManualGetLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState<any>([]);

    const [selectedLocation, setSelectedLocation] = useState("");
    const [selectedTime, setSelectedTime] = useState("");
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [targetDate, setTargetDate] = useState(new Date());
    const [mealList, setMealList] = useState<any>([]);
    const [selectedMealId, setSelectedMealId] = useState<any>(null);
    const [name, setName] = useState("");
    const componentRef = useRef(null);
    const [disable, setDisable] = useState(true);
    const [printData, setPrintData] = useState<any>([]);

    const [open2, setOpen2] = useState(false);
    const [message, setMessage] = useState<string>("");
    const [whichType, setWhichType] = useState("text");
    const [imgSrc, setImgSrc] = useState("");
    const [imgId, setImgId] = useState(0);
    const [isOver, setIsOver] = useState(false);
    const [imgLoading, setImgLoading] = useState(false);

    const [selectedLocations, setSelectedLocations] = useState<any>([]);

    const [fileName, setFileName] = useState<any>("");
    const [file, setFile] = useState<any>();
    const [loading2, setLoading2] = useState<boolean>(false);
    const [selectedIds, setSelectedIds] = useState<any>([]);

    const [messageLocation, setMessageLocation] = useState<any>("");
    const [messageWhenType, setMessageWhenType] = useState<any>("");
    const [messageMealName, setMessageMealName] = useState<any>("");

    const [messageMealList, setMessageMealList] = useState<any>([]);

    useEffect(() => {

        if (!value) {
            return;
        }

        const value1 = value[0];
        const value2 = value[1];

        if (!value1 || !value2) {
            setDisable(true);
            return;
        }

        const value1Date = value1.getDate();
        const value2Date = value2.getDate();

        if (value1Date !== value2Date) {
            setDisable(true);
            return;
        }

        if (alignment2 === "total" || alignment === "total") {
            setDisable(true);
        } else {
            setDisable(false);
        }

    }, [alignment2, alignment, value]);

    useEffect(() => {

        //전부 다 있어야 finalDisabled가 false가 된다.
        if (selectedTime && selectedLocation && selectedMealId && selectedUser) {
            setFinalDisabled(false);
        } else {
            setFinalDisabled(true);
        }

    }, [selectedTime, selectedLocation, selectedMealId, selectedUser])

    useEffect(() => {

        setMealList([]);

        setSelectedMealId(null);
        getSelectedMeal();

    }, [selectedTime, selectedLocation]);

    const getSelectedMeal = async () => {

        if (!selectedTime || !selectedLocation) {
            return;
        }

        setManualGetLoading(true);

        const time = targetDate.getTime();
        const whenType = selectedTime;
        const location = selectedLocation;

        fetch(`https://peetsunbae.com/dashboard/restaurant/manualget?time=${time}&whenType=${whenType}&location=${location}`, {
            method: "get",
            credentials: "include"
        }).then((response: any) => {
            response.json().then((result: any) => {

                setManualGetLoading(false);

                if (result.message === "success") {
                    setMealList([...result.data]);
                }
            })
        })
    }


    const getUsers = async () => {

        fetch("https://peetsunbae.com/dashboard/restaurant/users2", {
            method: "GET",
            credentials: "include",
        }).then((response: any) => {
            response.json()
                .then((result: any) => {

                    const rows: any = [];

                    if (!result.data) {
                        return;
                    }

                    result.data.forEach((each: any, index: number) => {
                        var data: any = {};
                        data.id = each.id;
                        data.label = `${each.name}${each.location ? " (" + each.location + ")" : ""} - ${each.amount ? each.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}원`;
                        data.phoneNumber = each.phoneNumber;
                        data.amount = each.amount;
                        data.value = each.value;
                        data.location = each.location;
                        data.key = index;
                        rows.push(data);
                    })
                    setUsers([...rows]);
                })
        })

    }

    const onchange = async (e: any, value: any) => {

        if (!users) {
            return;
        }




        setSelectedUser(value);
        setSelectedLocation(value ? value.location : "");
    }

    useEffect(() => {

        getUsers();

    }, [update]);

    const handleClose = () => {

        setSelectedMealId(null);
        setMealList([]);
        setSelectedLocation("");
        setSelectedUser(null);
        setSelectedTime("");

        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const notify1 = () => toast.success("취소 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify2 = () => toast.success("취소 가능 시간이 지나서 취소가 불가능합니다!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify3 = () => toast.success("수동 추가 성공!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "colored"
    });

    const notify4 = () => toast.success("이미 해당 시간에 주문이 있습니다!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify5 = () => toast.success("잔액이 부족합니다!", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        theme: "dark"
    });

    const notify6 = () => toast.success("클립보드에 변수가 저장되었습니다. 원하는 곳에 붙여넣기 해주세요!", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            theme: "light"
        });
    
        const notify7 = () => toast.success("모든 메세지 전송이 완료되었습니다!", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            theme: "light"
        });
    
        const notify8 = () => toast.error("메세지 전송 중에 에러가 발생했습니다!", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            theme: "light"
        });

    useEffect(() => {

        if (selectedId) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [selectedId]);

    const handleAlignmentChange = (e: any, newAlignment: string) => {

        if (!newAlignment) {
            return;
        }

        setAliment(newAlignment);
    }

    useEffect(() => {
        submit("E");
    }, [update]);

    useEffect(() => {

        const newFilterModel = filterModel;

        if (alignment === "total") {

            newFilterModel.items.forEach((each: any) => {
                if (each.id === 2) {
                    each.value = "";
                }
            })

        }

        if (alignment === "lunch") {

            newFilterModel.items.forEach((each: any) => {
                if (each.id === 2) {
                    each.value = "점심";
                }
            })
        }

        if (alignment === "dinner") {

            newFilterModel.items.forEach((each: any) => {
                if (each.id === 2) {
                    each.value = "저녁";
                }
            })
        }

        setFilterModel({ ...newFilterModel });


    }, [alignment]);

    const filterChange = (e: any) => {


        const allInfos: any = [];



        const filteredSortedEntries = gridFilteredSortedRowEntriesSelector(apiRef);

        const param: any = [];

        filteredSortedEntries.forEach((each: any) => {
            param.push(each.model);
        })


        var sumNumber = 0;

        param.forEach((each: any) => {
            if (each && each.priceNumber) {
                sumNumber += each.priceNumber
            }
            if (each && each.restaurant && each.mealName) {
                // 
                // 

                const string = `[${each.restaurant}] ${each.mealName}`;
                allInfos.push(string);
            }
        })

        const noDuplicate: any = new Set(allInfos);

        const shortenInfos = [...noDuplicate];

        // 
        // 

        const lastInfos: any = [];

        shortenInfos.forEach((eachShorten: any) => {
            var count = 0;
            allInfos.forEach((each: any) => {
                if (eachShorten === each) {
                    count++;
                }
            })

            const oneInfo: any = {
                name: eachShorten,
                count
            }
            lastInfos.push(oneInfo);
        })


        setOrderedInfo(lastInfos);

        // param.rows.idRowsLookup.forEach((each : any)=>{
        //     sumNumber += each.priceNumber;
        // })
        setSum(sumNumber);
    }

    const submit = async (e: any) => {
        var token = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        fetch("https://peetsunbae.com/dashboard/restaurant/total", {
            method: "POST",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                message: value
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {

                    var data: any = [];

                    result.data.sort((after: any, before: any) => {
                        if (new Date(after.targetDate).getTime() === new Date(before.targetDate).getTime()) {
                            if (after.restaurantName === "본도시락" && before.restaurantName !== "본도시락") {
                                return -1;
                            }
                        }
                    })

                    result.data.forEach((each: any, index: number) => {
                        var oneRow: any = {};
                        oneRow.id = index + 1;
                        oneRow.sqlId = each.id;
                        oneRow.userId = each.userId;
                        oneRow.location = each.location;
                        const englishLocation = koreanLocationToEnglish(each.location);
                        oneRow.englishLocation = englishLocation;
                        oneRow.name = each.name;
                        oneRow.date = `${new Date(each.targetDate).getMonth() + 1}월 ${new Date(each.targetDate).getDate()}일`;
                        oneRow.whenTypeEnglish = each.whenType;
                        oneRow.whenType = each.whenType === "lunch" ? "점심" : "저녁";
                        oneRow.restaurant = each.restaurantName;
                        oneRow.mealName = each.mealName;
                        oneRow.price = `${each.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`;
                        oneRow.priceNumber = +each.price;
                        oneRow.time = `${new Date(+each.time).getMonth() + 1}월 ${new Date(+each.time).getDate()}일 ${new Date(+each.time).getHours()}시`;
                        data.push(oneRow);
                    })

                    setRows([...data]);


                })
        })
    }

    const letsSum = (param: any) => {
        var sumNumber = 0;

        for (const [key, value] of Object.entries(param.rows.idRowsLookup) as any) {
            sumNumber += value.priceNumber;
        }
        // param.rows.idRowsLookup.forEach((each : any)=>{
        //     sumNumber += each.priceNumber;
        // })
        setSum(sumNumber);
    }

    const handleAlignmentChange2 = (e: any, value: string) => {

        if (!value) {
            return;
        }




        setAlignment2(value);

        const newFilterModel = filterModel;

        // if (value === "total") {
        //     newFilterModel.items.forEach((each: any) => {
        //         if (each.id === 3) {
        //             each.value = "";
        //         }
        //     })
        // }

        // if (value === "daechi") {
        //     newFilterModel.items.forEach((each: any) => {
        //         if (each.id === 3) {
        //             each.value = "대치점";
        //         }
        //     })
        // }

        // if (value === "gangnam") {
        //     newFilterModel.items.forEach((each: any) => {
        //         if (each.id === 3) {
        //             each.value = "강남점";
        //         }
        //     })
        // }

        // if (value === "daechi2") {
        //     newFilterModel.items.forEach((each: any) => {
        //         if (each.id === 3) {
        //             each.value = "대치3층";
        //         }
        //     })
        // }

        // if (value === "daechi3") {
        //     newFilterModel.items.forEach((each: any) => {
        //         if (each.id === 3) {
        //             each.value = "대치6층";
        //         }
        //     })
        // }

        totalLocationData.forEach((each) => {
            if (each.english === value) {
                newFilterModel.items.forEach((each2: any) => {
                    if (each2.id === 3) {
                        each2.value = each.filterValue_contains;
                    }
                })
            }
        })

        setFilterModel({ ...newFilterModel });

    }

    const forceCancel = () => {

        if (!selectedId) {
            return;
        }

        const selectedRow = rows.find((each: any) => each.sqlId === selectedId);

        const name = selectedRow.name;
        const location = selectedRow.location;
        const whenType = selectedRow.whenType;

        if (!window.confirm(`${location} ${name}님의 ${whenType} 주문을 취소시키겠습니까?`)) {
            return;
        }

        setLoading(true);

        fetch("https://peetsunbae.com/dashboard/restaurant/cancel", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                id: selectedId
            })
        }).then((response: any) => {
            response.json().then((result: any) => {


                if (result.message === "success") {
                    notify1();
                    setUpdate(Math.random());
                }

                if (result.message === "TIME_OUT") {
                    notify2();
                    setUpdate(Math.random());
                }

                setLoading(false);

            })
        })

    }

    const manualOrder = async () => {

        if (!targetDate) {

            return;
        }

        if (!selectedTime) {

            return;
        }

        if (!selectedMealId) {

            return;
        }

        const selectedMeal = mealList.find((each: any) => +each.id === +selectedMealId);




        if (!selectedMeal) {

            return;
        }

        if (!selectedUser) {

            return;
        }

        const userId = selectedUser.id;

        if (!userId) {

            return;
        }

        const amount = selectedUser.amount;
        const mealId = selectedMealId;
        const mealName = selectedMeal.mealName;
        const price = selectedMeal.mealPrice;
        const restaurantName = selectedMeal.restaurantName;

        if (amount < price) {
            alert("잔액이 부족합니다.");
            return;
        }

        const data = {
            targetYear: targetDate.getFullYear(),
            targetMonth: targetDate.getMonth() + 1,
            targetDate: targetDate.getDate(),
            whenType: selectedTime,
            restaurantName,
            mealName,
            price,
            mealId,
            userId
        }



        setLoading(true);

        fetch("https://peetsunbae.com/dashboard/restaurant/manualorder", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(data)
        }).then((response: any) => {
            response.json().then((result: any) => {


                setLoading(false);
                if (result.message === "TIME_OUT") {
                    alert("가능한 시간이 지났습니다.");
                } else if (result.message === "LACK_BALANCE") {
                    notify5();
                    return;
                } else if (result.message === "success") {
                    handleClose();
                    setUpdate(Math.random());
                    notify3();
                    return;
                } else if (result.message === "DUPLICATE") {
                    notify4();
                    return;
                }

            })
        });

    }

    const nameChange = (e: any) => {
        setName(e.target.value);


        const newFilterModel: any = filterModel;
        newFilterModel.items.forEach((each: any) => {
            if (each.id === 4) {
                each.value = e.target.value;
            }
        })
        setFilterModel({ ...newFilterModel });
    }

    useEffect(() => {

        updatePrintData(alignment, alignment2);

    }, [alignment, alignment2, value]);

    const updatePrintData = (alignment: string, alignment2: string) => {

        if (alignment === "total" || alignment2 === "total") {
            setPrintData([]);
            return;
        }

        const locationInfo = totalLocationData.find((each) => each.english === alignment2);

        if (!locationInfo) {

            setPrintData([]);
            return;
        }

        const location = locationInfo.korean;

        var when = "";

        switch (alignment) {
            case "lunch":
                when = "점심";
                break;
            case "dinner":
                when = "저녁";
                break;
        }

        if (!when) {

            setPrintData([]);
            return;
        }

        const selectedRows = rows.filter((each: any) => each.location === location && each.whenType === when);

        const meals: any = [];

        selectedRows.forEach((each: any) => {

            var isExist = false;

            meals.forEach((each2: any) => {

                if (each2.mealName === each.mealName) {
                    isExist = true;
                }

            });

            if (!isExist) {
                const mealInfo = {
                    mealName: each.mealName,
                    restaurant: each.restaurant,
                    users: [],
                }

                meals.push(mealInfo);
            }


            meals.forEach((each2: any) => {

                if (each2.mealName === each.mealName) {

                    var name = each.name;

                    //name을 최대 4글자로 줄이기
                    if (name.length > 4) {
                        name = name.slice(0, 4);
                    }

                    each2.users.push(name);
                }

            });

        })

        meals.forEach((each: any) => {

            each.users.sort((a: any, b: any) => {
                return a.localeCompare(b);
            })

        })



        setPrintData([...meals]);


    }

    const handleClose2 = () => {
        setMessage("");
        setWhichType("text");
        setImgSrc("");
        setImgId(0);
        setOpen2(false);
        setIsOver(false);

        setMessageLocation("");
        setMessageWhenType("");
        setMessageMealName("");
        setMessageMealList([]);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWhichType(event.target.value);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    }

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsOver(true);

    }

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsOver(false);

    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {

        e.preventDefault();



        if (e.dataTransfer.files.length === 0) {

            return;
        }

        if (e.dataTransfer.files.length !== 1) {
            alert("한 번에 한 장의 사진만 전송 가능합니다");
            return;
        }

        if (!e.dataTransfer.files[0].type.includes("image")) {
            alert("이미지 파일만 전송가능합니다");
            return;
        }

        const files = e.dataTransfer.files;

        setFile(files[0]);
        setFileName(files[0].name);

        setImgLoading(true);

        var formData = new FormData();
        formData.append("chat_img", files[0]);

        setOpen(true);

        fetch("https://peetsunbae.com/dashboard/envelope/img", {
            method: "post",
            credentials: "include",
            body: formData
        }).then((response: any) => {
            response.json()
                .then((result: any) => {

                    if (result.message === "success") {
                        setImgSrc(result.src);
                        setImgId(result.imgId);
                    }
                    setImgLoading(false);
                })
        })
    }

    const fileOnChange = (e: any) => {

        if (e.target && e.target.files.length > 0) {

            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);

            setImgLoading(true);

            var formData = new FormData();
            formData.append("chat_img", e.target.files[0]);

            setOpen(true);

            fetch("https://peetsunbae.com/dashboard/envelope/img", {
                method: "post",
                credentials: "include",
                body: formData
            }).then((response: any) => {
                response.json()
                    .then((result: any) => {

                        if (result.message === "success") {
                            setImgSrc(result.src);
                            setImgId(result.imgId);
                        }
                        setImgLoading(false);
                    })
            })

        }
    }

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                notify6();
            })
            .catch(err => {
                // 복사 실패 시 처리
                console.error('클립보드 복사에 실패했습니다.', err);
                alert('클립보드 복사에 실패했습니다.');
            });
    };

    const letsSendTotalMessages = async () => {

        try {

            const selectedMessageMealList = messageMealList.find((each: any) => each.mealName === messageMealName);
            const selectedIds = selectedMessageMealList.userList;

            if (!message && whichType === "text") {
                return;
            }

            if (!imgSrc && whichType === "img") {
                return;
            }

            if(!selectedIds){
                return;
            }

            if (selectedIds.length === 0) {
                return;
            }

            var targetMessage = "";

            switch (whichType) {
                case "text":
                    targetMessage = message;
                    break;
                case "img":
                    targetMessage = imgSrc;
                    break;
            }


            handleClose();
            setLoading2(true);


            // setTotalSendNumber(selectedIds.length);
            // setCurrentSendedNumber(0);

            const response = await fetch("https://peetsunbae.com/dashboard/envelope/sendMessageTotal", {
                method: "post",
                headers: {
                    "content-type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    chatString: targetMessage, selectedIds, whichType
                })
            });

            const result = await response.json();

            // for (const selectedId of selectedIds) {

            //     await sendMessage(targetMessage, selectedId);

            //     setCurrentSendedNumber(prevNumber => prevNumber + 1);

            //     await wait(2);

            // }

            setLoading2(false);

            handleClose();

            if (result.message === "success") {
                notify7();
            } else {
                notify8();
            }


        } catch (e) {

            setLoading2(false);
            notify3();
        }



    }

    const handleChangeMessage = (type : string, value : string) => {

        if(type === "location"){
            setMessageLocation(value);
        }

        if(type === "whenType"){
            setMessageWhenType(value);
        }

        if(type === "mealName"){
            setMessageMealName(value);
        }

    }

    useEffect(() => {

        if(!messageLocation || !messageWhenType){
            return;
        }

        const newMessageMealList : any = [];

        rows.forEach((each : any)=>{

            if(each.englishLocation !== messageLocation){
                return;
            }

            if(each.whenTypeEnglish !== messageWhenType){
                return;
            }

            var isAlready = false;

            newMessageMealList.forEach((each2 : any)=>{

                const mealName = each2.mealName;

                if(mealName === each.mealName){
                    isAlready = true;
                }

            });

            if(!isAlready){
                newMessageMealList.push({
                    mealName : each.mealName,
                    userList : []
                });
            }

            const selectedMessageMealList = newMessageMealList.find((each2 : any)=>each2.mealName === each.mealName);

            selectedMessageMealList.userList.push(each.userId);

        });

        setMessageMealList([...newMessageMealList]);

    }, [messageLocation, messageWhenType]);

    return (
        <div>

            <div className={styles.datePicker}>
                <LocalizationProvider locale={koLocale} dateAdapter={AdapterDateFns}>
                    <DateRangePicker
                        startText="시작일"
                        endText="마지막일"
                        value={value}
                        onChange={(newValue: any) => {
                            setValue(newValue);
                            if (newValue[0] && newValue[1]) {
                                setSubmitBool(true);
                            }
                        }}
                        renderInput={(startProps: any, endProps: any) => (
                            <React.Fragment>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                            </React.Fragment>
                        )}

                    />
                </LocalizationProvider>
            </div>
            {submitBool ?
                <div onClick={submit} className={styles.totalCheckBtn}>
                    조회하기
                </div>
                :
                <div className={styles.disableTotalCheckBtn}>
                    조회하기
                </div>
            }




            <div style={{ marginTop: "28px", display: rows ? "block" : "none" }}>
                <div>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment2}
                        exclusive
                        onChange={handleAlignmentChange2}
                    >
                        {
                            props.user && totalLocationData.filter(locationData => locationData.academy.includes(props.user.academy)).map((each, index: number) => (
                                <ToggleButton value={each.english} key={index}>{each.korean}</ToggleButton>
                            ))
                        }
                    </ToggleButtonGroup>
                </div>
                <div>

                </div>
            </div>

            <div style={{ marginTop: "18px", display: rows ? "flex" : "none", justifyContent: "space-between" }}>
                <div>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleAlignmentChange}
                    >
                        <ToggleButton value="total">전체</ToggleButton>
                        <ToggleButton value="lunch">점심</ToggleButton>
                        <ToggleButton value="dinner">저녁</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <div style={{
                        marginRight: "12px"
                    }}>
                        <Button size="lg" variant="outlined" color='neutral'
                        onClick={() => {
                            setOpen2(true);
                        }}
                        >
                            <EmailOutlined />
                        </Button>
                    </div>
                    <div>
                        <ReactToPrint
                            onAfterPrint={() => {

                            }}
                            trigger={() => {
                                return (
                                    <Button size="lg" variant='outlined' color='neutral'
                                        disabled={disable}
                                    >
                                        <Print />
                                    </Button>
                                )
                            }
                            }
                            content={() => componentRef.current}
                        />
                    </div>
                </div>
            </div>



            <div className={styles.dataGrid}>
                <div className={styles.cancelBtnDiv}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "24px",
                    }}
                >
                    <div>
                        <CustomInput value={name} onChange={nameChange} placeholder="이름을 검색하세요" />
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <div style={{
                            marginRight: "12px"
                        }}>
                            {selectedName}
                        </div>
                        <div>
                            <Button size="sm" sx={{ marginRight: "8px" }} onClick={() => { handleOpen(); }}>수동 추가하기</Button>
                            <Button onClick={forceCancel} disabled={disabled} size="sm">취소시키기</Button>
                        </div>
                    </div>
                </div>
                {rows &&
                    <div style={{ height: 500, width: '100%' }}>
                        <div style={{ display: "flex", height: "100%" }}>
                            <div style={{ flexGrow: 1 }}>
                                <DataGridPremium rows={rows} columns={columns} components={{ Toolbar: GridToolbar }} apiRef={apiRef}
                                    onStateChange={filterChange}
                                    filterModel={filterModel}
                                    onFilterModelChange={(model) => setFilterModel(model)}
                                    density="compact"
                                    onRowClick={(e) => {


                                        setSelectedId(e.row.sqlId);
                                        setSelectedName(e.row.name);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className={styles.totalSumDiv} style={{ display: rows ? "block" : "none" }}>
                <div>
                    {
                        orderedInfo && orderedInfo.map((each: any, index: number) => {

                            return (
                                <div key={index}>
                                    {
                                        index + 1
                                    }.&nbsp;
                                    {
                                        each.name
                                    }___
                                    {
                                        each.count
                                    }개
                                </div>
                            );
                        })
                    }
                    (총액 : {sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원)
                </div>
            </div>

            <div style={{ height: "60px" }}>

            </div>



            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 4,
                        boxShadow: 'lg',
                    }}
                >
                    <div style={{
                        fontFamily: "Apple_SB",
                        fontSize: "18px",
                        marginBottom: "18px"
                    }}>
                        도시락 수동 추가하기 ({targetDate.getFullYear()}/{targetDate.getMonth() + 1}/{targetDate.getDate()})
                    </div>

                    <div
                        style={{
                            marginBottom: "16px",
                        }}
                    >
                        <Autocomplete
                            onChange={onchange}
                            value={selectedUser}
                            disablePortal
                            id="combo-box-demo"
                            options={users}
                            sx={{ width: "350px" }}
                            renderInput={(params) => <TextField {...params} placeholder="학생을 선택하세요" />}
                        />
                    </div>

                    <div>
                        <div
                            style={{
                                fontFamily: "Apple_SB",
                                marginBottom: "9px",
                                marginTop: "24px"
                            }}
                        >
                            시간 선택
                        </div>
                        <RadioGroup defaultValue="outlined" name="radio-buttons-group" orientation='horizontal'
                            onChange={(e: any) => {
                                setSelectedTime(e.target.value);
                            }}
                        >
                            <Radio value="lunch" label="점심" variant="soft" />
                            <Radio value="dinner" label="저녁" variant="soft" />
                        </RadioGroup>
                    </div>

                    <div>
                        <div
                            style={{
                                fontFamily: "Apple_SB",
                                marginBottom: "12px",
                                marginTop: "24px"
                            }}
                        >
                            음식 선택
                        </div>
                        {
                            (mealList && mealList.length > 0 && !manualGetLoading)
                                ?
                                <RadioGroup defaultValue="outlined" name="radio-buttons-group"
                                    onChange={(e: any) => {
                                        
                                        setSelectedMealId(e.target.value);
                                    }}
                                >
                                    {
                                        mealList.map((each: any, index: number) => (
                                            <Radio key={index} value={each.id} label={each.mealName + " - " + each.mealPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원"} variant="soft" />
                                        ))
                                    }
                                </RadioGroup>
                                :
                                ""
                        }
                    </div>

                    <Button fullWidth
                        disabled={finalDisabled}
                        sx={{
                            marginTop: "32px"
                        }}
                        onClick={() => {
                            manualOrder();
                        }}
                    >
                        수동 추가하기
                    </Button>

                </Sheet>
            </Modal>


            <div ref={componentRef}>
                <style type="text/css">
                    {
                        `
                    .print-only {
                        display: none;
                    }
                    @media print {
                        .print-only {
                            display: block;
                        }
                    }
                    `
                    }
                </style>
                <div className="print-only">
                    <div className={styles.printPaper}>
                        <div className={styles.printTitle}>
                            도시락 신청 리스트
                        </div>
                        <div className={styles.printSubTitle}>
                            ENJOY YOUR MEAL
                        </div>
                        <div className={styles.printJustHeight}>

                        </div>
                        <div className={styles.printDate}>
                            {
                                value[0]
                                &&
                                (value[0].getMonth() + 1) + "월 " + value[0].getDate() + "일 " + dayNumberToKorean(value[0].getDay()) + "요일 " + ((alignment === "lunch") ? "점심식사" : "저녁식사")
                            }
                        </div>
                        <div className={styles.printBody}>
                            {
                                printData &&
                                printData.map((each: any, index: number) => {

                                    return (
                                        <div className={`${styles.printEachMeal} ${index === printData.length - 1 ? styles.lastElement : ""}`} key={index}>
                                            <div className={styles.printEachMealBox}>
                                                <div>
                                                    <div className={styles.printEachMealRestaurant}>
                                                        {each.restaurant}
                                                    </div>
                                                    <div className={styles.printEachMealName}>
                                                        {each.mealName}
                                                    </div>
                                                </div>
                                                <div className={styles.printEachMealNumber}>
                                                    {each.users.length} EA
                                                </div>
                                            </div>
                                            <div className={styles.printEachMealUsersBox}>
                                                {
                                                    each.users.map((each2: any, index2: number) => (
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}>
                                                            <div className={styles.printEachMealUser} key={index2}>
                                                                {each2}
                                                            </div>
                                                            <div style={{
                                                                width: "24px",
                                                                height: "24px",
                                                                border: "1px solid black",
                                                            }}>

                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={styles.printBottom}>
                            <div style={{
                                marginBottom: "1.09rem"
                            }}>
                                <MealListSvg className={styles.printMealListSvg} />
                            </div>
                            <div>
                                <SunbaeLogoSvg className={styles.printSunbaeLogoSvg} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{
                        fontFamily: "Apple_B",
                        fontSize: "20px",
                        marginBottom: "16px"
                    }}>
                        단체메세지 보내기
                    </div>
                    <div>
                        <div style={{
                            fontFamily: "Apple_SB",
                            fontSize: "16px",
                            marginBottom: "6px"
                        }}>
                            지점 선택
                        </div>
                        <div style={{
                            marginTop: "12px",
                            marginBottom: "12px"
                        }}>
                            {
                            props.user && totalLocationData.filter(locationData => locationData.academy.includes(props.user.academy) && locationData.english !== "total").map((each, index: number) => (
                                <Radio
                                checked={messageLocation === each.english}
                                onChange={(e) => {
                                    handleChangeMessage("location", each.english);
                                }}
                                value={each.english}
                                label={each.korean}
                                sx={{
                                    marginRight: "16px",
                                    marginBottom: "12px"
                                }}
                                />
                            ))
                            }
                        </div>
                        <div style={{
                            fontFamily: "Apple_SB",
                            fontSize: "16px",
                            marginBottom: "6px"
                        }}>
                            시간 선택
                        </div>
                        <div style={{
                            marginTop: "12px",
                            marginBottom: "12px"
                        }}>
                            <Radio
                                checked={messageWhenType === "lunch"}
                                onChange={(e) => {
                                    handleChangeMessage("whenType", "lunch");
                                }}
                                value={"lunch"}
                                label="점심"
                                sx={{
                                    marginRight: "16px"
                                }}
                            />
                            <Radio
                                checked={messageWhenType === "dinner"}
                                onChange={(e) => {
                                    handleChangeMessage("whenType", "dinner");
                                }}
                                value={"dinner"}
                                label="저녁"
                            />
                        </div>
                        <div>
                        <div
                            style={{
                                fontFamily: "Apple_SB",
                                marginBottom: "12px",
                                marginTop: "24px"
                            }}
                        >
                            음식 선택
                        </div>
                        {
                            (messageLocation && messageWhenType) && 
                            messageMealList.map((each : any, index : number)=>(
                                <div style={{
                                    marginBottom: "12px"
                                }}>
                                    <Radio
                                    checked={messageMealName === each.mealName}
                                    onChange={(e) => {
                                        handleChangeMessage("mealName", each.mealName);
                                    }}
                                    value={each.mealName}
                                    label={`${each.mealName} (${each.userList.length}명)`}
                                    />
                                </div>
                            ))
                        }
                    </div>
                        

                        <div>
                            {
                                selectedLocations.map((eachLocation: any, index: number) => {
                                    if (eachLocation.names.length === 0) {
                                        return null;
                                    }

                                    var isNeedComma = true;

                                    if (index === selectedLocations.length - 1) {
                                        isNeedComma = false;
                                    } else if (selectedLocations[index + 1].names.length === 0) {
                                        isNeedComma = false;
                                    }

                                    return (
                                        <span>
                                            {eachLocation.location} {eachLocation.names.length}명
                                            {
                                                isNeedComma ? ", " : ""
                                            }
                                        </span>
                                    )
                                })
                            }
                        </div>
                        <div style={{
                            fontFamily: "Apple_SB",
                            fontSize: "16px",
                            marginBottom: "8px",
                            marginTop: "16px"
                        }}>
                            보낼 메세지
                        </div>
                        <div style={{
                            marginTop: "12px",
                            marginBottom: "12px"
                        }}>
                            <Radio
                                checked={whichType === "text"}
                                onChange={handleChange}
                                value={"text"}
                                label="텍스트"
                                sx={{
                                    marginRight: "16px"
                                }}
                            />
                            <Radio
                                checked={whichType === "img"}
                                onChange={handleChange}
                                value={"img"}
                                label="이미지"
                            />
                        </div>
                        {
                            whichType === "text" &&
                            <div>
                                <Textarea minRows={6} maxRows={6}
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                    }}
                                />
                            </div>
                        }
                        {
                            (whichType === "img" && !imgLoading && !imgSrc) &&
                            <form encType="multipart/formdata">
                                <label htmlFor="file">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "160px",
                                        border: "1px solid #e0e0e0",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        backgroundColor: isOver ? "#f0f0f0" : "#ffffff"
                                    }}
                                        onDrop={(e) => {
                                            handleDrop(e);
                                        }}
                                        onDragOver={(e) => { handleDragOver(e) }}
                                        onDragEnter={(e) => { handleDragEnter(e) }}
                                        onDragLeave={(e) => { handleDragLeave(e) }}
                                    >
                                        <Image
                                            fontSize="large"
                                            color="primary"
                                        />
                                        <input onChange={(e: any) => { fileOnChange(e) }} type="file" name="file" id="file" accept="image/*" hidden />
                                        <div style={{
                                            fontSize: "14px",
                                            marginTop: "8px"
                                        }}>
                                            클릭하거나 이미지를 드래그하여 업로드
                                        </div>
                                    </div>
                                </label>
                            </form>
                        }
                        {
                            (whichType === "img" && imgLoading) &&
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "160px",
                                border: "1px solid #e0e0e0",
                                borderRadius: "8px",
                                cursor: "pointer"
                            }}>
                                <CircularProgress />
                            </div>
                        }
                        {
                            (whichType === "img" && !imgLoading && imgSrc) &&
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "160px",
                                border: "1px solid #e0e0e0",
                                borderRadius: "8px",
                                cursor: "pointer"
                            }}>
                                <img
                                    src={`https://peetsunbae.com/${imgSrc.split("/public/")[1]}`}
                                    alt="img" style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain"
                                    }} />
                            </div>
                        }
                        {
                            whichType === "text" &&
                            <div style={{
                                marginTop: "8px"
                            }}>
                                <Button variant="soft" color="neutral"
                                    onClick={() => {
                                        copyToClipboard("#{이름+}");
                                    }}
                                >
                                    이름(성포함) 변수
                                </Button>
                                <Button variant="soft" color="neutral"
                                    onClick={() => {
                                        copyToClipboard("#{이름-}");
                                    }}
                                    style={{
                                        marginLeft: "8px"
                                    }}
                                >
                                    이름(성제외) 변수
                                </Button>
                                <Button variant="soft" color="neutral"
                                    onClick={() => {
                                        copyToClipboard("#{id}");
                                    }}
                                    style={{
                                        marginLeft: "8px"
                                    }}
                                >
                                    id 변수
                                </Button>
                            </div>

                        }

                        <div>
                            <Button variant="soft" color="primary"
                                style={{
                                    marginTop: "32px"
                                }}
                                size="lg"
                                fullWidth
                                startDecorator={
                                    <Send />
                                }
                                disabled={
                                    (whichType === "text" && !message) || (whichType === "img" && !imgSrc) || !messageLocation || !messageWhenType || !messageMealName
                                }
                                onClick={() => {
                                    letsSendTotalMessages();
                                }}
                            >
                                보내기
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading2}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


        </div>
    )
}

export default Totalmeals;