import React, { useEffect, useState } from 'react';
import styles from "../contentsstyle.module.css";
import { Button } from '@mui/joy';
import { KeyboardArrowRight } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { Autocomplete, Backdrop, CircularProgress, FormControlLabel, FormHelperText, Radio, RadioGroup } from "@mui/material";
import { returnAccountInfo } from '../../../../data/locationFuntion';
import { getFetch, postFetch } from '../../../../fetch/fetch';
import { tableUseStyle } from '../../controls/etc/tableusestyle';
import StyledDataGrid from '../../controls/newtodo/styles/tablestyles';
import { dateToDateString } from '../../controls/etc/etcfunctions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const styleCharge = {
    borderRadius: "6px",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
};

const ContentsStatus: React.FC<any> = (props) => {

    const [chargedAmount, setChargedAmount] = useState(0);
    const [availableCharge, setAvailableCharge] = useState(0);
    const [availableChargeString, setAvailableChargeString] = useState("");
    const [openCharge, setOpenCharge] = useState(false);
    const [chargePrev, setChargePrev] = useState(true);
    const [amount, setAmount] = useState(0);
    const [checkResult, setCheckResult] = useState([]);

    const [name, setName] = useState("");
    const [chargedBool, setChargedBool] = useState(false);

    const [update, setUpdate] = useState(0);

    const [loading, setLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);

    const [rows, setRows] = useState<any>([]);
    const [columns, setColumns] = useState([
        { field: "contentName", headerName: "모의고사 이름", width: 200 },
        { field: "price", headerName: "가격", width: 100 },
        { field: "createdAt", headerName: "신청일", width: 200 },
        { field: "selectedOptions", headerName: "선택한 옵션", width: 300 },
        { field: "cancelDue", headerName: "취소기한", width: 200 },
        {
            field: "cancelBtn", headerName: "취소시키기", width: 200, renderCell: (params: any) => {

                var cancelAvailable = false;

                const cancelDate = params.row.cancelDate;
                const cancelDateTime = cancelDate.getTime();

                const now = new Date();
                const nowTime = now.getTime();

                if (nowTime < cancelDateTime) {
                    cancelAvailable = true;
                }

                if (cancelAvailable) {

                    return (
                        <div>
                            <Button
                                variant='soft'
                                size='sm'
                                color='danger'
                                onClick={() => letsCancel(params.row.id)}
                            >
                                취소시키기
                            </Button>
                        </div>
                    )

                } else {

                    return (
                        <div>

                        </div>
                    )

                }

            }
        }
    ]);

    const notify1 = () => toast.success("🗑️ 모의고사 신청 취소가 정상 처리되었습니다!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const notify2 = () => toast.error("🥵 취소 시간이 지났습니다", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    })

    const letsCancel = async (id: number) => {

        if (!window.confirm("정말로 취소하시겠습니까?")) {
            return;
        }

        console.log(id);

        if (!id) {
            alert("id가 없습니다");
            return;
        }

        setCancelLoading(true);

        try {

            const result: any = await postFetch("/dashboard/payments/content/delete", {
                id: id
            });

            if (result.message === "success") {
                notify1();
                setUpdate(Math.random());
            }

            if (result.message === "TIME_OUT") {
                notify2();
            }

            setCancelLoading(false);

        } catch (e) {
            setCancelLoading(false);
            console.log(e);
        }
    }

    const classes = tableUseStyle();

    const handleCloseCharge = () => setOpenCharge(false);

    const handleOpenCharge = () => {
        setOpenCharge(true);
    }

    const handleChangeAmount = (e: any) => {
        const targetAmount = e.target.value;

        if (targetAmount && (+targetAmount > 0) && (+targetAmount) + (+chargedAmount) <= 100000) {
            setChargedBool(true);
        } else {
            setChargedBool(false);
        }

        setAmount(e.target.value);
    }

    const handleChangeName = (e: any) => {
        setName(e.target.value);
    }

    const handleChargePrev = async (e: any) => {
        setChargePrev(false);

        var token = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        fetch("https://peetsunbae.com/dashboard/restaurant/charge", {
            method: "POST",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                amount: amount,
                name: name
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {

                })
        })
    }

    useEffect(() => {

        getCoin();

    }, [update]);

    const getCoin = async () => {

        try {

            const result: any = await getFetch("/dashboard/restaurant/getcoin");

            if (result.message === "success") {

                setChargedAmount(result.chargedMoney);
                setAvailableCharge(100000 - result.chargedMoney);
                setAvailableChargeString(`최대 보유 금액 100,000원. 현재 충전 가능액 ${(100000 - result.chargedMoney).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`);

            }

        } catch (e) {
        }

    }

    useEffect(() => {

        setLoading(true);

        const start = async () => {

            try {

                const result: any = await getFetch("/dashboard/payments/content/checkMobile");

                if (result.message === "success") {
                    console.log(result.totalData);

                    const data = result.totalData;

                    if (!data) {
                        setLoading(false);
                        return;
                    }

                    const newRows: any = [];

                    data.forEach((eachData: any) => {

                        const oneRow: any = {};
                        oneRow.id = eachData.id;
                        oneRow.contentName = eachData.content.menuName;
                        oneRow.price = eachData.price ? eachData.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0 + "원";

                        const createdAtDate = new Date(eachData.createdAt);

                        oneRow.createdAt = dateToDateString(createdAtDate);

                        var selectedOptions = "";

                        if (eachData.selectedOptions) {
                            eachData.selectedOptions.forEach((eachOption: any, index: number) => {
                                selectedOptions += `${eachOption.optionName} : ${eachOption.optionItem}`;

                                if (index !== eachData.selectedOptions.length - 1) {
                                    selectedOptions += " / ";
                                }
                            })
                        }

                        oneRow.selectedOptions = selectedOptions;

                        const endDate = new Date(eachData.content.endDateTime);

                        const cancelDue = dateToDateString(endDate, true);

                        oneRow.cancelDate = endDate;
                        oneRow.cancelDue = cancelDue;

                        newRows.push(oneRow);

                    })

                    setRows([...newRows]);

                    setLoading(false);
                }

            } catch (e) {
                setLoading(false);
                console.log(e)
            }

        }

        start();

    }, [update]);



    return (
        <div>
            <div style={{
                height: "40px"
            }}></div>
            <div className={styles.moneyCharge}>
                <div>
                    - 충전금(선배페이) 잔액 : <span className={styles.money}>{chargedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span>
                </div>
                <Button
                    variant='soft'
                    onClick={handleOpenCharge}
                    endDecorator={
                        <KeyboardArrowRight />
                    }
                >
                    충전하기
                </Button>

            </div>

            <div className={classes.root} style={{ width: "1200px", height: "500px", backgroundColor: "white", marginTop: "30px" }}>
                <StyledDataGrid
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    density='compact'
                    hideFooter
                    disableRowSelectionOnClick
                    rowHeight={54}
                />
            </div>



            {/* 결제용 모달 */}
            <Modal
                open={openCharge}
                onClose={handleCloseCharge}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleCharge}>


                    <div className={styles.chargeTitle}>
                        충전하기
                    </div>

                    {chargePrev &&
                        <>
                            <div className={styles.chargeBoard}>
                                <FormControl fullWidth>
                                    <div className={styles.chargeAmount}>충전금액</div>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        value={amount}
                                        onChange={handleChangeAmount}
                                        startAdornment={<InputAdornment position="start">₩</InputAdornment>}
                                    />
                                    <FormHelperText>최대 보유 금액 100,000원</FormHelperText>
                                </FormControl>

                                <FormControl fullWidth>
                                    <div className={styles.chargeName}>입금자명</div>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        value={name}
                                        onChange={handleChangeName}
                                    />
                                    <div style={{ fontSize: "0.75rem", marginTop: "3px", color: "rgba(0,0,0,0.6)", fontWeight: 400, lineHeight: 1.66, marginLeft: "14px" }}>
                                        실제 입금자명과 일치해야 반영됩니다.
                                    </div>
                                </FormControl>


                            </div>

                            <div className={styles.chargeBtnDiv}>
                                {chargedBool ?
                                    <div onClick={handleChargePrev} className={styles.chargeBtn}>
                                        충전하기
                                    </div>
                                    :
                                    <div className={styles.disabledChargedBtn}>
                                        충전하기
                                    </div>
                                }

                            </div>
                        </>
                    }
                    {!chargePrev &&
                        <>
                            <div className={styles.accountInfoDiv}>
                                <div className={styles.accountInfoTitle}>
                                    입금정보
                                </div>
                                <div className={styles.accountInfoDescription}>
                                    <div className={styles.accountInfoBank}>
                                        <div>
                                            - 입금은행
                                        </div>
                                        <div>
                                            {
                                                (props.user && props.user.academy) &&
                                                returnAccountInfo(props.user.academy).bank
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.accountInfoNumber}>
                                        <div>
                                            - 계좌번호
                                        </div>
                                        <div>
                                            {
                                                (props.user && props.user.academy) &&
                                                returnAccountInfo(props.user.academy).account
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.accountInfoName}>
                                        <div>
                                            - 예금주
                                        </div>
                                        <div>
                                            {
                                                (props.user && props.user.academy) &&
                                                returnAccountInfo(props.user.academy).name
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.accountInfoAmount}>
                                        <div>
                                            - 신청금액
                                        </div>
                                        <div>
                                            {amount ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}원
                                        </div>
                                    </div>
                                    <div className={styles.accountInfoAddDesc}>
                                        <div>
                                            - 입금자명
                                        </div>
                                        <div>
                                            {name}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.accountInfoAdd}>
                                    - 30분안에 입금하시면 충전금 잔액에 반영됩니다.
                                </div>
                                <div className={styles.accountInfoAdd2}>
                                    - 실제 입금내역과 신청금액, 입금자명이 일치해야 반영됩니다.
                                </div>
                            </div>
                        </>
                    }
                </Box>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={cancelLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default ContentsStatus;