import { Add } from "@mui/icons-material";
import { Button } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { tableUseStyle } from "../../etc/tableusestyle";
import { DataGridPremium, GridFilterModel } from "@mui/x-data-grid-premium";
import NewTodoModal from "./newtodomodal";
import NewTodoModalForRegular from "./newtodomodalforregular";
import { getFetch, postFetch } from "../../../../../fetch/fetch";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Select, Option } from '@mui/joy';
import NewKakaoMessageModalRegular from "./newkakaomessagemodalregular";
import { addZeroIfOne } from "../../etc/etcfunctions";

const chatRoomNames = [
    "강남 데스크방",
    "강남 조교방",
    "대치점 2/3/6F 데스크 소통방",
    "대치 2F 데스크방",
    "대치 2F 조교방",
    "대치 3F 데스크방",
    "대치 3F 조교방",
    "대치 6F 데스크방",
    "대치 6F 조교방",
    "분당점 도시락방(사감방)",
    "송파점 데스크방",
    "송파점 도시락방(사감방)",
    "수능선배 분당점 메인데스크",
    "수능선배 수아쌤방",
    "(강남) 담임멘토팀장",
    "(대치) 담임멘토팀장",
    "(분당) 담임멘토팀장",
    "(종호) 멘토업무알려주는방",
    "w/보경시진",
]


const RegularKakao: React.FC<any> = (props) => {

    const [open, setOpen] = useState<boolean>(false);
    const [columns, setColumns] = useState<any>([
        {
            field: "regular",
            headerName: "주기",
            width: 80,
        },
        {
            field: "time",
            headerName: "상세날짜",
            width: 100
        },
        {
            field : "hourminute",
            headerName : "시간",
            width : 100
        },
        {
            field: "projectName",
            headerName: "채팅방",
            width: 250
        },
        {
            field: "description",
            headerName: "내용",
            width: 500
        },
        {
            field: "author",
            headerName: "작성자",
            width: 100
        },
        {
            field: "createdAt",
            headerName: "작성일",
            width: 100
        }
    ]);
    const [rows, setRows] = useState<any>([]);
    const classes = tableUseStyle();
    const [update, setUpdate] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
    const [alignment, setAlignment] = useState("total");
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                id: 1,
                field: "projectName",
                operator: "contains",
                value: ""
            },
        ],
    });

    useEffect(() => {

        console.log("gogogo");


        getRegularTodos();

    }, [update]);

    const getRegularTodos = async () => {

        try {

            setLoading(true);

            const result: any = await getFetch("/dashboard/todoist/getRegularKakaos");

            console.log(result);

            const data = result.data;

            const newRows: any = [];

            console.log(3);

            data.forEach((each: any) => {

                const oneRow: any = {};

                oneRow.id = each.id;

                oneRow.projectName = each.selectedProjectId;


                oneRow.regular = each.regularType === "daily" ? "매일" : each.regularType === "weekly" ? "매주" : each.regularType === "monthly" ? "매월" : "매년";

                if (each.regularType === "weekly") {
                    var koreanDayString = "";

                    switch (each.dayString) {
                        case "mon":
                            koreanDayString = "월요일";
                            break;
                        case "tue":
                            koreanDayString = "화요일";
                            break;
                        case "wed":
                            koreanDayString = "수요일";
                            break;
                        case "thu":
                            koreanDayString = "목요일";
                            break;
                        case "fri":
                            koreanDayString = "금요일";
                            break;
                        case "sat":
                            koreanDayString = "토요일";
                            break;
                        case "sun":
                            koreanDayString = "일요일";
                            break;
                    }

                    oneRow.time = koreanDayString
                }

                if (each.regularType === "monthly") {
                    oneRow.time = `${each.dateString}일`;
                }

                oneRow.hourminute = `${addZeroIfOne(each.selectedHours)}:${addZeroIfOne(each.selectedMinutes)}`;


                oneRow.author = each.name;

                oneRow.description = each.description;

                const createdAtDate = new Date(each.createdAt);

                oneRow.createdAt = `${createdAtDate.getFullYear()}-${createdAtDate.getMonth() + 1}-${createdAtDate.getDate()}`;

                newRows.push(oneRow);

            })

            console.log(newRows);

            setRows([...newRows]);

            setLoading(false);

        } catch (e) {
            console.log(e);
        }

    }

    const letsUpdate = () => {
        setUpdate(Math.random());
    }

    const letsDelte = async () => {

        if (!window.confirm("정말 삭제하시겠습니까?")) return;

        try {

            setLoading(true);

            await postFetch("/dashboard/todoist/deleteRegularKakaos", {
                id: selectedRowId
            });

            setLoading(false);

            letsUpdate();

        } catch (e) {
            console.log(e);
        }

    }

    const handleChange = (event: any, newAlignment: string | null) => {

        if (!newAlignment) return;

        const newFilterModel: any = filterModel;

        newFilterModel.items.forEach((item: any) => {

            if (item.field === "projectName") {
                item.value = newAlignment;
            }

            if (newAlignment === "total" && item.field === "projectName") {
                item.value = "";
            }

        })

        setFilterModel({ ...newFilterModel });

        setAlignment(newAlignment);

    };


    return (
        <div style={{
            width: "96%"
        }}>
            <div style={{
                fontFamily: "Apple_B",
                marginTop: "50px",
                fontSize: "18px",
                display: "flex",
                alignItems: "center",
            }}>
                <div style={{
                    marginRight: "18px"
                }}>
                    반복 카카오 등록 내역
                </div>
                <div>
                    <Button variant="solid" startDecorator={<Add />} size="sm" onClick={(e: any) => { setOpen(true); }}>
                        추가하기
                    </Button>
                </div>
            </div>
            <div style={{
                height: "30px"
            }}></div>
            <div className={classes.root} style={{ height: "500px", width: "100%" }}>
                <div style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "12px"
                }}>
                    <div>
                        <Select
                            sx={{
                                width: "350px"
                            }}
                            value={alignment}
                            onChange={(e, newValue: any) => {
                                if (newValue === undefined || newValue === null) {
                                    return;
                                }
                                
                                handleChange("e", newValue);
                            }
                            }>
                            <Option value={"total"}>전체 채팅방</Option>
                            {
                                chatRoomNames.map((each: any, index: number) => {

                                    return (
                                        <Option value={each} key={index}>{each}</Option>
                                    )

                                })
                            }
                        </Select>
                    </div>
                    <Button variant="soft" color="danger"
                        disabled={!selectedRowId}
                        onClick={letsDelte}
                    >
                        삭제하기
                    </Button>
                </div>
                <DataGridPremium
                    rows={rows}
                    columns={columns}
                    density="compact"
                    loading={loading}
                    onRowClick={(e: any) => {
                        console.log(e.id);
                        setSelectedRowId(e.id);
                    }}
                    filterModel={filterModel}
                    onFilterModelChange={(model) => {
                        setFilterModel(model);
                    }}
                />
            </div>

            <NewKakaoMessageModalRegular open={open} handleClose={() => { setOpen(false); }} apiKey={props.apiKey}
                projects={props.projects} letsUpdate={letsUpdate}
                chatRoomNames={chatRoomNames}
            />
        </div>
    )
}

export default RegularKakao;