import React, { useEffect, useState } from 'react';
import styles from "../contentsstyle.module.css";
import { Button } from '@mui/joy';
import { KeyboardArrowRight } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { Autocomplete, Backdrop, CircularProgress, FormControlLabel, FormHelperText, Radio, RadioGroup } from "@mui/material";
import { returnAccountInfo } from '../../../../data/locationFuntion';
import { getFetch, postFetch } from '../../../../fetch/fetch';
import { addZeroIfOne } from '../../controls/etc/etcfunctions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const styleCharge = {
    borderRadius: "6px",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
};

const styleCharge2 = {
    borderRadius: "6px",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
};


const height = 280;

const SubmitContents: React.FC<any> = (props) => {

    const [chargedAmount, setChargedAmount] = useState(0);
    const [availableCharge, setAvailableCharge] = useState(0);
    const [availableChargeString, setAvailableChargeString] = useState("");

    const [openCharge, setOpenCharge] = useState(false);
    const [openSubmit, setOpenSubmit] = useState(false);

    const [chargePrev, setChargePrev] = useState(true);

    const [amount, setAmount] = useState(0);
    const [name, setName] = useState("");
    const [chargedBool, setChargedBool] = useState(false);

    const [update, setUpdate] = useState(0);

    const [contents, setContents] = useState<any[]>([]);
    const [content, setContent] = useState<any>({});

    const [contentLoading, setContentLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

    const [disabled, setDisabled] = useState(true);

    const [toastMessage, setToastMessage] = useState("");

    const notify1 = () => toast.success("신청 완료!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const notify2 = () => toast.error("😔 이미 신청한 내역입니다.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    })

    const notify3 = () => toast.error("😔 결제기한이 아닙니다.", 
        {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    const notify4 = () => toast.error("😔 잔액이 부족합니다.", 
        {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light"
    });

    useEffect(() => {

        if (!content) {
            return;
        }

        if (!content.options) {
            return;
        }

        var isComplete = true;

        //isMandatory가 true인 옵션들이 maxNumber만큼 선택되었는지 확인
        content.options.forEach((option: any) => {

            if (option.isMandantory) {

                var currentNumber = 0;

                selectedOptions.forEach((selectedOption: any) => {
                    if (selectedOption.optionId === option.id) {
                        currentNumber++;
                    }
                });

                if (currentNumber < option.maxNumber) {
                    isComplete = false;
                }
            }

        });

        setDisabled(!isComplete);

    }, [selectedOptions, content]);


    const handleCloseCharge = () => setOpenCharge(false);

    const handleOpenCharge = () => {
        setOpenCharge(true);
    }

    const handleCloseSubmit = () => {

        setSelectedOptions([]);
        setContent({});
        setDisabled(true);

        setOpenSubmit(false);
    }

    const handleOpenSubmit = () => {
        setOpenSubmit(true);
    }

    const handleChangeAmount = (e: any) => {
        const targetAmount = e.target.value;

        if (targetAmount && (+targetAmount > 0) && (+targetAmount) + (+chargedAmount) <= 100000) {
            setChargedBool(true);
        } else {
            setChargedBool(false);
        }

        setAmount(e.target.value);
    }

    const handleChangeName = (e: any) => {
        setName(e.target.value);
    }

    const handleChargePrev = async (e: any) => {
        setChargePrev(false);

        var token = "";
        if (window.electron) {
            token = await window.electron.sendMessageApi.getToken();
        }

        fetch("https://peetsunbae.com/dashboard/restaurant/charge", {
            method: "POST",
            headers: { "Authorization": token, "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                amount: amount,
                name: name
            })
        }).then((response: any) => {
            response.json()
                .then((result: any) => {

                })
        })
    }

    useEffect(() => {

        getCoin();

    }, [update]);

    const getCoin = async () => {

        try {

            const result: any = await getFetch("/dashboard/restaurant/getcoin");

            if (result.message === "success") {

                setChargedAmount(result.chargedMoney);
                setAvailableCharge(100000 - result.chargedMoney);
                setAvailableChargeString(`최대 보유 금액 100,000원. 현재 충전 가능액 ${(100000 - result.chargedMoney).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`);

            }

        } catch (e) {
        }

    }

    useEffect(() => {

        getContents();

    }, []);

    const getContents = async () => {

        try {

            setContentLoading(true);

            const result: any = await getFetch("/dashboard/payments/getContents");
            console.log(result);

            if (result.message !== "success") {
                return;
            }

            const data = result.data;

            setContents([...data]);

            setContentLoading(false);

        } catch (e) {
            console.error(e);
        }

    }

    const submitOrder = async () => {

        try {

            if (disabled) {
                return;
            }

            if (!content) {
                return;
            }

            setLoading(true);

            const body = {
                content,
                selectedOptions,
                contentId: content.id
            }

            console.log(body);

            const result: any = await postFetch("/dashboard/payments/content/order", body);

            setLoading(false);


            if (result.message === "LACK_BALANCE") {
                notify4();
                return;
            }

            if (result.message === "TIME_OUT") {
                notify3();
                return;
            }

            if (result.message === "DUPLICATE") {
                notify2();
                return;
            }

            if (result.message === "success") {

                notify1();
                setUpdate(Math.random());
                handleCloseSubmit();

            }

            setLoading(false);

        } catch (e) {
            console.error(e);
        }

    }

    const handleOptionClick = (optionId: string, optionName: string, optionItem: string, isExist: boolean, maxNumber: number, order: number) => {

        var newSelectedOptions = [...selectedOptions];

        if (isExist) {

            newSelectedOptions = newSelectedOptions.filter((option: any) => !(option.optionId === optionId && option.optionItem === optionItem));

        } else {

            var currentNumber = 0;

            selectedOptions.forEach((option: any) => {
                if (option.optionId === optionId) {
                    currentNumber++;
                }
            });

            console.log(currentNumber);

            if (currentNumber >= maxNumber) {

                const firstOption = selectedOptions.find((option: any) => option.optionId === optionId);
                const firstOptionId = firstOption.optionId;
                const firstOptionItem = firstOption.optionItem;

                newSelectedOptions = newSelectedOptions.filter((option: any) => !(option.optionId === firstOptionId && option.optionItem === firstOptionItem));


            }
            newSelectedOptions.push({
                optionId: optionId,
                optionItem: optionItem,
                optionName: optionName,
                order: order
            });

        }

        newSelectedOptions.sort((a, b) => a.order - b.order);

        setSelectedOptions([...newSelectedOptions]);

    }




    return (
        <div>
            <div style={{
                height: "40px"
            }}></div>
            <div className={styles.moneyCharge}>
                <div>
                    - 충전금(선배페이) 잔액 : <span className={styles.money}>{chargedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span>
                </div>
                <Button
                    variant='soft'
                    onClick={handleOpenCharge}
                    endDecorator={
                        <KeyboardArrowRight />
                    }
                >
                    충전하기
                </Button>

            </div>

            {
                contentLoading
                    ?
                    <div className={styles.contentsLoading}>
                        <CircularProgress
                        />
                    </div>
                    :
                    ""
            }

            {/* <div style={{
                display: "flex",
                flexWrap: "wrap",
                width: "1200px",
                marginTop: "2rem",
                gap: "1rem",
                padding: "0 1rem",
                justifyContent: "space-between"
            }}> */}

            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                width : "1200px",
                gap: "1.1rem",
                padding: "0 1rem",
                marginTop: "2rem",
                justifyContent: "space-between"
            }}>

                {
                    !contentLoading ? contents.map((content, index) => {

                        const endDate = new Date(content.endDateTime);
                        //endDateString 8월 24일 22:00
                        const endDateString = `${endDate.getMonth() + 1}월 ${endDate.getDate()}일 ${addZeroIfOne(endDate.getHours())}:${addZeroIfOne(endDate.getMinutes())}`;

                        const startDate = new Date(content.startDateTime);
                        const startDateString = `${startDate.getMonth() + 1}월 ${startDate.getDate()}일 ${addZeroIfOne(startDate.getHours())}:${addZeroIfOne(startDate.getMinutes())}`;

                        return (
                            <div key={content.id} style={{
                                width: `${height}px`,
                                marginBottom: "1.4rem",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setContent(content);
                                    handleOpenSubmit();
                                }}
                            >
                                <div style={{
                                    width: "100%",
                                    height: `${height}px`,
                                    overflow: "hidden",
                                    borderRadius: "0.5rem",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    <img src={
                                        "https://peetsunbae.com" + content.src
                                    } alt="content" style={{
                                        width: "100%",
                                        borderRadius: "0.5rem",
                                    }} />
                                </div>
                                <div style={{
                                    marginTop: "0.4rem",
                                }}>
                                    <div style={{
                                        fontFamily: "Apple_B",
                                        fontSize: "1.2rem",
                                        letterSpacing: "-0.5px",
                                        lineHeight: "1.2",
                                    }}>
                                        {content.menuName}
                                    </div>
                                    <div style={{
                                        marginTop: "0.2rem",
                                        fontFamily: "Apple_M",
                                        color: "#6B7583",
                                        fontSize: "1.2rem"
                                    }}>
                                        {content.price ? `${content.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}원
                                    </div>
                                    <div style={{
                                        fontFamily: "Apple_L",
                                        color: "#6B7583",
                                        marginTop: "0.15rem",
                                        fontSize: "0.9rem",
                                    }}>
                                        신청시작 {startDateString}
                                    </div>
                                    <div style={{
                                        fontFamily: "Apple_L",
                                        color: "#6B7583",
                                        marginTop: "0.15rem",
                                        fontSize: "0.9rem",
                                    }}>
                                        신청기한 {endDateString}
                                    </div>
                                </div>
                            </div>
                        )

                    })
                        :
                        ""
                }

            </div>





            {/* 결제용 모달 */}
            <Modal
                open={openCharge}
                onClose={handleCloseCharge}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleCharge}>


                    <div className={styles.chargeTitle}>
                        충전하기
                    </div>

                    {chargePrev &&
                        <>
                            <div className={styles.chargeBoard}>
                                <FormControl fullWidth>
                                    <div className={styles.chargeAmount}>충전금액</div>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        value={amount}
                                        onChange={handleChangeAmount}
                                        startAdornment={<InputAdornment position="start">₩</InputAdornment>}
                                    />
                                    <FormHelperText>최대 보유 금액 100,000원</FormHelperText>
                                </FormControl>

                                <FormControl fullWidth>
                                    <div className={styles.chargeName}>입금자명</div>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        value={name}
                                        onChange={handleChangeName}
                                    />
                                    <div style={{ fontSize: "0.75rem", marginTop: "3px", color: "rgba(0,0,0,0.6)", fontWeight: 400, lineHeight: 1.66, marginLeft: "14px" }}>
                                        실제 입금자명과 일치해야 반영됩니다.
                                    </div>
                                </FormControl>


                            </div>

                            <div className={styles.chargeBtnDiv}>
                                {chargedBool ?
                                    <div onClick={handleChargePrev} className={styles.chargeBtn}>
                                        충전하기
                                    </div>
                                    :
                                    <div className={styles.disabledChargedBtn}>
                                        충전하기
                                    </div>
                                }

                            </div>
                        </>
                    }
                    {!chargePrev &&
                        <>
                            <div className={styles.accountInfoDiv}>
                                <div className={styles.accountInfoTitle}>
                                    입금정보
                                </div>
                                <div className={styles.accountInfoDescription}>
                                    <div className={styles.accountInfoBank}>
                                        <div>
                                            - 입금은행
                                        </div>
                                        <div>
                                            {
                                                (props.user && props.user.academy) &&
                                                returnAccountInfo(props.user.academy).bank
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.accountInfoNumber}>
                                        <div>
                                            - 계좌번호
                                        </div>
                                        <div>
                                            {
                                                (props.user && props.user.academy) &&
                                                returnAccountInfo(props.user.academy).account
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.accountInfoName}>
                                        <div>
                                            - 예금주
                                        </div>
                                        <div>
                                            {
                                                (props.user && props.user.academy) &&
                                                returnAccountInfo(props.user.academy).name
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.accountInfoAmount}>
                                        <div>
                                            - 신청금액
                                        </div>
                                        <div>
                                            {amount ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}원
                                        </div>
                                    </div>
                                    <div className={styles.accountInfoAddDesc}>
                                        <div>
                                            - 입금자명
                                        </div>
                                        <div>
                                            {name}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.accountInfoAdd}>
                                    - 30분안에 입금하시면 충전금 잔액에 반영됩니다.
                                </div>
                                <div className={styles.accountInfoAdd2}>
                                    - 실제 입금내역과 신청금액, 입금자명이 일치해야 반영됩니다.
                                </div>
                            </div>
                        </>
                    }
                </Box>
            </Modal>

            {/* 모의고사 신청용 모달 */}
            <Modal
                open={openSubmit}
                onClose={handleCloseSubmit}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleCharge2}>
                    <div className={styles.chargeTitle} style={{
                        marginBottom : "0px"
                    }}>
                        신청하기
                    </div>
                    <>
                        {
                            content ?
                                <div style={{
                                    maxHeight: "70vh",
                                    overflowY: "scroll",
                                    position: "relative",
                                    paddingBottom: "4.5rem"
                                }}
                                    className={styles.body}
                                >
                                    <div>
                                        <img src={"https://peetsunbae.com" + content.src} alt={content.title}
                                            style={{
                                                width: "100%",
                                                aspectRatio: "1/1",
                                                objectFit: "cover"
                                            }}
                                        />
                                    </div>
                                    <div style={{
                                        width: "90.4%",
                                        margin: "0 auto",
                                        paddingBottom: "1rem",
                                        paddingTop: "0.5rem"
                                    }}>
                                        <div style={{
                                            fontFamily: "Apple_B",
                                            letterSpacing: "-0.5px",
                                            lineHeight: "1.2",
                                            fontSize: "1.2rem",
                                        }}>
                                            {
                                                content.menuName
                                            }
                                        </div>
                                        <div style={{
                                            color: "#6b7583",
                                            marginTop: "0.7rem",
                                            fontSize: "0.9rem",
                                            fontFamily: "Apple_R"
                                        }}>
                                            <div>
                                                - {
                                                    content.subDescription1
                                                }
                                            </div>
                                            <div style={{
                                                marginTop: "0.3rem",
                                                fontFamily: "Apple_R"
                                            }}>
                                                - {
                                                    content.subDescription2
                                                }
                                            </div>
                                            <div style={{
                                                marginTop: "0.3rem",
                                                fontFamily: "Apple_R"
                                            }}>
                                                - 신청시작 : {
                                                    new Date(content.startDateTime).getMonth() + 1
                                                }월 {
                                                    new Date(content.startDateTime).getDate()
                                                }일 {
                                                    new Date(content.startDateTime).getHours()
                                                }시 {
                                                    new Date(content.startDateTime).getMinutes()
                                                }분
                                            </div>
                                            <div style={{
                                                marginTop: "0.3rem",
                                                fontFamily: "Apple_R"
                                            }}>
                                                - 신청기한 : {
                                                    new Date(content.endDateTime).getMonth() + 1
                                                }월 {
                                                    new Date(content.endDateTime).getDate()
                                                }일 {
                                                    new Date(content.endDateTime).getHours()
                                                }시 {
                                                    new Date(content.endDateTime).getMinutes()
                                                }분
                                            </div>
                                        </div>
                                        <div style={{
                                            marginTop: "1.2rem",
                                            fontFamily: "Apple_B",
                                            letterSpacing: "-0.5px",
                                            lineHeight: "1.2",
                                            fontSize: "1.4rem",
                                            display: "flex",
                                            justifyContent: "flex-end"
                                        }}>
                                            {
                                                content.price ? content.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
                                            }원
                                        </div>
                                        <div style={{
                                            marginTop: "1.2rem"
                                        }}>
                                            {
                                                content.options
                                                    ?
                                                    content.options.map((option: any, index: number) => {
                                                        return (
                                                            <div key={
                                                                index
                                                            }>
                                                                <div style={{
                                                                    color: "#6B7583",
                                                                    fontFamily: "Apple_R"
                                                                }}>
                                                                    {
                                                                        option.optionName
                                                                    }{
                                                                        option.maxNumber > 1 ?
                                                                            ` (${option.maxNumber}개 선택)`
                                                                            :
                                                                            ""
                                                                    } {
                                                                        option.isMandantory ?
                                                                            <span style={{
                                                                                color: "#FF9C00"
                                                                            }}>필수</span>
                                                                            :
                                                                            ""
                                                                    }
                                                                </div>
                                                                <div style={{
                                                                    //한줄에 3개씩 같은 높이, 넓이로
                                                                    display: "grid",
                                                                    gridTemplateColumns: "1fr 1fr 1fr",
                                                                    gridGap: "0.5rem",
                                                                    marginTop: "0.8rem",
                                                                    marginBottom: "1.5rem"
                                                                }}>
                                                                    {
                                                                        option.optionItems.map((item: any, optionIndex: number) => {

                                                                            var isSelected = false;

                                                                            const optionId = option.id;
                                                                            const optionItem = item;
                                                                            const optionName = option.optionName;
                                                                            const order = index;

                                                                            selectedOptions.forEach((selectedOption: any) => {
                                                                                if (selectedOption.optionId === optionId && selectedOption.optionItem === optionItem) {
                                                                                    isSelected = true;
                                                                                }
                                                                            });

                                                                            return (
                                                                                <div style={{
                                                                                    backgroundColor: isSelected ? "#333d4b" : "#f2f4f6",
                                                                                    borderRadius: "0.5rem",
                                                                                    padding: "0.5rem",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    color: isSelected ? "white" : "#333d4b",
                                                                                    fontFamily: "Apple_R",
                                                                                    whiteSpace: "nowrap",
                                                                                    overflowX: "hidden",
                                                                                }}
                                                                                    onClick={() => handleOptionClick(optionId, optionName, optionItem, isSelected, option.maxNumber, order)}
                                                                                    key={
                                                                                        optionIndex
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    ""
                                            }
                                        </div>
                                        <div style={{
                                            width: "90.4%",
                                            backgroundColor: "#3d50b0",
                                            color: "white",
                                            position: "fixed",
                                            height: "3rem",
                                            bottom: "1.5rem",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "0.5rem",
                                            left: "50%",
                                            transform: "translateX(-50%)",
                                            fontFamily: "Apple_SB",
                                            opacity: disabled ? 0.35 : 1
                                        }}
                                            onClick={() => submitOrder()}
                                        >
                                            {
                                                content.price ? content.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
                                            }원 결제하기
                                        </div>

                                    </div>

                                </div>
                                :
                                ""
                        }
                    </>

                </Box>
            </Modal>


            <Backdrop
                sx={{ color: '#fff', zIndex: 999999999 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default SubmitContents;