import { fil } from "date-fns/locale";

const updateTaskBySyncApi = async (type : string, taskId: any, data: any, apiKey : string) => {


    const uuidValue = crypto.randomUUID(); // 범용 고유 식별자 생성

    const response = await fetch('https://api.todoist.com/sync/v9/sync', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
            commands: JSON.stringify([
                {
                    type: type,
                    uuid: uuidValue,   // 위에서 생성한 uuid 값
                    args: {
                        id: taskId,      // 이동할 Task의 ID
                        ...data
                    },
                },
            ]),
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();

    console.log(result);

}

const AddTaskBySyncApi = async (content : string, project_id : string | null, section_id : string | null, apiKey : string) => {

    const uuidValue1 = crypto.randomUUID(); // 범용 고유 식별자 생성
    const uuidValue2 = crypto.randomUUID(); // 범용 고유 식별자 생성

    const sectionId = (!section_id || section_id === "none") ? {} : {section_id};


    const response = await fetch('https://api.todoist.com/sync/v9/sync', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
            commands: JSON.stringify([
                {
                    type: "item_add",
                    uuid: uuidValue1,   // 위에서 생성한 uuid 값
                    temp_id: uuidValue2,
                    args: {
                        content,
                        project_id,
                        ...sectionId
                    },
                },
            ]),
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();

    console.log(result);

}

const reorderTaskBySyncApi = async (type : string, data: any, apiKey : string) => {


    const uuidValue = crypto.randomUUID(); // 범용 고유 식별자 생성

    const response = await fetch('https://api.todoist.com/sync/v9/sync', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
            commands: JSON.stringify([
                {
                    type: type,
                    uuid: uuidValue,   // 위에서 생성한 uuid 값
                    args: {
                        items : [
                            ...data
                        ]
                    },
                },
            ]),
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();

    console.log(result);

}

function convertLocalPathToPublicUrl(localPath : string) {

    if(!localPath) return "";

    // '/public'를 기준으로 문자열을 분할합니다.
    // 예: "/home/centos/peetsunbae/dist/public/img/message/chat_img-123.jpeg"
    //     => splitted = ["/home/centos/peetsunbae/dist", "/img/message/chat_img-123.jpeg"]
    const splitted = localPath.split('/public');
  
    // 만약 '/public'라는 구분자가 없으면, 그냥 원본 경로를 반환(에러 처리)
    if (splitted.length < 2) {
      return localPath;
    }
  
    // splitted[1]의 값은 예: "/img/message/chat_img-123.jpeg"
    // 앞에 우리가 원하는 도메인을 붙여 최종 URL 만듭니다.
    // "https://peetsunbae.com/img/message/chat_img-123.jpeg"
    return 'https://peetsunbae.com' + splitted[1];
  }
  

const addANoteToATask = async (type : any, taskId : string, data : string, apiKey : string) => {

    const uuidValue1 = crypto.randomUUID(); // 범용 고유 식별자 생성
    const uuidValue2 = crypto.randomUUID(); // 범용 고유 식별자 생성

    const description = type === "text" ? data : "";

    const img_data = {
        file_attachment : {
            file_url : convertLocalPathToPublicUrl(data),
            file_name : "image.jpg",
            file_type : "image/jpeg",
        }
    }

    

    const fileAttachment = type === "img" ? img_data : {};

    const response = await fetch('https://api.todoist.com/sync/v9/sync', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
            commands: JSON.stringify([
                {
                    type: "note_add",
                    uuid: uuidValue1,   // 위에서 생성한 uuid 값
                    temp_id: uuidValue2,
                    args: {
                        item_id: taskId,
                        content: description,
                        ...fileAttachment
                    },
                },
            ]),
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();

    console.log(result);

}

export { updateTaskBySyncApi, reorderTaskBySyncApi, addANoteToATask, convertLocalPathToPublicUrl, AddTaskBySyncApi };